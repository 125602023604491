import React, {Component} from "react";
import i18n, {LNG_EN, LNG_KO} from "../../translations/i18n";
import {withTranslation} from "react-i18next";
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';

const TEXT_LANG_ENG = "ENGLISH";
const TEXT_LANG_KOR = "KOREAN";

class LangDropDownList extends Component {

  changeLanguage = (lngOption) => {
    if (lngOption) {
      i18n.changeLanguage(lngOption.value);
    }
  };

  render() {
    return (
        <>
          <SelectrWrapper value={this.props.i18n.language} onChange={this.changeLanguage}>
            <option value={LNG_KO}>{TEXT_LANG_KOR}</option>
            <option value={LNG_EN}>{TEXT_LANG_ENG}</option>
          </SelectrWrapper>
        </>
    );
  }
}

export default withTranslation()(LangDropDownList);