import React from "react";
import {withTranslation} from 'react-i18next';
import BasePopup from './BasePopup';
import addTableNoticeImage from '../../images/add-multiple-product-table.png';
import modifyTableNoticeImage from '../../images/modify-multiple-product-table.png';

class MultipleAddModifyProductNoticePopup extends React.Component {

  render() {
    return (
        <BasePopup
            className={"modal-lg"}
            open={this.props.open}
            title={this.props.isModify ? this.props.t("MODIFY_MULTIPLE_PRODUCTS_NOTICE_TITLE") : this.props.t("ADD_MULTIPLE_PRODUCTS_NOTICE_TITLE")}
            onClose={() => this.props.handleModalClose(false)}
            body={
              <>
                {!this.props.isModify && (
                    <>
                      <div className="rounded border mb-30 overflow-hidden">
                        <img src={addTableNoticeImage} alt={""}/>
                      </div>
                      <ol className="pl-16 line-height-default text-primary">
                        <li className="mb-15">
                      <span className="text-muted">
                      <strong className="text-primary">{this.props.t("MULTIPLEADDPRODUCT_NOTICE_1")}</strong><br/>
                        {this.props.t("MULTIPLEADDPRODUCT_NOTICE_1_1")}<br/>{this.props.t("MULTIPLEADDPRODUCT_NOTICE_1_2")}
                      </span>
                        </li>
                        <li className="mb-15">
                      <span className="text-muted">
                      <strong className="text-primary">{this.props.t("MULTIPLEADDPRODUCT_NOTICE_2")}</strong><br/>
                        {this.props.t("MULTIPLEADDPRODUCT_NOTICE_2_1")}
                      </span>
                        </li>
                        <li>
                      <span className="text-muted">
                      <strong className="text-primary">{this.props.t("MULTIPLEADDPRODUCT_NOTICE_3")}</strong><br/>
                        {this.props.t("MULTIPLEADDPRODUCT_NOTICE_3_1")}<br/>
                        {this.props.t("MULTIPLEADDPRODUCT_NOTICE_3_2")}
                      </span>
                        </li>
                      </ol>
                    </>)}
                {this.props.isModify && (
                    <>
                      <div className="rounded border mb-30 overflow-hidden">
                        <img src={modifyTableNoticeImage} alt={""}/>
                      </div>
                      <ul className="pl-20 line-height-default small mb-30 text-success">
                        <li className="mb-2">
                          <span className="text-success">{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_0_1")}</span>
                        </li>
                        <li>
                          <span className="text-success">{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_0_2")}</span>
                        </li>
                      </ul>
                      <ol className="pl-16 line-height-default text-primary">
                        <li className="mb-15">
						<span className="text-muted">
							<strong className="text-primary">{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_1")}</strong><br/>
              {this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_1_1")}<br/>{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_1_2")}
						</span>
                        </li>
                        <li className="mb-15">
						<span className="text-muted">
							<strong className="text-primary">{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_2")}</strong><br/>
              {this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_2_1")}
						</span>
                        </li>
                        <li className="mb-15">
						<span className="text-muted">
							<strong className="text-primary">{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_3")}</strong><br/>
              {this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_3_1")}<br/>
              {this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_3_2")}
						</span>
                        </li>
                        <li>
						<span className="text-muted">
							<strong className="text-primary">{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_4")}</strong><br/>{this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_4_1")}<br/>
              {this.props.t("MULTIPLEMODIFYPRODUCT_NOTICE_4_2")}
						</span>
                        </li>
                      </ol>
                    </>)}
              </>
            }/>
    );
  }
}

export default withTranslation()(MultipleAddModifyProductNoticePopup);