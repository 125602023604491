
export const itemDetails = {
  "TEST_PAYMENTS" : {
    classes: "info07",
    showDateRange: true,
    title: "TEST_PAYMENTS",
  },
  "TODAY_REVENUE" : {
    classes: "info01",
    showDateRange: false,
    title: "TOTAL_DAYS_REVENUE",
  },
  "NUMBER_SALES" : {
    classes: "info02",
    showDateRange: true,
    title: "NUMBER_OF_SALES",
  },
  "SALES_AMOUNT" : {
    classes: "info03 flex-100",
    showDateRange: true,
    title: "SALES_REVENUE",
  },
  "TODAY_REFUND" : {
    classes: "info04",
    showDateRange: false,
    title: "TOTAL_DAYS_REFUND",
  },
  "NUMBER_REFUND" : {
    classes: "info05",
    showDateRange: true,
    title: "NUMBER_OF_REFUNDS",
  },
  "REFUND_AMOUNT" : {
    classes: "info06 flex-100",
    showDateRange: true,
    title: "REFUNDED_AMOUNT",
  },
};

export const chartDetails = {
  "APP_SALES": {
    chartType: "PieChart",
    classes: "bg-white align-items-start",
    title: "APP_SALES_STATISTICS",
    options: {
      pieHole: 0.4,
      width: 560,
      height: 360,
      pieSliceText: 'none',
      colors: ['#3f6fb8', '#a287df'],
      chartArea: {left: 40, top: 20},
      legend: {position: 'left', textStyle: {color: '#222', fontSize: 14}},
    },
    noData: [ ['', ''],
      ['Number of Sales', 10],
      ['Number of Refunds', .05]],
    noDataClass: "ml-100 pl-12 mt-8"
  },
  "SALES_AMOUNT": {
    chartType: "BarChart",
    classes: "bg-white",
    title: "SALES_AMOUNT_STATISTICS",
    options: {
      chartArea: {left: 110, top: 40, width: '50%'},
      legend: {position: 'none', textStyle: {color: '#808080', fontSize: 13}},
      vAxis: {
        textStyle: {
          fontSize: 13,
          color: '#808080'
        }
      }
    },
    noData: [['', '', { role: 'style' }],
      ['Sales Revenue', 0, '#acd443'],
      ['Refunded Amount', 0, '#bf99d2']],
  }, "COUNTRY_SALES": {
    classes: "bg-white flex-100 info-dashboard-table",
    title: "SALES_RANKING_BY_COUNTRY",
  }, "APP_SALES_REFUNDS": {
    chartType: "ComboChart",
    classes: "bg-white info-graph flex-100 mb-0",
    title: "APP_SALES_REFUNDS",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      colors: ['#7fc9f1', '#acd443'],
      chartArea: {top: 10, width: '92%', height: '65%'},
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      seriesType: 'bars',
      series: {0: {type: 'area'}, 1: {type: 'area'}},
      hAxis: {
        title: '',
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      }
    },
    noData: [	['', 'Number of Sales', 'Number of Refunds'],
      ['11/01',  0, 0],
      ['11/02',  0, 0],
      ['11/03',  0, 0],
      ['11/04',  0, 0],
      ['11/05',  0, 0],
      ['11/06',  0, 0],
      ['11/07',  0, 0],
      ['11/08',  0, 0],
      ['11/09',  0, 0],
      ['11/10',  0, 0],
      ['11/11',  0, 0],
      ['11/12',  0, 0],
      ['11/13',  0, 0],
      ['11/14',  0, 0],
      ['11/15',  0, 0],
      ['11/16',  0, 0],
      ['11/17',  0, 0],
      ['11/18',  0, 0],
      ['11/19',  0, 0],
      ['11/20',  0, 0],
      ['11/21',  0, 0],
      ['11/22',  0, 0],
      ['11/23',  0, 0],
      ['11/24',  0, 0],
      ['11/25',  0, 0],
      ['11/26',  0, 0],
      ['11/27',  0, 0],
      ['11/28',  0, 0],
      ['11/29',  0, 0],
      ['11/30',  0, 0],],
    noDataClass: "mb-100 pb-20"
  },
};


