import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import ApplyAllBasePopup from './ApplyAllBasePopup';
import ValidPeriodDateTimePicker from '../datable/ValidPeriodDateTimePicker';

class ApplyAllFlexibleOfferPopup extends Component {

  constructor(props) {
    super(props);
    const startTime = new Date();
    startTime.setHours(0, 0);
    const endTime = new Date();
    endTime.setHours(23, 59);
    this.state = {
      promotionPrice: "",
      validPeriodStart: null,
      validPeriodEnd: null,
      promotionSubscriptionCycle: "",
      isTooltipOpen: false,
      timePeriodStart: startTime,
      timePeriodEnd: endTime,
      isApplyAllTooltipOpen: false,
    };
  }

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  handleApplyAllTooltipOpenClick = () => {
    this.setState({isApplyAllTooltipOpen: true});
  };

  handleApplyAllTooltipCloseClick = () => {
    this.setState({isApplyAllTooltipOpen: false});
  };

  handleChangePromotionPrice(value) {
    this.setState({
      promotionPrice: value
    });
  }

  handleChangePromotionSubscriptionCycle(value) {
    this.setState({
      promotionSubscriptionCycle: value
    });
  }

  handleTimePickerChange = (isStart, country, event) => {
    if (isStart) {
      this.setState({timePeriodStart: event});
    } else {
      this.setState({timePeriodEnd: event});
    }
  }

  handleChangeValidDate = (isStart, datepicker) => {
    if (isStart) {
      datepicker.setValue(new Date());
      this.setState({
        validPeriodStart: datepicker
      });
    } else {
      const monthAfter12 = new Date();
      monthAfter12.setFullYear(monthAfter12.getFullYear() + 1);
      datepicker.setValue(new Date(monthAfter12));
      this.setState({
        validPeriodEnd: datepicker
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        promotionPrice: this.props.promotionPriceApplyAll,
        promotionSubscriptionCycle: this.props.promotionSubscriptionCycleApplyAll
      });
      this.state.validPeriodStart.setValue(new Date(this.props.validPeriodStartApplyAll.getValue()));
      this.state.validPeriodEnd.setValue(new Date(this.props.validPeriodEndApplyAll.getValue()));
    }
  }

  render() {
    const defaultCountryInfo = this.props.productInformationByCountries[0];
    const defaultCountryData = this.props.productCountryData
        .filter(i => i.country === defaultCountryInfo.country);

    let originalPrice = "";
    if (defaultCountryData.length > 0) {
      originalPrice = defaultCountryData[0].productPrice;
    }
    return (
        <ApplyAllBasePopup
            open={this.props.open}
            handleModalClose={this.props.handleModalClose}
            readOnly={false}
            defaultCountryInformation={this.props.productInformationByCountries}
            applyBody={<>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("ORIGINAL_PRICE")}
                    </label>
                    <div className="col">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">{defaultCountryInfo.currencySymbol}</span>
                        </div>
                        <input type="text" className="form-control pr-2"
                               value={originalPrice} readOnly="readonly"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("PROMOTION_PRICE")}
                    </label>
                    <div className="col">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">{defaultCountryInfo.currencySymbol}</span>
                        </div>
                        <input
                            type="number"
                            value={this.state.promotionPrice}
                            className="form-control"
                            onChange={event => {
                              this.handleChangePromotionPrice(event.target.value);
                            }}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("PROMOTION_PERIOD")}
                    </label>
                    <div className="col col-form-label">
                      <ValidPeriodDateTimePicker
                        startDatePicker={this.state.validPeriodStart}
                        endDatePicker={this.state.validPeriodEnd}
                        startTimePicker={this.state.timePeriodStart}
                        endTimePicker={this.state.timePeriodEnd}
                        country={defaultCountryInfo.country}
                        handleDatepickerInit={this.handleChangeValidDate}
                        onTimePickerChange={this.handleTimePickerChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("BENEFIT_PERIOD")}
                    </label>
                    <div className="col-3">
                      <div className="input-group">
                        <input
                            type="number"
                            value={this.state.promotionSubscriptionCycle}
                            className="form-control"
                            onChange={event => {
                              this.handleChangePromotionSubscriptionCycle(event.target.value);
                            }}/>
                      </div>
                    </div>
                    <div className="col-1 my-auto ml-n3">
                      <span>{this.props.t(this.props.billingPeriodType)}</span>
                    </div>
                    <div className="col-5 my-auto ml-3">
                      <span onMouseEnter={this.handleTooltipOpenClick}
                            onMouseLeave={this.handleTooltipCloseClick}
                            className={`tooltips text-center ${this.state.isTooltipOpen && "show"}`}>
                        <span className="tooltip-toggle font-weight-bold">i</span>
                        <div className={`tooltips_body`} data-toggle="tooltip" data-placement="right">
                          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                          <a className={"tooltip-toggle close"} onClick={this.handleTooltipCloseClick}/>
                          {this.props.t("DISCLAIMER_FOR_BENEFIT_PERIOD")}<br/>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
            }
            buttonApplyAll={
              <>
                {defaultCountryData.length <= 0 &&
                    <span onMouseEnter={this.handleApplyAllTooltipOpenClick}
                          onMouseLeave={this.handleApplyAllTooltipCloseClick}
                          className={`tooltips text-center ${this.state.isApplyAllTooltipOpen && "show"}`}>
                    <span className="tooltip-toggle font-weight-bold">i</span>
                    <div className={`tooltips_body`} data-toggle="tooltip" data-placement="right">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a className={"tooltip-toggle close"} onClick={this.handleApplyAllTooltipCloseClick}/>
                      {this.props.t("DISCLAIMER_FOR_FLEXIBLE_OFFER_APPLY_ALL")}<br/>
                    </div>
                  </span>
                }
                <button
                    type="button"
                    className={`btn btn-120 ${defaultCountryData.length <= 0 ? "btn-success disabled" : "btn-primary"}`}
                    onClick={() => this.props.handleModalClose(true, this.state)}
                    disabled={defaultCountryData.length <= 0}
                >
                  {this.props.t("APPLY_ALL")}
                </button>
              </>
            }
        />
    )
  }
}

export default withTranslation()(ApplyAllFlexibleOfferPopup);