import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {dynamicUrlToConstantUrlMapper, getFirstDepthMenuEntityByUri} from "../../utils/dpiPortalMenusAndUtils";
import {withRouter} from "react-router-dom";
import AllAppsButton from "./AllAppsButton";
import {connect} from "react-redux";
import LangDropDownList from "./LangDropDownList";
import SignOutStatusPopup from './SignOutStatusPopup';
import AppList from './AppList';

class TopNaviBar extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.clock = null;
    this.state = {
      currentTime: null,
    }

  }

  render() {

    let menuKey = "";
    if (this.props.location.pathname === "/") {
      menuKey = "DASHBOARD"
    } else {
      // added dummy code due to SCWS-4220, in case the developer cp do not have permission ; getFirstDepthMenuEntityByUri returns undefined
      let menu = getFirstDepthMenuEntityByUri(dynamicUrlToConstantUrlMapper(this.props.location.pathname)) || {key: "dummy"};
      menuKey = menu.key;
    }

    return (
        <nav id="topNavi">
          <div className="fix">
            <h1 className="mb-0">{this.props.t(menuKey)}</h1>
            <AllAppsButton allAppsButtonRef={this.ref}/>
            <div className="user_util">
              <ul className="d-flex align-items-center mb-0">
                <li className="list-inline-item mr-0"><span className="text-secondary">{this.state.currentTime}</span></li>
                <li className="list-inline-item mr-2">
                  <LangDropDownList/>
                </li>
                <li className="list-inline-item">
                  <SignOutStatusPopup/>
                </li>
              </ul>
            </div>
          </div>
          <AppList allAppsButtonRef={this.ref}/>
        </nav>
    );
  }

  componentDidMount() {
    this.clock = setInterval(() => {
      const today = new Date().toISOString().split('T'); // today's date time in GMT 0
      const time = today[1].split('.');
      this.setState({
        currentTime: '[GMT 0] ' + today[0] + ' ' + time[0]
      })
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.clock)
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
    carousel: state.carousel
  };
};

export default connect(mapStateToProps, 0)(withTranslation()(withRouter(TopNaviBar)));