import React, {Component} from 'react';
import {connect} from "react-redux";
import SHeader from "../SHeader";
import LeftNaviBar from "../lnb/LeftNaviBar";
import Content from "../Content";
import TopNaviBar from "../tnb/TopNaviBar";
import TabManager from "../TabManager";
import GroupAddModifyPanel from "../panel/GroupAddModifyPanel";

class GroupAddModifyPage extends Component {
  render() {
    let wrapClassName = "clearfix";
    if (!this.props.sideDrawer.isSideDrawerOpen) {
      wrapClassName += " closeMenu";
    }

    return (
        <div id="wrap" className={wrapClassName}>
          <SHeader>
            <LeftNaviBar/>
          </SHeader>
          <Content>
            <TopNaviBar/>
            <TabManager content={<GroupAddModifyPanel key={this.props.isModify ? "3" : "4"} isModify={this.props.isModify}/>}/>
          </Content>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    sideDrawer: state.sideDrawer
  });
}
export default connect(mapStateToProps)(GroupAddModifyPage);