import React from "react";

// docs: https://github.com/AvroraTeam/DatePickerX#datepickerx-methods

class DatePickerX extends React.Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    return (
        <input
            ref={this.ref}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            className={`datepicker form-control rounded ${this.props.disabled ? "datepicker-disabled" : ""} ${this.props.className ? this.props.className : ""}`}
        />
    )
  }

  componentDidMount() {
    if (!this.ref.current?.DatePickerX) {
      return;
    }

    this.ref.current.DatePickerX.init({
      mondayFirst: false,
      todayButton: false,
      clearButton: false,
      weekDayLabels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      singleMonthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      format: 'yyyy-mm-dd',

      maxDate: this.props.maxDate || new Date(9999, 11, 31),
      minDate: this.props.minDate || null,
    });

    if (this.props.onInit) {
      this.props.onInit({
        setValue: (date) => this.ref.current.DatePickerX.setValue(date),
        getValue: () => this.ref.current.DatePickerX.getValue(),
        getMinDate: () => this.ref.current.DatePickerX.getMinDate(),
        getMaxDate: () => this.ref.current.DatePickerX.getMaxDate(),
      })
    }

    if (this.props.initialValue) {
      this.ref.current.DatePickerX.setValue(this.props.initialValue);
    }
  }

  componentWillUnmount() {
    const element = this.ref.current;
    if (!element) {
      return;
    }
    element.DatePickerX.remove();
  }
}

export default DatePickerX;