const checkEmptyRefundAmountForPartialRefund = (refundAmount, samsungRewardsRefundAmount) => {
  return (parseFloat(refundAmount) === 0 || refundAmount === "") && (parseFloat(samsungRewardsRefundAmount) === 0 || samsungRewardsRefundAmount === "");
}

const setZeroRefundAmountForPartialRefund = (value) => {
  if (parseFloat(value) === 0 || value=== "") {
    return "0";
  }
  return value;
}

const checkRefundAmountValidForPartialRefund = (refundAmount, samsungRewardsRefundAmount) => {
  if (refundAmount < 0.0 || samsungRewardsRefundAmount < 0.0) {
    return true;
  }
}

const checkRefundAmountExceedPaymentAmount = (refundAmount, samsungRewardsRefundAmount, paymentAmount, samsungRewardsUsedAmount) => {
  if ((refundAmount > paymentAmount && refundAmount !== 0)
      || (samsungRewardsRefundAmount > samsungRewardsUsedAmount && samsungRewardsRefundAmount !== 0)) {
    return true;
  }
}

export{checkEmptyRefundAmountForPartialRefund, setZeroRefundAmountForPartialRefund, checkRefundAmountValidForPartialRefund, checkRefundAmountExceedPaymentAmount};