import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import LeftNaviBarList from "./LeftNaviBarList";
import {URL_INDEX_PAGE} from "../../utils/urlConstants";
import {toggleSideDrawer} from "../../ducks/sideDrawer";

/**
 * This component is responsible for syncing sideDrawerOpen reduxStore <-> cookie
 */

// TODO Let LeftNaviBar sync sideDrawerOpen reduxStore <-> cookie. sideDrawer initial redux state should be overrided by cookie value via this component

class LeftNaviBar extends React.Component {
  render() {
    return (
        <header id="header">
          <div className="header_wrap">
            <Link to={URL_INDEX_PAGE} className="logo" title="Samsung SMART TV Digital Product Inventory">
              <span>Samsung SMART TV Digital Product Inventory</span>
            </Link>
            <LeftNaviBarList/>
            <button
                type="button"
                title="menu close"
                onClick={() => this.props.toggleSideDrawer()}
                className="header_menu_close"/>
          </div>
        </header>
    );
  }
}

const mapDispatchToProps = {
  toggleSideDrawer: toggleSideDrawer
};

export default connect(0, mapDispatchToProps)(withTranslation()(LeftNaviBar));
