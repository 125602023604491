import React from 'react';
import {withTranslation} from 'react-i18next';
import {Chart} from 'react-google-charts';
import {INTERVALS} from '../../utils/statisticsUtils';
import IntervalSwitcher from './IntervalSwitcher';
import StatisticsFooter from './StatisticsFooter';

class Graph extends React.Component {

  render() {
    const intervals = INTERVALS.map(item => {
      return {value: item, isActive: this.props.activeInterval === item, isLast: false}
    });
    intervals[intervals.length - 1].isLast = true;
    return (
        <div className="tabs-toggle-container">
          {this.props.showTypeSwitcher &&
          <>
            {this.props.typeDetails.map(item =>
                <div key={item.type} className={`tabs-toggle-content ${this.props.activeType === item.type && "show"}`}>
                  {this.props.activeType === item.type &&
                  <>
                    {item.showIntervalSwitcher && this.props.isMonthly &&
                    <IntervalSwitcher
                        intervals={intervals}
                        selectInterval={this.props.handleSelectInterval}/>
                    }
                    <div className="tabs-toggle-in-container">
                      <Chart
                          chartType={item.chartType}
                          rows={this.props.rows[this.props.activeType]}
                          columns={item.columns}
                          options={item.options}/>
                    </div>
                    <StatisticsFooter
                        isFooter={this.props.isFooter}
                        footer={this.props.footer}
                        downloadClick={this.props.handleDownloadClick}/>
                  </>}
                </div>)
            }
          </>
          }
          {!this.props.showTypeSwitcher &&
          <>
            {this.props.isMonthly &&
            <IntervalSwitcher
                intervals={intervals}
                selectInterval={this.props.handleSelectInterval}/>
            }
            <div className="tabs-toggle-in-container">
              <Chart
                  chartType={this.props.typeDetails.chartType}
                  rows={this.props.rows}
                  columns={this.props.typeDetails.columns}
                  options={this.props.typeDetails.options}/>
            </div>
            <StatisticsFooter
                isFooter={this.props.isFooter}
                footer={this.props.footer}
                downloadClick={this.props.handleDownloadClick}/>
          </>
          }
        </div>
    )
  }
}

export default (withTranslation()(Graph));
