import React, {Component} from 'react';
import {connect} from 'react-redux';
import Content from "../Content";
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import SignInGuide from "../SignInGuide";
import Logo from "../Logo";
import SignInButtonWrapper from "../SignInButtonWrapper";

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: props.sessionData.isAuthenticated,
    }
  }

  render() {
    let {csrfToken} = this.props.sessionData;
    return (
        <div id="wrap" className={"clearfix"}>
          <Content className={"w-100"}>
            <main id="contentMain">
              <div className="container">
                <Logo/>
                <div className="sign-box bg-white rounded d-flex align-items-center mt-50 justify-content-center">
                  <div className="sign-box-content text-center">
                    <SignInButtonWrapper innerDivStyle={"mb-40"}
                                         csrfToken={csrfToken}
                                         viaSignIn={true}/>
                  </div>
                </div>
                <SignInGuide t={this.props.t}/>
              </div>
            </main>
          </Content>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
  };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(SignInPage)));