import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import common_en from './en/common.json';
import continents from './en/continents.json';
import common_ko from './ko/common.json';

export const LNG_EN = 'en';
export const LNG_KO = 'ko';

i18next
    .use(LngDetector)
    .init({
      debug: true,
      interpolation: {escapeValue: false},
      fallbackLng: LNG_EN,
      supportedLngs: [LNG_EN, LNG_KO],
      resources: {
        'en': {
          "common": common_en,
          "continents": continents
        },
        'ko': {
          "common": common_ko,
          "continents": continents
        },
      },

      // have a common namespace used around the full app
      ns: ['common', 'continents'],
      defaultNS: 'common',

      // i18next-browser-languagedetector options
      detection: {
        order: ['cookie', 'querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      },
    });

export default i18next;