import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import React from 'react';
import {withTranslation} from 'react-i18next';

function renderSelector(filter, placeholder, selectedFilter, filterChangeHandler) {
  return (
      <SelectrWrapper
          className="form-control"
          clearable={true}
          placeholder={placeholder}
          value={selectedFilter ? selectedFilter.value : null}
          onChange={(selectedTag) => filterChangeHandler(filter.key, selectedTag)}>
        {filter.value.map(v => <option key={v.key} value={v.key}>{v.value}</option>)}
      </SelectrWrapper>
  );
}

class FilterPanel extends React.Component {

  parseFilter = (filter) => {
    return {
      key: filter.key,
      value: filter.value.map((e) => {
        return {
          key: e,
          value: this.props.t(e)
        }
      })
    }
  };

  render() {
    return (
        <div className="form-group row">
          <label className="col col-form-label">{this.props.label}</label>
          {this.props.filters.length === 1 &&
          <div className="col">
            <div className="input-group-inline-block">
              <div className="input-group">
                <div className="flex-100">
                  <div>
                    {renderSelector(this.parseFilter(this.props.filters[0]), this.props.t(this.props.filters[0].key), this.props.selectedFilters[0], this.props.onFilterChange)}
                  </div>
                </div>
                {this.props.showSearch &&
                <div className="input-group-append">
                  <button className="btn btn-primary rounded ml-10" type="submit" onClick={this.props.onSearchClick}>
                    {this.props.t('SEARCH')}
                  </button>
                </div>}
              </div>
            </div>
            {this.props.showSearch &&
            <button type="button" className="btn btn-icon btn-refrash" onClick={this.props.onResetClick}>
              <i className="icon icon-refrash"/>
            </button>}
          </div>
          }
          {this.props.filters.length === 2 &&
          <div className="col">
            <div className="row">
              <div className="col-8">
                <div className="input-group">
                  <div className="custom-selectbox">
                    {renderSelector(this.parseFilter(this.props.filters[0]), this.props.t(this.props.filters[0].key), this.props.selectedFilters[0], this.props.onFilterChange)}
                  </div>
                  <div className="input-group-append">
              <span className="input-group-text bg-white border-0">
                &nbsp;
              </span>
                  </div>
                  <div className="custom-selectbox">
                    {renderSelector(this.parseFilter(this.props.filters[1]), this.props.t(this.props.filters[1].key), this.props.selectedFilters[1], this.props.onFilterChange)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          {this.props.filters.length === 3 &&
          <div className="col">
            <div className="row">
              <div className="col-12">
                <div className="input-group">
                  <div className="custom-selectbox">
                    {renderSelector(this.parseFilter(this.props.filters[0]), this.props.t(this.props.filters[0].key), this.props.selectedFilters[0], this.props.onFilterChange)}
                  </div>
                  <div className="input-group-append">
              <span className="input-group-text bg-white border-0">
                &nbsp;
              </span>
                  </div>
                  <div className="custom-selectbox">
                    {renderSelector(this.parseFilter(this.props.filters[1]), this.props.t(this.props.filters[1].key), this.props.selectedFilters[1], this.props.onFilterChange)}
                  </div>
                  <div className="input-group-append">
              <span className="input-group-text bg-white border-0">
                &nbsp;
              </span>
                  </div>
                  <div className="custom-selectbox">
                    {renderSelector(this.parseFilter(this.props.filters[2]), this.props.t(this.props.filters[2].key), this.props.selectedFilters[2], this.props.onFilterChange)}
                  </div>
                  {this.props.showSearch &&
                  <div className="col-3">
                    <div className="clearfix">
                      <div className="float-left">
                        <button className="btn btn-primary" type="submit" onClick={this.props.onSearchClick}>{this.props.t("SEARCH")}</button>
                        <button type="button" className="btn btn-icon btn-refrash" onClick={this.props.onResetClick}><i className="icon icon-refrash"/></button>
                      </div>
                      {this.props.showDownload &&
                      <div className="float-right">
                        <button type="button" className="btn btn-outline-primary" onClick={this.props.onDownloadClick}>{this.props.t("DOWNLOAD")}</button>
                      </div>
                      }
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          }
          {this.props.filters.length === 7 &&
          <div className="col">
            <div className="row mb-3">
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[0]), this.props.t(this.props.filters[0].key), this.props.selectedFilters[0], this.props.onFilterChange)}
              </div>
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[1]), this.props.t(this.props.filters[1].key), this.props.selectedFilters[1], this.props.onFilterChange)}
              </div>
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[2]), this.props.t(this.props.filters[2].key), this.props.selectedFilters[2], this.props.onFilterChange)}
              </div>
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[3]), this.props.t(this.props.filters[3].key), this.props.selectedFilters[3], this.props.onFilterChange)}
              </div>
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[4]), this.props.t(this.props.filters[4].key), this.props.selectedFilters[4], this.props.onFilterChange)}
              </div>
            </div>
            <div className="row">
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[5]), this.props.t(this.props.filters[5].key), this.props.selectedFilters[5], this.props.onFilterChange)}
              </div>
              <div className="col">
                {renderSelector(this.parseFilter(this.props.filters[6]), this.props.t(this.props.filters[6].key), this.props.selectedFilters[6], this.props.onFilterChange)}
              </div>
              <div className="col"/>
              <div className="col"/>
              <div className="col"/>
            </div>
          </div>
          }
        </div>
    );
  }
}

export default withTranslation()(FilterPanel);
