import {URL_ERROR_PAGE, URL_SIGNIN_PAGE} from "./urlConstants";
import {toast} from "react-toastify";
import {triggerGetSessionData} from '../components/App';

export const handleHttpError = (t, history, error) => {
  const response = error.response;
  const SESSION_EXPIRED = "SESSION_EXPIRED";

  if (!error.response) {
    // redirect to this static page : http://www.signaturegroup.co.kr/DPI_ENG/error_page/CO-LD-001.html
    history.push({pathname: URL_ERROR_PAGE, search: '?code=check'});
    return;
  }

  if (response.data.toast) {
    toast(response.data.message);
    // for session expired scenario --
    if (Object.is(response.data.status, SESSION_EXPIRED)) {
      history.push(URL_SIGNIN_PAGE);
      triggerGetSessionData();
    }
    return;
  }

  if (Object.is(response.status, 500) || Object.is(response.status, 400) || Object.is(response.status, 404)) {
    history.push({pathname: URL_ERROR_PAGE, search: '?code=' + response.status});
  }
};