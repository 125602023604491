import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import BasePopup from "../popup/BasePopup";
import {toast} from "react-toastify";

export const GET_REMINDER_DETAILS_URL = "/settings/cp/url/detail.api";
export const SET_REMINDER_DETAILS_URL = "/settings/cp/url/update.api";

const STATE = {VIEW: 'VIEW', EDIT: 'EDIT'}; //enum for state

/* bad design agreement.
  (a) useYN = null , useYn = "Y" => do not send notification reminder"
  (b) ueYn = "N" => send notification */
const userReminderPreferenceTranslator = (value) => {
  if (value === null || value === undefined) {
    return false;
  } /* not set => user do not want notification*/
  if (value === "Y") {
    return false;
  }
  if (value === "N") {
    return true;
  }

  if (value === true) {
    return "N";
  }
  if (value === false) {
    return "Y";
  }
}

const ReminderURLComponent = (props) => {
  const [reminderDetails, setReminderDetails] = useState({
    cpAppId: props.selectedAppId,
    url: null,
    statusCode: null, //current status string
    useYN: false, //receive reminder checkbox
  });

  let reminderStatus = "INACTIVE";

  if (reminderDetails.statusCode === "APPROVED") {
    reminderStatus = "ACTIVE";
  } else {
    reminderStatus = reminderDetails.statusCode === null ? "INACTIVE" : reminderDetails.statusCode;
  }

  const urlInputRef = useRef();
  const checkboxInputRef = useRef();
  const [componentState, setComponentState] = useState(STATE.VIEW);
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    setReminderDetails(
        {
          ...reminderDetails,
          cpAppId: props.selectedAppId
        });

    setComponentState(STATE.VIEW);
  }, [props.selectedAppId]);

  function getReminderDetaislFromUrl() {
    const getReminderDetails = async () => {
      //TODO: Get axios instance from some command interface (where timeout is also added in get/post + error message for post request)
      try {
        return await axios.get(GET_REMINDER_DETAILS_URL, {
          params: {
            cpAppId: reminderDetails.cpAppId
          }
        });
      } catch (e) {
        console.log("Issue happening during GET operations ", GET_REMINDER_DETAILS_URL);
        console.error(e);
        return "Failed to get data";
      }
    }

    /* option1: block the useEffect + then set data */
    /* option2: do not block the useEffect + set data  <= we are doing this below as do not use await below*/
    getReminderDetails()
        .then(response => response.data)
        .then(data => {
          setReminderDetails({
            ...reminderDetails,
            // cpAppId: data?.cpAppId || null, //Its set by app dropdown => do not include it.
            url: data?.url || null,
            statusCode: data?.statusCode || null,
            useYN: userReminderPreferenceTranslator(data?.useYN)
          });

          urlInputRef.current.value = data?.url || null;
          checkboxInputRef.current.checked = userReminderPreferenceTranslator(data.useYN); //TODO: fix it to move as cascading effect.

        })
        .catch(e => {
          console.log("error message: ", e);
        });
  }

  useEffect(() => {
    getReminderDetaislFromUrl();

  }, [reminderDetails.cpAppId]);

  const handleSaveOperation = () => {
    //Step1: Validate Entry
    //Step2: Issue update request to backend
    //Step3: Show success/failure message

    const isHttpValid = (str) => {
      try {
        const newUrl = new URL(str);
        // return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        console.log("url by URL(): ", newUrl);
        return newUrl.protocol === 'https:';
      } catch (err) {
        console.error("Cannot make valid url from input");
        return false;
      }
    }

    const isUrlValid = isHttpValid(urlInputRef.current.value);
    if (isUrlValid === false) {
      toast("Input URL is not valid. Please Check");
      return;
    }
    setModalState(true);
  }

  const handleModalSaveConfirmation = () => {

    const nurl = new URL(urlInputRef.current.value);
    const ncheckboxvalue = checkboxInputRef.current.checked;

    axios.post(SET_REMINDER_DETAILS_URL, {
      cpAppId: reminderDetails.cpAppId,
      url: nurl,
      useYN: userReminderPreferenceTranslator(ncheckboxvalue),
    })
        .then(response => {
          console.log("success response: ", response);

          getReminderDetaislFromUrl();
          toast("Request Successful");
        })
        .catch(e => {
          console.log("error: ", e);
          toast("Request Failed");
        });

    setModalState(false);
    setComponentState(STATE.VIEW);
  }

  return (
      <>
        <div className="clearfix mb-20 mt-60">
          <h2 className="mb-0"><strong>{"Reminder URL Setting"}</strong></h2>
          <p>
            Please register an URL for getting reminder notification (e.g purchases, subscription, cancellation, etc.).
            Please wait for 1-2 working days for the URL to be allowed to take effect.
          </p>
        </div>

        <div className="form-group-wrap">
          <div className="form-group row">
            <label className="col col-form-label">URL</label>
            <div className="col">
              <div className="input-group">
                <input type="url"
                       className="form-control"
                       aria-describedby="emailHelp"
                       placeholder="Enter Reminder URL "
                       ref={urlInputRef}
                       disabled={componentState === STATE.VIEW}
                       defaultValue={reminderDetails.url || ''}
                />
                <div className="input-group-append">
                  <button className="btn btn-primary"
                          type="button"
                          onClick={() => setComponentState(STATE.EDIT)}
                          hidden={componentState === STATE.EDIT}>
                    Edit
                  </button>
                </div>
              </div>
              <label className={`col-form-label`}>
                Status: {reminderStatus}
              </label>
              <div className="form-group-row" hidden={false}>
                <input
                    className="btn-checkbox"
                    type="checkbox"
                    ref={checkboxInputRef}
                    defaultChecked={reminderDetails.useYN}
                    disabled={componentState === STATE.VIEW}/>
                <label className="ml-6 mr-10">
                  Stop Sending Reminder Notification
                </label>
              </div>
              <div>
                <button
                    onClick={handleSaveOperation}
                    hidden={componentState === STATE.VIEW}
                    className="btn btn-primary mr-2" type="button">
                  Save
                </button>
                <button
                    onClick={() => {
                      checkboxInputRef.current.checked = reminderDetails.useYN;
                      urlInputRef.current.value = reminderDetails.url;
                      setComponentState(STATE.VIEW);
                    }}
                    hidden={componentState === STATE.VIEW}
                    className="btn btn-primary" type="button">
                  Cancel
                </button>
              </div>
            </div>

          </div>
        </div>
        <BasePopup
            open={modalState}
            className="modal-sm"
            title={"Confirmation"}
            onClose={() => setModalState(false)}
            body={
              <>
                <p>Do You Want to Change?</p>
              </>
            }
            footer={
              <>
                <button type="button" className="btn btn-secondary" onClick={handleModalSaveConfirmation}>
                  {("CONFIRM")}
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={() => setModalState(false)}>
                  {("CANCEL")}
                </button>
              </>
            }/>

      </>);
}
export default withTranslation()(ReminderURLComponent);
