import React, {Component} from "react";
import {startLoading, stopLoading} from "../../ducks/loading";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {
  AXIOS_CLIENT_TIMEOUT,
  FREE_TRIAL_SUBSCRIPTION,
  SUBSCRIPTION,
  SUCCESS,
} from '../../utils/globalConstants';
import axios from "axios";
import {
  getExpirationFromValidDates,
  productAttributes,
  productStatusCode2Key,
  productTypeCode2Key,
  visibilityCode2Key,
} from '../../utils/productTypesAndAttributes';
import {getAppFromSessionData} from '../../ducks/sessionData';
import {
  API_PRODUCT_TAX_CATEGORY_LIST,
  URL_ADD_FLEXIBLE_OFFER_API,
  URL_GET_COUNTRY_DATA_API,
  URL_GET_COUNTRY_INFORMATION_API,
  URL_DETAIL_FLEXIBLE_OFFER_API,
  URL_PRODUCT_DETAIL,
  URL_PRODUCT_LIST
} from "../../utils/urlConstants";
import {handleHttpError} from '../../utils/ErrorUtils';
import SelectrWrapper from "../pluginwrapper/SelectrWrapper";
import DataTableWrapperForFlexibleOffer from "../datable/DataTableWrapperForFlexibleOffer";
import {fetchCountries, fetchTaxCategoryList} from '../../utils/CountryListUtils';
import {
  getCountriesInformation,
  isCountriesInformationChanged,
  loadCountriesInformation
} from '../../ducks/countriesInformation';
import {isSelectedAppChanged} from '../../ducks/carousel';
import CountryListPopUp from '../popup/CountryListPopUp';
import {toast} from 'react-toastify';
import ApplyAllFlexibleOfferPopup from '../popup/ApplyAllFlexibleOfferPopup';
import BasePopup from "../popup/BasePopup";

class FlexibleOfferPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      isDataLoaded: false,
      savePopup: false,
      productId: "",
      productDescription: "",
      selectedProductType: null,
      selectedProductStatus: null,
      selectedVisibility: "HIDE",
      selectedExpiration: "NOT_APPLICABLE",
      selectedReturningUserEligibility: true,
      defaultCountry: null,
      productDeletable: null,
      isCountryListPopupOpen: false,
      countryData: [],
      selectedCountries: [],
      startDatePicker: null,
      endDatePicker: null,
      selectedCountry: '',
      modalOpen: false,
      isTooltipOpen: false,
      promotionPriceApplyAll: '',
      validPeriodStartApplyAll: {
        getValue: function () {
          return (new Date()).toISOString().slice(0, 10);
        }
      },
      validPeriodEndApplyAll: {
        getValue: function () {
          return (new Date()).toISOString().slice(0, 10);
        }
      },
      promotionSubscriptionCycleApplyAll: '',
    };
  }

  toTwoDigit = (data) => {
    if (data < 10) {
      return "0" + data;
    }
    return data;
  }

  initData = () => {
    if (this.props.sessionData.apps.length && this.props.countriesInformation.length) {
      const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
      this.props.startLoading();
      this.httpClient
          .get(URL_DETAIL_FLEXIBLE_OFFER_API, {params: {productId: this.props.match.params.productId}})
          .then(response => {
            const productDetail = response.data;
            let flexibleOfferInformationByCountries;
            if (response.data.flexibleOfferInformationByCountries) {
              flexibleOfferInformationByCountries = productDetail.flexibleOfferInformationByCountries.map(country => {
                let promotionStart = new Date(country.promotionStartDate);
                let promotionEnd = new Date(country.promotionEndDate);
                let countriesInformation = getCountriesInformation(this.props.countriesInformation, country.countryCode);
                country.initialValues = {
                  country: country.countryCode,
                  countryCode: country.countryCode,
                  productName: country.productName,
                  currencyCode: countriesInformation?.currency,
                  productPrice: country.productPrice,
                  promotionPrice: country.promotionPrice,
                  promotionStartDate: promotionStart.toISOString().slice(0, 10) + " " + this.toTwoDigit(promotionStart.getHours()) + "." + this.toTwoDigit(promotionStart.getMinutes()),
                  promotionEndDate: promotionEnd.toISOString().slice(0, 10) + " " + this.toTwoDigit(promotionEnd.getHours()) + "." + this.toTwoDigit(promotionEnd.getMinutes()),
                  benefitCycleCount: country.promotionSubscriptionCycle
                };
                country.new = false;
                country.isSelected = false;
                country.isActive = 'Y';
                country.country = country.countryCode;
                country.currencyCode = countriesInformation?.currency;
                country.isDefaultCountry = country.countryCode === app.defaultCountry;
                country.validPeriodStart = {
                  getValue: function () {
                    return promotionStart.toISOString().slice(0, 10);
                  }
                };
                country.validPeriodEnd = {
                  getValue: function () {
                    return promotionEnd.toISOString().slice(9, 10);
                  }
                }
                country.promotionStartTime = promotionStart;
                country.promotionEndTime = promotionEnd;
                return country;
              })
            } else {
              flexibleOfferInformationByCountries = []
            }
            const availableCountryCodes = productDetail.informationByCountries.map(countryInformation => countryInformation.country)
            const filteredCountryData = this.filterCountryDataByAvailableCountryCode(availableCountryCodes)
            if (!this.state.isDataLoaded) {
              this.setState({
                countryData: filteredCountryData,
                flexibleOfferInformationByCountries: flexibleOfferInformationByCountries,
                selectedCountries: flexibleOfferInformationByCountries.map(item => item.countryCode),
                defaultFlexibleOfferInformationByCountries: flexibleOfferInformationByCountries.map(item => item.countryCode),
                initialFlexibleOfferInformationByCountries: flexibleOfferInformationByCountries,
                informationByCountries: productDetail.informationByCountries,
                productId: productDetail.cpProductId,
                originalProductId: productDetail.productId,
                productDescription: productDetail.productDescription,
                selectedVisibility: visibilityCode2Key[productDetail.visibility],
                selectedProductStatus: productStatusCode2Key[productDetail.productStatus],
                selectedProductType: productTypeCode2Key[productDetail.productType],
                selectedExpiration: getExpirationFromValidDates(productDetail.validStartDate, productDetail.validEndDate),
                subscriptionGroupId: productDetail.subscriptionGroupId,
                billingPeriodType: this.billingPeriod[productDetail.cycle],
                selectedReturningUserEligibility: productDetail.returningUserEligibility,
                isDataLoaded: true
              }, () => this.setDatePickerInitValues())
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  filterCountryDataByAvailableCountryCode = (availableCountryCodes) => {
    return this.state.countryData.reduce((filteredCountryData, continentInformation) => {
      const filteredCountries = continentInformation.countries.filter(country => availableCountryCodes.includes(country));
      if (filteredCountries.length > 0) {
        filteredCountryData.push({
          continent: continentInformation.continent,
          countries: filteredCountries
        })
      }
      return filteredCountryData
    }, [])
  }

  billingPeriod = {
    "W": "BILLING_PERIOD_UNIT_IN_WEEKS",
    "M": "BILLING_PERIOD_UNIT_IN_MONTHS",
    "Y": "BILLING_PERIOD_UNIT_IN_YEARS"
  };

  initCountryData = () => {
    this.props.startLoading();
    fetchTaxCategoryList(API_PRODUCT_TAX_CATEGORY_LIST, this.props.t, this.props.history).then(response => {
      this.setState({taxCategoryData: response});
      fetchCountries(URL_GET_COUNTRY_DATA_API, this.props.t, this.props.history)
          .then(countryData => {
            this.setState({countryData: countryData});
            fetchCountries(URL_GET_COUNTRY_INFORMATION_API, this.props.t, this.props.history)
                .then((countryInformation => {
                  this.props.stopLoading();
                  this.props.loadCountriesInformation(countryInformation);
                  this.initData();
                }));
          });
    })
  };

  handleAddCountry = () => {
    this.setState({isCountryListPopupOpen: true});
  };
  handleCountryListModalClose = (isSave, selectedCountries) => {
    this.setState({isCountryListPopupOpen: false});
    if (!isSave) {
      return;
    }
    this.setState({selectedCountries: selectedCountries});
    this.setState({flexibleOfferInformationByCountries: this.processSelectedCountries(selectedCountries)});
  };

  processSelectedCountries = (selectedCountryCodes) => {
    const countriesExistedInFlexibleOffer = this.state.flexibleOfferInformationByCountries
      .filter(i => selectedCountryCodes.includes(i.country) || !i.new)
      .map(i => {
        i.isActive = 'N';
        return i;
      });

    for (const countryCode of selectedCountryCodes) {
      let countryNotExist = true;
      countriesExistedInFlexibleOffer.forEach(item => {
        if (item.countryCode === countryCode) {
          item.isActive = 'Y';
          countryNotExist = false;
        }
      });

      if (countryNotExist) {
        const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
        let countriesInformation = this.state.informationByCountries.find(item => item.country === countryCode);
        const startTime = new Date();
        startTime.setHours(0,0);
        const endTime = new Date();
        endTime.setHours(23, 59);

        countriesExistedInFlexibleOffer.push({
          new: true,
          countryCode: countryCode,
          country: countryCode,
          isDefaultCountry: countryCode === app.defaultCountry,
          isSelected: false,
          isActive: "Y",
          productName: countriesInformation.productName,
          currencyCode: countriesInformation.currencyCode,
          productPrice: countriesInformation.productPrice,
          promotionPrice: "",
          promotionStartDate: {
            getValue: function () {
              return (new Date()).toISOString().slice(0, 10);
            }
          },
          promotionEndDate: {
            getValue: function () {
              return (new Date()).toISOString().slice(0, 10);
            }
          },
          promotionStartTime: startTime,
          promotionEndTime: endTime,
          promotionSubscriptionCycle: "",
        })
      }
    }

    return countriesExistedInFlexibleOffer;
  };

  handleCountryProductPriceChange = (countryCode, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
          if (item.countryCode === countryCode) {
            item.promotionPrice = input
          }
          return item;
        })
      }
    });
  };

  handleCountryBenefitPeriodChange = (countryCode, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
          if (item.countryCode === countryCode) {
            item.promotionSubscriptionCycle = input
          }
          return item;
        })
      }
    });
  }

  handleCheck = (country) => {
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
          if (item.countryCode === country) {
            item.isSelected = !item.isSelected;
          }
          return item;
        })
      }
    });
  };

  handleTimePickerChange = (isStart, country, event) => {
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
          if (item.countryCode === country) {
            if (isStart) {
              item.promotionStartTime = event;
            } else {
              item.promotionEndTime = event;
            }
          }
          return item;
        })
      }
    });
  }

  handleCheckAll = () => {
    const allChecked = this.state.flexibleOfferInformationByCountries.every(item => item.isSelected);
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
          item.isSelected = !allChecked;
          return item;
        })
      }
    });
  };

  checkTableData = () => {
    let priceFlag = true;
    let validPeriodFlag = true;
    let validPeriodEndFlag = true;
    let benefitPeriodFlag = true;

    for (const item of this.state.flexibleOfferInformationByCountries) {
      if (item.isActive === 'N') {
        continue
      }
      let minimumSaleableAmount = getCountriesInformation(this.props.countriesInformation, item.countryCode)?.minimumSaleableAmount;
      let maximumSaleableAmount = getCountriesInformation(this.props.countriesInformation, item.countryCode)?.maximumSaleableAmount;

      if (!(item.promotionPrice >= minimumSaleableAmount && item.promotionPrice <= maximumSaleableAmount)) {
        priceFlag = false;
      }
      let validPeriodStart = new Date(item.validPeriodStart.getValue());
      validPeriodStart.setHours(item.promotionStartTime.getHours(), item.promotionStartTime.getMinutes());

      let validPeriodEnd = new Date(item.validPeriodEnd.getValue());
      validPeriodEnd.setHours(item.promotionEndTime.getHours(), item.promotionEndTime.getMinutes());

      if (validPeriodStart >= validPeriodEnd) {
        validPeriodFlag = false;
      }

      const endOfYesterday = new Date()
      endOfYesterday.setTime(endOfYesterday.getTime() - 86400000)
      endOfYesterday.setHours(23, 59, 59, 999)
      if (endOfYesterday >= validPeriodEnd) {
        validPeriodEndFlag = false;
      }

      if (item.promotionSubscriptionCycle === "" || item.promotionSubscriptionCycle < 0 || item.promotionSubscriptionCycle > 30000) {
        benefitPeriodFlag = false;
      }

      if (!(priceFlag && validPeriodFlag && validPeriodEndFlag && benefitPeriodFlag)) {
        break;
      }
    }

    let alertMessage = "";
    if (!priceFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_PROMOTION_PRICE_NOT_VALID");
    }
    if (!validPeriodFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_PROMOTION_PERIOD_NOT_VALID");
    }
    if (!validPeriodEndFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_PROMOTION_END_DATE_NOT_VALID");
    }
    if (!benefitPeriodFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_BENEFIT_PERIOD_NOT_VALID");
    }

    if (!(priceFlag && validPeriodFlag && validPeriodEndFlag && benefitPeriodFlag)) {
      toast(alertMessage);
      return false;
    }
    return true;
  };

  handleSave = () => {
    this.setState({savePopup: false});

    if (this.checkTableData()) {
      let params = {
        cpAppId: this.props.carousel.selectedAppId,
        productId: this.state.originalProductId,
        eligibleForReturningUserYN: this.state.selectedReturningUserEligibility ? 'Y' : 'N',
        settingsByCountries: this.state.flexibleOfferInformationByCountries
            .filter(item => {
              if (item.isActive === 'N') {
                return this.state.defaultFlexibleOfferInformationByCountries.includes(item.countryCode)
              } else if (item.isActive === 'Y') {
                return this.state.informationByCountries.map(countryInformation => countryInformation.country).includes(item.countryCode)
              } else {
                console.error("This should never happened.")
                return false;
              }
            })
            .map(item => {
              if (item.isActive === 'N') {
                return {
                  isActive: 'N',
                  ...item.initialValues
                }
              } else if (item.isActive === 'Y') {
                return {
                  isActive: 'Y',
                  countryCode: item.countryCode,
                  productName: item.productName,
                  currencyCode: item.currencyCode,
                  productPrice: item.productPrice,
                  promotionPrice: item.promotionPrice,
                  promotionStartDate: item.validPeriodStart.getValue() + " " + this.toTwoDigit(item.promotionStartTime.getHours()) + "." + this.toTwoDigit(item.promotionStartTime.getMinutes()),
                  promotionEndDate: item.validPeriodEnd.getValue() + " " + this.toTwoDigit(item.promotionEndTime.getHours()) + "." + this.toTwoDigit(item.promotionEndTime.getMinutes()),
                  benefitCycleCount: item.promotionSubscriptionCycle
                }
              } else {
                console.error("This should never happened.")
                return false;
              }
            })
      };
      if (params.settingsByCountries.length === 0) {
        toast(this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_NO_FLEXIBLE_OFFER"));
      } else {
        this.props.startLoading();
        this.httpClient
          .post(URL_ADD_FLEXIBLE_OFFER_API, params)
          .then(response => {
            if (response.data.status !== SUCCESS) {
              toast(this.props.t("FLEXIBLE_OFFER_ALERT_ERROR_CONFIGURING"));
            } else {
              toast(this.props.t("FLEXIBLE_OFFER_ALERT_SUCCESSFULLY_CONFIGURED"));
              this.props.history.push(URL_PRODUCT_DETAIL + "/" + this.state.originalProductId)
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            toast(error.response.data.message);
            this.props.stopLoading();
          });
      }
    }
  }

  handlePopupApplyAll = () => {
    this.setState({isApplyAllPopupOpen: true});
  }

  closePopupApplyAllValidation = (newValue) => {
    let priceFlag = true;
    let validPeriodFlag = true;
    let validPeriodEndFlag = true;
    let benefitPeriodFlag = true;

    if (newValue.promotionPrice === "" || newValue.promotionPrice < 0) {
      priceFlag = false;
    }

    if (!(new Date(newValue.validPeriodStart.getValue()) <= new Date(newValue.validPeriodEnd.getValue()))) {
      validPeriodFlag = false;
    }
    const endOfYesterday = new Date()
    endOfYesterday.setTime(endOfYesterday.getTime() - 86400000)
    endOfYesterday.setHours(23, 59, 59, 999)
    if (!(endOfYesterday <= new Date(newValue.validPeriodEnd.getValue()))) {
      validPeriodEndFlag = false;
    }

    if (newValue.promotionSubscriptionCycle === "" || newValue.promotionSubscriptionCycle <= 0) {
      benefitPeriodFlag = false;
    }

    let alertMessage = "";
    if (!priceFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_PROMOTION_PRICE_NOT_VALID");
    }
    if (!validPeriodFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_PROMOTION_PERIOD_NOT_VALID");
    }
    if (!validPeriodEndFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_PROMOTION_END_DATE_NOT_VALID");
    }
    if (!benefitPeriodFlag) {
      alertMessage += this.props.t("FLEXIBLE_OFFER_CREATE_ALERT_ERROR_BENEFIT_PERIOD_NOT_VALID");
    }

    if (!(priceFlag && validPeriodFlag && validPeriodEndFlag && benefitPeriodFlag)) {
      toast(alertMessage);
      return false;
    }
    return true;
  }
  handleClosePopupApplyAll = (isSave, newValue) => {
    if (!isSave) {
      this.setState({isApplyAllPopupOpen: false});
      return;
    }
    let closeModal = this.closePopupApplyAllValidation(newValue);
    if (closeModal) {
      this.setState(prevState => {
        return {
          flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
              if (item.isActive === "Y") {
                item.validPeriodStart.setValue(new Date(newValue.validPeriodStart.getValue()));
                item.validPeriodEnd.setValue(new Date(newValue.validPeriodEnd.getValue()));
                item.promotionSubscriptionCycle = newValue.promotionSubscriptionCycle;
                item.promotionStartTime = newValue.timePeriodStart;
                item.promotionEndTime = newValue.timePeriodEnd;
              }
            return item;
          }),
          promotionPriceApplyAll: newValue.promotionPrice,
          validPeriodStartApplyAll: newValue.validPeriodStart,
          validPeriodEndApplyAll: newValue.validPeriodEnd,
          promotionSubscriptionCycleApplyAll: newValue.promotionSubscriptionCycle
        }
      }, () => this.handleApplyAllPrices());
      this.setState({isApplyAllPopupOpen: false})
    }
  }

  handleApplyAllPrices = () => {
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    let defaultCountryUSDPrice = ((Number(this.state.promotionPriceApplyAll) * 1000000000) / (getCountriesInformation(this.props.countriesInformation, app.defaultCountry)?.usdBaseExchangeCurrencyRate * 1000000000));
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map((item) => {
          if (!item.isDefaultCountry) {
            const countryInfo = getCountriesInformation(this.props.countriesInformation, item.countryCode);
            let setPrice = ((defaultCountryUSDPrice * 1000000000) * (countryInfo?.usdBaseExchangeCurrencyRate * 1000000000)) / (1000000000 * 1000000000);
            item.promotionPrice = (setPrice).toFixed(countryInfo?.currencyDecimalRoundingLength);
            if (item.promotionPrice < Number(countryInfo?.minimumSaleableAmount)) {
              item.promotionPrice = countryInfo?.minimumSaleableAmount;
            } else if (item.promotionPrice > Number(countryInfo?.maximumSaleableAmount)) {
              item.promotionPrice = countryInfo?.maximumSaleableAmount;
            }
          } else {
            item.promotionPrice = this.state.promotionPriceApplyAll;
          }
          return item;
        })
      }
    });

    toast(this.props.t("APPLY_PRODUCT_PRICE_ALL_COUNTRY_MESSAGE"));
  };
  extractActiveCountryCodes = (productInformationByCountries) => {
    return productInformationByCountries.filter(item => item.isActive === 'Y').map(item => item.countryCode);
  };
  handleDeleteCountry = () => {
    this.setState(prevState => {
      let flexibleOfferInformationByCountries = prevState.flexibleOfferInformationByCountries.map(item => {
        if (item.isSelected === true) {
          item.isActive = 'N';
          item.isSelected = false;
        }
        return item;
      }).filter(item => item.isActive === 'Y' || !item.new);
      return {
        flexibleOfferInformationByCountries: flexibleOfferInformationByCountries,
        selectedCountries: this.extractActiveCountryCodes(flexibleOfferInformationByCountries)
      }
    }, () => toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_COUNTRIES_DELETED")));
  }

  setValidStartEndPeriodForCountry = (isStart, datepicker, country, flexibleOfferInformationByCountries) => {
    return {
      flexibleOfferInformationByCountries: flexibleOfferInformationByCountries.map((item) => {
        if (item.countryCode === country) {
          datepicker.setValue(new Date());
          if (isStart) {
            item.validPeriodStart = datepicker;
          } else {
            item.validPeriodEnd = datepicker;
          }
        }
        return item;
      })
    }
  };
  delegateDatePickerEvent = (isStart, datepicker, country) => {
    this.setState(prevState => this.setValidStartEndPeriodForCountry(isStart, datepicker, country, prevState.flexibleOfferInformationByCountries))
  }

  setDatePickerInitValues = () => {
    this.setState(prevState => {
      return {
        flexibleOfferInformationByCountries: prevState.flexibleOfferInformationByCountries.map(item => {
          item.validPeriodStart.setValue(new Date(item.promotionStartDate));
          item.validPeriodEnd.setValue(new Date(item.promotionEndDate));
          return item;
        })
      }
    })
  };

  setSelectedReturningUserEligibility(option) {
    this.setState({selectedReturningUserEligibility: option})
  }

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  render() {
    if (!this.state.isDataLoaded) {
      return <div/>;
    }
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    const productTypes = productAttributes.productType.value.filter(word => word !== "FREE_TRIAL_SUBSCRIPTION");
    return (<form className="needs-validation" noValidate>
      <div className="clearfix mb-20">
        <h2 className="float-left mb-0">
          <strong>{this.props.t("FLEXIBLE_OFFER_INFORMATION")}</strong>
        </h2>
      </div>
      <div className="form-group-wrap">
        <div className="form-group row">
          <label className="col col-form-label text-muted">{this.props.t("PRODUCT_ID")}</label>
          <div className="col-sm-10">
            <div className="row">
              <div className="col-12">
                <input type="text" ref={this.focusId} className="form-control"
                       value={this.state.productId} readOnly="readonly"/>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col col-form-label text-muted">{this.props.t("DESCRIPTION")}</label>
          <div className="col-sm-10">
            <div className="textarea_count">
                <textarea
                    maxLength="400"
                    disabled={true}
                    value={this.state.productDescription}
                    className="form-control text_counter"
                />
            </div>
          </div>
        </div>
        <div className="form-group row">
              <label className="col col-form-label text-muted">{this.props.t("PROD_STATUS")}</label>
              <div className="col-sm-10">
                <div className="btn-group btn-group-toggle btn-group-radio"
                     data-toggle="buttons">
                  <label
                      className={`btn btn-radio ${this.state.selectedProductStatus === "ACTIVE" && "checked"} disabled`}><input
                      type="radio"
                      disabled={true}/>{this.props.t("ACTIVE")}
                  </label>
                  {!this.state.productDeletable ? (<label
                      className={`btn btn-radio ${this.state.selectedProductStatus === "RETIRED" && "checked"} disabled`}><input
                      type="radio"/>{this.props.t("RETIRED")}
                  </label>) : (<label
                      className={`btn btn-radio ${this.state.selectedProductStatus === "DELETED" && "checked"} disabled`}><input
                      type="radio"
                      disabled={true}/>{this.props.t("DELETED")}
                  </label>)}
                </div>
              </div>
        </div>
        <div className="form-group row">
              <label className="col col-form-label text-muted">{this.props.t("PRODUCT_TYPE")}
              </label>
              <div className="col-sm-10">
                <div className="row">
                  <div className="col-6">
                    <SelectrWrapper
                        isDisable={true}
                        className="form-control"
                        clearable={true}
                        placeholder={this.props.t("SELECT")}
                        value={(this.state.selectedProductType === SUBSCRIPTION || this.state.selectedProductType === FREE_TRIAL_SUBSCRIPTION) ? SUBSCRIPTION : this.state.selectedProductType}
                        onChange={(selected) => this.productTypeChangeHandler(selected)}>
                      {productTypes.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                    </SelectrWrapper>
                  </div>
                </div>
              </div>
        </div>
        <div className="form-group row">
          <label
              className="col col-form-label font-weight-bold text-muted">{this.props.t("VISIBILITY")}</label>
          <div className="col-sm-10">
            <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
              <label
                  className={`btn btn-radio ${this.state.selectedVisibility === "HIDE" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setVisibility('HIDE')}/>{this.props.t("HIDE")}</label>
              <label
                  className={`btn btn-radio ${this.state.selectedVisibility === "SHOW" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setVisibility('SHOW')}/>{this.props.t("SHOW")}</label>
              <label
                  className={`btn btn-radio ${this.state.selectedVisibility === "OPTIONAL" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setVisibility('OPTIONAL')}/>{this.props.t("OPTIONAL")}</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label
              className="col col-form-label font-weight-bold text-muted">{this.props.t("EXPIRATION")}</label>
          <div className="col-sm-10">
            <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
              <label
                  className={`btn btn-radio ${this.state.selectedExpiration === "NOT_APPLICABLE" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setExpiration('NOT_APPLICABLE')}/>{this.props.t("NOT_APPLICABLE")}
              </label>
              <label
                  className={`btn btn-radio ${this.state.selectedExpiration === "ONE_MONTH" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setExpiration('ONE_MONTH')}/>{this.props.t("ONE_MONTH")}
              </label>
              <label
                  className={`btn btn-radio ${this.state.selectedExpiration === "SIX_MONTH" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setExpiration('SIX_MONTH')}/>{this.props.t("SIX_MONTH")}
              </label>
              <label
                  className={`btn btn-radio ${this.state.selectedExpiration === "YEAR" && "checked"} disabled`}><input
                  disabled={true} type="radio"
                  onClick={() => this.setExpiration('YEAR')}/>{this.props.t("YEAR")}</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col col-form-label font-weight-bold">
            {this.props.t("RETURNING_USER_ELIGIBILITY")}
            &nbsp;&nbsp;
            <span onMouseEnter={this.handleTooltipOpenClick}
              onMouseLeave={this.handleTooltipCloseClick}
              className={`tooltips text-center ${this.state.isTooltipOpen && "show"}`}>
              <span className="tooltip-toggle font-weight-bold">i</span>
              <div className={`tooltips_body`} data-toggle="tooltip" data-placement="right">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a className={"tooltip-toggle close"} onClick={this.handleTooltipCloseClick} />
                {this.props.t("DISCLAIMER_FOR_RETURNING_USER_ELIGIBILITY")}<br />
              </div>
            </span>
          </label>
          <div className="col-sm-10">
            <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
              <label
                  className={`btn btn-radio ${this.state.selectedReturningUserEligibility && "checked"}`}><input
                  type="radio"
                  onClick={() => this.setSelectedReturningUserEligibility(true)}/>{this.props.t("ELIGIBLE")}
              </label>
              <label
                  className={`btn btn-radio ${!this.state.selectedReturningUserEligibility && "checked"}`}><input
                  type="radio"
                  onClick={() => this.setSelectedReturningUserEligibility(false)}/>{this.props.t("NOT_ELIGIBLE")}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="clearfix mb-10 mt-60">
        <h2 className="float-left mb-0">
          {this.props.t("COUNTRIES_AND_PRICING_CONFIGURATION")}
        </h2>
        {this.state.flexibleOfferInformationByCountries.filter(country => country.isActive === 'Y').length > 0 && (
            <div className="float-right">
              <div className="btn-group-toggle btn-group-checkbox ml-7" data-toggle="buttons">
                <button
                    type="button"
                    className={"btn btn-sm btn-auto btn-success"}
                    onClick={this.handlePopupApplyAll}>
                  {this.props.t("APPLY_ALL")}
                </button>
              </div>
            </div>)}

      </div>
      {this.state.flexibleOfferInformationByCountries.filter(country => country.isActive === 'Y').length > 0 ?
          <div className="table-overlay">
            <DataTableWrapperForFlexibleOffer
              productCountryData={this.state.flexibleOfferInformationByCountries}
              onCountryProductPriceChange={this.handleCountryProductPriceChange}
              onCountryBenefitPeriodChange={this.handleCountryBenefitPeriodChange}
              handleDatepickerInit={this.delegateDatePickerEvent}
              onCheck={this.handleCheck}
              onCheckAll={this.handleCheckAll}
              billingPeriodType={this.state.billingPeriodType}
              onTimePickerChange={this.handleTimePickerChange}
            />
          </div> : <div className="form-group-wrap d-flex justify-content-center">
            <div>
              <button type="button"
                      className="btn btn-sm btn-auto btn-outline-primary"
                      onClick={this.handleAddCountry}>{this.props.t("ADD_COUNTRY")}
              </button>
            </div>
          </div>}


      <div className="row mt-30">
        <div className="col-6">
          {this.state.flexibleOfferInformationByCountries.filter(country => country.isActive === 'Y').length > 0 && <>
            <button type="button"
                    className={"btn btn-sm btn-auto btn-success"}
                    onClick={this.handleAddCountry}>{this.props.t("ADD_COUNTRY")}</button>
            <button type="button"
                    className={"btn btn-sm btn-auto btn-outline-success ml-8"}
                    onClick={this.handleDeleteCountry}>{this.props.t("DELETE_COUNTRY")}</button>
          </>}
        </div>
        <div className="col-6 text-right">
          <Link to={URL_PRODUCT_LIST}>
            <button type="button"
                    className="btn btn-sm btn-auto btn-outline-primary">{this.props.t("LIST")}</button>
          </Link>
          <button type="button"
                  className={"btn btn-sm btn-auto btn-primary ml-10"}
                  onClick={() => this.setState({savePopup: true})}>{this.props.t("SAVE")}</button>
        </div>
      </div>
      <CountryListPopUp
          countryData={this.state.countryData}
          open={this.state.isCountryListPopupOpen}
          title={this.props.t("ADD_COUNTRY")}
          initCountries={this.state.selectedCountries}
          handleModalClose={this.handleCountryListModalClose}
          disabledCountry={[]}
          isMultipleSelect={true}
          isContinentSelect={true}/>
      <ApplyAllFlexibleOfferPopup open={this.state.isApplyAllPopupOpen}
                                  handleModalClose={this.handleClosePopupApplyAll}
                                  productCountryData={this.state.informationByCountries}
                                  productInformationByCountries={[getCountriesInformation(this.props.countriesInformation, app.defaultCountry)]}
                                  promotionPriceApplyAll={this.state.promotionPriceApplyAll}
                                  validPeriodStartApplyAll={this.state.validPeriodStartApplyAll}
                                  validPeriodEndApplyAll={this.state.validPeriodEndApplyAll}
                                  promotionSubscriptionCycleApplyAll={this.state.promotionSubscriptionCycleApplyAll}
                                  billingPeriodType={this.state.billingPeriodType}/>
      <BasePopup
          open={this.state.savePopup}
          className="modal-sm"
          title={this.props.t("ALERT")}
          onClose={() => this.setState({savePopup: false})}
          body={
            <p>{this.props.t("DO_YOU_WANT_TO_SAVE_THE_CHANGES")}</p>
          }
          footer={
            <>
              <button type="button" className="btn btn-secondary" onClick={() => this.handleSave()}>
                {this.props.t("CONFIRM")}
              </button>
              <button type="button" className="btn btn-outline-secondary"
                      onClick={() => this.setState({savePopup: false})}>
                {this.props.t("CANCEL")}
              </button>
            </>
          }/>
    </form>);
  }

  componentDidMount() {
    this.initCountryData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)
        || isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)) {
      this.initCountryData()
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation,
    loadCountriesInformation: loadCountriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading, stopLoading: stopLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FlexibleOfferPanel)))
