import {URL_GET_STATISTICS_PRODUCTLIST_API} from './urlConstants';
import {handleHttpError} from './ErrorUtils';
import {AXIOS_CLIENT_TIMEOUT, BILLING_SERVICE_START_DATE, DESC_SORTING_ORDER, LIMIT, PAGE_NUMBER} from './globalConstants';
import axios from 'axios';

export const INTERVALS = ["WEEKLY", "DAILY"];
const ALL_PRODUCTS_ENG = "All Products";
const ALL_PRODUCTS_KOR = "상품 전체";
export const typeDetails = {
  "APP_SALES": [{
    type: "AMOUNT",
    showIntervalSwitcher: true,
    chartType: "ColumnChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      height: 450,
      colors: ['#34bcdf', '#9c4dc3'],
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        },
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }, {
    type: "COUNT",
    showIntervalSwitcher: true,
    chartType: "ColumnChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      isStacked: true,
      height: 450,
      colors: ['#34bcdf', '#9c4dc3'],
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }, {
    type: "COUNTRY",
    showIntervalSwitcher: false,
    chartType: "ComboChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }, {
      type: "number", label: "Sales Count"
    }],
    options: {
      title: '',
      height: 500,
      colors: ['#34bcdf', '#acd443', '#9c4dc3'],
      seriesType: 'bars',
      series: {2: {type: 'line'}},
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        },
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }],
  "COUNTRY_SALES": [{
    type: "AMOUNT",
    showIntervalSwitcher: true,
    chartType: "ColumnChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      height: 450,
      colors: ['#34bcdf', '#9c4dc3'],
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }, {
    type: "COUNT",
    showIntervalSwitcher: true,
    chartType: "ColumnChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      isStacked: true,
      height: 450,
      colors: ['#34bcdf', '#9c4dc3'],
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }],
  "PRODUCT_SALES": [{
    type: "AMOUNT",
    showIntervalSwitcher: true,
    chartType: "ColumnChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      height: 450,
      colors: ['#34bcdf', '#9c4dc3'],
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        },
        viewWindow: {
          min: 0
        }
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }, {
    type: "COUNT",
    showIntervalSwitcher: true,
    chartType: "ColumnChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Sales"
    }, {
      type: "number", label: "Refund"
    }],
    options: {
      isStacked: true,
      height: 450,
      colors: ['#34bcdf', '#9c4dc3'],
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        },
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'}
    }
  }],
  "SUBSCRIBER_STATISTICS": {
    chartType: "ComboChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Total Accumulated Subscribers"
    }, {
      type: "number", label: "New Subscribers"
    }, {
      type: "number", label: "Cancelled Subscribers"
    }],
    options: {
      title: '',
      height: 450,
      colors: ['#34bcdf', '#acd443', '#9c4dc3'],
      seriesType: 'bars',
      series: {2: {type: 'line'}},
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'},
    }
  },
  "CAMPAIGN_STATISTICS": {
    chartType: "ComboChart",
    columns: [{
      type: "string", label: ""
    }, {
      type: "number", label: "Allocated Coupons"
    }, {
      type: "number", label: "Used Coupons"
    }, {
      type: "number", label: "Coupon Usage Rate (cumulative)"
    }],
    options: {
      title: '',
      height: 450,
      colors: ['#34bcdf', '#acd443', '#9c4dc3'],
      seriesType: 'bars',
      series: {2: {type: 'line'}},
      hAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      vAxis: {
        textStyle: {
          fontSize: 12,
          color: '#b3b1b1'
        }
      },
      legend: {position: 'bottom', textStyle: {color: '#222', fontSize: 14}},
      chartArea: {left: 40, top: 10, width: '98%', height: '90%'},
    }
  }
};

export const isDurationLessThan1Month = (dateStart, dateEnd) => {
  const date1 = new Date(dateStart);
  const date2 = new Date(dateEnd);
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10) <= 31;
};

export const convertToProductId = (input) => {
  if (input === ALL_PRODUCTS_ENG || input === ALL_PRODUCTS_KOR) {
    return "ALL_PRODUCTS";
  }
  return input.replace(/.{0,10000}\(|\)/g, '')
};

export const convertToCouponId = (input) => {
  return input.split('_').pop();
};

export const round2DecimalPlaces = (input) => {
  return Math.round(input * 100) / 100;
};

const monthBefore12 = new Date();
monthBefore12.setFullYear(monthBefore12.getFullYear() - 1);
var date = new Date();
export const endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    .toISOString()
    .split("T")[0];
export const startDateString = new Date(monthBefore12.getTime() - (monthBefore12.getTimezoneOffset() * 60000))
    .toISOString()
    .split("T")[0];

export const getValues = (input, interval) => {
  let amountValues = [];
  let countValues = [];
  if (input ? input.length > 0 : false) {
    input.forEach(e => {
      if (interval === "MONTHLY") {
        amountValues.push([e.period, e.salesAmount, e.refundAmount]);
        countValues.push([e.period, e.salesCount, e.refundCount]);
      } else if (interval === "WEEKLY") {
        amountValues.push([convertIntoWeeks(e.period), e.salesAmount, e.refundAmount]);
        countValues.push([convertIntoWeeks(e.period), e.salesCount, e.refundCount]);
      } else {
        amountValues.push([convertIntoDaily(e.period), e.salesAmount, e.refundAmount]);
        countValues.push([convertIntoDaily(e.period), e.salesCount, e.refundCount]);
      }
    });
  }
  return {
    "AMOUNT": amountValues,
    "COUNT": countValues
  };

};

export const convertIntoWeeks = (input) => {
  return input.substring(0, 4) + "-W" + input.substring(4);
}

export const convertIntoDaily = (input) => {
  return input.split("-")[1] + "-" + input.split("-")[2];
}

export const fetchProductListUtil = (selectedAppId, history, translator, startLoading, stopLoading) => {

  return new Promise((resolve, reject) => {
    const startDate = BILLING_SERVICE_START_DATE;
    const endDate = new Date()
        .toISOString()
        .split("T")[0];

    const params = {
      cpAppId: selectedAppId,
      dateStart: startDate,
      dateEnd: endDate,
      sortOrder: DESC_SORTING_ORDER,
      pageNumber: PAGE_NUMBER,
      limit: LIMIT
    };
    startLoading();

    axios.create({timeout: AXIOS_CLIENT_TIMEOUT})
        .get(URL_GET_STATISTICS_PRODUCTLIST_API, {
          params: params
        })
        .then(response => {
          const productList = response.data.data.map(e => e.productName + "(" + e.cpProductId + ")");
          productList.unshift(translator("ALL_PRODUCTS"));
          stopLoading();
          resolve({
            productList: productList,
            selectedProduct: productList[0]
          })
        })
        .catch(error => {
          handleHttpError(translator, history, error);
          stopLoading();
          reject();
        });
  });
};
