import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../css/bootstrap-extend.css';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {getCountriesInformation} from '../../ducks/countriesInformation';
import ValidPeriodDatePickers from './ValidPeriodDatePickers';

class DataTableWrapperForCouponIssue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false,
    };
  }

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  isCheckAll = () => {
    const newData = this.props.couponCountryData.filter(item => item.isDisabled === false);
    return newData.map(item => item.isSelected).every(item => item) ? newData.length > 0 ? " checked" : "" : "";
  };

  render() {
    return (
        <table className="table table-bordered table-hover table-data">
          <caption>Coupon Issue List</caption>
          <colgroup>
            <col width="60"/>
            <col width="100"/>
            <col width="100"/>
            {this.props.couponType === "DISCOUNT_AMOUNT" &&
            <>
              {this.props.showPrice &&
              <col width="100"/>
              }
              <col width="100"/>
            </>}
            {this.props.couponType === "DISCOUNT_RATE" &&
            <>
              <col width="100"/>
            </>}
            <col width="180"/>
            {this.props.couponType === "DISCOUNT_AMOUNT" &&
            <>
              <col width="60"/>
            </>
            }
            <col width="300"/>
          </colgroup>
          <thead>
          <tr>
            <th className="none-sort" scope="col">
              <div className="btn-group-toggle btn-group-checkbox btn-check">
                <label className={"btn-checkbox checkbox-all".concat(this.isCheckAll())}><input onClick={() => this.props.handleCheckAll()} type="checkbox"/></label>
              </div>
            </th>
            <th scope="col">{this.props.t("COUNTRY")}</th>
            <th scope="col">{this.props.t("CURRENCY")}</th>
            {this.props.couponType === "DISCOUNT_AMOUNT" &&
            <>
              {this.props.showPrice &&
              <th scope="col">{this.props.t("PRODUCT_PRICE")}</th>
              }
              <th scope="col">{this.props.t("DISCOUNT_AMOUNT")}</th>
            </>}
            {this.props.couponType === "DISCOUNT_RATE" &&
            <>
              <th scope="col">{this.props.t("DISCOUNT_RATE_%")}</th>
            </>}
            <th scope="col">{this.props.t("NO_OF_COUPONS")} {"  "}
              <span className={`tooltips right ${this.state.isTooltipOpen && "show"}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
                    <div className="tooltips_body">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a onClick={this.handleTooltipCloseClick} className="tooltip-toggle close"/>
                      <br/>
                      {this.props.t("TOTAL_ISSUED_TOOLTIP1")}<br/>
                    </div>
                  </span>
            </th>
            {this.props.couponType === "DISCOUNT_AMOUNT" &&
            <>
              <th scope="col">{this.props.t("MINIMUM_PAYMENT_AMOUNT")}</th>
            </>}
            <th scope="col">{this.props.t("VALID_PERIOD")}</th>
          </tr>
          </thead>
          <tbody className="text-center">
          {
            this.props.couponCountryData.map((item) => (
                <tr key={item.country}>
                  <td className="text-center">
                    <div className="btn-group-toggle btn-group-checkbox btn-check ">
                      <label className={"btn-checkbox".concat(item.isSelected ? " checked" : "").concat(item.isDisabled ? " disabled" : "")}><input disabled={item.isDisabled} onClick={() => this.props.handleCheck(item.country, item.isDisabled)} type="checkbox"/></label>
                    </div>
                  </td>
                  <td>
                    {this.props.t(item.country)}
                  </td>
                  <td>
                    {getCountriesInformation(this.props.countriesInformation, item.country).currency}
                    ({getCountriesInformation(this.props.countriesInformation, item.country).currencySymbol})
                  </td>
                  {this.props.couponType === "DISCOUNT_AMOUNT" &&
                  <>
                    {this.props.showPrice &&
                    <td>{this.props.countryPricing[item.country]}</td>
                    }
                    <td><input type="number"
                               value={item.discountAmount}
                               className="form-control"
                               min={0}
                               onBlur={(event) => {
                                 this.props.handleDiscountAmountBlur(item.country, event);
                               }}
                               onChange={(event) => {
                                 this.props.handleDiscountAmountChange(item.country, event);
                               }}/>
                    </td>
                  </>}
                  {this.props.couponType === "DISCOUNT_RATE" &&
                  <td><input type="number"
                             value={item.discountRate}
                             className="form-control"
                             min={0}
                             max={100}
                             onBlur={(event) => {
                               this.props.handleDiscountRateBlur(item.country, event);
                             }}
                             onChange={(event) => {
                               this.props.handleDiscountRateChange(item.country, event);
                             }}/>
                  </td>}
                  <td>
                    <input type="number"
                           value={item.totalIssued}
                           className="form-control"
                           onBlur={(event) => {
                             this.props.handleTotalIssuedBlur(item.country, event);
                           }}
                           onChange={(event) => {
                             this.props.handleTotalIssuedChange(item.country, event);
                           }}/>
                  </td>
                  {this.props.couponType === "DISCOUNT_AMOUNT" &&
                  <td>
                    {getCountriesInformation(this.props.countriesInformation, item.country).minimumSaleableAmount}
                  </td>
                  }
                  <td>
                    <ValidPeriodDatePickers startDatePicker={item.validPeriodStart}
                                            endDatePicker={item.validPeriodEnd}
                                            country={item.country}
                                            handleDatepickerInit={this.props.handleDatepickerInit}/>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    countriesInformation: state.countriesInformation
  };
};

export default connect(mapStateToProps)(withTranslation()(DataTableWrapperForCouponIssue));