export const mockMemberList = {
  memberList: [
    {
      userId: '15',
      cpLoginId: 'shin01@gmail.com',
      userName: 'Manu Cheon1',
      groupCount: 2,
      selectedGroups: ['Samsung Health Management Group', 'Samsung Art Management Group'],
      status: '01',
      joinedDate: '2019-12-24',
      accessDate: '2019-12-25'
    },
    {
      userId: '16',
      cpLoginId: 'shin02@gmail.com',
      userName: 'Yash Cheon2',
      groupCount: 1,
      selectedGroups: ['Samsung Art Management Group'],
      status: '00',
      joinedDate: '2019-12-24',
      accessDate: '2020-01-12'
    },
    {
      userId: '17',
      cpLoginId: 'shin00@gmail.com',
      userName: 'Shailender Cheon0',
      groupCount: 0,
      status: '02',
      joinedDate: '2019-12-24',
      accessDate: '2019-11-12'
    }
  ],
  groupList: [
    {
      groupId: '2222',
      groupName: 'Samsung Health Management Group',
      menuCount: 5,
      memberCount: 1,
      createdDate: '2019-12-06 07:23:24',
      modifiedDate: '201-12-27 01:05:23',
      permittedMenus: ["SETTINGS_APPDETAILS", "PRODUCT_LIST", "PRODUCT_ADDNEW",
        "PRODUCT_ADDMULTIPLE", "PRODUCT_MODIFYMULTIPLE", "SALES_PRODUCT",
        "SALES_SUBSCRIPTION", "CAMPAIGN_COUPONLIST"]
    },
    {
      groupId: '2',
      groupName: 'Samsung App Management Group',
      menuCount: 5,
      memberCount: 0,
      createdDate: '2019-12-06 07:23:24',
      modifiedDate: '201-12-27 01:05:23',
      permittedMenus: ["SETTINGS_APPDETAILS", "PRODUCT_LIST", "PRODUCT_ADDNEW",
        "PRODUCT_ADDMULTIPLE", "PRODUCT_MODIFYMULTIPLE"]
    },
    {
      groupId: '3',
      groupName: 'Samsung Art Management Group',
      menuCount: 8,
      memberCount: 2,
      members: ["shin01@gmail.com, shin02@gmail.com"],
      createdDate: '2019-11-27 01:05:23 [GMT]',
      modifiedDate: '2019-12-27 01:05:23 [GMT]',
      permittedMenus: ["SETTINGS_APPDETAILS", "PRODUCT_LIST", "PRODUCT_ADDNEW",
        "PRODUCT_ADDMULTIPLE", "PRODUCT_MODIFYMULTIPLE", "SALES_PRODUCT",
        "SALES_SUBSCRIPTION", "CAMPAIGN_COUPONLIST"]
    }
  ]
}