import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, transformRequestOptions} from '../../utils/globalConstants';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {URL_GET_STATISTICS_SUBSCRIBER_SALES_API, URL_GET_STATISTICS_SUBSCRIBER_SALES_DOWNLOAD_API} from '../../utils/urlConstants';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import {convertIntoDaily, convertIntoWeeks, convertToProductId, endDateString, fetchProductListUtil, isDurationLessThan1Month, startDateString, typeDetails} from '../../utils/statisticsUtils';
import Table from '../statistics/Table';
import Graph from '../statistics/Graph';
import {handleHttpError} from '../../utils/ErrorUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';

class SubscriberStatisticsPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      totalSubscribers: "0",
      activeSubscribers: "0",
      newSubscribers: "0",
      returningSubscribers: "0",
      unSubscribers: "0",
      productList: [],
      activeInterval: "MONTHLY",
      rowData: {},
      searchParams: {},
      dataLoaded: false
    }
  }

  handleSelectInterval = (interval) => {
    this.setState({activeInterval: interval}, () => this.search());
  };

  handleDownloadClick = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    handleDownloadFile(URL_GET_STATISTICS_SUBSCRIBER_SALES_DOWNLOAD_API, searchParams, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      cpProdIds: searchParams.selectedFilter[0] ? [searchParams.selectedFilter[0].value] : []
    };
    this.setState({
      selectedProduct: params.cpProdIds ? params.cpProdIds[0] : this.state.productList[0]
    });
    if (params.cpProdIds.length > 0) {
      params.cpProdIds = [convertToProductId(params.cpProdIds[0])];
    }
    this.setState({
      searchParams: params,
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_SUBSCRIBER_SALES_API, {
            params: searchParams,
            paramsSerializer: transformRequestOptions
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              rowData: this.getValues(response.data[0].dataList, this.state.activeInterval),
              totalSubscribers: response.data[0].totalSubscribers,
              activeSubscribers: response.data[0].activeSubscribers,
              newSubscribers: response.data[0].newSubscribers,
              returningSubscribers: response.data[0].returningSubscribers,
              unSubscribers: response.data[0].unSubscribers
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    })
  };

  getValues = (input, interval) => {
    let values = [];
    if (input ? input.length > 0 : false) {
      input.forEach(e => {
        if (interval === "MONTHLY") {
          values.push([e.period, e.totalSubscribers, e.newSubscribers, e.unSubscribers]);
        } else if (interval === "WEEKLY") {
          values.push([convertIntoWeeks(e.period), e.totalSubscribers, e.newSubscribers, e.unSubscribers]);
        } else {
          values.push([convertIntoDaily(e.period), e.totalSubscribers, e.newSubscribers, e.unSubscribers]);
        }
      });
      return values;
    } else {
      return values;
    }
  };

  fetchProductList = async () => {
    let result = await fetchProductListUtil(this.props.carousel.selectedAppId, this.props.history, this.props.t, this.props.startLoading, this.props.stopLoading);
    this.setState(result);
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const typeDetail = typeDetails["SUBSCRIBER_STATISTICS"];
    const tableRows = [{id: "selectedProduct", header: "PRODUCT_NAME", value: this.state.selectedProduct},
      {id: "totalSubscribers", header: "STATISTICS_TOTAL_SUBSCRIBERS", value: this.state.totalSubscribers},
      {id: "activeSubscribers", header: "STATISTICS_ACTIVE_SUBSCRIBERS", value: this.state.activeSubscribers},
      {id: "newSubscribers", header: "STATISTICS_NEW_SUBSCRIBERS", value: this.state.newSubscribers},
      {id: "returningSubscribers", header: "STATISTICS_RETURNING_SUBSCRIBERS", value: this.state.returningSubscribers},
      {id: "unSubscribers", header: "STATISTICS_UNSUBSCRIBERS", value: this.state.unSubscribers}];
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, this.props.carousel.selectedAppId));

    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              filterPanel={{
                show: true,
                showSearch: true,
                label: this.props.t("PRODUCT"),
                filters: [
                  {key: "PRODUCT", value: this.state.productList, defaultValue: this.props.t("ALL_PRODUCTS")},
                ]
              }}
              datePanel={{
                show: true,
                label: this.props.t("SALES_PERIOD"),
                defaultDuration: durationKey.DURATION_YEAR,
                showSearch: false,
                maxDate: true
              }}
              keywordPanel={{show: false}}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}
              onDownloadClick={() => {
              }}/>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{selectedApp.name + this.props.t("SUBSCRIBER_STATISTICS_HEADER")}</strong></h2>
          </div>
          <Table data={tableRows}/>
          <Graph typeDetails={typeDetail}
                 showTypeSwitcher={false}
                 activeInterval={this.state.activeInterval}
                 handleSelectInterval={this.handleSelectInterval}
                 handleDownloadClick={this.handleDownloadClick}
                 isFooter={false}
                 isMonthly={isDurationLessThan1Month(this.state.searchParams.dateStart, this.state.searchParams.dateEnd)}
                 rows={this.state.rowData}
          />
        </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchProductList()
          .then(() => this.handleSearchClick({
            startDate: startDateString,
            endDate: endDateString,
            selectedFilter: [{value: this.state.selectedProduct}]
          }))
    }
  }

  componentDidMount() {
    this.fetchProductList()
        .then(() => this.handleSearchClick({
          startDate: startDateString,
          endDate: endDateString,
          selectedFilter: [{value: this.state.selectedProduct}]
        }))
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SubscriberStatisticsPanel)));
