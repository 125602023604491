import React, {Component} from "react";
import Footer from "./Footer";

class Content extends Component {
  render(props) {
    return (
        <div id="contents" className={this.props.className}>
          {this.props.children}
          <Footer/>
        </div>
    )
  }
}

export default Content;