import React, {Component} from 'react';
import CountryListPopUp from '../popup/CountryListPopUp';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getAppFromSessionData, updateDefaultCountry} from '../../ducks/sessionData';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {URL_GET_APP_SEC_KEY_API, URL_GET_COUNTRY_DATA_API, URL_GET_COUNTRY_INFORMATION_API, URL_SET_DEFAULT_COUNTRY_API} from '../../utils/urlConstants';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import {triggerGetSessionData} from "../App";
import {fetchCountries} from '../../utils/CountryListUtils';
import {loadCountriesInformation} from '../../ducks/countriesInformation';
import ReminderURLComponent from "./ReminderURLComponent";

function ButtonWrapper(props) {
  return (
      <div className="input-group-append">
        <button className={props.className} type="button" onClick={props.onClick} style={props.style}>{props.label}</button>
      </div>
  )
}

class AppDetailsSettingPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      open: false,
      readOnly: false,
      selectedCountries: [],
      countryDataLoaded: false,
      countryData: [],
      appSecKey: "",
    }
  }

  initData = () => {
    if (!this.props.sessionData.apps) {
      return;
    }
    if (this.props.sessionData.apps.length && this.state.countryData.length) {
      const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);

      this.setState({
        selectedCountries: app.defaultCountry ? [app.defaultCountry] : [],
        countryDataLoaded: true
      });
      this.getAppSecKey(this.props.carousel.selectedAppId);
    }
  };

  getAppSecKey = (selectedAppId) => {
    this.httpClient
        .get(URL_GET_APP_SEC_KEY_API, {params : {cpAppId: selectedAppId}})
        .then((response) => {
          this.setState({appSecKey: response.data})
        })
  }

  handleModalOpen = () => {
    this.setState({
      open: true,
      readOnly: false,
    });
  };

  handleListOpen = () => {
    this.setState({
      open: true,
      readOnly: true,
    });
  };

  handleModalClose = (isSave, selectedCountries) => {
    this.setState({open: false});

    if (!isSave) {
      return;
    }

    this.setState({selectedCountries: selectedCountries});
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .post(URL_SET_DEFAULT_COUNTRY_API, {cpAppId: this.props.carousel.selectedAppId, countryCode: selectedCountries[0]})
          .then(() => {
            this.props.updateDefaultCountry(selectedCountries[0], this.props.carousel.selectedAppId);
            this.props.stopLoading();
            triggerGetSessionData();
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject();
            this.props.stopLoading();
          });
    });
  };

  initCountryData = () => {
    this.props.startLoading();
    fetchCountries(URL_GET_COUNTRY_DATA_API, this.props.t, this.props.history)
        .then(countryData => {
          this.setState({countryData: countryData});
          fetchCountries(URL_GET_COUNTRY_INFORMATION_API, this.props.t, this.props.history)
              .then((countryInformation => {
                this.props.stopLoading();
                this.props.loadCountriesInformation(countryInformation);
                this.initData();
              }));
        });
  };

  render() {
    if (!this.state.countryDataLoaded) {
      return <div/>;
    }

    const {open, selectedCountries, readOnly} = this.state;

    const app = this.props.sessionData.apps.find((appItem) => appItem.appId === this.props.carousel.selectedAppId);
    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t('APP_INFORMATION')}</strong></h2>
          </div>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t('APP_ID')}</label>
              <div className="col">
                <input type="text" className="form-control" value={app.appId} readOnly="readonly"/>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t('APP_NAME')}</label>
              <div className="col">
                <input type="text" className="form-control" value={app.name ? app.name : ""} readOnly="readonly"/>
              </div>
            </div>
          </div>
          <div className="clearfix mb-20 mt-60">
            <h2 className="float-left mb-0"><strong>{this.props.t('APP_SECURITY_KEY')}</strong></h2>
          </div>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t('SECURITY_KEY')}</label>
              <div className="col">
                <div className="input-group">
                  <input type="text" value={this.state.appSecKey} className="form-control" readOnly={true}/>
                  <CopyToClipboard text={this.state.appSecKey} onCopy={() => {
                    toast(this.state.appSecKey !== null ? this.props.t("COPIED_URL") : this.props.t("COPIED_URL_EMPTY"));
                  }}>
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">{this.props.t('COPY')}</button>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix mb-20 mt-60">
            <h2 className="float-left mb-0"><strong>{this.props.t('DEFAULT_COUNTRY_SETTING')}</strong></h2>
          </div>
          <div className="form-group-wrap" id="app-details-setting-default-country-location-setting">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t('DEFAULT_COUNTRY')}</label>
              <div className="col">
                <div className="row">
                  <div className="col-3">
                    <div className="input-group">
                      {!selectedCountries.length ? (
                          <div className="input-group-append flex-100">
                            <span className="input-group-text w-100 border-0 bg-white pl-0 text-muted">{this.props.t('PLEASE_SET_DEFAULT_COUNTRY')}</span>
                          </div>
                      ) : (
                          <div className="flex-100 align-items-center">
                            <ul className="list-inline mb-0 mt-2">
                              <li className="list-inline-item">
                                <div className="btn-group-toggle btn-group-checkbox">
                                  <label className="btn-checkbox checked disabled"><input type="checkbox" checked={true} readOnly={true}/></label>
                                </div>
                                <span className="ml-6 mr-10">{this.props.t(selectedCountries[0])} ({this.props.t('DEFAULT')})</span>
                              </li>
                            </ul>
                          </div>
                      )}
                    </div>
                  </div>
                  <ButtonWrapper
                      className="btn btn-auto btn-primary rounded"
                      onClick={this.handleModalOpen}
                      label={selectedCountries.length ? this.props.t('CHANGE') : this.props.t('SETTING')}
                      style={{minWidth: "6rem"}}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t('CHECKOUT_SUPPORTED')}<br/>{this.props.t('COUNTRIES')}</label>
              <div className="col">
                <div className="row">
                  <div className="col-3">
                    <div className="input-group">
                      <div className="flex-100 align-items-center">
                        <ul className="list-inline mb-0 mt-2">
                          <li className="list-inline-item">
                            <div className="btn-group-toggle btn-group-checkbox">
                              <label className="btn-checkbox active disabled"><input type="checkbox" checked={true} readOnly={true}/></label>
                            </div>
                            <span className="ml-6 mr-10">{this.props.countriesInformation.length} {this.props.t('COUNTRIES')}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ButtonWrapper
                      className="btn btn-auto btn-outline-primary rounded"
                      onClick={this.handleListOpen}
                      label={this.props.t('VIEW')}
                      style={{minWidth: "6rem"}}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Webhook Related UI Here*/}
          <ReminderURLComponent selectedAppId={this.props.carousel.selectedAppId}/>

          <CountryListPopUp
              countryData={this.state.countryData}
              open={open}
              title={this.props.t("CHECKOUT_SUPPORTED_COUNTRIES")}
              readOnly={readOnly}
              initCountries={selectedCountries}
              disabledCountry={[]}
              handleModalClose={this.handleModalClose}
              isMultipleSelect={false}
              isContinentSelect={false}
          />
        </div>
    );
  }

  componentDidMount() {
    this.initCountryData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.carousel.selectedAppId !== prevProps.carousel.selectedAppId) {
      this.initCountryData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation,
  };
};

const mapDispatchToProps = {
  loadCountriesInformation: loadCountriesInformation,
  updateDefaultCountry: updateDefaultCountry,
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppDetailsSettingPanel));
