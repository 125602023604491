//
// actions
//

const TYPE_ACTION_TOGGLE_SIDE_DRAWER = "TYPE_ACTION_TOGGLE_SIDE_DRAWER";

export function toggleSideDrawer() {
  return {
    type: TYPE_ACTION_TOGGLE_SIDE_DRAWER
  };
}

//
// reducer
//

const initialState = {
  isSideDrawerOpen: true
};

export function sideDrawerReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE_ACTION_TOGGLE_SIDE_DRAWER:
      return {...state, isSideDrawerOpen: !state.isSideDrawerOpen}
    default:
      return state;
  }
}