import axios, {AxiosResponse} from 'axios';
import {API_ADMIN_CHECK_PERMISSION} from './urlConstants';
import {handleHttpError} from './ErrorUtils';

export const isWafResponse = (response: AxiosResponse<any>): boolean => {
  return response.status === 405;
}

export const checkAdminPermission = (props) => {
  return new Promise((resolve, reject) => {
    axios
        .get(API_ADMIN_CHECK_PERMISSION)
        .catch(error => {
          handleHttpError(props.t, props.history, error);
          reject(error);
        })
  });
}