import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {productAttributes, productStatusCode2Key} from "../../utils/productTypesAndAttributes";
import SearchBar from "../searchbar/SearchBar";
import {invoiceAttributes, subscriptionAttributes, subscriptionStatusCode2Key, subscriptionStatusKey2Code} from "../../utils/invoiceAttributes";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import BasePopup from "../popup/BasePopup";
import {isSelectedAppChanged} from '../../ducks/carousel';
import {

  API_SALES_MULTI_SUBSCRIPTION_CANCEL,
  API_SALES_SUBSCRIPTION_CANCEL,
  API_SALES_SUBSCRIPTION_LIST,
  API_SUBSCRIPTION_LIST_DOWNLOAD,
  URL_PRODUCT_DETAIL, URL_REQUEST_LIST,
  URL_SALES_SUBSCRIPTION,
  API_SALES_SUBSCRIPTION_LIST_INHOUSE_DOWNLOAD,
  API_SALES_SUBSCRIPTION_COUNT
} from '../../utils/urlConstants';
import {getAllCurrencyList} from "../../ducks/countriesInformation";
import {durationKey, getDefaultTimePeriod_1Month, isDateFutureThan} from '../../utils/searchBarUtils';
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, DESC_SORTING_ORDER} from "../../utils/globalConstants";
import {toast} from "react-toastify";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from '../../ducks/loading';
import {convertToCountryCode, sortCountryNames} from '../../utils/CountryListUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';

class SubscriptionListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      // for product list data table, will be init in fetchProductList
      pageNumber: 1,
      totalListSize: 0,
      listSizePerPage: 15,
      subscriptionList: [],
      selectedSubscriptionList: [],
      searchParams: {},
      sortOrder: "DESC",
      sortName: "orderDate",
      // for modal
      multipleSubscriptionModalOpen: false,
      currentSubscription: null,
      modalOpen: false,
      modalVisibilityValue: ""
    }
  }

  fetchSubscriptionList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod_1Month();
    const params = {
      dateStart: searchPeriod.startDate,
      dateEnd: searchPeriod.endDate,
    };
    this.setState({searchParams: params}, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        cpAppId: this.props.carousel.selectedAppId,
        sortOrder: this.state.sortOrder,
        sortName: this.state.sortName,
        pageNumber: page,
        limit: sizePerPage
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_SALES_SUBSCRIPTION_LIST, {
              params: params
            })
            .then(response => {
              this.setState({
                selectedSubscriptionList: [],
                pageNumber: page,
                listSizePerPage: sizePerPage,
                totalListSize: response.data.records,
                subscriptionList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
              reject(error);
            })
      })
    });
  };

  handleSubscriptionSearch = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      // cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      countries: searchParams.selectedFilter[0].value ? searchParams.selectedFilter[0].value : "",
      currencies: searchParams.selectedFilter[1].value ? searchParams.selectedFilter[1].value : "",
      subscriptionStatus: searchParams.selectedFilter[2].value ? subscriptionStatusKey2Code[searchParams.selectedFilter[2].value] : "",
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
    };
    this.setState({searchParams: params, pageNumber: 1, sortOrder: "DESC", sortName: "orderDate"}, () => {
      const countryCode = convertToCountryCode(this.props.countriesInformation, params.countries, this.props.t);
      if (countryCode.length) {
        params.countries = countryCode;
      }
      this.triggerSubscriptionSearch()
    })
  };

  triggerSubscriptionSearch = () => {
    let {searchParams} = this.state;
    searchParams = {
      ...searchParams,
      cpAppId: this.props.carousel.selectedAppId,
      pageNumber: this.state.pageNumber,
      limit: this.state.listSizePerPage,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SALES_SUBSCRIPTION_LIST, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              totalListSize: response.data.records,
              subscriptionList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
            reject(error);
          })
    });
  };

  handleDownloadSubscriptionListClick = (searchParams) => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      sortOrder: DESC_SORTING_ORDER,
      pageNumber: this.state.pageNumber,
      limit: this.state.listSizePerPage
    };
      return new Promise((resolve, reject) => {
          this.props.startLoading();
          this.httpClient
              .get(API_SALES_SUBSCRIPTION_COUNT, {
                  params: params
              })
              .then(response => {
                 if(response.data) {
                   this.subscriptionListDownloadNewApi(params);
                 }else {
                   this.subscriptionListDownloadOldApi(params);
                 }
              })
              .catch(error => {
                  handleHttpError(this.props.t, this.props.history, error);
                  reject(error);
                  this.props.stopLoading();
              })
      });
  };

  subscriptionListDownloadNewApi = (params) => {
    return new Promise((resolve, reject) => {
      this.httpClient
          .get(API_SUBSCRIPTION_LIST_DOWNLOAD, {
            params: params
          })
          .then(response => {
            this.setState({
              downloadPopup :  true,
              downloadData :  response.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  subscriptionListDownloadOldApi = (params) => {
    handleDownloadFile(API_SALES_SUBSCRIPTION_LIST_INHOUSE_DOWNLOAD, params, this.props.t, this.props.history,
        this.httpClient, this.props.startLoading, this.props.stopLoading);
  }

  handleRowSelect = (isSelect, rows) => {

    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedSubscriptionList.find(subscriptionId => row["subscriptionId"] === subscriptionId)) {
            return {
              selectedSubscriptionList: [...prevState.selectedSubscriptionList,
                {
                  subscriptionId: row["subscriptionId"],
                  buyerGuid: row["buyerGuid"],
                  buyerUid: row["buyerUid"],
                  langCode: this.props.i18n.language,
                  countryCode: row["countryCode"]
                }]
            };
          }
        });
      } else {
        this.setState(prevState => {
          return {
            selectedSubscriptionList: prevState.selectedSubscriptionList.filter(subscription => row["subscriptionId"] !== subscription.subscriptionId)
          };
        })
      }
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      pageNumber: page,
      listSizePerPage: sizePerPage,
    }, () => this.triggerSubscriptionSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const invoiceHeaderField2SortName = {
      "invoiceIdLink": "invoiceId",
      "subscriptionIdLink": "subscriptionId",
      "orderCustomerId": "orderCustomerId",
      "cpProductIdLink": "cpProductId",
      "countryName": "countryCode",
      "currency": "paymentCurrencyCode",
      "paymentAmountDisplay": "paymentAmount",
      "paymentMethod": "paymentMethodCode",
      "transactionStatus": "transactionStatusCode",
      "refund": "orderCancelDate",
      "subscriptionStatus": "subscriptionStatusCode",
      "cancelYN": "cancelButton"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: invoiceHeaderField2SortName[sortField] ? invoiceHeaderField2SortName[sortField] : sortField
    }, () => this.triggerSubscriptionSearch())
  };

  onCancelSubscriptionClick = (subscription, e) => {
    this.setState({currentSubscription: subscription, modalOpen: true});
    e.preventDefault();
  };

  handleModalToggle = (isSave) => {
    this.setState({modalOpen: false});
    if (isSave) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_SALES_SUBSCRIPTION_CANCEL, {
              subscriptionId: this.state.currentSubscription.subscriptionId,
              buyerGuid: this.state.currentSubscription.buyerGuid,
              buyerUid: this.state.currentSubscription.buyerUid,
              langCode: this.props.i18n.language,
              countryCode: this.state.currentSubscription.countryCode
            })
            .then((response) => {
              if (Object.is(response.data.code, '100000')) {
                this.fetchSubscriptionList(this.state.pageNumber, this.state.listSizePerPage);
              } else {
                toast(response.data.name);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    }
  };

  handleCancelMultiSubscriptionClick = (isOpen) => {
    this.setState({multipleSubscriptionModalOpen: isOpen});
  };

  handleCancelMultiSubscriptionClose = (isSave) => {
    this.setState({multipleSubscriptionModalOpen: false});
    if (isSave) {
      const {selectedSubscriptionList} = this.state;
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_SALES_MULTI_SUBSCRIPTION_CANCEL, selectedSubscriptionList)
            .then((response) => {
              this.fetchSubscriptionList(this.state.pageNumber, this.state.totalListSize);
              toast(response.data.completeCancelRequest + " / " + response.data.totalCancelRequest + " Subscriptions Cancelled");
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    }
  };

  render() {
    const nonSelected = this.state.selectedSubscriptionList.length === 0;
    if (this.props.countriesInformation.length === 0) {
      return (<></>);
    }
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                showToolTip: true,
                toolTipText: [this.props.t("SUBSCRIPTION_LIST_PANEL_TOOLTIP_LINE_1"), <br/>,
                  this.props.t("KEYWORD_PANEL_TOOLTIP_LINE_2"), <br/>,
                  this.props.t("KEYWORD_PANEL_TOOLTIP_LINE_3")],
                label: "Keyword",
                placeholder: "Please enter Keywords.",
                options: [subscriptionAttributes.subscriptionId.key,
                  invoiceAttributes.buyer.key,
                  productAttributes.productId.key,
                  productAttributes.productName.key],
                showDownload: true,
                  downloadToolTip :  true
              }}
              datePanel={{
                show: true,
                label: this.props.t("SUBSCRIPTION_DATE"), // Order Date
                defaultDuration: durationKey.DURATION_ONE_MONTH,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: invoiceAttributes.country.key, value: sortCountryNames(this.props.countriesInformation, this.props.t)},
                  {key: invoiceAttributes.currency.key, value: getAllCurrencyList(this.props.countriesInformation)},
                  {key: subscriptionAttributes.subscriptionStatus.key, value: subscriptionAttributes.subscriptionStatus.value},
                ]
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSubscriptionSearch}
              onDownloadClick={this.handleDownloadSubscriptionListClick}/>
          <DataTableWrapperForListing
              noSelect
              wrapperClasses="table-responsive"
              keyField="subscriptionId" // one of 'dataField'
              tableStyle="table-width2000"
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "cpProductId", hidden: true},
                {dataField: "cpProductIdLink", text: this.props.t(productAttributes.cpProductId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate", titleHeader: "cpProductId"},
                {dataField: "cpProductName", text: this.props.t(productAttributes.productName.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-left text-truncate"},
                {dataField: "productStatus", text: this.props.t(productAttributes.productStatus.key), headerStyle: {width: "130px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "buyer", text: this.props.t(invoiceAttributes.buyer.key), headerStyle: {width: "150px"}, sort: false, classes: () => "text-truncate"},
                {dataField: "subscriptionId", hidden: true},
                {dataField: "subscriptionIdLink", text: this.props.t(subscriptionAttributes.subscriptionId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate", titleHeader: "subscriptionId"},
                {dataField: "buyerUid", text: this.props.t(invoiceAttributes.buyerUId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "orderCustomerId", text: this.props.t(invoiceAttributes.orderCustomerId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "countryName", text: this.props.t(invoiceAttributes.country.key), headerStyle: {width: "180px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "paymentCurrencyCode", text: this.props.t(invoiceAttributes.currency.key), headerStyle: {width: "100px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "paymentAmountDisplay", text: this.props.t(subscriptionAttributes.salesPrice.key), headerStyle: {width: "100px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "subscriptionStatus", text: this.props.t(subscriptionAttributes.subscriptionStatus.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "subscriptionStartDate", text: this.props.t(subscriptionAttributes.subscriptionStartDate.key), headerStyle: {width: "180px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "subscriptionEndDate", text: this.props.t(subscriptionAttributes.subscriptionEndDate.key), headerStyle: {width: "180px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "lastPaymentDate", text: this.props.t(subscriptionAttributes.lastPaymentDate.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "nextPaymentDate", text: this.props.t(subscriptionAttributes.nextPaymentDate.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "cancelYN", text: this.props.t("CANCEL_SUBSCRIPTION"), headerStyle: {width: "170px"}, sort: true},
              ]}
              page={this.state.pageNumber}
              sizePerPage={this.state.listSizePerPage}
              totalSize={this.state.totalListSize}
              rows={this.state.subscriptionList.map(subscription => {
                return {
                  ...subscription,
                  countryName: this.props.t(subscription.countryCode),
                  subscriptionIdLink:
                      <Link to={{}} title={subscription.subscriptionId}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_SALES_SUBSCRIPTION + "/" + subscription.subscriptionId);
                            }}>
                        {subscription.subscriptionId}
                      </Link>,
                  cpProductIdLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_PRODUCT_DETAIL + "/" + subscription.productId);
                            }}>
                        {subscription.cpProductId}
                      </Link>,
                  productStatus: this.props.t(productStatusCode2Key[subscription.productStatus]),
                  cancelYN:
                      Object.is(subscription.cancelButton, true) ? (
                          <button
                              type="button"
                              className="btn btn-auto btn-sm btn-outline-primary"
                              onClick={(e) => this.onCancelSubscriptionClick(subscription, e)}>
                            {this.props.t("CANCEL_SUBSCRIPTION")}
                          </button>
                      ) : (
                          <span>-</span>
                      ),
                  subscriptionStatus: this.props.t(subscriptionStatusCode2Key[subscription.subscriptionStatusCode])
                };
              })}
              nonSelectableRows={ // Should use 'keyField' value
                this.state.subscriptionList
                    .map(subscription => !subscription.cancelButton ? subscription.subscriptionId : null)
                    .filter(e => e != null)}
              onTableChange={this.handleTableChange}
              onTableSort={this.handleTableSort}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            <div className="float-left">
              <button
                  type="button"
                  className={`btn btn-sm btn-success ${nonSelected && "disabled"}`}
                  disabled={nonSelected}
                  onClick={() => this.handleCancelMultiSubscriptionClick(true)}>
                {this.props.t("CANCEL_SUBSCRIPTIONS")}
              </button>
            </div>
          </div>
          <BasePopup
              open={this.state.multipleSubscriptionModalOpen}
              className="modal-sm"
              title={this.props.t("SUBSCRIPTION")}
              onClose={() => this.handleCancelMultiSubscriptionClose(false)}
              body={
                <p>{this.props.t("DO_YOU_WANT_TO_CANCEL_SELECTED_SUBSCRIPTION_ITEM")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleCancelMultiSubscriptionClose(true)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleCancelMultiSubscriptionClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
          <BasePopup
              open={this.state.modalOpen}
              className="modal-sm"
              title={this.props.t("SUBSCRIPTION")}
              onClose={() => this.handleModalToggle(false)}
              body={
                <p>{this.props.t("DO_YOU_WANT_TO_CANCEL_THE_SUBSCRIPTION")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleModalToggle(true)}>
                    {this.props.t("OK")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleModalToggle(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
            <BasePopup
                open={this.state.downloadPopup}
                className="modal-sm"
                title={"Request"}
                onClose={() => {
                    this.setState({
                        downloadPopup : false
                    })
                }}
                body={
                    <p>{`Request ${this.state.downloadData} has been generated. Please check it's Progress on CSV Download Repository Page`}</p>
                }
                footer={
                    <>
                        <button type="button" className="btn btn-secondary" onClick={() =>this.setState({downloadPopup : false})}>
                            {this.props.t("OK")}
                        </button>

                        <button type="button" className="btn btn-secondary" onClick={() =>{
                            this.setState({downloadPopup : false})
                            this.props.history.push(URL_REQUEST_LIST);
                        }}>
                            {"Go to Page"}
                        </button>
                    </>
                }/>
        </div>
    );
  }

  componentDidMount() {
    this.fetchSubscriptionList(this.state.pageNumber, this.state.listSizePerPage)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchSubscriptionList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SubscriptionListPanel)));