import React from 'react';
import DatepickerxWrapper from '../pluginwrapper/DatepickerxWrapper';

function ValidPeriodDatePickers(props) {
  return (
      <div className="input-group input-group-datepicker">
        <div className="input-group-append flex-100">
          <DatepickerxWrapper className="form-control rounded"
                              startDate={props.startDatePicker}
                              endDate={props.endDatePicker}
                              isDateStart={true}
                              onInit={(datepicker) => props.handleDatepickerInit(true, datepicker, props.country)}/>
        </div>
        <div className="input-group-append">
          <span className="input-group-text bg-white border-0">~</span>
        </div>
        <div className="input-group-append flex-100">
          <DatepickerxWrapper className="form-control rounded"
                              startDate={props.startDatePicker}
                              endDate={props.endDatePicker}
                              isDateStart={false}
                              onInit={(datepicker) => props.handleDatepickerInit(false, datepicker, props.country)}/>
        </div>
      </div>
  )
}

export default ValidPeriodDatePickers;
