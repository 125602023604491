export const couponAttributes = {
  couponId: {
    key: "COUPON_ID",
    value: null // Less than or equal to 20 characters
  },
  couponIssueId: {
    key: "COUPON_ISSUE_ID",
    value: null
  },
  couponName: {
    key: "COUPON_NAME",
    value: null
  },
  couponCode: {
    key: "COUPON_CODE",
    value: null
  },
  couponType: {
    key: "COUPON_TYPE",
    value: [
      "FREE",
      "FREE_TRIAL",
    ]
  },
  targetRange: {
    key: "TARGET_RANGE",
    value: [
      "PRODUCT",
      "APP",
    ]
  },

  product: {
    key: "TARGET_PRODUCT",
    value: null
  },
  country: {
    key: "COUNTRY",
    value: null
  },
  totalIssued: {
    key: "TOTAL_ISSUED",
    value: null
  },
  remainingCount: {
    key: "REMAINING",
    value: null
  },
  validPeriod: {
    key: "VALID_PERIOD",
    value: null
  },
};

export const couponAllocationAttributes = {
  couponName: {
    key: "COUPON_NAME",
    value: null
  },
  couponIssueId: {
    key: "COUPON_ISSUE_ID",
    value: null
  },
  couponType: {
    key: "COUPON_TYPE",
    value: [
      "DISCOUNT_AMOUNT",
      "DISCOUNT_RATE",
      "FREE",
      "FREE_TRIAL",
    ]
  },
  couponTargetRange: {
    key: "TARGET_RANGE",
    value: [
      "PRODUCT",
      "APP",
    ]
  },
  product: {
    key: "TARGET_PRODUCT",
    value: null
  },
  country: {
    key: "COUNTRY",
    value: null
  },
  startDate: {
    key: "START_DATE",
    value: null
  },
  completeDate: {
    key: "COMPLETE_DATE",
    value: null
  },
  applicant: {
    key: "APPLICANT",
    value: null
  },
  status: {
    key: "STATUS",
    value: null
  },
  result: {
    key: "RESULT",
    value: null
  },
};

export const couponIssueAttributes = {
  couponIssueId: {
    key: "COUPON_ISSUE_ID",
    value: null
  },
  couponTargetRange: {
    key: "TARGET_RANGE",
    value: null
  },
  couponType: {
    key: "COUPON_TYPE",
    value: null
  },
  country: {
    key: "COUNTRY",
    value: null
  },
  currency: {
    key: "CURRENCY",
    value: null
  },
  productPrice: {
    key: "PRODUCT_PRICE",
    value: null
  },
  discountAmount: {
    key: "DISCOUNT_AMOUNT",
    value: null
  },
  discountRate: {
    key: "DISCOUNT_RATE",
    value: null
  },
  couponNumber: {
    key: "COUPON_NUMBER",
    value: null
  },
  buyerEmail: {
    key: "BUYER_EMAIL",
    value: null
  },
  zipCode: {
    key: "ZIP_CODE",
    value: null
  },
  invoiceId: {
    key: "INVOICE_ID",
    value: null
  },
  productName : {
    key : "PRODUCT_NAME",
    value  : null
  },
  issueDate: {
    key: "ALLOCATION_DATE",
    value: null
  },
  statusCode: {
    key: "STATUS",
    value: null
  },
  dateOfUse: {
    key: "DATE_OF_USE",
    value: null
  },
  refundDate: {
    key: "REFUND_DATE",
    value: null
  },
};



export const couponTypeKey2Code = {
  DISCOUNT_AMOUNT: "1",
  DISCOUNT_RATE: "2",
  FREE: "3",
  FREE_TRIAL: "4",
};

export const productTypeKey2Code = {
  PRODUCT: "PRD",
  APP: "APP",
};

export const couponTypeCode2Key = {
  "1": "DISCOUNT_AMOUNT",
  "2": "DISCOUNT_RATE",
  "3": "FREE",
  "4": "FREE_TRIAL",
};

export const productTypeCode2Key = {
  "PRD": "PRODUCT",
  "APP": "APP",
};

export const statusCode2Key = {
  "CPT": "Completed",
};


export const statusNumber2Key = {
  "01": "Issued",
  "03": "Registered",
  "05": "Reserved(Korea Only)",
  "06": "Used",
};
