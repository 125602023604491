import React from 'react';
import {withTranslation} from 'react-i18next';
import {itemDetails} from '../../utils/dashboardUtils';

class DashboardItem extends React.Component {

  render() {
    const itemDetail = itemDetails[this.props.name];
    return (
        <div className={`info bg-white rounded ${itemDetail.classes}`}>
          <div>
            <span className="d-block mb-6">
              <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.t(itemDetail.title)
                  }}/>
            </span>
            <div className="f22">{this.props.data}</div>
            {itemDetail.showDateRange &&
            <small className="text-muted d-block mt-10">{this.props.dateRange}</small>
            }
          </div>
        </div>
    )
  }
}

export default withTranslation()(DashboardItem);
