import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {isSelectedAppChanged} from '../../ducks/carousel';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {isDateFutureThan, durationKey} from '../../utils/searchBarUtils';
import {API_SUPPORT_NOTICE_LIST} from "../../utils/urlConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from "../../ducks/loading";
import {toast} from 'react-toastify';

class NoticePanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      noticeListPage: 0,
      totalNoticeListSize: 0,
      noticeListSizePerPage: 0,
      fetchedNoticeList: [],
      searchParams: {},
    }
  }

  fetchDefaultNoticeList = (page, sizePerPage) => {
    const monthBefore12 = new Date();
    monthBefore12.setFullYear(monthBefore12.getFullYear() - 1);
    var date = new Date();
    var endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    var startDateString = new Date(monthBefore12.getTime() - (monthBefore12.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    const params = {
      dateStart: startDateString,
      dateEnd: endDateString,
      sortOrder: "DESC",
      sortName: "registeredDate",
      pageNumber: page,
      limit: sizePerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUPPORT_NOTICE_LIST, {
            params: params
          })
          .then(response => {
            this.setState({
              noticeListPage: page,
              noticeListSizePerPage: sizePerPage,
              fetchedNoticeList: response.data.data.map(item => {
                return {...item, noticeShow: false}
              }),
              totalNoticeListSize: response.data.records
            });
            if (this.state.fetchedNoticeList.length) {
              this.state.fetchedNoticeList[0].noticeShow = true;
            }
            this.props.stopLoading();
          })
          .catch(error => {
            this.props.stopLoading();
            handleHttpError(this.props.t, this.props.history, error);
          });
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      noticeListPage: page,
      noticeListSizePerPage: sizePerPage,
    }, () => this.triggerSearch());
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      noticeListPage: 1
    }, () => this.triggerSearch())
  };

  triggerSearch = () => {
    const {searchParams} = this.state;
    searchParams.contentId = this.props.carousel.selectedContsId;
    searchParams.pageNumber = this.state.noticeListPage;
    searchParams.limit = this.state.noticeListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_SUPPORT_NOTICE_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                fetchedNoticeList: response.data.data.map(item => {
                  return {...item, noticeShow: false}
                }),
                totalNoticeListSize: response.data.records
              });
              if (this.state.fetchedNoticeList.length) {
                this.state.fetchedNoticeList[0].noticeShow = true;
              }
              this.props.stopLoading();
            })
            .catch(error => {
              this.props.stopLoading();
              handleHttpError(this.props.t, this.props.history, error);
            });
      });
    }
  };

  handleAccordion = (id) => {
    this.setState(prevState => {
      return {
        fetchedNoticeList: prevState.fetchedNoticeList.map((item) => {
          if (item.noticeId === id) {
            item.noticeShow = !item.noticeShow;
          } else {
            item.noticeShow = false;
          }
          return item;
        })
      }
    });
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: ["NOTICE_TITLE", "NOTICE_CONTENT"],
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("REGISTRATION_DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                maxDate: true
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              keyField="noticeId"
              injectedColumns={[
                {
                  dataField: "noticeId", title: false, hidden: true, headerFormatter: () => {
                  }
                },
                {
                  dataField: "noticeRow", title: false, classes: () => "text-left border-none", headerFormatter: () => {
                  }
                }
              ]}
              page={this.state.noticeListPage}
              sizePerPage={this.state.noticeListSizePerPage}
              totalSize={this.state.totalNoticeListSize}
              hideSelectColumn={true}
              headerClass={"col-hidden"}
              rows={this.state.fetchedNoticeList.map(notice => {
                return {
                  noticeId: notice.noticeId,
                  noticeRow: <div className={`accordion ${notice.noticeShow && "show"}`}>
                    <div className="accordion-header">
                      <Link to={{}} onClick={() => this.handleAccordion(notice.noticeId)} className="questions none-before accordion-toggle">
                        {notice.noticeTitle}
                        <span className="h6 mb-0 float-right mt-1 mr-10">{notice.registeredDate}</span>
                      </Link>
                    </div>
                    <div className="accordion-body pb-0 pt-6 mt-9">
                      <div className="answer none-before">
                        <div
                            dangerouslySetInnerHTML={{
                              __html: notice.noticeContent
                            }}/>
                        {notice.fileName &&
                        <>
                          <br/>
                          <a href={notice.s3FilePath} download={notice.Filename} className="underline" title={notice.s3FilePath}>{notice.fileName}</a>
                        </>
                        }
                      </div>
                    </div>
                  </div>,
                };
              })}
              onTableChange={this.handleTableChange}  />
        </div>
    );
  }

  componentDidMount() {
    this.fetchDefaultNoticeList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultNoticeList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(NoticePanel)));
