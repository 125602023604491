import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import BasePopup from '../popup/BasePopup';
import {Link, withRouter} from 'react-router-dom';
import {URL_CAMPAIGN_COUPON, URL_CAMPAIGN_ISSUECOUPON, URL_GET_COUPON_LIST_DATA_API, URL_MODIFY_COUPON_VALIDPERIOD_API} from '../../utils/urlConstants';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {isDateFutureThan, durationKey} from '../../utils/searchBarUtils';
import {couponAttributes, couponTypeCode2Key, couponTypeKey2Code, productTypeCode2Key, productTypeKey2Code} from '../../utils/couponAttributes';
import {toast} from 'react-toastify';
import DatepickerxWrapper from '../pluginwrapper/DatepickerxWrapper';
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';

class CouponListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      // for coupon list data table, will be init in fetchCouponList
      couponListPage: 0,
      totalCouponListSize: 0,
      couponListSizePerPage: 0,
      fetchedCouponList: [],
      selectedCouponIdList: [],
      searchParams: {},

      // for modal
      modalOpen: false,
      validStartDate: null,
      validEndDate: null,
      startDatePicker: null,
      endDatePicker: null,
      changeReason: "",
      selectedCouponIssueId: null,
    }
  }

  handleTableSort = (sortOrder, sortField) => {
    const couponHeaderField2SortName = {
      "couponName": "couponName",
      "couponIdLink": "couponId",
      "couponType": "couponType",
      "productType": "couponTargetRange",
      "product": "productName",
      "couponIssueId": "couponIssueId",
      "country": "countryCode",
      "totalIssued": "totalIssued",
      "remainingCount": "remainingCount",
      "validPeriod": "validStartDate",
      "refund": "orderCancelDate"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: couponHeaderField2SortName[sortField] ? couponHeaderField2SortName[sortField] : sortField,
      couponListPage: 1,
    }, () => this.search())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      couponListPage: page,
      couponListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  handleModalOpen = (validStartDate, validEndDate, couponId) => {
    this.setState({selectedCouponIssueId: couponId});
    this.state.startDatePicker.setValue(new Date(validStartDate));
    this.state.endDatePicker.setValue(new Date(validEndDate));
    this.setState({modalOpen: true});
  };

  handleDatepickerInit = (isStart, datepicker) => {
    isStart ? this.setState({startDatePicker: datepicker}) : this.setState({endDatePicker: datepicker});
  };

  handleChangeReason = (event) => {
    let input = event.target.value;
    this.setState({
      changeReason: input
    });
  };

  handleModalClose = (isSave) => {
    if (isSave) {
      if (new Date(this.state.startDatePicker.getValue()) > new Date(this.state.endDatePicker.getValue())) {
        toast("INVALID_DATE_RANGE");
        return
      }
      if (new Date(this.state.endDatePicker.getValue()) < new Date()) {
        toast("YOU_CANT_ENTER_PAST_DATES");
        return
      }
      const params = {};
      params.couponIssueId = this.state.selectedCouponIssueId;
      params.changeReason = this.state.changeReason;
      params.validStartDate = this.state.startDatePicker.getValue();
      params.validEndDate = this.state.endDatePicker.getValue();
      this.props.startLoading();
      this.httpClient
          .post(URL_MODIFY_COUPON_VALIDPERIOD_API, params)
          .then(response => {
            this.setState(prevState => {
              return {
                fetchedCouponList: prevState.fetchedCouponList.map((item) => {
                  if (item.couponIssueId === this.state.selectedCouponIssueId) {
                    item.validStartDate = this.state.startDatePicker.getValue();
                    item.validEndDate = this.state.endDatePicker.getValue();
                  }
                  return item;
                })
              }
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });

      toast("DATE_CHANGED_SUCCESSFULLY");
    }
    this.setState({modalOpen: false});
  };

  handleSearchClick = (searchParams) => {
    const monthBefore12 = new Date();
    monthBefore12.setFullYear(monthBefore12.getFullYear() - 1);
    var date = new Date();
    var endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    var startDateString = new Date(monthBefore12.getTime() - (monthBefore12.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    if (!searchParams) {
      this.setState({
        couponListSizePerPage: 15,
        couponListPage: 1,
      });
    }
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams ? searchParams.startDate : startDateString,
      dateEnd: searchParams ? searchParams.endDate : endDateString,
      duration: searchParams ? searchParams.duration : durationKey.DURATION_YEAR,
      couponType: searchParams ? (searchParams.selectedFilter[0].value ? couponTypeKey2Code[searchParams.selectedFilter[0].value] : "") : "",
      couponTargetRange: searchParams ? (searchParams.selectedFilter[1].value ? productTypeKey2Code[searchParams.selectedFilter[1].value] : "") : "",
      keywordType: searchParams ? searchParams.keywordOption : "",
      searchText: searchParams ? searchParams.keywordText : "",
      sortName: "",
      sortOrder: "DESC",
      pageNumber: this.state.couponListPage,
      limit: this.state.couponListSizePerPage
    };
    if (isDateFutureThan(params.dateStart, params.dateEnd)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    this.setState({searchParams: params}, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.pageNumber = this.state.couponListPage;
    searchParams.limit = this.state.couponListSizePerPage;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_COUPON_LIST_DATA_API, {
          params: searchParams
        })
        .then(response => {
          this.setState({
            fetchedCouponList: response.data.data,
            totalCouponListSize: response.data.records
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });

  };

  render() {
    const {fetchedCouponList} = this.state;
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: [couponAttributes.couponId.key, couponAttributes.couponName.key, couponAttributes.couponIssueId.key,
                          couponAttributes.product.key, couponAttributes.couponCode.key],
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("VALID_PERIOD"),
                defaultDuration: durationKey.DURATION_YEAR,
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: couponAttributes.couponType.key, value: couponAttributes.couponType.value},
                  {key: couponAttributes.targetRange.key, value: couponAttributes.targetRange.value},
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              keyField="couponIssueId" // one of 'dataField'
              hideSelectColumn={true}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "couponName", text: this.props.t(couponAttributes.couponName.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "couponIdLink", text: this.props.t(couponAttributes.couponId.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate", titleHeader: "couponId"},
                {dataField: "couponType", text: this.props.t(couponAttributes.couponType.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "productType", text: this.props.t(couponAttributes.targetRange.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "product", text: this.props.t(couponAttributes.product.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "couponIssueId", text: this.props.t(couponAttributes.couponIssueId.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "country", text: this.props.t(couponAttributes.country.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "totalIssued", text: this.props.t(couponAttributes.totalIssued.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "remainingCount", text: this.props.t(couponAttributes.remainingCount.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "validPeriod", text: this.props.t(couponAttributes.validPeriod.key), headerStyle: {width: "9%"}, sort: true, classes: () => "text-truncate", titleHeader: "validEndDate"},
              ]}
              page={this.state.couponListPage}
              sizePerPage={this.state.couponListSizePerPage}
              totalSize={this.state.totalCouponListSize}
              rows={fetchedCouponList.map(coupon => {
                return {
                  ...coupon,
                  couponIdLink: <Link to={{}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(URL_CAMPAIGN_COUPON + "/" + coupon.couponId);
                                      }}>
                    {coupon.couponId}
                  </Link>,
                  couponType: this.props.t(couponTypeCode2Key[coupon.couponType]),
                  productType: this.props.t(productTypeCode2Key[coupon.couponTargetRange]),
                  country: this.props.t(coupon.countryCode),
                  product: coupon.productName,
                  validPeriod: new Date(coupon.validEndDate) >= new Date() ? <Link to={{}}
                                                                                   onClick={(e) => {
                                                                                     e.preventDefault();
                                                                                     this.handleModalOpen(coupon.validStartDate, coupon.validEndDate, coupon.couponIssueId);
                                                                                   }}>
                    {coupon.validStartDate} ~ {coupon.validEndDate}
                  </Link> : <>{coupon.validStartDate} ~ {coupon.validEndDate}</>,
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
          />
          <div className="pagenationGroup clearfix max">
            <div className="float-right">
              <Link to={URL_CAMPAIGN_ISSUECOUPON}>
                <button type="button" className="btn btn-sm btn-primary">
                  {this.props.t("ISSUE_COUPON")}
                </button>
              </Link>
            </div>
          </div>
          {/* Below is for popup for 'Change Visibility' button */}
          <BasePopup
              open={this.state.modalOpen}
              title={this.props.t("CHANGE_VALID_PERIOD")}
              onClose={() => this.handleModalClose(false)}
              body={
                <>
                  <div className="form-group row">
                    <label className="col-3 col-form-label align-items-center d-flex"><strong>{this.props.t("VALID_PERIOD")}</strong></label>
                    <div className="col-9">
                      <div className="input-group input-group-datepicker">
                        <div className="input-group-append flex-100">
                          <DatepickerxWrapper className="form-control rounded" startDate={this.state.startDatePicker} endDate={this.state.endDatePicker} isDateStart={true} onInit={(datepicker) => this.handleDatepickerInit(true, datepicker)}/>
                        </div>
                        <div className="input-group-append">
                          <span className="input-group-text bg-white border-0">~</span>
                        </div>
                        <div className="input-group-append flex-100">
                          <DatepickerxWrapper className="form-control rounded" startDate={this.state.startDatePicker} endDate={this.state.endDatePicker} isDateStart={false} onInit={(datepicker) => this.handleDatepickerInit(false, datepicker)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label"><strong>{this.props.t("REASON_FOR_CHANGE")}</strong></label>
                    <div className="col-9">
                      <div className="textarea_count">
                        <input type="text"
                               maxLength="100"
                               className="form-control text_counter"
                               value={this.state.changeReason}
                               onChange={(event) => {
                                 this.handleChangeReason(event);
                               }}
                               placeholder={this.props.t("ENTER_REASON_FOR_CHANGE")}/>
                        <div className="counts text-right"><span className="text_count">{this.state.changeReason.length}</span> / 100Byte</div>
                      </div>
                    </div>
                  </div>
                </>
              }
              footer={
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleModalClose(true)}>{this.props.t("CONFIRM")}</button>
                </div>
              }/>
        </div>
    );
  }

  componentDidMount() {
    this.handleSearchClick();
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CouponListPanel)));
