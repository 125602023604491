export const memberAttributes = {
  userId: {
    key: 'USER_ID',
    value: null
  },
  userName: {
    key: 'USER_NAME',
    value: null
  },
  groupCount: {
    key: 'GROUP_COUNT',
    value: null
  },
  status: {
    key: 'STATUS',
    value: [
      'INACTIVE',
      'ACTIVE',
      'UNSUBSCRIBED'
    ]
  },
  settlementAccess: {
    key: "MONTHLY_SETTLEMENT_ACCESS",
    value: [
        'YES',
        'NO',
    ]
  },
  accessDate: {
    key: 'ACCESS_DATE',
    value: null
  },
};

export const groupAttributes = {
  groupName: {
    key: 'GROUP_NAME',
    value: null
  },
  menuCount: {
    key: 'MENU_COUNT',
    value: null
  },
  memberCount: {
    key: 'MEMBER_COUNT',
    value: null
  },
  createdDate: {
    key: 'CREATED_DATE',
    value: null
  }
};

export const memberStatusCode2Key = {
  "00": "INACTIVE",
  "01": "ACTIVE",
  "02": "UNSUBSCRIBED"
};

export const memberStatusKey2Code = {
  "INACTIVE": "00",
  "ACTIVE": "01",
  "UNSUBSCRIBED": "02"
};
