import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {productAttributes} from "../../utils/productTypesAndAttributes";
import SearchBar from "../searchbar/SearchBar";
import {appAttributes, findDays, invoiceAttributes, invoiceReflectionStatusCode2Key, invoiceReflectionStatusKey2Code, paymentMethodCode2Key, paymentMethodKey2Code, transactionStatusCode2Key, transactionStatusKey2Code, transactionTypeCode2Key, transactionTypeKey2Code} from "../../utils/invoiceAttributes";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import BasePopup from "../popup/BasePopup";
import RequestRefundPopUp from "../popup/RequestRefundPopUp";
import {isSelectedAppChanged} from '../../ducks/carousel';
import {
  API_SALES_INVOICE_COUNT,
  API_SALES_INVOICE_LIST_DOWNLOAD, API_SALES_INVOICE_LIST_INHOUSE_DOWNLOAD,
  API_SALES_MULTIPLE_INVOICE_REFUND,
  API_SALES_SINGLE_INVOICE_REFUND,
  API_SALES_TRANSACTION_LIST,
  URL_REQUEST_LIST,
  URL_SALES_SALESPRODUCT
} from '../../utils/urlConstants';
import {getAllCurrencyList} from "../../ducks/countriesInformation";
import {durationKey, getDefaultTimePeriod_1Month, isDateFutureThan} from '../../utils/searchBarUtils';
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from '../../ducks/loading';
import {convertToCountryCode, sortCountryNames} from '../../utils/CountryListUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {checkEmptyRefundAmountForPartialRefund, checkRefundAmountExceedPaymentAmount, checkRefundAmountValidForPartialRefund, setZeroRefundAmountForPartialRefund} from '../../utils/RefundUtils';

class InvoiceListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      // for product list data table, will be init in fetchProductList
      invoiceListPage: 1,
      totalInvoiceListSize: 0,
      invoiceListSizePerPage: 15,
      fetchedInvoiceList: [],
      fetchedProductNameList: [],
      selectedInvoiceList: [],

      // for modal
      currentInvoice: null,
      modalOpen: false,
      multiModalOpen: false,
      modalVisibilityValue: "",
      searchParams: {},
      sortOrder: "DESC",
      sortName: "orderDate",
      alertPopup: false
    }
  }

  fetchInvoiceList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod_1Month();
    const params = {
      dateStart: searchPeriod.startDate,
      dateEnd: searchPeriod.endDate,
    };
    this.setState({searchParams: params}, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        cpAppId: this.props.carousel.selectedAppId,
        sortName: this.state.sortName,
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.invoiceListPage,
        limit: this.state.invoiceListSizePerPage
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_SALES_TRANSACTION_LIST, {
              params: params
            })
            .then(response => {
              this.setState({
                selectedInvoiceList: [],
                invoiceListPage: page,
                invoiceListSizePerPage: sizePerPage,
                totalInvoiceListSize: response.data.records,
                fetchedInvoiceList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    })
  };

  handleInvoiceSelect = (isSelect, rows) => {

    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedInvoiceList.find(invoiceId => row["invoiceId"] === invoiceId)) {
            return {
              selectedInvoiceList: [...prevState.selectedInvoiceList, {
                invoiceId: row["invoiceId"],
                buyerGuid: row["buyerGuid"],
                buyerUid: row["buyerUId"],
                cancelDesc: "",
                countryCode: row["countryCode"],
                langCode: this.props.i18n.language
              }
              ]
            };
          }
        });
      } else {
        this.setState(prevState => {
          return {
            selectedInvoiceList: prevState.selectedInvoiceList.filter(invoice => row["invoiceId"] !== invoice.invoiceId)
          };
        })
      }
    });
  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      invoiceListPage: page,
      invoiceListSizePerPage: sizePerPage,
    }, () => this.triggerInvoiceSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const invoiceHeaderField2SortName = {
      "invoiceIdLink": "invoiceId",
      "orderCustomerId": "orderCustomerId",
      "country": "countryCode",
      "currency": "currencyCode",
      "paymentMethod": "paymentMethodCode",
      "transactionStatus": "transactionStatusCode",
      "refund": "orderCancelDate"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: invoiceHeaderField2SortName[sortField] ? invoiceHeaderField2SortName[sortField] : sortField
    }, () => this.triggerInvoiceSearch())
  };

  handleSingleRefundToggle = (invoice, e) => {
    this.setState({currentInvoice: invoice, modalOpen: true}, () => {
      let result = findDays(new Date(this.state.currentInvoice.orderDate), this.state.currentInvoice.countryCode);
      this.setState({alertPopup: result});
    });
    e.preventDefault();
  };

  handleMultiModalToggle = (isSave, isOpen) => {
    this.setState({multiModalOpen: isOpen});
    if (isSave) {
      const {selectedInvoiceList} = this.state;
      this.props.startLoading();
      return new Promise((resolve, reject) => {
        this.httpClient
            .post(API_SALES_MULTIPLE_INVOICE_REFUND, selectedInvoiceList)
            .then((response) => {
              this.fetchInvoiceList(this.state.invoiceListPage, this.state.invoiceListSizePerPage);
              toast(response.data.completeRefund + " / " + response.data.totalRefundRequest + " Invoices Refunded");
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
              reject(error);
            })
      })
    }
  };

  handleRequestRefundPopupClose = (isSave, reason, refundType, refundAmount, samsungRewardsRefundAmount) => {
    this.setState({modalOpen: false, alertPopup: false});
    if (refundType === "PARTIAL_REFUND") {
      if (checkEmptyRefundAmountForPartialRefund(refundAmount, samsungRewardsRefundAmount)) {
        toast(this.props.t("PLEASE_ENTER_THE_REFUND_AMOUNT"));
        return;
      }
      let partialRefundAmountFloat = parseFloat(refundAmount);
      let partialSamsungRewardsRefundAmountFloat = parseFloat(samsungRewardsRefundAmount) ;
      let paymentAmount = parseFloat(this.state.currentInvoice.paymentAmount);
      let samsungRewardsUsedAmount = parseFloat(this.state.currentInvoice.samsungRewardsAmount)
      if (checkRefundAmountValidForPartialRefund(partialRefundAmountFloat, partialSamsungRewardsRefundAmountFloat)) {
        toast(this.props.t("PLEASE_ENTER_VALID_REFUND_AMOUNT"));
        return;
      }
      if (checkRefundAmountExceedPaymentAmount(partialRefundAmountFloat, partialSamsungRewardsRefundAmountFloat, paymentAmount,samsungRewardsUsedAmount)) {
        toast(this.props.t("PARTIAL_REFUND_AMOUNT_GREATER_THAN_FULL_PAYMENT_AMOUNT"));
        return;
      }
    }
    if (isSave) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_SALES_SINGLE_INVOICE_REFUND, {
              invoiceId: this.state.currentInvoice.invoiceId,
              buyerGuid: this.state.currentInvoice.buyerGuid,
              buyerUid: this.state.currentInvoice.buyerUId,
              cancelDesc: reason,
              countryCode: this.state.currentInvoice.countryCode,
              langCode: this.props.i18n.language,
              refundAmount: refundType === "FULL_REFUND" ? "" : setZeroRefundAmountForPartialRefund(refundAmount),
              samsungRewardsRefundAmount: refundType === "FULL_REFUND" ? "" : setZeroRefundAmountForPartialRefund(samsungRewardsRefundAmount)
            })
            .then((response) => {
              if (Object.is(response.data.code, '100000')) {
                this.fetchInvoiceList(this.state.invoiceListPage, this.state.invoiceListSizePerPage);
              } else {
                toast(response.data.name);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    }
  };

  CheckIsDateLessThanYear = (_startDate, _endDate)  => {
    const startDate = new Date(_startDate);
    const nextYearStartDate = new Date(startDate);
    nextYearStartDate.setFullYear(startDate.getFullYear() + 1);
    const endDate = new Date(_endDate);
    return endDate <= nextYearStartDate;
  }

  handleInvoiceSearch = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    if (!this.CheckIsDateLessThanYear(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("DATES_SHOULD_BE_LESS_THAN_YEAR"))
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      countries: searchParams.selectedFilter[0].value ? searchParams.selectedFilter[0].value : "",
      currencies: searchParams.selectedFilter[1].value ? searchParams.selectedFilter[1].value : "",
      paymentMethod: searchParams.selectedFilter[2].value ? paymentMethodKey2Code[searchParams.selectedFilter[2].value] : "",
      additionalPaymentInfo: searchParams.selectedFilter[3].value ? searchParams.selectedFilter[3].value : "",
      transactionType: searchParams.selectedFilter[4].value ? transactionTypeKey2Code[searchParams.selectedFilter[4].value] : "",
      paymentStatus: searchParams.selectedFilter[5].value ? invoiceReflectionStatusKey2Code[searchParams.selectedFilter[5].value] : "",
      transactionStatus: searchParams.selectedFilter[6].value ? transactionStatusKey2Code[searchParams.selectedFilter[6].value] : "",
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      pageNumber: 1,
      rowsPerPage: this.state.invoiceListSizePerPage
    };
    this.setState({
      searchParams: params,
      invoiceListPage: 1,
      sortOrder: "DESC",
      sortName: "orderDate"
    }, () => {
      const countryCode = convertToCountryCode(this.props.countriesInformation, params.countries, this.props.t);
      if (countryCode.length) {
        params.countries = countryCode;
      }
      this.triggerInvoiceSearch()
    })
  };

  refundColumnSorting = (data) => {
    data.sort(function (a, b) {
      return (!a.refundButton && a.orderCancelDate === null) - (!b.refundButton && b.orderCancelDate === null);
    })
    if (this.state.sortOrder === "DESC") {
      data.reverse();
    }
    return data;
  }

  triggerInvoiceSearch = () => {
    const {searchParams} = this.state;
    searchParams.cpAppId = this.props.carousel.selectedAppId;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.invoiceListPage;
    searchParams.limit = this.state.invoiceListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_SALES_TRANSACTION_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalInvoiceListSize: response.data.records,
                fetchedInvoiceList: this.state.sortName === "orderCancelDate" ? this.refundColumnSorting(response.data.data) : response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }
  };

  handleDownloadClick = (searchParams) => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      sortOrder: "DESC",
      pageNumber: this.state.invoiceListPage,
      limit: this.state.invoiceListSizePerPage
    };

    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SALES_INVOICE_COUNT, {
            params: params
          })
          .then(response => {
            if (response.data) {
              this.csvDownloadNewApi(params)
            } else {
              this.csvDownloadOldApi(params);
            }
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  }

  csvDownloadNewApi = (params) => {
    return new Promise((resolve, reject) => {
      this.httpClient
          .get(API_SALES_INVOICE_LIST_DOWNLOAD, {
            params: params
          })
          .then(response => {
            this.setState({
              downloadPopup :  true,
              downloadData :  response.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  }

  csvDownloadOldApi = (params) => {
    handleDownloadFile(API_SALES_INVOICE_LIST_INHOUSE_DOWNLOAD, params, this.props.t, this.props.history,
        this.httpClient, this.props.startLoading, this.props.stopLoading);
  }

  render() {
    const nonSelected = this.state.selectedInvoiceList.length === 0;
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                showToolTip: true,
                toolTipText: [this.props.t("INVOICE_LIST_PANEL_TOOLTIP_LINE_1"), <br/>,
                  this.props.t("KEYWORD_PANEL_TOOLTIP_LINE_2"), <br/>,
                  this.props.t("KEYWORD_PANEL_TOOLTIP_LINE_3")],
                label: "Keyword",
                placeholder: "Please enter Keywords.",
                options: [invoiceAttributes.invoiceId.key,
                  invoiceAttributes.buyer.key,
                  appAttributes.appId.key,
                  appAttributes.appName.key,
                  productAttributes.productId.key,
                  productAttributes.productName.key],
                  showDownload: true,
                  downloadToolTip :  true
              }}
              datePanel={{
                show: true,
                label: this.props.t(invoiceAttributes.orderDate.key), // Order Date
                defaultDuration: durationKey.DURATION_ONE_MONTH,
                durationOptions: [
                  durationKey.DURATION_ONE_MONTH,
                  durationKey.DURATION_SIX_MONTH,
                  durationKey.DURATION_YEAR,
                ],
                maxDate: true
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: invoiceAttributes.country.key, value: sortCountryNames(this.props.countriesInformation, this.props.t)},
                  {key: invoiceAttributes.currency.key, value: getAllCurrencyList(this.props.countriesInformation)},
                  {key: invoiceAttributes.paymentMethod.key, value: invoiceAttributes.paymentMethod.value},
                  {key: invoiceAttributes.additionalPaymentInfo.key, value: invoiceAttributes.additionalPaymentInfo.value},
                  {key: invoiceAttributes.transactionType.key, value: invoiceAttributes.transactionType.value},
                  {key: invoiceAttributes.reflectionStatus.key, value: invoiceAttributes.reflectionStatus.value},
                  {key: invoiceAttributes.transactionStatus.key, value: invoiceAttributes.transactionStatus.value},
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleInvoiceSearch}
              onDownloadClick={this.handleDownloadClick}/>
          <DataTableWrapperForListing
              noSelect
              wrapperClasses="table-responsive"
              keyField="invoiceId" // one of 'dataField'
              tableStyle="table-width2000"
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "invoiceId", hidden: true},
                {dataField: "invoiceIdLink", text: this.props.t(invoiceAttributes.invoiceId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate", titleHeader: "invoiceId"},
                {dataField: "appId", text: this.props.t(appAttributes.appId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "appName", text: this.props.t(appAttributes.appName.key), headerStyle: {width: "170px"}, sort: true, classes: () => "text-left text-truncate"},
                {dataField: "cpProductId", text: this.props.t(productAttributes.cpProductId.key), headerStyle: {width: "100px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "productName", text: this.props.t(productAttributes.productName.key), headerStyle: {width: "120px"}, sort: true, classes: () => "text-left text-truncate"},
                {dataField: "buyer", text: this.props.t(invoiceAttributes.buyer.key), headerStyle: {width: "70px"}, sort: false, classes: () => "text-truncate"},
                {dataField: "buyerUId", text: this.props.t(invoiceAttributes.buyerUId.key), headerStyle: {width: "100px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "orderCustomerId", text: this.props.t(invoiceAttributes.orderCustomerId.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "country", text: this.props.t(invoiceAttributes.country.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "zipCode", text: this.props.t(invoiceAttributes.zipCode.key), headerStyle: {width: "90px"}, sort: false, classes: () => "text-truncate"},
                {dataField: "currency", text: this.props.t(invoiceAttributes.currency.key), headerStyle: {width: "90px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "paymentAmount", text: this.props.t(invoiceAttributes.paymentAmount.key), headerStyle: {width: "140px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "samsungRewardsAmount", text: this.props.t(invoiceAttributes.samsungRewardsAmount.key), headerStyle: {width: "210px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "paymentMethod", text: this.props.t(invoiceAttributes.paymentMethod.key), headerStyle: {width: "140px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "additionalPaymentInfo", text: this.props.t(invoiceAttributes.additionalPaymentInfo.key), headerStyle: {width: "190px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "transactionType", text: this.props.t(invoiceAttributes.transactionType.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "reflectionStatus", text: this.props.t(invoiceAttributes.reflectionStatus.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "transactionStatus", text: this.props.t(invoiceAttributes.transactionStatus.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "orderDate", text: this.props.t(invoiceAttributes.orderDate.key), headerStyle: {width: "150px"}, sort: true, classes: () => "text-truncate"},
                {dataField: "refund", text: this.props.t("REFUND_PRODUCT"), headerStyle: {width: "90px"}, sort: true, hideTitle: true},
              ]}
              page={this.state.invoiceListPage}
              sizePerPage={this.state.invoiceListSizePerPage}
              totalSize={this.state.totalInvoiceListSize}
              rows={this.state.fetchedInvoiceList.map(invoice => {
                return {
                  ...invoice,
                  appId: invoice.settlementAppId !== '-' ? invoice.settlementAppId : invoice.appId,
                  appName: invoice.settlementAppId !== '-' ? invoice.settlementAppName : invoice.appName,
                  country: this.props.t(invoice.countryCode),
                  currency: this.props.t(invoice.currencyCode),
                  paymentAmount: invoice.paymentAmountDisplay,
                  samsungRewardsAmount: invoice.samsungRewardsUsedAmountDisplay,
                  additionalPaymentInfo: invoice.samsungRewardsUsedAmount !== 0 ? "Samsung Rewards" : invoice.additionalPaymentInfo,
                  invoiceIdLink:
                      <Link to={{}} title={invoice.invoiceId}
                            onClick={(e) => {
                              e.preventDefault();
                              const pushUrl = (Object.is(invoice.transactionStatusCode, '202') ||
                                  Object.is(invoice.transactionStatusCode, '203')) ? invoice.invoiceId : invoice.invoiceCancelId;
                              this.props.history.push(URL_SALES_SALESPRODUCT + "/" + pushUrl)

                            }}>
                        {invoice.invoiceId}
                      </Link>,
                  reflectionStatus: this.props.t(invoiceReflectionStatusCode2Key[invoice.reflectionStatus]),
                  transactionStatus: this.props.t(transactionStatusCode2Key[invoice.transactionStatusCode]),
                  paymentMethod: paymentMethodCode2Key[invoice.paymentMethodCode],
                  transactionType: this.props.t(transactionTypeCode2Key[invoice.transactionType]),
                  refund:
                      invoice.refundButton ? (
                          <button
                              type="button"
                              className="btn btn-auto btn-sm btn-outline-primary"
                              onClick={(e) => this.handleSingleRefundToggle(invoice, e)}>
                            {this.props.t("REFUND")}
                          </button>
                      ) : (
                          invoice.orderCancelDate
                      )
                };
              })}
              nonSelectableRows={ // Should use 'keyField' value
                this.state.fetchedInvoiceList
                    .map(invoice => !invoice.refundButton ? invoice.invoiceId : null)
                    .filter(e => e != null)}
              onTableChange={this.handleSizePerPageChange}
              onTableSort={this.handleTableSort}
              onSizePerPageChange={this.handleSizePerPageChange}
              onRowSelect={this.handleInvoiceSelect}/>
          <div className="pagenationGroup clearfix max">
            <div className="float-left">
              <button
                  type="button"
                  className={`btn btn-sm btn-success ${nonSelected && "disabled"}`}
                  disabled={nonSelected}
                  onClick={() => this.handleMultiModalToggle(false, true)}>
                {this.props.t("MULTIPLE_REFUND")}
              </button>
            </div>
          </div>
          <BasePopup
              open={this.state.multiModalOpen}
              className="modal-sm"
              title={"Refund"}
              onClose={() => this.handleMultiModalToggle(false, false)}
              body={
                <p>{this.props.t("DO_YOU_WANT_TO_REFUND_ITEM")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleMultiModalToggle(true, false)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleMultiModalToggle(false, false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
          <BasePopup
              open={this.state.downloadPopup}
              className="modal-sm"
              title={"Request"}
              onClose={() => {
                this.setState({
                  downloadPopup : false
                })
              }}
              body={
              <>
                  <p>{`Request ${this.state.downloadData} has been generated. Please check it's Progress on CSV Download Repository Page`}</p>
              </>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() =>this.setState({downloadPopup : false})}>
                    {this.props.t("OK")}
                  </button>
                    <button type="button" className="btn btn-secondary" onClick={() =>{
                        this.setState({downloadPopup : false})
                        this.props.history.push(URL_REQUEST_LIST);
                    }}>
                        {"Go to Page"}
                    </button>
                </>
              }/>
          {this.state.currentInvoice &&
              <RequestRefundPopUp
                  currentInvoice={this.state.currentInvoice}
                  buyer={this.state.currentInvoice.buyer}
                  invoiceId={this.state.currentInvoice.invoiceId}
                  paymentAmount={this.state.currentInvoice.paymentAmountDisplay}
                  paymentAmountNumber={this.state.currentInvoice.paymentAmount}
                  paymentMethod={paymentMethodCode2Key[this.state.currentInvoice.paymentMethodCode]}
                  additionalPaymentInfo={this.state.currentInvoice.additionalPaymentDetail}
                  open={this.state.modalOpen}
                  alertPopup={this.state.alertPopup}
                  handlePopupClose={this.handleRequestRefundPopupClose}
                  samsungRewardsUsedAmount={this.state.currentInvoice.samsungRewardsUsedAmount}
                  samsungRewardsUsedAmountDisplay={this.state.currentInvoice.samsungRewardsUsedAmountDisplay}
                  key={this.state.currentInvoice.invoiceId}
              />
          }
        </div>
    )
        ;
  }

  componentDidMount() {
    this.fetchInvoiceList(this.state.invoiceListPage, this.state.invoiceListSizePerPage)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchInvoiceList(this.state.invoiceListPage, this.state.invoiceListSizePerPage)
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(InvoiceListPanel)));