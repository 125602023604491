import React from 'react';

function SupportFooter(props) {
  return (
      <div className="mt-50">
        <div className="row align-items-center justify-content-center">
          <div className="col-3 align-items-center justify-content-center d-flex">
            <div className="step_pen">
              <h2 className="mb-15"><strong>{props.feedbackTitle}</strong></h2>
              <span>cs.checkout@partner.sec.co.kr</span>
            </div>
          </div>
        </div>
      </div>
  )
}

export default SupportFooter;
