import React, {Component} from 'react';

// Should only be used as base component for popup components
class BasePopup extends Component {
  render() {
    return (
        <div className={`modal fade ${this.props.open && "show"}`}>
          <div className={`modal-dialog modal-dialog-centered ${this.props.className}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">{this.props.title}</h3>
                <button type="button" className="close" onClick={this.props.onClose}/>
              </div>
              {this.props.body &&
              <div className="modal-body">
                {this.props.body}
              </div>
              }
              {this.props.footer &&
              <div className={`modal-footer ${this.props.footerClass}`}>
                {this.props.footer}
              </div>
              }
            </div>
          </div>
        </div>
    );
  }
}

export default BasePopup;