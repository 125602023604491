import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {couponIssueAttributes, couponTypeCode2Key, productTypeCode2Key, productTypeKey2Code, statusNumber2Key} from '../../utils/couponAttributes';
import {toast} from 'react-toastify';
import ApplyAllCouponPopup from '../popup/ApplyAllCouponPopup';
import CountryListPopUp from '../popup/CountryListPopUp';
import {getAppFromSessionData} from '../../ducks/sessionData';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {getCountriesInformation, isCountriesInformationChanged, loadCountriesInformation} from '../../ducks/countriesInformation';
import DataTableWrapperForCouponDetail from '../datable/DataTableWrapperForCouponDetail';
import {URL_CAMPAIGN_COUPON, URL_GET_COUNTRY_DATA_API, URL_GET_COUNTRY_INFORMATION_API, URL_GET_COUPON_DETAIL_API, URL_GET_COUPON_DOWNLOAD_API, URL_GET_COUPON_ISSUE_API, URL_GET_COUPON_ISSUE_DOWNLOAD_API, URL_GET_COUPON_MODIFY_API, URL_GET_COUPON_PRODUCT_COUNTRYLIST_DATA_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {postAjaxCoupon, setAllCouponInformationStartEndPeriodAsDefaultCountry, setValidStartEndPeriodForCountry, closePopupApplyAllValidation} from "../../utils/CouponUtils";
import {fetchCountries} from '../../utils/CountryListUtils';

const TOTAL_ISSUED_CEIL = 10000;

class CouponDetailPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      disabledCountries: [],
      initialCountries: [],
      dataLoaded: false,
      isApplyAllPopupOpen: false,
      modalShow: false,
      fetchedCouponIssueData: [],
      couponListPage: 1,
      totalCouponListSize: 0,
      couponListSizePerPage: 15,
      searchParams: {},
      initLoad : false
    }
  }

  initData = () => {
    if (this.props.sessionData.apps.length && this.state.countryData.length && this.props.countriesInformation.length) {
      const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COUPON_DETAIL_API, {params: {couponId: this.props.match.params.couponId}})
          .then(response => {
            this.setState({
              couponInfo: response.data
            }, () => {
              if(!this.state.initLoad) {
                this.getCountryInfo(response.data, app.defaultCountry);
                if (response.data.couponTargetRange === productTypeKey2Code["PRODUCT"]) {
                  this.getDisabledCountries(response.data.productId)
                }
              }
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  getCountryInfo = (couponDetail, defaultCountry) => {
    let couponInformationByCountries = couponDetail.couponIssueList;
    this.setState({
      couponInformationByCountries: couponInformationByCountries.map(item => {
        if (item.country === defaultCountry) {
          item.isDefaultCountry = true;
        }
        item.isDisabled = true;
        item.initialIssued = item.totalIssued;
        return item;
      }),
      selectedCountries: couponInformationByCountries.map(item => item.country),
      initialCountries: couponInformationByCountries.map(item => item.country),
      dataLoaded: true,
      initLoad : true
    }, () => this.setDatePickerInitValues())
  };

  setDatePickerInitValues = () => {
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map(item => {
          item.validPeriodStart.setValue(new Date(item.validStartDate));
          item.validPeriodEnd.setValue(new Date(item.validEndDate));
          return item;
        })
      }
    })
  };

  getDisabledCountries = (productId) => {
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_COUPON_PRODUCT_COUNTRYLIST_DATA_API, {
          params: {productId: productId}
        })
        .then(response => {
          let productCountries = response.data.map(e => e.country);
          this.setState({
            disabledCountries: this.props.countriesInformation.map(e => e.country)
                .filter(function (val) {
                  return productCountries.indexOf(val) === -1;
                }),
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  checkNewCountryAdded = (country, couponInformationByCountries) => {
    let oldInfo = null;
    couponInformationByCountries.forEach(item => {
      if (item.country === country) {
        oldInfo = item;
      }
    });
    return oldInfo;
  };

  convertToCouponInformationByCountries = (selectedCountries, isDefault) => {
    return selectedCountries.map(country => {
      const isAlreadyAdded = this.checkNewCountryAdded(country, this.state.couponInformationByCountries);
      if (isAlreadyAdded) {
        return isAlreadyAdded;
      } else {
        return {
          country: country,
          isDefaultCountry: isDefault,
          isSelected: false,
          isDisabled: false,
          couponIssueId: "",
          used: 0,
          totalIssued: 0,
          initialIssued: 0,
          validPeriodStart: {
            getValue: function () {
              return (new Date()).toISOString().slice(0, 10);
            }
          },
          validPeriodEnd: {
            getValue: function () {
              return (new Date()).toISOString().slice(0, 10);
            }
          },
        }
      }
    });
  };

  handleAddCountry = () => {
    this.setState({isCountryListPopupOpen: true});
  };

  extractCountryList = (couponInformationByCountries) => {
    return couponInformationByCountries.map(item => item.country);
  };

  handleCountryListModalClose = (isSave, selectedCountries) => {
    this.setState({isCountryListPopupOpen: false});
    if (!isSave) {
      return;
    }
    this.setState({selectedCountries: selectedCountries});
    this.setState({couponInformationByCountries: this.convertToCouponInformationByCountries(selectedCountries, false)});
  };

  handleCheckAll = () => {
    const allChecked = this.state.couponInformationByCountries.filter(item => item.isDisabled === false).map(item => item.isSelected).every(item => item);
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (!item.isDisabled) {
            item.isSelected = !allChecked;
          }
          return item;
        })
      }
    });
  };

  handleCheck = (country, isDisabled) => {
    if (!isDisabled) {
      this.setState(prevState => {
        return {
          couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
            if (item.country === country) {
              item.isSelected = !item.isSelected;
            }
            return item;
          })
        }
      });
    }
  };

  handleDeleteCountry = () => {
    this.setState(prevState => {
      let newCouponInformationByCountries = prevState.couponInformationByCountries.filter(function (item) {
        return item.isDisabled || !item.isSelected;
      });
      return {
        couponInformationByCountries: newCouponInformationByCountries,
        selectedCountries: this.extractCountryList(newCouponInformationByCountries)
      }
    }, () => toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_COUNTRIES_DELETED")));
  };

  handleDownloadAllCoupons = () => {
    handleDownloadFile(URL_GET_COUPON_DOWNLOAD_API, {couponId: this.state.couponInfo.couponId}, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  handleDownloadCouponIssue = () => {
    handleDownloadFile(URL_GET_COUPON_ISSUE_DOWNLOAD_API, {couponIssueId: this.state.selectedCouponIssueId}, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };
  handleApplyAllPeriod = (validStart, validEnd) => {
    this.setState(prevState => setAllCouponInformationStartEndPeriodAsDefaultCountry(prevState.couponInformationByCountries, validStart, validEnd, this.props.t));
  }

  handleApplyAllTotalIssued = (noOfCoupon) => {
    if (noOfCoupon.length === 0) {
      toast(this.props.t("COUPON_DETAIL_ALERT_ENTER_TOTAL_ISSUED_FOR_DEFAULT_COUNTRY"));
      return;
    }
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          item.totalIssued = noOfCoupon > item.initialIssued ? noOfCoupon : item.initialIssued;
          return item;
        })
      }
    });
    toast(this.props.t("COUPON_DETAIL_ALERT_TOTAL_ISSUED_APPLIED_TO_ALL_COUNTRIES"));
  };

  handleTotalIssuedBlur = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            if (Number(input)) {
              if (Number(input) < item.initialIssued) {
                toast(this.props.t("CANNOT_REDUCE_TOTAL_ISSUED_BELOW_INITIAL_QUANTITY"));
                item.totalIssued = item.initialIssued;
              }
              if (Number(input) > TOTAL_ISSUED_CEIL) {
                toast(this.props.t("YOU_CAN_ISSUE_UP_TO_TEN_THOUSAND_COUPONS_PER_COUNTRY"));
                item.totalIssued = item.initialIssued;
              }
            } else {
              toast(this.props.t("ENTER_VALID_NUMBER"));
              item.totalIssued = item.initialIssued;
            }
          }
          return item;
        })
      }
    });
  };

  handleTotalIssuedChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            item.totalIssued = input;
          }
          return item;
        })
      }
    });
  };

  handleIssueDetailsPopupOpen = (couponIssueId) => {
    const searchParams = {};
    searchParams.pageNumber = this.state.couponListPage;
    searchParams.limit = this.state.couponListSizePerPage;
    searchParams.couponIssueId = couponIssueId;
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_COUPON_ISSUE_API, {params: searchParams})
        .then(response => {
          this.setState({
            fetchedCouponIssueData: response.data.data,
            totalCouponListSize: response.data.records,
            selectedCouponIssueId: couponIssueId,
            couponListPage: 1,
            couponListSizePerPage: 15,
            modalShow: true
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleIssueDetailsPopupClose = () => {
    this.setState({modalShow: false});
  };

  handlePopupApplyAll = () => {
    this.setState({ isApplyAllPopupOpen: true });
  }

  handleClosePopupApplyAll = (isSave, newValue) => {
    if (!isSave) {
      this.setState({ isApplyAllPopupOpen: false });
      return;
    }
    let closeModal = closePopupApplyAllValidation(newValue, TOTAL_ISSUED_CEIL, this.state.couponInformationByCountries, this.props.t);
    if (closeModal) {
      this.setState({ isApplyAllPopupOpen: false });
      this.handleApplyAllTotalIssued(newValue.noOfCoupon);
      this.handleApplyAllPeriod(newValue.validStart, newValue.validEnd);
    }
  }

  handleSave = () => {
    let validPeriodFlag = false;
    this.state.couponInformationByCountries.forEach((e) => {
      if (new Date(e.validPeriodStart.getValue()) > new Date(e.validPeriodEnd.getValue())) {
        validPeriodFlag = true;
      }
    });
    if (validPeriodFlag) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_VALID_PERIOD_NOT_VALID"));
      return
    }

    let totalIssuedFlag = false;
    this.state.couponInformationByCountries.forEach((e) => {
      if (e.totalIssued === "0" || e.totalIssued === 0 || Number(e.totalIssued) < Number(e.initialIssued)) {
        totalIssuedFlag = true;
      }
    });
    if (totalIssuedFlag) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_NUMBER_OF_COUPON_NOT_VALID"));
      return;
    }

    let params = {
      cpAppId: this.props.carousel.selectedAppId,
      couponId: this.state.couponInfo.couponId,
      couponTargetRange: this.state.couponInfo.couponTargetRange,
      productId: this.state.couponInfo.productId,
      couponIssueList: this.state.couponInformationByCountries.map((e) => {
        return {
          couponIssueId: e.couponIssueId,
          country: e.country,
          discountAmount: parseFloat(e.discountAmount),
          discountRate: parseFloat(e.discountRate),
          totalIssued: parseInt(e.totalIssued),
          validStartDate: e.validPeriodStart.getValue(),
          validEndDate: e.validPeriodEnd.getValue(),
        }
      }),
    };
    this.props.startLoading();
    postAjaxCoupon(URL_GET_COUPON_MODIFY_API, params, this.props.t, this.props.history, this.props.startLoading, this.props.stopLoading, this.httpClient, URL_CAMPAIGN_COUPON, 'COUPON_CREATE_ALERT_ERROR_CREATING_COUPON', 'COUPON_CREATE_ALERT_COUPON_SUCCESSFULLY_CREATED');
  };
  delegateDatePickerEvent = (isStart, datepicker, country) => {
    this.setState(prevState => setValidStartEndPeriodForCountry(isStart, datepicker, country, prevState.couponInformationByCountries))
  }

  handleTableSort = (sortOrder, sortField) => {
    const couponHeaderField2SortName = {
      "couponIssueId": "couponIssueId",
      "couponTargetRange": "couponTargetRange",
      "couponType": "couponType",
      "country": "country",
      "currency": "currencyCode",
      "productPrice": "productPrice",
      "discountAmount": "discountAmount",
      "discountRate": "discountRate",
      "couponNumber": "couponNumber",
      "buyerEmail": "buyerEmail",
      "zipCode": "zipCode",
      "invoiceId": "invoiceId",
      "issueDate": "issueDate",
      "statusCode": "statusCode",
      "dateOfUse": "useDate",
      "refundDate": "refundDate"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: couponHeaderField2SortName[sortField] ? couponHeaderField2SortName[sortField] : sortField,
      couponListPage: 1,
    }, () => this.search())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      couponListPage: page,
      couponListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  search = () => {
    const searchParams = {};
    searchParams.pageNumber = this.state.couponListPage;
    searchParams.limit = this.state.couponListSizePerPage;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.couponIssueId = this.state.selectedCouponIssueId;
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_COUPON_ISSUE_API, {params: searchParams})
        .then(response => {
          this.setState({
            fetchedCouponIssueData: response.data.data,
            totalCouponListSize: response.data.records
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  initCountryData = () => {
    this.props.startLoading();
    fetchCountries(URL_GET_COUNTRY_DATA_API, this.props.t, this.props.history)
        .then(countryData => {
          this.setState({countryData: countryData});
          fetchCountries(URL_GET_COUNTRY_INFORMATION_API, this.props.t, this.props.history)
              .then((countryInformation => {
                this.props.stopLoading();
                this.props.loadCountriesInformation(countryInformation);
                this.initData();
              }));
        });
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    const {couponInfo} = this.state;
    return (
        <div className="tab-pane active" role="tabpanel">
          <h2 className="mb-20"><strong>{this.props.t("COUPON_DETAILS")}</strong></h2>
          <div className="info-table mb-60">
            <div className="row">
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_NAME")}</strong></div>
              <div className="col-4">{couponInfo.couponName}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TOTAL_ISSUED")}</strong></div>
              <div className="col-4">{couponInfo.totalIssued}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_ID")}</strong></div>
              <div className="col-4">{couponInfo.couponId}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("REMAINING")}</strong></div>
              <div className="col-4">{couponInfo.remaining}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TARGET_RANGE")}</strong></div>
              <div className="col-4">{this.props.t(productTypeCode2Key[couponInfo.couponTargetRange])}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("ISSUE_DATE")}</strong></div>
              <div className="col-4">{couponInfo.issueDate}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TARGET_APP")}</strong> <span className="text-muted">(ID)</span></div>
              <div className="col-4">{couponInfo.appName}<span className="text-muted">({couponInfo.cpAppId})</span></div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_TYPE")}</strong></div>
              <div className="col-4">{this.props.t(couponTypeCode2Key[couponInfo.couponType])}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TARGET_PRODUCT")}</strong> <span className="text-muted">(ID)</span></div>
              {couponInfo.couponTargetRange === "PRD" &&
              <div className="col-4">{couponInfo.productName}<span className="text-muted">({couponInfo.cpProductId})</span></div>
              }
              {couponInfo.couponTargetRange === "APP" &&
              <div className="col-4"/>
              }
              {couponInfo.couponType === "4" &&
              <>
                <div className="col-2 text-center bg-100"><strong>{this.props.t("FREE_TRIAL_PERIOD")}</strong></div>
                <div className="col-4">{couponInfo.freeTrialPeriod}</div>
              </>
              }
            </div>
          </div>
          {couponInfo.couponType === '1' &&
          <p>
            {this.props.t("DISCLAIMER_TEXT_WHEN_COUPON_TYPE_IS_DISCOUNT_AMOUNT")}
          </p>
        }
        <div className="text-right clearfix mb-10">
          <div className="float-left">
            <button className="btn btn-sm btn-outline-primary" onClick={() => this.handleDownloadAllCoupons()}>{this.props.t("DOWNLOAD_ALL_COUPONS")}</button>
          </div>
          <div className="btn-group-toggle btn-group-checkbox ml-7" data-toggle="buttons">
            <button
              type="button"
              className={`btn btn-sm btn-auto btn-success ${this.state.readOnly && "disabled"}`}
              disabled={this.state.readOnly}
              onClick={this.handlePopupApplyAll}>
              {this.props.t("APPLY_ALL")}
            </button>
          </div>
        </div>
        <div className="table-overlay">

            <DataTableWrapperForCouponDetail couponCountryData={this.state.couponInformationByCountries}
                                             couponType={couponInfo.couponType}
                                             targetRange={couponInfo.couponTargetRange}
                                             handleCheck={this.handleCheck}
                                             handleCheckAll={this.handleCheckAll}
                                             handleDatepickerInit={this.delegateDatePickerEvent}
                                             handleTotalIssuedChange={this.handleTotalIssuedChange}
                                             handleTotalIssuedBlur={this.handleTotalIssuedBlur}
                                             handleIssueDetailsPopupOpen={this.handleIssueDetailsPopupOpen}/>
          </div>
          <div className="row mt-30">
            <div className="col-6">
              <button type="button" className="btn btn-sm btn-auto btn-success" onClick={this.handleAddCountry}>{this.props.t("ADD_COUNTRY")}</button>
              <button type="button"
                      className="btn btn-sm btn-auto btn-outline-success ml-8"
                      disabled={!(this.state.couponInformationByCountries.filter(item => item.isDisabled === false).map(item => item.isSelected).some(item => item))}
                      onClick={this.handleDeleteCountry}>{this.props.t("DELETE_COUNTRY")}</button>
            </div>
            <div className="col-6 text-right">
              <button type="button"
                      className={`btn btn-sm btn-auto ml-10 ${this.state.couponInformationByCountries <= 0 ? "btn-success disabled" : "btn-primary"}`}
                      onClick={() => this.handleSave()}
                      disabled={this.state.couponInformationByCountries <= 0}
              >
                {this.props.t("SAVE")}
              </button>
            </div>
          </div>
          <div className={`modal fade ${this.state.modalShow ? "show" : ""}`} tabIndex="-1" role="dialog" aria-labelledby="dateChange" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">{this.props.t("COUPON_POPUP_HEADING")}</h3>
                  <button type="button" className="close" aria-label="Close" onClick={() => this.handleIssueDetailsPopupClose()}/>
                </div>
                <div className="modal-body">
                  <DataTableWrapperForListing
                      noSelect
                      keyField="couponNumber" // one of 'dataField'
                      hideSelectColumn={true}
                      columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                        {dataField: "couponIssueId", text: this.props.t(couponIssueAttributes.couponIssueId.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "couponTargetRange", text: this.props.t(couponIssueAttributes.couponTargetRange.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "couponType", text: this.props.t(couponIssueAttributes.couponType.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "country", text: this.props.t(couponIssueAttributes.country.key), headerStyle: {width: "10%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "currency", text: this.props.t(couponIssueAttributes.currency.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "productPrice", text: this.props.t(couponIssueAttributes.productPrice.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "discountAmount", text: this.props.t(couponIssueAttributes.discountAmount.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "discountRate", text: this.props.t(couponIssueAttributes.discountRate.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "couponNumber", text: this.props.t(couponIssueAttributes.couponNumber.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "buyerEmail", text: this.props.t(couponIssueAttributes.buyerEmail.key), headerStyle: {width: "12%"}, sort: false, classes: () => "text-truncate"},
                        {dataField: "zipCode", text: this.props.t(couponIssueAttributes.zipCode.key), headerStyle: {width: "7%"}, sort: false, classes: () => "text-truncate"},
                        {dataField: "invoiceId", text: this.props.t(couponIssueAttributes.invoiceId.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "productName", text: this.props.t(couponIssueAttributes.productName.key), headerStyle: {width: "7%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "issueDate", text: this.props.t(couponIssueAttributes.issueDate.key), headerStyle: {width: "10%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "statusCode", text: this.props.t(couponIssueAttributes.statusCode.key), headerStyle: {width: "8%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "dateOfUse", text: this.props.t(couponIssueAttributes.dateOfUse.key), headerStyle: {width: "10%"}, sort: true, classes: () => "text-truncate"},
                        {dataField: "refundDate", text: this.props.t(couponIssueAttributes.refundDate.key), headerStyle: {width: "10%"}, sort: true, classes: () => "text-truncate"},
                      ]}
                      page={this.state.couponListPage}
                      sizePerPage={this.state.couponListSizePerPage}
                      totalSize={this.state.totalCouponListSize}
                      rows={this.state.fetchedCouponIssueData.map(coupon => {
                        return {
                          ...coupon,
                          couponTargetRange: this.props.t(productTypeCode2Key[coupon.couponTargetRange]),
                          currency: getCountriesInformation(this.props.countriesInformation, coupon.country).currency,
                          couponType: this.props.t(couponTypeCode2Key[coupon.couponType]),
                          country: this.props.t(coupon.country),
                          statusCode: statusNumber2Key[coupon.statusCode],
                          productName: coupon.appliedProductName,
                          dateOfUse: coupon.useDate ? coupon.useDate + (coupon.couponUsedCycle > 0 ? ' [' + coupon.couponUsedCycle + '/' + coupon.couponTotalCycle + ']' : '') : ''
                        };
                      })}
                      onTableSort={this.handleTableSort}
                      onTableChange={this.handleTableChange}
                      onRowSelect={this.handleRowSelect}/>
                  <div className="pagenationGroup clearfix">
                    <button type="button" className="btn btn-sm btn-primary float-right" onClick={() => this.handleDownloadCouponIssue()}>{this.props.t("DOWNLOAD")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CountryListPopUp
              countryData={this.state.countryData}
              open={this.state.isCountryListPopupOpen}
              title={this.props.t("ADD_COUNTRY")}
              readOnly={false}
              initCountries={this.state.selectedCountries}
              handleModalClose={this.handleCountryListModalClose}
              disabledCountry={[...this.state.disabledCountries, ...this.state.initialCountries]}
              isMultipleSelect={true}
          isContinentSelect={true} />

        <ApplyAllCouponPopup
          open={this.state.isApplyAllPopupOpen}
          handleModalClose={this.handleClosePopupApplyAll}
          readOnly={false}
          couponCountryData={[getCountriesInformation(this.props.countriesInformation, app.defaultCountry)]}
        />
      </div>
    );
  }

  componentDidMount() {
    this.initCountryData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)
        || isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.initCountryData();
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  loadCountriesInformation: loadCountriesInformation,
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CouponDetailPanel)));
