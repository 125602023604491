import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {API_ADMIN_DELETE_NOTICE, API_ADMIN_NOTIFICATION_LIST, URL_ADMIN_NOTIFICATIONMANAGEMENT, URL_ADMIN_NOTIFICATIONREGISTRATION} from "../../utils/urlConstants";
import {mockMemberList} from "../../mockApiResponse/mockMemberData";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from "../../utils/globalConstants";
import SearchBar from "../searchbar/SearchBar";
import {isDateFutureThan, durationKey, getDefaultTimePeriod} from "../../utils/searchBarUtils";
import {noticeAttributes, noticeCategoryCode2Key, noticeCategoryKey2Code, noticeVisibilityCode2Key, noticeVisibilityKey2Code} from "../../utils/adminAttributes";
import BasePopup from "../popup/BasePopup";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from "../../ducks/loading";
import {toast} from "react-toastify";

class NotificationListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      pageNumber: 1,
      totalListSize: 0,
      listSizePerPage: 15,
      notificationList: [],
      selectedNotificationIdList: [],
      searchParams: {},
      modal: false,
    }
  }

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      searchText: searchParams.keywordText,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      category: searchParams.selectedFilter[0].value ? noticeCategoryKey2Code[searchParams.selectedFilter[0].value] : "",
      useYN: searchParams.selectedFilter[1].value ? noticeVisibilityKey2Code[searchParams.selectedFilter[1].value] : "",
      sortName: "registeredDate",
      sortOrder: "DESC",
      pageNumber: 1,
      limit: this.state.listSizePerPage
    };
    this.setState({searchParams: params, pageNumber: 1}, () => this.triggerNotificationSearch())
  };

  triggerNotificationSearch = () => {
    const {searchParams} = this.state;
    if (this.state.pageNumber && this.state.listSizePerPage) {
      searchParams.limit = this.state.listSizePerPage;
      searchParams.pageNumber = this.state.pageNumber;
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_NOTIFICATION_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalListSize: response.data.records,
                notificationList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }

  };

  handleDeleteNotification = () => {
    this.setState({modal: false});
    if (!this.state.selectedNotificationIdList.length) {
      return;
    }
    this.props.startLoading();
    this.httpClient
        .post(API_ADMIN_DELETE_NOTICE, this.state.selectedNotificationIdList)
        .then(response => {
          if (response.data !== SUCCESS) {
            toast(this.props.t("NOTIFICATION_DELETE_ALERT_FAIL"));
          } else {
            toast(this.props.t("NOTIFICATION_DELETE_ALERT_SUCCESS"));
            this.fetchNotificationList(this.state.pageNumber, this.state.listSizePerPage);
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  handleRowSelect = (isSelect, rows) => {

    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedNotificationIdList.find(noticeId => row["noticeId"] === noticeId)) {
            return {
              selectedNotificationIdList: [...prevState.selectedNotificationIdList, row["noticeId"]]
            };
          }
        });
      } else {
        this.setState(prevState => {
          return {
            selectedNotificationIdList: prevState.selectedNotificationIdList.filter(noticeId => row["noticeId"] !== noticeId)
          };
        })
      }
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      pageNumber: page,
      listSizePerPage: sizePerPage,
    }, () => this.triggerNotificationSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const tableHeaderField2SortName = {
      "noticeIdLink": "noticeTitle",
      "visibility": "inUse",
      "registrationDate": "registeredDate"
    };

    this.setState(prevState => {
      return {
        searchParams: {
          ...prevState.searchParams,
          sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
          sortName: tableHeaderField2SortName[sortField] ? tableHeaderField2SortName[sortField] : sortField,
          pageNumber: 1,
          limit: prevState.listSizePerPage
        }
      }
    }, () => this.triggerNotificationSearch())
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                label: "Keyword",
                placeholder: "Please enter Keywords.",
                // options: ["USER_ID", "USER_NAME"],
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("REGISTRATION_DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: noticeAttributes.categoryName.key, value: noticeAttributes.categoryName.value},
                  {key: noticeAttributes.status.key, value: noticeAttributes.status.value},
                ]
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              noSelect
              keyField="noticeId" // one of 'dataField'
              hideSelectColumn={false}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                // 60 + 174.67(11.51166) + 724(47.71539) + 169.33(11.15973) + 149.33(9.84162) + 240(15.81725)
                {dataField: "category", text: this.props.t(noticeAttributes.categoryName.key), headerStyle: {width: "11.51166%"}, sort: true},
                {dataField: "noticeId", hidden: true},
                {dataField: "noticeIdLink", text: this.props.t(noticeAttributes.title.key), headerStyle: {width: "47.71539%"}, sort: true, titleHeader: "noticeTitle", classes: () => "text-truncate"},
                {dataField: "author", text: this.props.t(noticeAttributes.author.key), headerStyle: {width: "11.15973%"}, sort: false,  classes: () => "text-truncate"},
                {dataField: "visibility", text: this.props.t(noticeAttributes.visibility.key), headerStyle: {width: "9.84162%"}, sort: true},
                {dataField: "registrationDate", text: this.props.t(noticeAttributes.registrationDate.key), headerStyle: {width: "15.81725%"}, sort: true, classes: () => "text-truncate"},
              ]}
              page={this.state.pageNumber}
              sizePerPage={this.state.listSizePerPage}
              totalSize={this.state.totalListSize}
              rows={this.state.notificationList.map(notice => {
                return {
                  ...notice,
                  category: this.props.t(noticeCategoryCode2Key[notice.category]),
                  noticeIdLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_ADMIN_NOTIFICATIONMANAGEMENT + "/" + notice.noticeId);
                            }}>
                        {notice.noticeTitle}
                      </Link>,
                  registrationDate: notice.registeredDate,
                  visibility: this.props.t(noticeVisibilityCode2Key[notice.inUse])
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            {/*<div className="float-left">*/}
            <button
                type="button" className="btn btn-sm btn-outline-primary float-left"
                onClick={() => this.setState({modal: true})}>
              {this.props.t("DELETE")}
            </button>
            <Link to={URL_ADMIN_NOTIFICATIONREGISTRATION}>
              <button type="button" className="btn btn-sm btn-primary float-right">
                {this.props.t("REGISTER")}
              </button>
            </Link>
          </div>
          <BasePopup
              open={this.state.modal}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modal: false})}
              body={
                <p>{this.state.selectedNotificationIdList.length ? this.props.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE") : this.props.t("PLEASE_SELECT_AT_LEAST_ONE_ITEM")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleDeleteNotification()}>
                    {this.props.t("CONFIRM")}
                  </button>
                  {
                    this.state.selectedNotificationIdList.length > 0 &&
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modal: false})}>
                      {this.props.t("CANCEL")}
                    </button>
                  }

                </>
              }/>
        </div>
    );
  }

  fetchNotificationList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod();
    const searchParams = {
      dateStart: searchPeriod.startDate,
      dateEnd: searchPeriod.endDate,
    };
    this.setState({searchParams: searchParams}, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        sortOrder: this.state.sortOrder,
        sortName: this.state.sortName,
        pageNumber: page,
        limit: sizePerPage
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_NOTIFICATION_LIST, {
              params
            })
            .then(response => {
              this.setState({
                pageNumber: page,
                listSizePerPage: sizePerPage,
                totalListSize: response.data.records,
                notificationList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
              reject(error);
            })
      });
    });
  };

  componentDidMount() {
    this.fetchNotificationList(1, 15);
  }

  getGroupNameList = () => {
    //TODO: AJAX Call needed for fetching all the group names
    return mockMemberList.groupList.map(group => group.groupName);
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(NotificationListPanel)));