//
// actions
//

import {delCookie, getCookie, setCookie} from '../utils/simpleCookieUtils';

const TYPE_ACTION_TOGGLE_CAROUSEL = "TYPE_ACTION_TOGGLE_CAROUSEL";
const TYPE_ACTION_CLOSE_CAROUSEL = "TYPE_ACTION_CLOSE_CAROUSEL";
const TYPE_ACTION_UPDATE_SELECTED_APP_ID_CAROUSEL = "TYPE_ACTION_UPDATE_SELECTED_APP_ID_CAROUSEL";
const TYPE_ACTION_UPDATE_DEFAULT_APP_ID_CAROUSEL = "TYPE_ACTION_UPDATE_DEFAULT_APP_ID_CAROUSEL";

export function toggleCarousel() {
  return {
    type: TYPE_ACTION_TOGGLE_CAROUSEL
  };
}

export function closeCarousel() {
  return {
    type: TYPE_ACTION_CLOSE_CAROUSEL
  };
}
/**
 * updateSelectedAppIdInCarousel
 *
 * Below is how Each component hear that carousel.selectedAppId is updated.
 *
 * componentDidUpdate(prevProps, prevState, snapshot) {
 *   if (this.props.carousel.selectedAppId !== prevProps.carousel.selectedAppId) {
 *     console.log("current carousel.selectedAppId", this.props.carousel.selectedAppId);
 *     console.log("before carousel.selectedAppId", prevProps.carousel.selectedAppId);
 *   }
 * }
 *
 * const mapStateToProps = (state) => {
 *  return {
 *    carousel: state.carousel,
 *  };
 * };
 *
 * export default connect(mapStateToProps, xxx)(SomeComponent);
 */
export function updateSelectedAppIdInCarousel(appId, contsId) {
  return {
    type: TYPE_ACTION_UPDATE_SELECTED_APP_ID_CAROUSEL, appId: appId, contsId: contsId
  }
}

export function updateDefaultAppIdInCarousel(appId) {
  return {
    type: TYPE_ACTION_UPDATE_DEFAULT_APP_ID_CAROUSEL, appId: appId
  }
}

//
// reducer
//

const _defaultAppId = getCookie('defaultAppId');
const _lastUsedAppId = getCookie('lastUsedAppId');

const initialState = {
  isCarouselOpen: false,
  defaultAppId: _defaultAppId,
  selectedAppId: _defaultAppId || _lastUsedAppId,
  selectedContsId: ""
};

export function carouselReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE_ACTION_TOGGLE_CAROUSEL:
      return {...state, isCarouselOpen: !state.isCarouselOpen};
      case TYPE_ACTION_CLOSE_CAROUSEL:
      return {...state, isCarouselOpen: false};
    case TYPE_ACTION_UPDATE_SELECTED_APP_ID_CAROUSEL:
      setCookie('lastUsedAppId', action.appId);
      return {...state, selectedAppId: action.appId, selectedContsId: action.contsId};
    case TYPE_ACTION_UPDATE_DEFAULT_APP_ID_CAROUSEL:
      if (Object.is(state.defaultAppId, action.appId)) {
        delCookie('defaultAppId');
        return {...state, defaultAppId: null}
      } else {
        setCookie('defaultAppId', action.appId);
        return {...state, defaultAppId: action.appId}
      }
    default:
      return state;
  }
}

export function isSelectedAppChanged(currentSelectedApp, prevSelectedApp) {
  return currentSelectedApp !== prevSelectedApp
}