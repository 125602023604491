import React, {Component} from 'react';
import SHeader from "../SHeader";
import LeftNaviBar from "../lnb/LeftNaviBar";
import Content from "../Content";
import TopNaviBar from "../tnb/TopNaviBar";
import {connect} from "react-redux";
import TabManager from "../TabManager";
import ChangeInfoPanel from "../panel/ChangeInfoPanel";

class BankInformationPage extends Component {
  render() {
    let wrapClassName = "clearfix";
    if (!this.props.sideDrawer.isSideDrawerOpen) {
      wrapClassName += " closeMenu";
    }
    return (
        <div id="wrap" className={wrapClassName}>
          <SHeader>
            <LeftNaviBar menuData={this.props.menuData} activeMenu={this.props.activeMenu} toggleClick={this.props.toggleClick}/>
          </SHeader>
          <Content>
            <TopNaviBar tnbData={this.props.tnbData} isOpen={this.props.isOpen} title={this.props.menu}/>
            <TabManager content={
              <div className="tab-pane active" role="tabpanel">
                <ChangeInfoPanel isPopup={false}/>
              </div>
            }/>
          </Content>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sideDrawer: state.sideDrawer
  };
};
export default connect(mapStateToProps)(BankInformationPage);