import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import {URL_GET_STATISTICS_APPSALES_API, URL_GET_STATISTICS_APPSALES_COUNTRY_API, URL_GET_STATISTICS_APPSALES_COUNTRY_DOWNLOAD_API, URL_GET_STATISTICS_APPSALES_DOWNLOAD_API, URL_STATISTICS_ANALYSISBYCOUNTRY} from '../../utils/urlConstants';
import {connect} from 'react-redux';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import Table from '../statistics/Table';
import Switcher from '../statistics/Switcher';
import {endDateString, getValues, isDurationLessThan1Month, round2DecimalPlaces, startDateString, typeDetails} from '../../utils/statisticsUtils';
import Graph from '../statistics/Graph';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';

class AppSalesStatisticsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalSalesAmount: "0",
      totalRefundAmount: "0",
      totalSalesCount: "0",
      totalRefundCount: "0",
      activeType: "AMOUNT",
      activeInterval: "MONTHLY",
      rowData: {},
      searchParams: {},
      dataLoaded: false
    };

    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
  }

  handleSelectInterval = (interval) => {
    this.setState({activeInterval: interval}, () => this.search());
  };

  handleDownloadClick = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    const url = this.state.activeType === "COUNTRY" ? URL_GET_STATISTICS_APPSALES_COUNTRY_DOWNLOAD_API : URL_GET_STATISTICS_APPSALES_DOWNLOAD_API;
    handleDownloadFile(url, searchParams, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  handleSelectType = (type) => {
    this.setState({activeType: type});
  };
  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
    };
    let activeInterval;
    if (isDurationLessThan1Month(searchParams.startDate, searchParams.endDate)) {
      activeInterval = "WEEKLY";
    } else {
      activeInterval = "MONTHLY";
    }
    this.setState({
      searchParams: params,
      activeInterval: activeInterval
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_APPSALES_API, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              rowData: getValues(response.data.dataList, this.state.activeInterval),
              totalSalesAmount: round2DecimalPlaces(response.data.totalSalesAmount),
              totalRefundAmount: round2DecimalPlaces(response.data.totalRefundAmount),
              totalSalesCount: response.data.totalSalesCount,
              totalRefundCount: response.data.totalRefundCount
            }, () => this.getCountryData());
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    })
  };

  getCountryData = () => {
    const {searchParams} = this.state;
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_APPSALES_COUNTRY_API, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              rowData: {...this.state.rowData, "COUNTRY": this.getCountryValues(response.data.dataList)},
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    })
  };

  getCountryValues = (input) => {
    let values = [];
    if (input ? input.length > 0 : false) {
      input.forEach(e => {
        values.push([this.props.t(e.countryCode), e.salesAmount, e.refundAmount, e.salesCount]);
      });
    }
    return values;
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const typeDetail = typeDetails["APP_SALES"];
    const tableRows = [{id: "totalSalesAmount", header: "STATISTICS_SALES_AMOUNT", value: this.state.totalSalesAmount},
      {id: "totalRefundAmount", header: "STATISTICS_REFUND_AMOUNT", value: this.state.totalRefundAmount},
      {id: "totalSalesCount", header: "STATISTICS_SALES_COUNT", value: this.state.totalSalesCount},
      {id: "totalRefundCount", header: "STATISTICS_REFUND_COUNT", value: this.state.totalRefundCount}];
    const types = typeDetail.map(e => e.type).map(item => {
      return {type: item, isActive: this.state.activeType === item}
    });
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, this.props.carousel.selectedAppId));

    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              datePanel={{
                show: true,
                label: this.props.t("SALES_PERIOD"),
                defaultDuration: durationKey.DURATION_YEAR,
                showSearch: true,
                maxDate: true
              }}
              keywordPanel={{show: false}}
              filterPanel={{show: false}}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}
              onDownloadClick={() => {
              }}/>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{selectedApp.name + this.props.t("APP_SALES_STATISTICS_HEADER")}</strong></h2>
          </div>
          <Table data={tableRows}/>
          <Switcher types={types}
                    selectType={this.handleSelectType}/>
          <Graph typeDetails={typeDetail}
                 showTypeSwitcher={true}
                 activeType={this.state.activeType}
                 activeInterval={this.state.activeInterval}
                 handleSelectInterval={this.handleSelectInterval}
                 handleDownloadClick={this.handleDownloadClick}
                 isFooter={true}
                 footer={
                   <>
                     {this.props.t("APP_SALES_STATISTICS_1")}
                     <Link to={URL_STATISTICS_ANALYSISBYCOUNTRY} className="underline text-primary">
                       {this.props.t("ANALYSIS_BY_COUNTRY")}
                     </Link>
                     {this.props.t("APP_SALES_STATISTICS_2")}
                   </>}
                 isMonthly={isDurationLessThan1Month(this.state.searchParams.dateStart, this.state.searchParams.dateEnd)}
                 rows={this.state.rowData}
          />
        </div>
    );
  }

  componentDidMount() {
    this.handleSearchClick({
      startDate: startDateString,
      endDate: endDateString,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.handleSearchClick({
        startDate: startDateString,
        endDate: endDateString,
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(AppSalesStatisticsPanel)));
