import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import {endDateString, getValues, isDurationLessThan1Month, round2DecimalPlaces, startDateString, typeDetails} from '../../utils/statisticsUtils';
import Table from '../statistics/Table';
import Switcher from '../statistics/Switcher';
import Graph from '../statistics/Graph';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {URL_GET_STATISTICS_COUNTRY_SALES_API, URL_GET_STATISTICS_COUNTRY_SALES_DOWNLOAD_API} from '../../utils/urlConstants';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, transformRequestOptions} from '../../utils/globalConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {getAppFromSessionData} from '../../ducks/sessionData';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';
import {convertToCountryCode, sortCountryNames} from '../../utils/CountryListUtils';

class CountrySalesStatisticsPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      totalSalesAmount: "0",
      totalRefundAmount: "0",
      totalSalesCount: "0",
      totalRefundCount: "0",
      selectedCountry: "",
      activeType: "AMOUNT",
      activeInterval: "MONTHLY",
      rowData: {},
      searchParams: {},
      dataLoaded: false
    };
  }

  handleSelectType = (type) => {
    this.setState({activeType: type});
  };

  handleSelectInterval = (interval) => {
    this.setState({activeInterval: interval}, () => this.search());
  };

  handleDownloadClick = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    handleDownloadFile(URL_GET_STATISTICS_COUNTRY_SALES_DOWNLOAD_API, searchParams, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      countries: searchParams.selectedFilter[0] ? [searchParams.selectedFilter[0].value] : null
    };
    let activeInterval;
    if (isDurationLessThan1Month(searchParams.startDate, searchParams.endDate)) {
      activeInterval = "WEEKLY";
    } else {
      activeInterval = "MONTHLY";
    }
    this.setState({
      selectedCountry: params.countries ? params.countries[0] : null,
      activeInterval: activeInterval,
      searchParams: params,
    }, () => {
      const countryCode = convertToCountryCode(this.props.countriesInformation, params.countries[0], this.props.t);
      if (countryCode.length) {
        params.countries[0] = countryCode;
      }
      this.search()
    })
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_COUNTRY_SALES_API, {
            params: searchParams,
            paramsSerializer: transformRequestOptions
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              rowData: getValues(response.data[0].dataList, this.state.activeInterval),
              totalSalesAmount: round2DecimalPlaces(response.data[0].totalSalesAmount),
              totalRefundAmount: round2DecimalPlaces(response.data[0].totalRefundAmount),
              totalSalesCount: response.data[0].totalSalesCount,
              totalRefundCount: response.data[0].totalRefundCount
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    })
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const typeDetail = typeDetails["COUNTRY_SALES"];
    const tableRows = [{id: "selectedCountry", header: "SALES_COUNTRY", value: this.state.selectedCountry},
      {id: "totalSalesAmount", header: "STATISTICS_SALES_AMOUNT", value: this.state.totalSalesAmount},
      {id: "totalRefundAmount", header: "STATISTICS_REFUND_AMOUNT", value: this.state.totalRefundAmount},
      {id: "totalSalesCount", header: "STATISTICS_SALES_COUNT", value: this.state.totalSalesCount},
      {id: "totalRefundCount", header: "STATISTICS_REFUND_COUNT", value: this.state.totalRefundCount}];
    const types = typeDetail.map(e => e.type).map(item => {
      return {type: item, isActive: this.state.activeType === item}
    });
    const selectedApp = this.props.sessionData.apps.find(item => Object.is(item.appId, this.props.carousel.selectedAppId));
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              datePanel={{
                show: true,
                label: this.props.t("SALES_PERIOD"),
                defaultDuration: durationKey.DURATION_YEAR,
                showSearch: false,
                maxDate: true
              }}
              keywordPanel={{show: false}}
              filterPanel={{
                show: true,
                showSearch: true,
                label: this.props.t("COUNTRY"),
                filters: [
                  {key: "COUNTRY", value: sortCountryNames(this.props.countriesInformation, this.props.t), defaultValue: this.props.t(app.defaultCountry)},
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}
              onDownloadClick={() => {
              }}/>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{selectedApp.name + this.props.t("COUNTRY_SALES_STATISTICS_HEADER")}</strong></h2>
          </div>
          <Table data={tableRows}/>
          <Switcher types={types}
                    selectType={this.handleSelectType}/>
          <Graph typeDetails={typeDetail}
                 showTypeSwitcher={true}
                 activeType={this.state.activeType}
                 activeInterval={this.state.activeInterval}
                 handleSelectInterval={this.handleSelectInterval}
                 handleDownloadClick={this.handleDownloadClick}
                 isFooter={false}
                 isMonthly={isDurationLessThan1Month(this.state.searchParams.dateStart, this.state.searchParams.dateEnd)}
                 rows={this.state.rowData}
          />
        </div>
    );
  }

  componentDidMount() {
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    this.setState({selectedCountry: app.defaultCountry});
    this.handleSearchClick({
      startDate: startDateString,
      endDate: endDateString,
      selectedFilter: [{value: app.defaultCountry}]
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.setState({selectedCountry: app.defaultCountry});
      this.handleSearchClick({
        startDate: startDateString,
        endDate: endDateString,
        selectedFilter: [{value: app.defaultCountry}]
      })
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CountrySalesStatisticsPanel)));
