import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import BasePopup from './BasePopup';

class ApplyAllBasePopup extends Component {
  render() {
    return (
      <BasePopup
        className="modal-lg"
        open={this.props.open}
        title={this.props.t("APPLY_ALL")}
        onClose={() => this.props.handleModalClose(false)}
        body={this.props.defaultCountryInformation
          .map(countryInfo => {
            return (<div key={countryInfo.country}>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("DEFAULT_COUNTRY")}
                    </label>
                    <div className="col">
                      <div className="input-group">
                        <input type="text" className="form-control" value={this.props.t(countryInfo.country)}
                          readOnly="readonly" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.applyBody}
            </div>)
          })
        }
        footer={
          !this.props.readOnly &&
          <div className="modal-footer">
            {this.props.buttonApplyAll}
            <button
              type="button"
              className="btn btn-120 btn-outline-primary"
              onClick={() => this.props.handleModalClose(false)}>
              {this.props.t("CANCEL")}
            </button>
          </div>
        }
      />
    )
  }
}


export default withTranslation()(ApplyAllBasePopup);
