export const requestAttributes = {
    dates: {
        key: "DATES",
        value: null
    },
    requestId : {
        key : "REQUEST_ID",
        value: null
    },
    apiName : {
        key : "API_NAME",
        value :  null
    },
    requestStatus : {
        key : "REQUEST_STATUS",
        value : null
    }
}
export const keywordTypeCode = {
    "REQUEST_STATUS": "requestState",
    "API_NAME": "apiName",
    "REQUEST_ID": "requestID",
};