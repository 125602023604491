import React from 'react';
import '../../css/react-bootstrap-table2.css';
import '../../css/bootstrap-extend.css';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

class DataTableWrapperForCouponAllocate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false,
    };
  }

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  render() {
    return (
        <table className="table table-bordered table-hover table-data tblHt">
          <caption>List</caption>
          <colgroup>
            <col width="*"/>
            <col width="*"/>
            <col width="110"/>
          </colgroup>
          <thead>
          <tr>
            <th scope="col">{this.props.t("ACCOUNT")} {"  "}
              <span className={`tooltips ${this.state.isTooltipOpen && "show"}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
                <div className="tooltips_body" style={{width: "280px"}}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                  <a onClick={this.handleTooltipCloseClick} className="tooltip-toggle close"/>
                  <div dangerouslySetInnerHTML={{__html: this.props.t("COUPON_ALLOCATE_TOOLTIP")}}/>
                </div>
              </span>
            </th>
            <th scope="col">{this.props.t("NO_COUPONS_ALLOCATE")}</th>
            <th scope="col"/>
          </tr>
          </thead>
          <tbody className="text-center">
          {
            this.props.couponAllocateUsersData.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <input type="text"
                           className="form-control"
                           placeholder={this.props.t("ENTER_SAMSUNG_ACCOUNT")}
                           value={item.accountName}
                           onChange={(event) => {
                             this.props.handleAccountNameChange(item.id, event);
                           }}/>
                  </td>
                  <td>
                    <input type="number"
                           className="form-control"
                           placeholder={this.props.t("ENTER_NUMBER")}
                           value={item.couponCount}
                           onChange={(event) => {
                             this.props.handleCouponCountChange(item.id, event);
                           }}/>
                  </td>
                  <td>
                    {index === 0 &&
                        <button className="btn btn-icon btn-icon-sm"
                                type="button"
                                onClick={() => this.props.handleAddNewRow()}>
                          <i className="icon icon-plus"/>
                        </button>
                    }
                    {(index > 0 || this.props.couponAllocateUsersData.length > 1) &&
                        <button className={`btn btn-icon btn-icon-sm ${index === 0 && "ml-1"}`}
                                type="button"
                                onClick={() => this.props.handleRemoveRow(item.id)}>
                          <i className="icon icon-minus"/>
                        </button>
                    }
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countriesInformation: state.countriesInformation
  };
};

export default connect(mapStateToProps)(withTranslation()(DataTableWrapperForCouponAllocate));