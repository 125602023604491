import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {API_ADMIN_DELETE_POPUP, API_ADMIN_POPUP_LIST, URL_ADMIN_POPUPMANAGEMENT, URL_ADMIN_POPUPREGISTRATION} from "../../utils/urlConstants";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from "../../utils/globalConstants";
import SearchBar from "../searchbar/SearchBar";
import {isDateFutureThan, durationKey, getDefaultTimePeriod} from "../../utils/searchBarUtils";
import {noticeVisibilityCode2Key, popupAttributes, popupUseKey2Code} from "../../utils/adminAttributes";
import BasePopup from "../popup/BasePopup";
import {handleHttpError} from '../../utils/ErrorUtils';
import {toast} from 'react-toastify';
import {startLoading, stopLoading} from '../../ducks/loading';

class PopupListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      popupListPage: 1,
      totalListSize: 0,
      listSizePerPage: 15,
      popupList: [],
      selectedPopupIdList: [],
      searchParams: {},
      modal: false,
    }
  }

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      contentId: this.props.carousel.selectedContsId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      useYN: searchParams.selectedFilter[0].value ? popupUseKey2Code[searchParams.selectedFilter[0].value] : "",
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      pageNumber: this.state.popupListPage,
      limit: this.state.listSizePerPage
    };
    this.setState({searchParams: params, pageNumber: 1}, () => this.triggerPopupSearch())
  };

  triggerPopupSearch = () => {
    const {searchParams} = this.state;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.sortName = this.state.sortName;
    if (this.state.popupListPage && this.state.listSizePerPage) {
      searchParams.limit = this.state.listSizePerPage;
      searchParams.pageNumber = this.state.popupListPage;
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_POPUP_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalListSize: response.data.records,
                popupList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }
  };

  handleDeletePopup = () => {
    this.setState({modal: false});
    if (!this.state.selectedPopupIdList.length) {
      return;
    }
    this.props.startLoading();
    this.httpClient
        .post(API_ADMIN_DELETE_POPUP, this.state.selectedPopupIdList)
        .then(response => {
          if (response.data !== SUCCESS) {
            toast(this.props.t("POPUP_DELETE_ALERT_FAIL"));
          } else {
            toast(this.props.t("POPUP_DELETE_ALERT_SUCCESS"));
            this.fetchPopupList(this.state.pageNumber, this.state.listSizePerPage);
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  handleRowSelect = (isSelect, rows) => {

    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedPopupIdList.find(popUpId => row["popUpId"] === popUpId)) {
            return {
              selectedPopupIdList: [...prevState.selectedPopupIdList, row["popUpId"]]
            };
          }
        });
      } else {
        this.setState(prevState => {
          return {
            selectedPopupIdList: prevState.selectedPopupIdList.filter(popUpId => row["popUpId"] !== popUpId)
          };
        })
      }
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      popupListPage: page,
      listSizePerPage: sizePerPage,
    }, () => this.triggerPopupSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const tableHeaderField2SortName = {
      "popupIdLink": "popUpName",
      "author": "author",
      "displayPeriod": "validEndDate",
      "visibility": "inUse",
      "registrationDate": "registeredDate"
    };
    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: tableHeaderField2SortName[sortField] ? tableHeaderField2SortName[sortField] : sortField,
      popupListPage: 1,
    }, () => this.triggerPopupSearch())
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                label: "Keyword",
                placeholder: this.props.t("PLEASE_ENTER_TITLE"),
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("REGISTRATION_DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                showSearch: false,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: popupAttributes.visibility.key, value: popupAttributes.visibility.value},
                ]
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              noSelect
              keyField="popUpId" // one of 'dataField'
              hideSelectColumn={false}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "popUpId", hidden: true},
                {dataField: "popupIdLink", text: this.props.t(popupAttributes.title.key), headerStyle: {width: "40.8612%"}, sort: true, classes: () => "text-left text-truncate", titleHeader: "popUpName"},
                {dataField: "author", text: this.props.t(popupAttributes.author.key), headerStyle: {width: "11.0721%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "displayPeriod", text: this.props.t(popupAttributes.displayPeriod.key), headerStyle: {width: "18.1022%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "visibility", text: this.props.t(popupAttributes.visibility.key), headerStyle: {width: "9.9299%"}, sort: true},
                {dataField: "registrationDate", text: this.props.t(popupAttributes.registrationDate.key), headerStyle: {width: "15.99322%"}, sort: true, classes: () => "text-truncate"},
              ]}
              page={this.state.pageNumber}
              sizePerPage={this.state.listSizePerPage}
              totalSize={this.state.totalListSize}
              rows={this.state.popupList.map(popup => {
                return {
                  ...popup,
                  popupIdLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_ADMIN_POPUPMANAGEMENT + "/" + popup.popUpId);
                            }}>
                        {popup.popUpName}
                      </Link>,
                  displayPeriod: popup.validStartDate + "~" + popup.validEndDate,
                  registrationDate: popup.registeredDate,
                  visibility: this.props.t(noticeVisibilityCode2Key[popup.inUse])
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            <button
                type="button" className="btn btn-sm btn-outline-primary float-left"
                onClick={() => this.setState({modal: true})}>
              {this.props.t("DELETE")}
            </button>
            <Link to={URL_ADMIN_POPUPREGISTRATION}>
              <button type="button" className="btn btn-sm btn-primary float-right">
                {this.props.t("REGISTER")}
              </button>
            </Link>
          </div>
          <BasePopup
              open={this.state.modal}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modal: false})}
              body={
                <p>{this.state.selectedPopupIdList.length ? this.props.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE") : this.props.t("PLEASE_SELECT_AT_LEAST_ONE_ITEM")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleDeletePopup()}>
                    {this.props.t("CONFIRM")}
                  </button>
                  {
                    this.state.selectedPopupIdList.length > 0 &&
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modal: false})}>
                      {this.props.t("CANCEL")}
                    </button>
                  }

                </>
              }/>
        </div>
    );
  }

  fetchPopupList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod();
    const searchParams = {
      dateStart: searchPeriod.startDate,
      dateEnd: searchPeriod.endDate,
    };
    this.setState({searchParams: searchParams,
      sortName: "registeredDate",
      sortOrder: "DESC"
    }, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        sortOrder: this.state.sortOrder,
        sortName: this.state.sortName,
        pageNumber: page,
        limit: sizePerPage
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_POPUP_LIST, {
              params
            })
            .then(response => {
              this.setState({
                pageNumber: page,
                listSizePerPage: sizePerPage,
                totalListSize: response.data.records,
                popupList: response.data.data,
                searchParams: params
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
              reject(error);
            })
      });
    });
  };

  componentDidMount() {
    this.fetchPopupList(1, 15);
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(PopupListPanel)));