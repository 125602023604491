import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

class KeywordPanel extends React.Component {

  render() {
    return (
        <div className="form-group row">
          <label className="col col-form-label">{this.props.label}
            {this.props.showToolTip &&
            <span className={`tooltips ${this.props.isTooltipOpen && "show"} ml-2`}>
              <Link to={{}} onClick={this.props.onToolTipOpenClick} className="tooltip-toggle">?</Link>
										<div className="tooltips_body">
                      <Link to={{}} onClick={this.props.onToolTipCloseClick} className="tooltip-toggle close"></Link>
                      {this.props.toolTipText}
										</div>
									</span>
            }
          </label>
          <div className="col">
            <div className={`input-group-inline-block ${this.props.showDownload && "download"}`}>
              <div className="input-group">
                {this.props.options &&
                <div className="mr-10">
                  <div className="custom-selectbox" style={{width: "145px"}}>
                    <SelectrWrapper className="form-control" value={this.props.optionValue} onChange={this.props.onOptionChange}>
                      {this.props.options.map(option => <option value={option} key={option}>{this.props.t(option)}</option>)}
                    </SelectrWrapper>
                  </div>
                </div>
                }
                <input type="text" className="form-control rounded-left" placeholder={this.props.placeHolder} value={this.props.textValue} onChange={this.props.onTextChange}/>
                <div className="input-group-append">
                  <button className="btn btn-primary" onClick={this.props.onSearchClick}>{this.props.t('SEARCH')}</button>
                </div>
              </div>
            </div>
            <button type="button" className="btn btn-icon btn-refrash" onClick={this.props.onResetClick}>
              <i className="icon icon-refrash"/>
            </button>
            {this.props.showDownload &&
            <button type="button" className="btn btn-outline-primary align-top ml-10"
                    data-toggle={`${this.props.downloadToolTip && "tooltip"}` } data-placement={`${this.props.downloadToolTip && "bottom"}`} title={this.props.downloadToolTip && "Go to CSV Download Repository Menu"} onClick={this.props.onDownloadClick}>
              {this.props.t('DOWNLOAD')}
            </button>
            }
          </div>
        </div>
    );
  }
}

export default withTranslation()(KeywordPanel);
