import React from 'react';
import {withTranslation} from 'react-i18next';

class Table extends React.Component {

  render() {
    return (
        <table className="table table-bordered">
          <caption>List</caption>
          <colgroup>
            <col width="*"/>
          </colgroup>
          <thead>
          <tr>
            {this.props.data.map(item => <th key={item.id} scope="col">{this.props.t(item.header)}</th>)}
          </tr>
          </thead>
          <tbody className="text-center">
          <tr>
            {this.props.data.map(item => <td key={item.id}>{item.value}</td>)}
          </tr>
          </tbody>
        </table>
    );
  }
}

export default (withTranslation()(Table));
