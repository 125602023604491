// Invoice ID, Buyer, App ID, App Name, Product ID, Product Name

export const appAttributes = {
  appId: {
    key: "SETTLEMENT_APP_ID",
    value: null
  },
  appName: {
    key: "SETTLEMENT_APP_NAME",
    value: null
  },
};

export const invoiceReflectionStatusCode2Key = {
  "Y": "REFLECTED",
  "N": "NOT_REFLECTED",
};

export const invoiceReflectionStatusKey2Code = {
  "REFLECTED": "Y",
  "NOT_REFLECTED": "N",
};

export const transactionStatusCode2Key = {
  "202": "COMPLETED",
  "203": "UNCONFIRMED",
  "206": "REFUND_RESERVED",
  "302": "REFUNDED",
  "303": "AUTO_REFUND_FAILED",
  "304": "AUTO_REFUNDED",
  "306": "REFUND"
}

export const transactionStatusKey2Code = {
  "COMPLETED": "202",
  "UNCONFIRMED": "203",
  "REFUND_RESERVED": "206",
  "REFUNDED": "302",
  "AUTO_REFUND_FAILED": "303",
  "AUTO_REFUNDED": "304",
  "REFUND": "306"
}

export const paymentMethodKey2Code = {
  "PAYPAL": "PP",
  "GLOBAL_CREDIT_CARD": "GCC",
  "INICIS_CREDIT_CARD": "KCC",
  "INICIS_PHONE_BILL": "KPI",
  "DUMMY_PAY": "DM",
  "FREE_TRIAL": "FT",
  "SAMSUNG_PAY_KR": "KSP",
  "SAMSUNG_PAY_WW": "WSP",
  "PAYTM" : "IWP"
}

export const paymentMethodCode2Key = {
  "PP": "PayPal",
  "GCC": "Global Credit Card",
  "KCC": "Inicis (Credit Card)",
  "KPI": "Inicis (PhoneBill)",
  "DM": "Dummy Pay",
  "FT": "Free Trial",
  "KSP": "Samsung Pay KR",
  "WSP": "Samsung Pay WW",
  "IWP" : "Paytm"
};

export const transactionTypeCode2Key = {
  "N": "REAL",
  "Y": "TEST"
};

export const transactionTypeKey2Code = {
  "REAL": "N",
  "TEST": "Y"
};

export const transactionCancelCode2Key = {
  "01": "BILLING_CLIENT_REQUEST_TO_CANCEL",
  "02": "REFUND_BY_CP",
  "03": "REFUND_BY_ADMIN",
  "04": "PAYMENT_CANCEL_DUE_TO_PAYMENT_FAILURE",
  "05": "BUYER_PORTAL_REQUEST_TO_CANCEL",
  "06": "CHARGE_BACK"
};

export const changeSystemCode2Key = {
  "API": "API",
  "BAT": "Batch",
  "PTL": "Portal"
};

export const subscriptionStatusCode2Key = {
  "00": "ACTIVE",
  "01": "EXPIRED",
  "02": "CANCEL_BY_BUYER",
  "03": "CANCEL_PAYMENT_FAILURE",
  "04": "CANCEL_BY_CP",
  "05": "CANCEL_BY_ADMIN",
  "06": "CANCEL_BY_GDPR",
  "07": "CANCEL_BY_WITHDRAWAL",
  "08": "CANCEL_BY_NOT_USED",
  "09": "CANCEL_BY_MISUSE_ID",
  "10": "CANCEL_BY_BLACKLIST_ID",
  "11": "PAYMENT_FAILED", //결제 실패
  "12": "PAUSED_BY_PAYTM"
}

export const subscriptionStatusKey2Code = {
  "ACTIVE": "00",
  "EXPIRED": "01",
  "CANCEL_BY_BUYER": "02",
  "CANCEL_PAYMENT_FAILURE": "03",
  "CANCEL_BY_CP": "04",
  "CANCEL_BY_ADMIN": "05",
  "CANCEL_BY_GDPR": "06",
  "CANCEL_BY_WITHDRAWAL": "07",
  "CANCEL_BY_NOT_USED": "08",
  "CANCEL_BY_MISUSE_ID": "09",
  "CANCEL_BY_BLACKLIST_ID": "10",
  "PAYMENT_FAILED": "11", //결제 실패
  "PAUSED_BY_PAYTM": "12"
}

export const subscriptionFrequencyCode2Key = {
  "M": "EVERY_MONTH",
  "W": "EVERY_WEEK",
  "D": "EVERY_DAY",
  "H": "EVERY_HOUR",
  "Y": "EVERY_YEAR"
}

export const subscriptionAttributes = {
  subscriptionId: {
    key: "SUBSCRIPTION_ID",
    value: null
  },
  salesPrice: {
    key: "SALES_PRICE",
    value: null
  },
  subscriptionStartDate: {
    key: "SUBSCRIPTION_START_DATE",
    value: null
  },
  subscriptionEndDate: {
    key: "SUBSCRIPTION_END_DATE",
    value: null
  },
  lastPaymentDate: {
    key: "LAST_PAYMENT_DATE",
    value: null
  },
  nextPaymentDate: {
    key: "NEXT_PAYMENT_DATE",
    value: null
  },
  subscriptionStatus: {
    key: "SUBSCRIPTION_STATUS",
    value: [
      "ACTIVE",
      "CANCEL_BY_CP"
    ]
  },
  procStatusDisplay: {
    key: "PAYMENT_STATUS",
    value: null
  },
  paymentDate: {
    key: "PAYMENT_DATE",
    value: null
  },
  refundDate: {
    key: "REFUND_DATE",
    value: null
  }
};

export const invoiceAttributes = {
  invoiceId: {
    key: "INVOICE_ID",
    value: null
  },
  buyer: {
    key: "BUYER",
    value: null
  },
  buyerUId: {
    key: "BUYER_UID",
    value: null
  },
  orderCustomerId: {
    key: "ORDER_CUSTOMER_ID",
    value: null
  },
  country: {
    key: "COUNTRY",
    value: null
  },
  zipCode: {
    key: "ZIPCODE",
    value: null
  },
  currency: {
    key: "CURRENCY",
    value: null
  },
  orderDate: {
    key: "ORDER_DATE",
    value: null
  },
  paymentAmount: {
    key: "PAYMENT_AMOUNT",
    value: null
  },
  samsungRewardsAmount: {
    key: "SAMSUNG_REWARDS_AMOUNT",
    value: null
  },
  paymentMethod: {
    key: "PAYMENT_METHOD",
    value: [
      "PAYPAL", //PayPal
      "GLOBAL_CREDIT_CARD", //Global Credit Card
      "INICIS_CREDIT_CARD", //Inicis (Credit Card)
      "DUMMY_PAY", //Dummy Pay"
      "FREE_TRIAL", //Free Trial
      "SAMSUNG_PAY_KR", //Samsung Pay KR"
      "SAMSUNG_PAY_WW", //Samsung Pay WW"
      "PAYTM"
    ]
  },
  additionalPaymentInfo: {
    key: "ADDITION_PAYMENT_INFO",
    value: [
      "COUPON",
      "SAMSUNG_REWARDS"
    ]
  },
  transactionType: {
    key: "TRANSACTION_TYPE",
    value: [
      "REAL",
      "TEST"
    ]
  },
  reflectionStatus: {
    key: "REFLECTION_STATUS",
    value: [
      "REFLECTED",
      "NOT_REFLECTED"
    ]
  },
  transactionStatus: {
    key: "TRANSACTION_STATUS",
    value: [
      "COMPLETED",
      "REFUND",
      "AUTOMATIC_REFUND_FAILED",
      "REFUND_RESERVED",
      "REFUNDED"
    ]
  }
};

export function findDays(orderDate, countryCode) {
  let currentDate = new Date();
  const days = (firstDate, secondDate) => {
    let difference = firstDate.getTime() - secondDate.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  }
  return days(currentDate, orderDate) > 300 && countryCode === "BR";
}