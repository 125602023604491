export const subscriptionGroupAttributes = {
  groupId: {
    key: "GROUP_ID",
    value: null
  },
  groupName: {
    key: "GROUP_NAME",
    value: null
  },
  freeTrialOffering: {
    key: "FREE_TRIAL_OFFERING",
    value: null
  },
  subscriptionProduct: {
    key: "SUBSCRIPTION_PRODUCT",
    value: null
  },
  deleteGroup: {
    key: "DELETE_GROUP",
    value: null
  },
  modifiedDate: {
    key: "MODIFIED_DATE",
    value: null
  },
};
