import React, {Component} from "react";
import Content from '../Content';
import SHeader from '../SHeader';
import LeftNaviBar from '../lnb/LeftNaviBar';
import TopNaviBar from '../tnb/TopNaviBar';
import TabManager from '../TabManager';
import {connect} from 'react-redux';
import RequestListPanel from "../panel/RequestListPanel";

class RequestListPage extends Component {
    render() {
        let wrapClassName = "clearfix";
        if (!this.props.sideDrawer.isSideDrawerOpen) {
            wrapClassName += " closeMenu";
        }

        return (
            <div id="wrap" className={wrapClassName}>
                <SHeader>
                    <LeftNaviBar/>
                </SHeader>
                <Content>
                    <TopNaviBar/>
                    <TabManager content={<RequestListPanel/>}/>
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sideDrawer: state.sideDrawer
    };
};

export default connect(mapStateToProps)(RequestListPage);