import {toast} from "react-toastify";
import {SUCCESS} from './globalConstants';
import {handleHttpError} from './ErrorUtils';

const setAllCouponInformationStartEndPeriodAsDefaultCountry = (couponInformationByCountries, validStart, validEnd, translator) => {
  if (!validStart || !validEnd) {
    toast(translator("COUPON_DETAIL_ALERT_ENTER_VALID_PERIOD_FOR_DEFAULT_COUNTRY"));
    return {applyAllPeriod: false};
  }
  toast(translator("COUPON_DETAIL_ALERT_VALID_PERIOD_APPLIED_TO_ALL_COUNTRIES"));
  return {
    couponInformationByCountries: couponInformationByCountries.map((item) => {
      item.validPeriodStart.setValue(new Date(validStart.getValue()));
      item.validPeriodEnd.setValue(new Date(validEnd.getValue()));
      return item;
    })
  }
};

const setValidStartEndPeriodForCountry = (isStart, datepicker, country, couponInformationByCountries) => {
  return {
    couponInformationByCountries: couponInformationByCountries.map((item) => {
      if (item.country === country) {
        if (isStart) {
          datepicker.setValue(new Date());
          item.validPeriodStart = datepicker
        } else {
          const monthAfter12 = new Date();
          monthAfter12.setFullYear(monthAfter12.getFullYear() + 1);
          datepicker.setValue(new Date(monthAfter12));
          item.validPeriodEnd = datepicker
        }
      }
      return item;
    })
  }
};

const postAjaxCoupon = (api, params, translator, history, startLoading, stopLoading, httpClient, redirectURL, messageOnFailed, messageOnSuccess) => {
  return new Promise((resolve, reject) => {
    startLoading();
    httpClient
        .post(api, params)
        .then(response => {
          if (response.data !== SUCCESS) {
            toast(translator(messageOnFailed));
          } else {
            toast(translator(messageOnSuccess));
            history.push(redirectURL);
          }
          stopLoading();
        })
        .catch(error => {
          handleHttpError(translator, history, error);
          stopLoading();
        })
  });
}

const closePopupApplyAllValidation = (newValue, totalIssuedCeil, couponInformationByCountries, translator) => {
  if (!Number(newValue.noOfCoupon)) {
    toast(translator("ENTER_VALID_NUMBER"));
    return false;
  }
  let closeModal = true;

  couponInformationByCountries
    .map((item) => {
      if (item.isDefaultCountry) {
        if (Number(newValue.noOfCoupon) < item.initialIssued) {
          toast(translator("CANNOT_REDUCE_TOTAL_ISSUED_BELOW_INITIAL_QUANTITY"));
          closeModal = false;
        }
        if (Number(newValue.noOfCoupon) > totalIssuedCeil) {
          toast(translator("YOU_CAN_ISSUE_UP_TO_TEN_THOUSAND_COUPONS_PER_COUNTRY"));
          closeModal = false;
        }
      }
      return closeModal;
    });

  if (!newValue.validStart.getValue() || !newValue.validEnd.getValue()) {
    toast(translator("COUPON_DETAIL_ALERT_ENTER_VALID_PERIOD_FOR_DEFAULT_COUNTRY"));
    closeModal = false;
  } else if (new Date(newValue.validStart.getValue()) > new Date(newValue.validEnd.getValue())) {
    toast(translator("COUPON_CREATE_ALERT_ERROR_VALID_PERIOD_NOT_VALID"));
    closeModal = false;
  }
  return closeModal;
}

export {setAllCouponInformationStartEndPeriodAsDefaultCountry, setValidStartEndPeriodForCountry, postAjaxCoupon, closePopupApplyAllValidation};