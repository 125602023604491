import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import TaxPricingGuidePopup from '../popup/TaxPricingGuidePopup';
import MultipleAddModifyProductNoticePopup from '../popup/MultipleAddModifyProductNoticePopup';
import {handleDownloadFile, multipleAddModifyProductAttributes, productTypeCode2Key, productTypeKey2Code, productTypes} from '../../utils/multipleAddModifyProductAttributes';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import Papa from 'papaparse'
import {connect} from 'react-redux';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {getCountriesInformation, isCountriesInformationChanged} from '../../ducks/countriesInformation';
import {getAppFromSessionData} from '../../ducks/sessionData';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, DYNAMIC_PRODUCT, FREE_TRIAL_SUBSCRIPTION, LIMITED_PERIOD, SUBSCRIPTION, SUCCESS, TAX_CATEGORY_PAID_ITEM_OR_APP, TAX_CATEGORY_PICTURE_DOWNLOAD_TO_LOCAL_MEMORY, TAX_CATEGORY_VISUAL_OR_STREAMING_PRODUCT, transformRequestOptions} from '../../utils/globalConstants';
import {API_PRODUCT_MULTIPLE_LIST_DOWNLOAD, API_PRODUCT_TAX_CATEGORY_LIST, URL_GET_MULTIPLE_PRODUCTS_BY_ID_API, URL_MULTIPLE_ADD_PRODUCT_API, URL_MULTIPLE_DOWNLOAD_TEMPLATE_API, URL_MULTIPLE_MODIFY_PRODUCT_API, URL_PRODUCT_LIST} from '../../utils/urlConstants';
import {billingPeriodCode2Key, billingPeriodKey2Code, visibilityKey2Code} from '../../utils/productTypesAndAttributes';
import {handleHttpError} from '../../utils/ErrorUtils';
import {Link, withRouter} from 'react-router-dom';
import {startLoading, stopLoading} from '../../ducks/loading';
import {fetchTaxCategoryList} from '../../utils/CountryListUtils';

const WEEKLY = "Weekly";
const MONTHLY = "Monthly";
const ANNUALLY = "Annually";
const MAX_FREE_TRIAL_DAYS = 999;
const MIN_FREE_TRIAL_DAYS = 0;

class MultipleAddModifyProductPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTaxGuidePopupOpen: false,
      isNoticePopupOpen: false,

      init: false,
      defaultCountry: null,

      productListPage: 1,
      totalProductListSize: 0,
      productListSizePerPage: 15,
      productListData: [],
      tableData: [],
      csvFile: null,
    };
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.fileRef = React.createRef();
  }

  initData = () => {
    fetchTaxCategoryList(API_PRODUCT_TAX_CATEGORY_LIST, this.props.t, this.props.history).then(response => {
      this.setState({taxCategoryData: response});
      if (this.props.sessionData.apps.length && this.props.countriesInformation.length) {
        const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
        this.setState({
          init: true,
          defaultCountry: app.defaultCountry,
        });
      }
    });
  };

  getTaxCategoryId = (productCode) => {
    let id = "";
    this.state.taxCategoryData.forEach(item => {
      if (item.taxProductCode === productCode) {
        id = item.taxCategoryId;
      }
    })
    return id;
  }

  handleFileChange = event => {
    const allowedExtensions = ['csv'];
    if (!event.target || event.target.files.length === 0) {
      return;
    }
    const {name: fileName, size: fileSize} = event.target.files[0];
    const fileExtension = fileName ? fileName.split(".").pop() : '';
    if (!allowedExtensions.includes(fileExtension)) {
      toast(this.props.t("FILE_NOT_ALLOWED"));
    } else {
      this.setState({
        csvFile: event.target.files[0]
      });
    }
  };

  fetchProductListByIds = (productIds) => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      cpProductIds: [...productIds]
    };
    if (params.cpProductIds.length === 0) {
      toast(this.props.t("Please Check Your Uploaded File"));
      return new Promise((resolve, reject) => {
        reject("Please Check Your Uploaded File");
      })
    }
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_MULTIPLE_PRODUCTS_BY_ID_API, {
            params: params,
            paramsSerializer: transformRequestOptions
          })
          .then(response => {
            this.props.stopLoading();
            this.setState({fetchedProductList: response.data});
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  handleRegisterButtonClick = () => {
    const {csvFile} = this.state;
    Papa.parse(csvFile, {
      complete: this.updateData,
      header: true,
      skipEmptyLines: true
    });
  };

  // Function to read csv data and generate alerts
  updateData = (result) => {
    const data = result.data;
    let tableData = []; // data object for table
    let count = 0;
    let hash = {}; // 2D hash map of information in hash[productId][country]
    let productIdList = new Set(); // set of unique product ids contained in table
    let minimumSaleableAmount;
    let maximumSaleableAmount;
    let countryList = this.props.countriesInformation.map(item => item.country); // total list of available countries

    data.forEach(e => {
      if (e["[Applied Country]"] !== "x") {
        let row = {};
        row.id = count;
        row.productId = e["[Product ID]"];
        row.productName = e["[ProductNames]"];
        row.country = e["[Country Code]"];
        row.description = e["[Description]"];
        row.productType = e["[Type]"];
        row.period = e["[Period]"]; //Only for limited period Product type
        row.subscriptionGroupName = e["[Subscription Group Name]"];
        row.subscriptionGroupId = e["[Subscription Group Id]"];
        row.frequency = e["[Frequency]"];
        row.subscriptionProductLevel = e["[Subscription Product Level]"];
        row.duplicateBenefitYN = e["[Duplicate Benefit]"];
        row.freeTrialPeriod = e["[Free Trial Days]"];
        row.verifyUrl = e["[Verify URI]"];
        row.visibility = e["[Visibility]"];
        row.price = e["[Local Prices]"];
        row.minPrice = e["[Minimum Prices]"];
        row.maxPrice = e["[Maximum Prices]"];
        row.taxRate = e["[Vat rate (%)]"];
        row.taxIncluded = e["[Tax Included]"];
        row.taxCategory = e["[Tax Category]"];
        let alert = "";

        //if id exists
        if (row.productId) {
          productIdList.add(row.productId);

          const globalRegex = new RegExp('[a-zA-Z0-9-_\.]+', 'g');
          if (globalRegex.test(row.productId) && globalRegex.lastIndex !== row.productId.length) {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_ID_FORMAT_CHECK");
          }
          //if id is less than 50 chars
          if (row.productId.length > 50) {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_ID_LENGTH_CHECK");
          }
        } else {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_ID_MISSING");
        }

        //if description is valid
        if (row.description) {
          //if description is between 5 and 400 chars
          if (row.description.length > 400 || row.description.length < 5) {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_DESC_LENGTH_CHECK");
          }
        } else {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_DESC_MISSING");
        }

        //if name exists
        if (row.productName) {
          //if name is less than 50 chars
          if (row.productName.length > 50) {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_NAME_LENGTH_CHECK");
          }
        } else {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PROD_NAME_MISSING");
        }

        //if visibility exists
        if (row.visibility) {
          //if name is less than 50 chars
          if (row.visibility !== "show" && row.visibility !== "hide" && row.visibility !== "optional") {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_VISIBILITY_FORMAT_NOT_CORRECT");
          }
        } else {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_VISIBILITY_MISSING");
        }

        //if country code exists
        if (row.country) {
          //if country code is valid
          if (!countryList.includes(row.country)) {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_COUNTRY_CODE_NOT_SUPPORTED")
          } else {
            // country exists
            const countryInfo = getCountriesInformation(this.props.countriesInformation, row.country);
            minimumSaleableAmount = countryInfo.minimumSaleableAmount;
            maximumSaleableAmount = countryInfo.maximumSaleableAmount;
          }
        } else {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_LOCATION_MISSING");
        }

        //if applied country format is right
        if (e["[Applied Country]"] !== "o") {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_LOCATION_FORMAT_NOT_CORRECT");
        }

        //if product type exists
        if (row.productType) {
          if (row.productType === productTypes.LIMITED_PERIOD) {
            if (!isNaN(Number(row.period))) {
              if (Number(row.period) <= 0) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_PERIOD_SHOULD_BE_NATURAL_NUMBER");
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_PERIOD_NOT_NUMBER");
            }
          } else if ((row.productType === productTypes.SUBSCRIPTION || row.productType === productTypes.FREE_TRIAL_SUBSCRIPTION)) {
            if (row.subscriptionGroupId) {
              const globalRegex = new RegExp('[a-zA-Z0-9_-]+', 'g');
              if (globalRegex.test(row.subscriptionGroupId) && globalRegex.lastIndex !== row.subscriptionGroupId.length) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_SUBSCRIPTION_GROUP_ID_FORMAT_CHECK");
              }
              if (row.subscriptionGroupId.length > 20) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_SUBSCRIPTION_GROUP_ID_LENGTH_CHECK");
              }
              if (row.subscriptionGroupId.length <= 0) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_SUBSCRIPTION_GROUP_ID_MISSING");
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_SUBSCRIPTION_GROUP_ID_MISSING");
            }
            if (row.subscriptionProductLevel) {
              if (row.subscriptionProductLevel < 1 && row.subscriptionProductLevel > 10) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PRODUCT_LEVEL_NOT_IN_RANGE");
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_PRODUCT_LEVEL_MISSING");
            }
            if (row.duplicateBenefitYN) {
              if (row.duplicateBenefitYN !== 'Y' && row.duplicateBenefitYN !== 'N') {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_DUPLICATE_BENEFIT_FORMAT_NOT_CORRECT");
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_DUPLICATE_BENEFIT_MISSING");
            }
            if (row.frequency) {
              if (row.frequency === WEEKLY || row.frequency === MONTHLY || row.frequency === ANNUALLY) {
                //if Every Week, Every Month, Every Year
              } else {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_FREQUENCY_NOT_VALID");
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ERROR_MESSAGE_INVALID_FREQUENCY");
            }
            if (parseInt(row.freeTrialPeriod) !== 0) {
              if (!isNaN(Number(row.freeTrialPeriod))) {
                if (Number(row.freeTrialPeriod) > MAX_FREE_TRIAL_DAYS || Number(row.freeTrialPeriod) <= MIN_FREE_TRIAL_DAYS) {
                  alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ERROR_MESSAGE_FREETRIALDAYS_OUT_OF_RANGE");
                }
              } else {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ERROR_MESSAGE_FREETRIALDAYS_NAN");
              }
            }
          } else if (row.productType === productTypes.DYNAMIC_PRODUCT) {
            //Product Type is Dynamic Product
            if (row.verifyUrl && !(/^(http|https):\/\//g).test((row.verifyUrl))) {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_VERIFY_URL");
            }
          } else if (row.productType === productTypes.CONSUMABLE || row.productType === productTypes.NON_CONSUMABLE) {
            // Intentionally left Empty
          } else {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_PRODUCT_TYPE_NOT_VALID");
          }

          if (row.productType === productTypes.DYNAMIC_PRODUCT) {
            if (row.minPrice) {
              if (minimumSaleableAmount && maximumSaleableAmount) {
                if (row.minPrice < minimumSaleableAmount || row.minPrice > maximumSaleableAmount) {
                  alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_MINIMUM_PRICE_RANGE_NOT_VALID");
                }
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_MINIMUM_PRICE_IS_EMPTY");
            }
            if (row.maxPrice) {
              if (minimumSaleableAmount && maximumSaleableAmount) {
                if (row.maxPrice < minimumSaleableAmount || row.maxPrice > maximumSaleableAmount) {
                  alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_MAXIMUM_PRICE_RANGE_NOT_VALID");
                }
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_MAXIMUM_PRICE_IS_EMPTY");
            }
          } else {
            if (row.price) {
              if (minimumSaleableAmount && maximumSaleableAmount) {
                if (row.price < minimumSaleableAmount || row.price > maximumSaleableAmount) {
                  alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_RANGE_OF_PRODUCT_PRICE_NOT_VALID");
                }
              }
            } else {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_RANGE_OF_PRICE_NOT_VALID");
            }
          }
        } else {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_INVALID_PRODUCT_TYPE");
        }

        //tax category
        let taxCategoryApplicable = getCountriesInformation(this.props.countriesInformation, row.country) ? getCountriesInformation(this.props.countriesInformation, row.country).taxCategoryApplicable : false;
        if (row.taxCategory && !taxCategoryApplicable) {
          alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_TAX_CATEGORY_CHANGE_NOT_ALLOWED");
        }
        if (taxCategoryApplicable) {
          if (!row.taxCategory) {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_TAX_CATEGORY_IS_MISSING");
          } else {
            if (row.taxCategory.length !== 10) {
              row.taxCategory = row.taxCategory.padStart(10, '0'); // SCWS-5130
            }
            if (!(row.taxCategory === TAX_CATEGORY_PAID_ITEM_OR_APP
                || row.taxCategory === TAX_CATEGORY_PICTURE_DOWNLOAD_TO_LOCAL_MEMORY
                || row.taxCategory === TAX_CATEGORY_VISUAL_OR_STREAMING_PRODUCT)) {
              alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_TAX_CATEGORY_WRONG_FORMAT");
            }
          }
        }

        let isAgentCountry = getCountriesInformation(this.props.countriesInformation, row.country) ? getCountriesInformation(this.props.countriesInformation, row.country).agentCountry : false;
        let taxApplicable = getCountriesInformation(this.props.countriesInformation, row.country) ? getCountriesInformation(this.props.countriesInformation, row.country).taxApplicable : false;
        let agentCountryTaxApplicable = getCountriesInformation(this.props.countriesInformation, row.country) ? getCountriesInformation(this.props.countriesInformation, row.country).agentCountryTaxApplicable : false;
        // SCWS-3811
        if (!taxApplicable && agentCountryTaxApplicable) {
          row.taxIncluded = 'o';
          row.taxRate = null;
        }
        if (taxApplicable && row.taxIncluded !== 'o' && row.taxIncluded !== 'x') {
          alert += "isTaxIncludedValid is invalid<br/>";
          row.taxIncluded = '';
        }

        if (taxApplicable) {
          if (row.taxIncluded !== 'o' && row.taxIncluded !== 'x') {
            alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_VAT_FORMAT_NOT_VALID");
          }
          if (isAgentCountry) {
            if (row.taxIncluded === 'o') {
              if (isNaN(Number(row.taxRate))) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_CHECK_VAT_RATE");
              } else if (row.taxIncluded === 'x') {
                if (row.taxRate) {
                  alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_VAT_RATE_NOT_ENTERED_WHEN_VAT_INCLUSIVE_NOT_SELECTED");
                }
              }
            } else {
              if (Number(row.taxRate)) {
                alert = alert + this.props.t("MULTIPLEADDMODIFYPRODUCT_ALERT_ERROR_VAT_RATE_NOT_FOR_THIS_COUNTRY");
              }
            }

          }
        }

        if (row.country && row.productType && row.country === "IN" && row.productType !== "Dynamic Product"
            && !Number.isInteger(Number(row.price))) {
          alert += "Product Price cannot be decimal for India";
        }

        if (row.country && row.minPrice && row.maxPrice && row.country === "IN" && row.productType === "Dynamic Product"
            && (!Number.isInteger(Number(row.minPrice))
                || !Number.isInteger(Number(row.maxPrice)))) {
          alert += "Product Price Range cannot be decimal for India";
        }

        row.alertReason = alert;
        if (row.productId) {
          if (!hash[row.productId]) {
            hash[row.productId] = {};
          }
          hash[row.productId][row.country] = row;
        }
        tableData.push(row);
        count = count + 1;
      }
    });

    if (productIdList.size > 100) {
      toast(this.props.t("MULTIPLEMODIFYPRODUCT_TOAST_1"));
      return;
    }
    this.fetchProductListByIds(productIdList).then(() => this.checkDataColumns(productIdList, hash, tableData))
        .catch(error => {
          console.log(error)
        });
  };

  checkDataColumns = (productIdList, hash, tableData) => {
    //hash : 2D hash map of information in hash[productId][country]
    //productIdList : set of unique product ids contained in table
    const productInfoList = this.state.fetchedProductList; //DB value

    productIdList.forEach(item => {
      let productCountries = Object.values(hash[item]);
      let idExists = false;
      let defaultFlag = true;

      let type = "";
      let description = "";
      let visibility = "";
      let period = "";
      let frequency = "";
      let cycles = "";
      let freeTrialDays = "";
      let verifyUrl = "";
      let subscriptionProductLevel = "";
      let duplicateBenefitYN = "";

      let typeFlag = true;

      // TODO set 2 sets of variables, 1 for checking if unmodifiable column is changed, another for checking conistency

      if (this.props.isModify) {
        productInfoList.forEach(e => {
          if (e.cpProductId === item) {
            idExists = true;
            type = productTypeCode2Key[e.productType];
            period = parseInt(e.limitedPeriodDays) * 24 * 60 + parseInt(e.limitedPeriodHours) * 60 + parseInt(e.limitedPeriodMinutes);
            frequency = e.cycle ? billingPeriodCode2Key[e.cycle] : "";
            duplicateBenefitYN = e.duplicateBenefitYN ? e.duplicateBenefitYN : "";
            subscriptionProductLevel = e.subscriptionProductLevel ? e.subscriptionProductLevel : "";
            cycles = cycles ? cycles : "";
            verifyUrl = e.verifyUrl;
          }
        });
      } else {
        productInfoList.forEach(e => {
          if (e.cpProductId === item) {
            idExists = true;
          }
        });
      }

      let isDefaultCountryDataPresent = productCountries.find(productDataByCountry => productDataByCountry.country === this.state.defaultCountry)

      productCountries.forEach((e, index, array) => {
        if (!isDefaultCountryDataPresent) {
          array[index].alertReason = "Default Country Data for this product is not present";
        }
        if (!this.props.isModify) {
          if (idExists) {
            array[index].alertReason = array[index].alertReason + "- Product ID already exists";
          }
        } else {
          if (idExists === false) {
            array[index].alertReason = array[index].alertReason + "- Product ID is Invalid";
            return;
          }
        }
        if (type === "") {
          type = productTypeCode2Key[productTypeKey2Code[e.productType]];
        } else if (typeFlag) {
          typeFlag = (productTypes[type] === e.productType)
        }
        description = e.description;
        visibility = e.visibility;
        freeTrialDays = e.freeTrialPeriod;
        // chack if default country exists
        if (!defaultFlag) {
          defaultFlag = (e.country === this.state.defaultCountry)
        }
        if (this.props.isModify) {
          //check for unchangeable columns
          //type, period, frequency, free trial days, free trial criteria, allowed devices, verifyURL, tax category

          period = period ? period : "";
          frequency = frequency ? frequency : "";
          cycles = cycles ? cycles : "";
          verifyUrl = verifyUrl ? verifyUrl : "";

          if (e.productType !== productTypes[type]) {
            array[index].alertReason = array[index].alertReason + "- Product type cannot be modified";
          }
          if (type === LIMITED_PERIOD && parseInt(e.period) !== period) {
            array[index].alertReason = array[index].alertReason + "- Period cannot be modified";
          }
          if (type === SUBSCRIPTION || type === FREE_TRIAL_SUBSCRIPTION) {
            if (e.frequency !== frequency) {
              array[index].alertReason = array[index].alertReason + "- Frequency cannot be modified";
            }
          }
          if (type === "DYNAMIC_PRODUCT") {
            if (e.verifyUrl !== verifyUrl) {
              array[index].alertReason = array[index].alertReason + "- Verify URI cannot be modified";
            }
          }

          // TODO check tax category
          // const countryTaxCategory = taxCategory ? taxCategory.filter((x) => {
          //   return x.country === e.country
          // }) ? taxCategory.filter((x) => {
          //   return x.country === e.country
          // }).taxCategory : "" : "";
          // if (e.taxCategory !== countryTaxCategory) {
          //   array[index].alertReason = array[index].alertReason + "- Tax Category cannot be modified for this country";
          // }
        }
      });
      productCountries.forEach((element, index, array) => {
        if (description !== array[index].description) {
          array[index].alertReason = array[index].alertReason + "- Product description value should be same for all country/location.\n";
        }
        if (!typeFlag) {
          array[index].alertReason = array[index].alertReason + "- Product type value should be same for all country/location.\n";
        }
        if (visibility !== array[index].visibility) {
          array[index].alertReason = array[index].alertReason + "- Visibility value should be same for all country/location.\n";
        }
        if (freeTrialDays !== array[index].freeTrialPeriod) {
          array[index].alertReason = array[index].alertReason + "- Free Trial Days value should be same for all country/location.\n";
        }
        if (!defaultFlag) {
          array[index].alertReason = array[index].alertReason + "- Default Country is missing for this product.\n";
        }
      })
    });

    tableData = tableData.map((e) => {
      if (hash[e.productId]) {
        e.alertReason = hash[e.productId][e.country].alertReason;
      }
      return e;
    });

    this.setState({
      productListData: tableData,
      tableData: tableData.slice(0, 15),
      productListPage: 1,
      productListSizePerPage: 15,
      totalProductListSize: tableData.length
    });
  };

  handleTaxGuidePopupOpenClick = () => {
    this.setState({isTaxGuidePopupOpen: true});
  };

  handleNoticePopupOpenClick = () => {
    this.setState({isNoticePopupOpen: true});
  };

  handlePopupCloseClick = () => {
    this.setState({
      isTaxGuidePopupOpen: false,
      isNoticePopupOpen: false
    });
  };

  fetchProductList = (page, sizePerPage) => {
    this.setState({
      productListPage: page,
      productListSizePerPage: sizePerPage,
      totalProductListSize: this.state.productListData.length,
      tableData: this.state.productListData.slice((page - 1) * sizePerPage, page * sizePerPage)
    });
  };

  handleTableSort = (sortOrder, sortField) => {
    //TODO sort locally
  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      productListPage: page,
      productListSizePerPage: sizePerPage,
    });
  };

  handleDownloadTemplateClick = () => {
    if (!this.props.isModify) { //add case
      handleDownloadFile(URL_MULTIPLE_DOWNLOAD_TEMPLATE_API, {}, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);

    } else { // modify case
      const startDateString = "2015-01-01";
      const endDateString = new Date()
          .toISOString()
          .split("T")[0];
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        dateStart: startDateString,
        dateEnd: endDateString,
        productVisibility: "",
        productStatus: "",
        productType: "",
        keywordType: "",
        searchText: "",
        sortName: "",
        sortOrder: "DESC",
        pageNumber: 1,
        rowsPerPage: null
      };
      handleDownloadFile(API_PRODUCT_MULTIPLE_LIST_DOWNLOAD, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
    }
  };

  parseCountryInformation = (data) => {
    let countryList = [];
    data.forEach((e) => {
      let countryInfo = {
        country: e.country,
        currencyCode: getCountriesInformation(this.props.countriesInformation, e.country).currency,
        maximumDynamicProductPrice: e.maxPrice,
        minimumDynamicProductPrice: e.minPrice,
        priceTaxIncluded: e.taxIncluded === "o",
        productName: e.productName,
        productPrice: e.price,
        taxCategory: this.getTaxCategoryId(e.taxCategory),
        // SCWS-4421
        taxRate: (e.taxRate === null) ? null : (Number(e.taxRate) / 100).toFixed(5)
      };
      countryList.push(countryInfo);
    });
    return countryList;
  };

  addSubscriptionProperties = (product, productInfo) => {
    return {
      ...product,
      cycle: billingPeriodKey2Code[productInfo.frequency],
      frequency: 1,
      degree: 9000, //default: "Until Cancelled"
      freeTrialDays: parseInt(productInfo.freeTrialDays),
      subscriptionProductLevel: productInfo.subscriptionProductLevel,
      duplicateBenefitYN: productInfo.duplicateBenefitYN === 'yes' ? 'Y' : 'N',

    }
  }

  handleSave = () => {
    const {productListData} = this.state;
    let productInfo = {};

    let flag = false;
    productListData.forEach((e) => {
      if (e.alertReason !== "") {
        flag = true;
      }
    });
    if (flag) { // errors in table exist
      toast(this.props.t("MULTIPLEMODIFYPRODUCT_PRODUCT_REGISTER_ERROR"));
      return;
    }
    productListData.forEach((e) => {
      if (productInfo[e.productId]) {
        productInfo[e.productId].push(e);
      } else {
        productInfo[e.productId] = [e];
      }
    });
    const params = [];
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    Object.values(productInfo).forEach((e) => {

      let product = {
        cpProductId: e[0].productId,
        productName: e.filter(item => item.country === app.defaultCountry)[0].productName,
        productDescription: e[0].description,
        productType: productTypeKey2Code[e[0].productType],
        visibility: visibilityKey2Code[e[0].visibility.toUpperCase()],
      };
      if (e[0].productType === productTypes.SUBSCRIPTION) {
        product = this.addSubscriptionProperties(product, e[0]);
      } else if (e[0].productType === productTypes.DYNAMIC_PRODUCT) {
        product = {
          ...product,
          verifyUrlUseYN: e[0].verifyUrl ? 'Y' : 'N',
          verifyUrl: e[0].verifyUrl
        }
      } else if (e[0].productType === productTypes.LIMITED_PERIOD) {
        let totalMinutes = parseInt(e[0].period);
        product = {
          ...product,
          limitedPeriodDays: Math.floor(Math.floor(totalMinutes / 60) / 24).toString(),
          limitedPeriodHours: (Math.floor(totalMinutes / 60) % 24).toString(),
          limitedPeriodMinutes: (totalMinutes % 60).toString(),
        }
      }

      product = {
        ...product,
        informationByCountries: this.parseCountryInformation(e),
        cpAppId: this.props.carousel.selectedAppId,
        modifiedDate: "",
        productDeletable: null,
        freeTrialDays: e[0].freeTrialPeriod,
        subscriptionGroupId: e[0].subscriptionGroupId,
        subscriptionGroupName: e[0].subscriptionGroupName
      };
      params.push(product);
    });

    let api, errorResponseMsg, successResponseMsg;
    if (!this.props.isModify) { // Add case
      api = URL_MULTIPLE_ADD_PRODUCT_API;
      errorResponseMsg = "Error adding products";
      successResponseMsg = "Successfully added products";
    } else {
      api = URL_MULTIPLE_MODIFY_PRODUCT_API;
      errorResponseMsg = "Error modifying products";
      successResponseMsg = "Successfully modified products";
    }
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .post(api, params)
          .then(response => {
            if (response.data.rtnMsg !== SUCCESS) {
              toast(errorResponseMsg);
            } else {
              toast(successResponseMsg);
              this.props.history.push(URL_PRODUCT_LIST);
            }
            this.props.stopLoading();
            resolve()
          })
          .catch(error => {
            reject(error);
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });

  };

  render() {
    if (!this.state.init) {
      return <div/>;
    }
    return (
        <div id="tab-content" className="tab-content">
          <div id="nav-all-edit-product" className="tab-pane active" role="tabpanel">
            <div className="row mb-20">
              <div className="col-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text bg-white border-0"><span className="h4 m-0"><strong>{this.props.t("REGISTER_CSV_FILE")}</strong></span></div>
                  </div>
                  <label className="form-control bg-white text-100 rounded-left">
                    <input type="file" accept=".csv" style={{display: "none"}} onChange={this.handleFileChange} ref={this.fileRef}/>
                    <input type="text" value={this.state.csvFile ? this.state.csvFile.name : ""} readOnly={true} style={{border: "none", outline: "transparent", width: "100%"}} onClick={() => this.fileRef.current.click()} placeholder={this.props.t("PLEASE_UPLOAD_FILE")}/>
                  </label>
                  <div className="input-group-append upload-btn-wrapper">
                    <button className={"btn btn-primary rounded-right"} disabled={this.state.csvFile === null} onClick={() => this.handleRegisterButtonClick()} type="button">{this.props.t("REGISTER_FILE")}</button>
                  </div>
                </div>
              </div>
              <div className="col-6 align-items-center d-flex justify-content-end">
                <a onClick={this.handleTaxGuidePopupOpenClick} className="text-400 mr-10">{this.props.t("TAX_RATE_AND_PRICING_GUIDE")}<i className="icon icon-modal align-top ml-1"/></a>
              </div>
            </div>
            <div className="info-table mb-40">
              <div className="row">
                <div className="col-12 bg-100 border-0">
                  <div className="row">
                    <div className="col-3 align-items-center justify-content-center d-flex">
                      <div className="step_download" title="Step 01">
                        <Link to={{}} onClick={() => this.handleDownloadTemplateClick()} className="underline">{this.props.isModify ? this.props.t("DOWNLOAD_PRODUCT_LIST") : this.props.t("DOWNLOAD_TEMPLATE")}</Link>
                      </div>
                    </div>
                    <div className="col-1 align-items-center justify-content-center d-flex">
                      <span className="next-step"/>
                    </div>
                    <div className="col-4 align-items-center justify-content-center d-flex">
                      <div className="step_info" title="Step 02">
                        <span>{this.props.isModify ? this.props.t("MODIFY_DETAILS") : this.props.t("ENTER_DETAILS")}</span>
                        <Link to={{}} onClick={this.handleNoticePopupOpenClick} className="d-block underline">{this.props.t("NOTICE")}</Link>
                      </div>
                    </div>
                    <div className="col-1 align-items-center justify-content-center d-flex">
                      <span className="next-step"/>
                    </div>
                    <div className="col-3 align-items-center justify-content-center d-flex">
                      <div className="step_upload" title="Step 03">
                        <span>{this.props.t("SAVE_CSV_REGISTER_FILE")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTableWrapperForListing
                wrapperClasses="table-responsive"
                keyField="id"
                tableStyle="table-width3000"
                hideSelectColumn={true}
                columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                  {dataField: "id", hidden: true},
                  {dataField: "productName", text: this.props.t(multipleAddModifyProductAttributes.productName.key), headerStyle: {width: "12.5%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "productId", text: this.props.t(multipleAddModifyProductAttributes.productId.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "country", text: this.props.t(multipleAddModifyProductAttributes.country.key), headerStyle: {width: "12.5%"}, sort: false, classes: () => "text-left text-truncate"},
                  {dataField: "description", text: this.props.t(multipleAddModifyProductAttributes.description.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "productType", text: this.props.t(multipleAddModifyProductAttributes.productType.key), headerStyle: {width: "12.5%"}, sort: false, classes: () => "text-left text-truncate"},
                  {dataField: "subscriptionGroupName", text: this.props.t(multipleAddModifyProductAttributes.subscriptionGroupName.key), headerStyle: {width: "20%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "subscriptionGroupId", text: this.props.t(multipleAddModifyProductAttributes.subscriptionGroupId.key), headerStyle: {width: "17.5%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "period", text: this.props.t(multipleAddModifyProductAttributes.period.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "frequency", text: this.props.t(multipleAddModifyProductAttributes.frequency.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "subscriptionProductLevel", text: this.props.t(multipleAddModifyProductAttributes.productLevel.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "duplicateBenefitYN", text: this.props.t(multipleAddModifyProductAttributes.duplicateBenefit.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "freeTrialPeriod", text: this.props.t(multipleAddModifyProductAttributes.freeTrialPeriod.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "freeTrialCriteria", text: this.props.t(multipleAddModifyProductAttributes.freeTrialCriteria.key), headerStyle: {width: "22.5%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "allowedDevices", text: this.props.t(multipleAddModifyProductAttributes.allowedDevices.key), headerStyle: {width: "15%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "verifyUrl", text: this.props.t(multipleAddModifyProductAttributes.verifyUrl.key), headerStyle: {width: "15%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "visibility", text: this.props.t(multipleAddModifyProductAttributes.visibility.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "price", text: this.props.t(multipleAddModifyProductAttributes.price.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "minPrice", text: this.props.t(multipleAddModifyProductAttributes.minPrice.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "maxPrice", text: this.props.t(multipleAddModifyProductAttributes.maxPrice.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "taxRate", text: this.props.t(multipleAddModifyProductAttributes.taxRate.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "taxIncluded", text: this.props.t(multipleAddModifyProductAttributes.taxApplicable.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "taxCategory", text: this.props.t(multipleAddModifyProductAttributes.taxCategory.key), headerStyle: {width: "10%"}, sort: false, classes: () => "text-truncate"},
                  {dataField: "alertReason", text: this.props.t(multipleAddModifyProductAttributes.alertReason.key), headerStyle: {width: "37.5%"}, sort: false, classes: () => "text-truncate"},
                ]}
                rowStyle={(row, rowindex) => {
                  const style = {};
                  if (row.alertReason.length > 0) {
                    style.backgroundColor = '#ff470082';
                  }
                  return style;
                }}
                page={this.state.productListPage}
                sizePerPage={this.state.productListSizePerPage}
                totalSize={this.state.totalProductListSize}
                rows={this.state.tableData.map(e => {
                  return {
                    ...e,
                    country: this.props.t(e.country)
                  };
                })}
                onTableSort={this.handleTableSort}
                onTableChange={this.fetchProductList}
                onSizePerPageChange={this.handleSizePerPageChange}/>

            <div className="pagenationGroup clearfix">
              <div className="float-right">
                {/*TODO disable save if alerts*/}
                <button type="button" className="btn btn-sm btn-primary btn-auto" onClick={() => this.handleSave()}>{this.props.t("REGISTER")}</button>
              </div>
            </div>
          </div>
          <TaxPricingGuidePopup handleModalClose={this.handlePopupCloseClick} open={this.state.isTaxGuidePopupOpen}/>
          <MultipleAddModifyProductNoticePopup handleModalClose={this.handlePopupCloseClick} isModify={this.props.isModify} open={this.state.isNoticePopupOpen}/>
        </div>
    );
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)
        || isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)) {
      this.initData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(MultipleAddModifyProductPanel)));
