import React from "react";
import FilterPanel from './FilterPanel';
import DateRangePanel from './DateRangePanel';
import KeywordPanel from './KeywordPanel';
import RadioButtonPanel from './RadioButtonPanel';
import {durationKey} from '../../utils/searchBarUtils';

/*
 * Note : filter key will be used as placeholder for SelectWrapper.
 */
class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitState(),
      startDatePicker: null,
      endDatePicker: null,
    };
  }

  getInitState = () => {
    let selectedFilters = [];
    if (this.props.filterPanel.show) {
      selectedFilters = this.props.filterPanel.filters.map(filter => {
        return {
          key: filter.key,
          value: filter.defaultValue ? filter.defaultValue : null // init value should be null if no default value
        };
      });
    }

    return {
      // keyword panel states --
      keywordText: "",
      keywordOption: this.props.keywordPanel.show && this.props.keywordPanel.options ? this.props.keywordPanel.options[0] : "",
      isTooltipOpen: false,
      // date panel states --
      selectedDuration: this.props.datePanel.show ? this.props.datePanel.defaultDuration : "",
      // filter panel states --
      selectedFilters: selectedFilters,
      // radio button options states --
      selectedOption: "",
    };
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  resetState = () => {
    this.setState(this.getInitState());
    if (this.state.endDatePicker && this.state.startDatePicker) {
      this.state.endDatePicker.setValue(new Date());
      const beforeDate = new Date();

      beforeDate.setFullYear(beforeDate.getFullYear() - 1);
      this.state.startDatePicker.setValue(beforeDate);
    }
  };

  handleReset = () => {
    if (this.props.datePanel.show) {
      // Resets the date filter
      this.state.endDatePicker.setValue(new Date());
      let beforeDate = new Date();

      if( this.props.datePanel.defaultDuration === "ONE_MONTH") {
        beforeDate.setMonth(beforeDate.getMonth() - 1);
      }else if( this.props.datePanel.defaultDuration === "SIX_MONTH") {
        beforeDate.setMonth(beforeDate.getMonth() - 6);
      }else if( this.props.datePanel.defaultDuration === "YEAR") {
        beforeDate.setFullYear(beforeDate.getFullYear() - 1);
      }else {
        beforeDate.setFullYear(beforeDate.getFullYear() - 10);
      }

      this.state.startDatePicker.setValue(beforeDate);
    }
    this.setState(this.getInitState(), () => this.handleSearchClick({
      preventDefault: () => {
      }
    }));
  };

  handleKeywordTextChange = (e) => {
    this.setState({keywordText: e.target.value});
  };

  handleKeywordOptionChange = (keywordOptionElement) => {
    if (keywordOptionElement) {
      this.setState({keywordOption: keywordOptionElement.value});
    }
  };

  handleSearchClick = (e) => {
    e.preventDefault();

    this.props.onSearchClick({
      keywordText: this.state.keywordText,
      keywordOption: this.state.keywordOption,
      startDate: this.state.startDatePicker ? this.state.startDatePicker.getValue() : null,
      endDate: this.state.endDatePicker ? this.state.endDatePicker.getValue() : null,
      duration: this.state.selectedDuration,
      selectedFilter: this.state.selectedFilters,
      selectedRadioButton: this.state.selectedOption,
    });
  };

  handleDownloadClick = (e) => {
    e.preventDefault();
    this.props.onDownloadClick({
      keywordText: this.state.keywordText,
      keywordOption: this.state.keywordOption,
      startDate: this.state.startDatePicker ? this.state.startDatePicker.getValue() : null,
      endDate: this.state.endDatePicker ? this.state.endDatePicker.getValue() : null,
      duration: this.state.selectedDuration,
      selectedFilter: this.state.selectedFilters,
      selectedRadioButton: this.state.selectedOption,
    });
  };

  handleDatepickerInit = (isStart, datepicker) => {
    isStart ? this.setState({startDatePicker: datepicker}) : this.setState({endDatePicker: datepicker});
  };

  handleDurationSelect = (duration) => {
    this.setState({selectedDuration: duration});

    switch (duration) {
      case durationKey.DURATION_ALL: {
        this.state.startDatePicker.set10yearBeforeValue(this.state.endDatePicker.getValue());
        break;
      }
      case durationKey.DURATION_YEAR: {
        this.state.startDatePicker.set1YearBeforeValue(this.state.endDatePicker.getValue());
        break;
      }
      case durationKey.DURATION_SIX_MONTH: {
        this.state.startDatePicker.set6monthBeforeValue(this.state.endDatePicker.getValue());
        break;
      }
      case durationKey.DURATION_ONE_MONTH: {
        this.state.startDatePicker.set1monthBeforeValue(this.state.endDatePicker.getValue());
        break;
      }
      default :
        throw new Error("Will Never Happen");
    }
  };

  handleFilterChange = (filterKey, selectedFilterTag) => {
    this.setState({
      selectedFilters: this.state.selectedFilters.map((filter => {
        if (filter.key !== filterKey) {
          return filter;
        }

        filter.value = (selectedFilterTag ? selectedFilterTag.value : null);
        return filter;
      }))
    })
  };

  handleRadioButtonSelectionChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption
    })
  }

  render() {
    const {keywordPanel, datePanel, filterPanel, radioButtonPanel} = this.props;

    return (
        <div className="search_field">
          <form>
            {keywordPanel.show &&
            <KeywordPanel
                showToolTip={keywordPanel.showToolTip}
                toolTipText={keywordPanel.toolTipText}
                isTooltipOpen={this.state.isTooltipOpen}
                textValue={this.state.keywordText}
                optionValue={this.state.keywordOption}
                label={keywordPanel.label}
                options={keywordPanel.options}
                placeHolder={keywordPanel.placeholder}
                showDownload={keywordPanel.showDownload}
                onTextChange={this.handleKeywordTextChange}
                onOptionChange={this.handleKeywordOptionChange}
                onSearchClick={this.handleSearchClick}
                onResetClick={this.handleReset}
                onDownloadClick={this.handleDownloadClick}
                onToolTipOpenClick={this.handleTooltipOpenClick}
                onToolTipCloseClick={this.handleTooltipCloseClick}
                downloadToolTip = {keywordPanel.downloadToolTip}/>
            }
            {datePanel.show &&
            <DateRangePanel
                label={datePanel.label}
                durations={datePanel.durationOptions}
                selectedDuration={this.state.selectedDuration}
                showSearch={datePanel.showSearch}
                maxDate={datePanel.maxDate}
                initOneMonth={datePanel.initOneMonth}
                onDurationSelect={this.handleDurationSelect}
                onDatepickerInit={this.handleDatepickerInit}
                onSearchClick={this.handleSearchClick}
                onResetClick={this.handleReset}/>
            }
            {filterPanel.show &&
            <FilterPanel
                label={filterPanel.label}
                selectedFilters={this.state.selectedFilters}
                filters={filterPanel.filters}
                showSearch={filterPanel.showSearch}
                showDownload={filterPanel.showDownload}
                onFilterChange={this.handleFilterChange}
                onSearchClick={this.handleSearchClick}
                onResetClick={this.handleReset}
                onDownloadClick={this.handleDownloadClick}/>
            }
            {radioButtonPanel.show &&
            <RadioButtonPanel
                label={this.props.radioButtonPanel.label}
                selectedOption={this.state.selectedOption}
                onButtonSelect={this.handleRadioButtonSelectionChange}
                options={this.props.radioButtonPanel.options}
            />
            }
          </form>
        </div>
    );
  }
}

export default SearchBar;
