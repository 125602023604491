import React, {Component} from "react";
import Content from '../Content';
import SHeader from '../SHeader';
import LeftNaviBar from '../lnb/LeftNaviBar';
import {connect} from 'react-redux';
import {URL_SETTINGS_APPDETAILS} from '../../utils/urlConstants';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import TopNaviBar from '../tnb/TopNaviBar';

class NoDefaultCountryPage extends Component {
  render() {
    let wrapClassName = "clearfix";
    if (!this.props.sideDrawer.isSideDrawerOpen) {
      wrapClassName += " closeMenu";
    }

    return (
        <div id="wrap" className={wrapClassName}>
          <SHeader>
            <LeftNaviBar/>
          </SHeader>
          <Content>
            <TopNaviBar/>
            <main id="contentMain">
              <div className="tab-content">
                <div className="text-center mt-40">
                  <h2 className="oops text-primary text-center">
                    {this.props.t("NO_DEFAULTCOUNTRY_PAGE_1")}<br/>
                    <span className="f50">{this.props.t("NO_DEFAULTCOUNTRY_PAGE_2")}</span>
                  </h2>
                  <div className="select-country">
                    <div className="pt-85 pr-7">
                      <h4 className="line-height-default text-400 mb-3">
                        {this.props.t("NO_DEFAULTCOUNTRY_PAGE_3")}<br/>
                        {this.props.t("NO_DEFAULTCOUNTRY_PAGE_4")}<br/>{this.props.t("NO_DEFAULTCOUNTRY_PAGE_5")}
                      </h4>
                      <Link to={URL_SETTINGS_APPDETAILS}>
                        <button className="btn btn-lg btn-primary">{this.props.t("NO_DEFAULTCOUNTRY_PAGE_6")}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </Content>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sideDrawer: state.sideDrawer
  };
};

export default connect(mapStateToProps)(withTranslation()(NoDefaultCountryPage));