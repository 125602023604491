import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import BasePopup from './BasePopup';
import {sortCountryObjectNames} from '../../utils/CountryListUtils';
import {toast} from "react-toastify";

class CountryListPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel: this.getPanel(0),
      selectedCountries: props.initCountries,
    }
  }

  getPanel = (initPanel) => {
    const panel = [];
    this.props.countryData.forEach(() => panel.push(false));
    panel[initPanel] = true;
    return panel;
  };

  handleAccordion = (index) => {
    const {panel} = this.state;
    let isOpen = panel[index];
    panel.forEach((e, i) => panel[i] = false);
    panel[index] = !isOpen;
    this.setState({panel});
  };

  handleCountryClick = (country) => {
    if (this.props?.disableIndiaSelection && country === "IN") {
      toast(this.props.t('COUPON_CANNOT_BE_ISSUED_IN_INDIA'));
      return;
    }

    const selectedCountries = this.state.selectedCountries;

    if (this.props.isMultipleSelect) {
      if (this.state.selectedCountries.indexOf(country) > -1) {
        this.setState({selectedCountries: selectedCountries.filter((e => !Object.is(e, country)))});
      } else {
        this.setState({selectedCountries: [...selectedCountries, country]});
      }
    } else {
      this.setState({selectedCountries: [country]});
    }
  };

  handleContinentSelect = (data) => {
    let selectedCountries = this.state.selectedCountries;
    let disabledCountries = this.props.disabledCountry;
    let initCountries = this.props.initCountries;
    if (this.checkIsContinentSelected(data, selectedCountries)) {
      selectedCountries = selectedCountries.filter(function (el) {
        return !data.countries.includes(el) ? true : disabledCountries.includes(el);
      });
      this.setState({selectedCountries: selectedCountries})
    } else {
      const newSelection = [...new Set([...selectedCountries, ...data.countries])]
          .filter(function (val) {
            return !disabledCountries.includes(val) ? true : initCountries.includes(val);
          });
      this.setState({selectedCountries: newSelection});
    }
  };

  checkIsContinentSelected = (data, selectedCountries) => {
    let flag = true;
    data.countries.forEach(e => {
      if (selectedCountries.indexOf(e) === -1 && this.props.disabledCountry.indexOf(e) === -1) {
        flag = false;
      }
    });
    return flag;
  };

  render() {
    const {panel, selectedCountries} = this.state;
    const {readOnly, open, countryData} = this.props;

    return (
        <BasePopup
            className="modal-lg"
            open={open}
            title={this.props.title}
            onClose={() => this.props.handleModalClose(false)}
            body={countryData.map((data, index) => (
                <div key={data.continent} className={`accordion ${panel[index] && "show"}`}>
                  <div className="accordion-header">
                    <div onClick={() => this.handleAccordion(index)} className="questions none-before pl-0 accordion-toggle">
                      {this.props.isContinentSelect &&
                          <div className="btn-group-toggle btn-group-checkbox">
                            <label className={`btn-checkbox checkbox-text checkbox-all ${this.checkIsContinentSelected(data, selectedCountries) && "checked"}`}>
                              <input type="checkbox" onClick={() => {
                                // e.stopPropagation();
                                this.handleContinentSelect(data);
                              }}/>
                              {this.props.t("continents:" + data.continent)}
                            </label>
                          </div>
                      }
                      {!this.props.isContinentSelect &&
                          <>{this.props.t("continents:" + data.continent)}</>
                      }
                    </div>
                  </div>
                  <div className="accordion-body pb-0 pt-6 mt-9">
                    <div className="row country-list">
                      {sortCountryObjectNames(data.countries, this.props.t).map((countryObject) => (

                          <div key={countryObject.countryCode} className="col-3">
                            {readOnly ? (
                                countryObject.countryName
                            ) : (
                                <>
                                  {this.props.disabledCountry && this.props.disabledCountry.indexOf(countryObject.countryCode) === -1 ?
                                      <div className="btn-group-toggle btn-group-radio">
                                        <label className={`btn btn-radio ${selectedCountries.indexOf(countryObject.countryCode) > -1 && "checked"}`}>
                                          <input type="radio" onClick={() => this.handleCountryClick(countryObject.countryCode)}/> {countryObject.countryName}
                                        </label>
                                      </div> :
                                      <div className="btn-group-toggle btn-group-radio">
                                        <label className={`btn btn-radio ${selectedCountries.indexOf(countryObject.countryCode) > -1 && "checked"} disabled`}>
                                          <input type="radio"/> {countryObject.countryName}
                                        </label>
                                      </div>}
                                </>
                            )}
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
            ))}
            footer={
                !readOnly &&
                <div className="modal-footer">
                  <button type="button" className="btn btn-120 btn-primary" onClick={() => this.props.handleModalClose(true, selectedCountries)}>{this.props.t("SAVE")}</button>
                  <button type="button" className="btn btn-120 btn-outline-primary" onClick={() => this.props.handleModalClose(false)}>{this.props.t("CANCEL")}</button>
                </div>
            }/>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.initCountries !== prevProps.initCountries) {
      this.setState({
        selectedCountries: this.props.initCountries
      });
    }
  }
}

export default withTranslation()(CountryListPopUp);
