import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class PayoutStatusListTable extends Component {
  render() {
    return (
        <table className="table table-bordered">
          <caption>Payment Status List</caption>
          <colgroup>
            <col width="*"/>
          </colgroup>
          <thead>
          <tr>
            {this.props.columns.map(column => <th key={column.text} scope={"col"}>{column.text}</th>)}
          </tr>
          </thead>
          <tbody className="text-center">
          {
            this.props.rows.slice(0, this.props.rows.length - 3).map((row,index) => {
              if (row.priceStandard === "USD") {
                return (
                    <tr key={index}>
                      <td rowSpan="3">{row.bizModelDisplay}</td>
                      <td rowSpan="3">{row.targetDate}</td>
                      <td rowSpan="3">{row.expectedPayoutDate}</td>
                      <td rowSpan="3">{row.paymentCurrencyCode}</td>
                      <td>{row.priceStandard}</td>
                      <td>{row.baseExpectedPayoutAmount}</td>
                      <td>{row.baseRemittanceFeeAmount}</td>
                      <td>{row.baseRemittanceFailFeeAmount}</td>
                      <td>{row.baseActualExpectedPayoutAmount}</td>
                    </tr>
                )
              } else {
                return (
                    <tr key={index}>
                      <td>{row.priceStandard}</td>
                      <td>{row.baseExpectedPayoutAmount}</td>
                      <td>{row.baseRemittanceFeeAmount}</td>
                      <td>{row.baseRemittanceFailFeeAmount}</td>
                      <td>{row.baseActualExpectedPayoutAmount}</td>
                    </tr>
                )
              }
            })
          }
          </tbody>
          <tfoot>
          {
            this.props.rows.slice(this.props.rows.length - 3, this.props.rows.length).map((row, index) => {
              const currencies = ["USD", "KRW", "EUR"]
              return (
                  <tr key={index} className="bg-100 h5">
                    <th colSpan="5">Total ({currencies[index]})</th>
                    <td className={"text-center"}>{row.baseExpectedPayoutAmount}</td>
                    <td className={"text-center"}>{row.baseRemittanceFeeAmount}</td>
                    <td className={"text-center"}>{row.baseRemittanceFailFeeAmount}</td>
                    <td className={"text-center"}>{row.baseActualExpectedPayoutAmount}</td>
                  </tr>
              )
            })
          }
          </tfoot>
        </table>
    );
  }
}

export default withTranslation()(PayoutStatusListTable);