import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom'
import {
  API_APP_SELECTION_UPDATE,
  URL_ADMIN_CPMANAGEMENT,
  URL_ADMIN_FAQMANAGEMENT,
  URL_ADMIN_FAQREGISTRATION,
  URL_ADMIN_NOTIFICATIONMANAGEMENT,
  URL_ADMIN_NOTIFICATIONREGISTRATION,
  URL_ADMIN_POPUPMANAGEMENT,
  URL_ADMIN_POPUPREGISTRATION,
  URL_CAMPAIGN_ALLOCATECOUPON,
  URL_CAMPAIGN_BUYER_REDEMPTION_STATUS,
  URL_CAMPAIGN_COUPON,
  URL_CAMPAIGN_COUPON_ALLOCATIONLIST,
  URL_CAMPAIGN_ISSUECOUPON,
  URL_DSA,
  URL_DSA_SELLER_INFORMATION,
  URL_ERROR_PAGE,
  URL_GET_COUNTRY_INFORMATION_API,
  URL_GET_SESSION_DATA_API,
  URL_INDEX_PAGE,
  URL_MEMBER_CREATEGROUP,
  URL_MEMBER_EDITPERMISSIONS,
  URL_MEMBER_GROUPMANAGEMENT,
  URL_MEMBER_MEMBERSHIPMANAGEMENT,
  URL_MONTHLY_SETTLEMENT,
  URL_PRODUCT_ADDMULTIPLE,
  URL_PRODUCT_ADDNEW,
  URL_PRODUCT_DETAIL,
  URL_PRODUCT_LIST,
  URL_PRODUCT_MODIFYMULTIPLE,
  URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW,
  URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL,
  URL_PRODUCT_SUBSCRIPTIONGROUP_LIST, URL_REQUEST_LIST,
  URL_SALES_SALESPRODUCT,
  URL_SALES_SUBSCRIPTION,
  URL_SETTINGS_AGREEMENT,
  URL_SETTINGS_APPDETAILS,
  URL_SETTINGS_CHANGEINFORMATION,
  URL_SETTINGS_SELLER_INFORMATION,
  URL_SETTINGS_TESTBUYER,
  URL_SETTINGS_TESTBUYER_REGISTRATION,
  URL_SIGNIN_PAGE,
  URL_SIGNOUT_PAGE,
  URL_STATISTICS_ANALYSISBYCOUNTRY,
  URL_STATISTICS_ANALYSISBYPRODUCT,
  URL_STATISTICS_APPSALESANALYSIS,
  URL_STATISTICS_CAMPAIGNANALYSIS,
  URL_STATISTICS_SUBSCRIBERANALYSIS,
  URL_SUPPORT_ERRORCODE,
  URL_SUPPORT_FAQ,
  URL_SUPPORT_NOTICE,
  URL_PRODUCT_FLEXIBLEOFFER
} from '../utils/urlConstants';
import {getAppFromSessionData, loadSessionData} from "../ducks/sessionData";
import AppDetailsSettingPage from "./page/AppDetailsSettingPage";
import DashboardPage from "./page/DashboardPage";
import {isSelectedAppChanged, updateSelectedAppIdInCarousel} from '../ducks/carousel';
import ProductListPage from './page/ProductListPage';
import BankInformationPage from "./page/BankInformationPage";
import SignInPage from "./page/SignInPage";
import SignOutPage from "./page/SignOutPage";
import AddModifyProductPage from './page/AddModifyProductPage';
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../utils/globalConstants";
import {loadCountriesInformation} from '../ducks/countriesInformation';
import InvoiceListPage from "./page/InvoiceListPage";
import MultipleAddModifyProductPage from './page/MultipleAddModifyProductPage';
import TransactionDetailPage from "./page/TransactionDetailPage";
import SubscriptionListPage from "./page/SubscriptionListPage";
import NoDefaultCountryPage from './page/NoDefaultCountryPage';
import SubscriptionDetailsPage from "./page/SubscriptionDetailsPage";
import {getCookie, setCookie} from "../utils/simpleCookieUtils";
import SettlementListPage from "./page/SettlementPage";
import SettlementDetailPage from "./page/SettlementDetailPage";
import AppSalesStatisticsPage from './page/AppSalesStatisticsPage';
import CountrySalesStatisticsPage from './page/CountrySalesStatisticsPage';
import ProductSalesStatisticsPage from './page/ProductSalesStatisticsPage';
import SubscriberStatisticsPage from './page/SubscriberStatisticsPage';
import CampaignStatisticsPage from './page/CampaignStatisticsPage';
import MembershipManagementPage from "./page/MembershipMgmtPage";
import GroupManagementPage from "./page/GroupManagementPage";
import PermissionDetailsPage from "./page/PermissionDetailsPage";
import GroupAddModifyPage from "./page/GroupAddModifyPage";
import CouponListPage from './page/CouponListPage';
import {Slide, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from "./page/ErrorPage";
import {handleHttpError} from "../utils/ErrorUtils";
import CouponDetailPage from './page/CouponDetailPage';
import NotificationManagementPage from "./page/NotificationManagementPage";
import NotificationAddModifyPage from "./page/NotificationAddModifyPage";
import {withTranslation} from "react-i18next";
import PopupManagementPage from "./page/PopupManagementPage";
import FAQManagementPage from "./page/FAQManagementPage";
import CPManagementPage from "./page/CPManagementPage";
import PopupAddModifyPage from "./page/PopupAddModifyPage";
import FAQAddModifyPage from "./page/FAQAddModifyPage";
import CouponIssuePage from './page/CouponIssuePage';
import CouponAllocationListPage from './page/CouponAllocationListPage';
import CouponAllocatePage from './page/CouponAllocatePage';
import FaqPage from './page/FaqPage';
import NoticePage from './page/NoticePage';
import ErrorCodePage from './page/ErrorCodePage';
import {startLoading, stopLoading} from '../ducks/loading';
import TestBuyerListPage from './page/TestBuyerListPage';
import TestBuyerAddModifyPage from './page/TestBuyerAddModifyPage';
import AgreementListPage from './page/AgreementListPage';
import DsaPage from './page/DsaPage';
import SellerBankInformationPage from './page/SellerBankInformationPage';
import SubscriptionGroupListPage from './page/SubscriptionGroupListPage';
import SubscriptionProductGroupAddModifyPage from './page/SubscriptionProductGroupAddModifyPage';
import BuyerRedemptionStatusPage from './page/BuyerRedemptionStatusPage';
import RequestListPage from "./page/RequestListPage";
import FlexibleOfferPage from './page/FlexibleOfferPage'

let trigger = null;
export const triggerGetSessionData = function () {
  if (trigger) {
    trigger();
  }
};

const RESOLVE_ALL = "RESOLVE_ALL";
const RESOLVE_NONE = "RESOLVE_NONE";
const RESOLVE_COUNTRY_APIS = "RESOLVE_COUNTRY_APIS";

class App extends Component {
  constructor(props) {
    super(props);

    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      sessionDataLoaded: null,
      defaultCountry: null
    }
  }

  ifAppExists = (selectedId, list) => {
    return list.some(app => app.appId === selectedId) ? selectedId : list[0].appId;
  };

  getContsId = (appId, list) => {
    return list.filter(app => app.appId === appId)[0].contsId;
  };

  isDsaAgreementNotDone = (isManager, dsaConsent, sellerServiceEU, dtaConsent) => {
    return isManager && !(dsaConsent && (!sellerServiceEU || dtaConsent));
  };

  compare = (a, b) => {
    if (a.name !== null && b.name !== null) {
      let first = a.name.toLowerCase();
      let second = b.name.toLowerCase();
      return first.localeCompare(second);
    } else if (a.name !== null) {
      return 1;
    } else if (b.name !== null) {
      return -1;
    }
    const aAppid = +(a.appid);
    const bAppid = +(b.appid);
    return (aAppid < bAppid) ? -1 : 1;
  }

  fetchSessionData = () => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_SESSION_DATA_API)
          .then(response => {
            this.props.stopLoading();

            // if session api is success (irrespective of actual data)
            this.setState({sessionDataLoaded: true});

            // no app sign out scenario --
            // happens when login via deep link and access portal having no apps
            if (this.props.location.pathname.match('/auth/signout/cp_no_apps*')) {
              resolve(RESOLVE_NONE); // do not call further
              return;
            }

            const data = response.data;
            const isManager = data.user ? data.user.cpRoleName === 'manager' : false;

            this.props.loadSessionData(data.guid,
                data.emailAddress,
                data.user ? data.user.sellerGroupId : null,
                data.apps,
                data.authenticated,
                data.csrfToken,
                data.requestFromSellerOffice,
                data.bankInfoSaved,
                data.sellerServiceEU,
                data.contractSkipByAdmin,
                data.dsaConsent,
                data.dtaConsent,
                isManager,
                data.superUser);

            // error scenario --

            if (this.props.location.pathname.match('/support/errorcode') === null && //.match returns null when their is no match
                  this.props.location.pathname.match('/error/*')) { // when backend explicitly redirects to error page
              this.props.history.push(URL_ERROR_PAGE + this.props.location.search);
              resolve(RESOLVE_NONE); // do not call further
              return;
            }

            // first session api call (returns only csrf) scenario --

            if (!data.authenticated) {
              if (!this.props.location.pathname.match('/auth/*')) { // when not authenticated, page refresh (not at auth pages) from frontend
                this.props.history.push(URL_SIGNIN_PAGE);
              }
              resolve(RESOLVE_NONE); // do not call further
              return;
            }

            // bank info scenario --

            if(isManager && !data.bankInfoSaved){
              this.props.history.push(URL_DSA_SELLER_INFORMATION);
              resolve(RESOLVE_COUNTRY_APIS);
              return;
            }

            // dsa scenario --

            const isContractMandatory = data.sellerServiceEU && !data.contractSkipByAdmin ;
            const isContractAccepted = data.dsaConsent && data.dtaConsent ;
            if (isManager && isContractMandatory && !isContractAccepted) {
              this.props.history.push(URL_DSA);
              // since this is manager and contract is not accepted, dont need to care about updating selected app
              // after contract is updated, session data is fetched again which updates selected app
              resolve(RESOLVE_COUNTRY_APIS);
              return;
            }

            // normal login scenario --

            if (data.apps.length) { // If session data exists and authenticated
              data.apps.sort(this.compare);
              let defaultApp = getCookie('defaultAppId');
              let selectedApp = getCookie('selectedAppId');
              let beforeSelectedAppId = (defaultApp !== undefined) ? defaultApp :
                  (selectedApp !== undefined ? selectedApp : data.apps[0].appId);
              let currentSelectedAppId = this.ifAppExists(beforeSelectedAppId, data.apps);
              let currentContsId = this.getContsId(currentSelectedAppId, data.apps);
              this.props.updateSelectedAppIdInCarousel(currentSelectedAppId, currentContsId);
              this.setDefaultCountry(this.props.carousel.selectedAppId);

              setCookie('selectedAppId', this.props.carousel.selectedAppId);
              resolve(RESOLVE_ALL);
              return;
            }

            // if there is no app then, update selected app call is skipped
            resolve(RESOLVE_COUNTRY_APIS);
          })
          .catch(error => {
            this.setState({sessionDataLoaded: false});
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    });
  };

  updateSelectedApp = (resolveType) => {
    if (resolveType === RESOLVE_NONE || resolveType === RESOLVE_COUNTRY_APIS) {
      return resolveType;
    }

    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_APP_SELECTION_UPDATE, {
            params: {cpAppId: this.props.carousel.selectedAppId}
          })
          .then(() => {
            this.props.stopLoading();
            resolve(resolveType);
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
      resolve();
    });
  };

  fetchCountryInformation = (resolveType) => {
    if (resolveType === RESOLVE_NONE) {
      return resolveType;
    }

    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COUNTRY_INFORMATION_API)
          .then(response => {
            this.props.loadCountriesInformation(response.data);
            resolve(resolveType);
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    })
  };

  setDefaultCountry = (selectedAppId) => {
    this.setState({defaultCountry: getAppFromSessionData(this.props.sessionData, selectedAppId).defaultCountry});
  };

  render() {
    if (this.state.sessionDataLoaded === null) {
      return <div/>;
    }
    if (this.state.sessionDataLoaded === false) {
      return (
          <>
            <div className={`co-ma-dim ${this.props.loading.isLoading ? "active" : ""}`}/>
            <Switch>
              <Route path={URL_ERROR_PAGE}>
                <ErrorPage/>
              </Route>
              <Route exact path={URL_INDEX_PAGE}>
                <SignInPage/>
              </Route>
            </Switch>
            <ToastContainer
                position="top-center"
                transition={Slide}
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
          </>
      )
    }

    if (!this.props.sessionData.isAuthenticated) {
      return (
          <>
            <div className={`co-ma-dim ${this.props.loading.isLoading ? "active" : ""}`}/>
            <Switch>
              <Route exact path={URL_ERROR_PAGE}>
                <ErrorPage/>
              </Route>
              <Route exact path={URL_INDEX_PAGE}>
                <SignInPage/>
              </Route>
              <Route exact path={URL_SIGNIN_PAGE}>
                <SignInPage/>
              </Route>
              <Route exact path={`${URL_SIGNOUT_PAGE}/:status`}>
                <SignOutPage/>
              </Route>
            </Switch>
            <ToastContainer
                position="top-center"
                transition={Slide}
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
          </>
      )
    }

    let isContractMandatory = this.props.sessionData.sellerServiceEU && !this.props.sessionData.isContractSkipByAdmin ;
    let isContractAccepted = this.props.sessionData.dsaConsent && this.props.sessionData.dtaConsent ;
    if (this.props.sessionData.isManager && (!this.props.sessionData.isBankInfoSaved || (isContractMandatory && !isContractAccepted))) {
      return (
          <>
            <div className={`co-ma-dim ${this.props.loading.isLoading ? "active" : ""}`}/>
            <Switch>
              <Route exact path={URL_ERROR_PAGE}>
                <ErrorPage/>
              </Route>
              <Route exact path={URL_DSA_SELLER_INFORMATION}>
                <SellerBankInformationPage deeplink={false}/>
              </Route>
              <Route exact path={URL_SETTINGS_SELLER_INFORMATION}>
                <SellerBankInformationPage deeplink={true}/>
              </Route>
              <Route exact path={URL_DSA}>
                <DsaPage/>
              </Route>
            </Switch>
            <ToastContainer
                position="top-center"
                transition={Slide}
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
          </>
      );
    }
    // sessionDataLoaded: true
    // no default country set
    if (!this.state.defaultCountry) {
      return (
          <>
            <div className={`co-ma-dim ${this.props.loading.isLoading ? "active" : ""}`}/>
            <Switch>
              <Route exact path={URL_ERROR_PAGE}>
                <ErrorPage/>
              </Route>
              <Route exact path={URL_SETTINGS_APPDETAILS}>
                <AppDetailsSettingPage/>
              </Route>
              <Route exact path={URL_DSA_SELLER_INFORMATION}>
                <SellerBankInformationPage deeplink={false}/>
              </Route>
              <Route exact path={URL_SETTINGS_SELLER_INFORMATION}>
                <SellerBankInformationPage deeplink={true}/>
              </Route>
              <Route path={URL_INDEX_PAGE}>
                <NoDefaultCountryPage/>
              </Route>
            </Switch>
            <ToastContainer
                position="top-center"
                transition={Slide}
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
          </>
      );
    } else { // default country exists
      return (
          <>
            <div className={`co-ma-dim ${this.props.loading.isLoading ? "active" : ""}`}/>
            <Switch>
              <Route exact path={URL_ERROR_PAGE}>
                <ErrorPage/>
              </Route>
              <Route exact path={URL_INDEX_PAGE}>
                <DashboardPage/>
              </Route>
              <Route exact path={URL_SIGNIN_PAGE}>
                <SignInPage/>
              </Route>
              <Route exact path={`${URL_SIGNOUT_PAGE}/:status`}>
                <SignOutPage/>
              </Route>
              <Route exact path={URL_SETTINGS_APPDETAILS}>
                <AppDetailsSettingPage/>
              </Route>

              <Route exact path={URL_SETTINGS_TESTBUYER}>
                <TestBuyerListPage/>
              </Route>
              <Route exact path={`${URL_SETTINGS_TESTBUYER}/:testBuyerId`}>
                <TestBuyerAddModifyPage isModify={true}/>
              </Route>
              <Route exact path={URL_SETTINGS_TESTBUYER_REGISTRATION}>
                <TestBuyerAddModifyPage isModify={false}/>
              </Route>
              <Route exact path={URL_SETTINGS_AGREEMENT}>
                <AgreementListPage/>
              </Route>
              <Route exact path={URL_SETTINGS_CHANGEINFORMATION}>
                <BankInformationPage/>
              </Route>
              <Route exact path={URL_SETTINGS_SELLER_INFORMATION}>
                <SellerBankInformationPage deeplink={true}/>
              </Route>
              <Route exact path={URL_PRODUCT_LIST}>
                <ProductListPage/>
              </Route>
              <Route exact path={URL_PRODUCT_ADDNEW}>
                <AddModifyProductPage isModify={false}/>
              </Route>
              <Route path={`${URL_PRODUCT_DETAIL}/:productId`}>
                <AddModifyProductPage isModify={true}/>
              </Route>
              <Route exact path={URL_PRODUCT_ADDMULTIPLE}>
                <MultipleAddModifyProductPage isModify={false}/>
              </Route>
              <Route exact path={URL_PRODUCT_MODIFYMULTIPLE}>
                <MultipleAddModifyProductPage isModify={true}/>
              </Route>
              <Route exact path={URL_SALES_SALESPRODUCT}>
                <InvoiceListPage/>
              </Route>
              <Route path={`${URL_SALES_SALESPRODUCT}/:invoiceId`}>
                <TransactionDetailPage/>
              </Route>
              <Route exact path={URL_SALES_SUBSCRIPTION}>
                <SubscriptionListPage/>
              </Route>
              <Route path={`${URL_SALES_SUBSCRIPTION}/:subscriptionId`}>
                <SubscriptionDetailsPage/>
              </Route>
              <Route exact path={URL_MONTHLY_SETTLEMENT}>
                <SettlementListPage/>
              </Route>
              <Route path={`${URL_MONTHLY_SETTLEMENT}/:settlementMonth`}>
                <SettlementDetailPage/>
              </Route>
              <Route exact path={URL_STATISTICS_APPSALESANALYSIS}>
                <AppSalesStatisticsPage/>
              </Route>
              <Route exact path={URL_STATISTICS_ANALYSISBYCOUNTRY}>
                <CountrySalesStatisticsPage/>
              </Route>
              <Route exact path={URL_STATISTICS_ANALYSISBYPRODUCT}>
                <ProductSalesStatisticsPage/>
              </Route>
              <Route exact path={URL_STATISTICS_SUBSCRIBERANALYSIS}>
                <SubscriberStatisticsPage/>
              </Route>
              <Route exact path={URL_STATISTICS_CAMPAIGNANALYSIS}>
                <CampaignStatisticsPage/>
              </Route>
              <Route exact path={URL_CAMPAIGN_COUPON}>
                <CouponListPage/>
              </Route>
              <Route path={`${URL_CAMPAIGN_COUPON}/:couponId`}>
                <CouponDetailPage/>
              </Route>
              <Route exact path={URL_CAMPAIGN_ISSUECOUPON}>
                <CouponIssuePage/>
              </Route>
              <Route path={`${URL_CAMPAIGN_ALLOCATECOUPON}/:couponIssueId`}>
                <CouponAllocatePage/>
              </Route>
              <Route exact path={URL_CAMPAIGN_COUPON_ALLOCATIONLIST}>
                <CouponAllocationListPage/>
              </Route>
              <Route exact path={URL_MEMBER_MEMBERSHIPMANAGEMENT}>
                <MembershipManagementPage/>
              </Route>
              <Route path={`${URL_MEMBER_MEMBERSHIPMANAGEMENT}/:userId`}>
                <PermissionDetailsPage isModify={true}/>
              </Route>
              <Route exact path={URL_MEMBER_EDITPERMISSIONS}>
                <PermissionDetailsPage isModify={false}/>
              </Route>
              <Route exact path={URL_MEMBER_GROUPMANAGEMENT}>
                <GroupManagementPage/>
              </Route>
              <Route path={`${URL_MEMBER_GROUPMANAGEMENT}/:groupId`}>
                <GroupAddModifyPage isModify={true}/>
              </Route>
              <Route exact path={URL_MEMBER_CREATEGROUP}>
                <GroupAddModifyPage isModify={false}/>
              </Route>
              <Route exact path={URL_ADMIN_NOTIFICATIONMANAGEMENT}>
                <NotificationManagementPage/>
              </Route>
              <Route path={`${URL_ADMIN_NOTIFICATIONMANAGEMENT}/:notificationId`}>
                <NotificationAddModifyPage isModify={true}/>
              </Route>
              <Route exact path={URL_ADMIN_NOTIFICATIONREGISTRATION}>
                <NotificationAddModifyPage isModify={false}/>
              </Route>
              <Route exact path={URL_ADMIN_POPUPMANAGEMENT}>
                <PopupManagementPage/>
              </Route>
              <Route path={`${URL_ADMIN_POPUPMANAGEMENT}/:popupId`}>
                <PopupAddModifyPage isModify={true}/>
              </Route>
              <Route exact path={URL_ADMIN_POPUPREGISTRATION}>
                <PopupAddModifyPage isModify={false}/>
              </Route>
              <Route exact path={URL_ADMIN_FAQMANAGEMENT}>
                <FAQManagementPage/>
              </Route>
              <Route path={`${URL_ADMIN_FAQMANAGEMENT}/:faqId`}>
                <FAQAddModifyPage isModify={true}/>
              </Route>
              <Route exact path={URL_ADMIN_FAQREGISTRATION}>
                <FAQAddModifyPage isModify={false}/>
              </Route>
              <Route exact path={URL_ADMIN_CPMANAGEMENT}>
                <CPManagementPage/>
              </Route>
              <Route exact path={URL_SUPPORT_FAQ}>
                <FaqPage/>
              </Route>
              <Route exact path={URL_SUPPORT_NOTICE}>
                <NoticePage/>
              </Route>
              <Route exact path={URL_SUPPORT_ERRORCODE}>
                <ErrorCodePage/>
              </Route>
              <Route exact path={URL_DSA}>
                <DsaPage/>
              </Route>
              <Route exact path={URL_PRODUCT_SUBSCRIPTIONGROUP_LIST}>
                <SubscriptionGroupListPage/>
              </Route>
              <Route exact path={URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW}>
                <SubscriptionProductGroupAddModifyPage isModify={false}/>
              </Route>
              <Route exact path={`${URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL}/:groupId`}>
                <SubscriptionProductGroupAddModifyPage isModify={true}/>
              </Route>
              <Route exact path={URL_CAMPAIGN_BUYER_REDEMPTION_STATUS}>
                <BuyerRedemptionStatusPage/>
              </Route>
              <Route exact path={URL_REQUEST_LIST}>
                <RequestListPage/>
              </Route>
              <Route exact path={`${URL_PRODUCT_FLEXIBLEOFFER}/:productId`}>
                <FlexibleOfferPage />
              </Route>
              <Route path='*'>
                <ErrorPage/>
              </Route>
            </Switch>
            <ToastContainer
                position="top-center"
                transition={Slide}
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
          </>
      );
    }
  }

  componentDidMount() {
    //iff error then dont call below func
    this.fetchSessionData()
        .then(this.updateSelectedApp)
        .then(this.fetchCountryInformation)
        .catch(error => {
          console.log(error); // Log why chain has stopped
        });

    trigger = this.fetchSessionData;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.setDefaultCountry(this.props.carousel.selectedAppId);
      setCookie('selectedAppId', this.props.carousel.selectedAppId);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
    carousel: state.carousel,
    loading: state.loading
  };
};

const mapDispatchToProps = {
  loadSessionData: loadSessionData,
  updateSelectedAppIdInCarousel: updateSelectedAppIdInCarousel,
  loadCountriesInformation: loadCountriesInformation,
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(App)));
