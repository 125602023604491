import React from 'react';
import {withTranslation} from 'react-i18next';
class StatisticsFooter extends React.Component {

  render() {
    return (
        <div className="row align-items-center mt-20">
          {this.props.isFooter && (
              <div className="col">
                <span className="text-success align-middle">*</span>
                {this.props.footer}
              </div>
          )}
          <div className="col text-right">
            <button type="button" className="btn btn-sm btn-auto btn-primary" onClick={() => this.props.downloadClick()}>{this.props.t("DOWNLOAD")}</button>
          </div>
        </div>
    );
  }
}

export default (withTranslation()(StatisticsFooter));
