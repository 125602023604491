import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, transformRequestOptions} from '../../utils/globalConstants';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {URL_GET_STATISTICS_PRODUCT_SALES_API, URL_GET_STATISTICS_PRODUCT_SALES_DOWNLOAD_API, URL_GET_STATISTICS_PRODUCTLIST_API} from '../../utils/urlConstants';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import {convertToProductId, endDateString, getValues, isDurationLessThan1Month, round2DecimalPlaces, startDateString, typeDetails} from '../../utils/statisticsUtils';
import Table from '../statistics/Table';
import Switcher from '../statistics/Switcher';
import Graph from '../statistics/Graph';
import {handleHttpError} from '../../utils/ErrorUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';

class ProductSalesStatisticsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalSalesAmount: "0",
      totalRefundAmount: "0",
      totalSalesCount: "0",
      totalRefundCount: "0",
      productList: [],
      activeType: "AMOUNT",
      activeInterval: "MONTHLY",
      rowData: {},
      searchParams: {},
      dataLoaded: false
    };
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
  }

  handleSelectType = (type) => {
    this.setState({activeType: type});
  };

  handleSelectInterval = (interval) => {
    this.setState({activeInterval: interval}, () => this.search());
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"));
      return;
    }
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      cpProdIds: searchParams.selectedFilter[0] ? [searchParams.selectedFilter[0].value] : []
    };
    this.setState({
      selectedProduct: params.cpProdIds ? params.cpProdIds[0] : this.state.productList[0]
    });
    if (params.cpProdIds.length > 0) {
      params.cpProdIds = [convertToProductId(params.cpProdIds[0])];
    }
    let activeInterval;
    if (isDurationLessThan1Month(searchParams.startDate, searchParams.endDate)) {
      activeInterval = "WEEKLY";
    } else {
      activeInterval = "MONTHLY";
    }
    this.setState({
      searchParams: params,
      activeInterval: activeInterval
    }, () => this.search())
  };

  handleDownloadClick = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    handleDownloadFile(URL_GET_STATISTICS_PRODUCT_SALES_DOWNLOAD_API, searchParams, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_PRODUCT_SALES_API, {
            params: searchParams,
            paramsSerializer: transformRequestOptions
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              rowData: getValues(response.data[0].dataList, this.state.activeInterval),
              totalSalesAmount: round2DecimalPlaces(response.data[0].totalSalesAmount),
              totalRefundAmount: round2DecimalPlaces(response.data[0].totalRefundAmount),
              totalSalesCount: response.data[0].totalSalesCount,
              totalRefundCount: response.data[0].totalRefundCount
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    })
  };

  fetchProductList = () => {
    return new Promise((resolve, reject) => {
      const startDate = "2015-01-01";
      const endDate = new Date()
          .toISOString()
          .split("T")[0];

      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        dateStart: startDate,
        dateEnd: endDate,
        sortOrder: "DESC",
        pageNumber: 1,
        limit: 999
      };

      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_PRODUCTLIST_API, {
            params: params
          })
          .then(response => {
            const productList = response.data.data.map(e => e.productName + "(" + e.cpProductId + ")");
            if (productList.length > 0) {
              this.setState({
                productList: productList,
                selectedProduct: productList[0]
              }, () => {
                resolve()
              });
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  render() {
    const typeDetail = typeDetails["PRODUCT_SALES"];
    if (!this.state.dataLoaded) {
      return <div> {this.props.t("NO_PRODUCT")} </div>
    }
    const tableRows = [{id: "selectedProduct", header: "PRODUCT_NAME", value: this.state.selectedProduct},
      {id: "totalSalesAmount", header: "STATISTICS_SALES_AMOUNT", value: this.state.totalSalesAmount},
      {id: "totalRefundAmount", header: "STATISTICS_REFUND_AMOUNT", value: this.state.totalRefundAmount},
      {id: "totalSalesCount", header: "STATISTICS_SALES_COUNT", value: this.state.totalSalesCount},
      {id: "totalRefundCount", header: "STATISTICS_REFUND_COUNT", value: this.state.totalRefundCount}];
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, this.props.carousel.selectedAppId));
    const types = typeDetail.map(e => e.type).map(item => {
      return {type: item, isActive: this.state.activeType === item}
    });

    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{show: false}}
              datePanel={{
                show: true,
                label: this.props.t("SALES_PERIOD"),
                defaultDuration: durationKey.DURATION_YEAR,
                showSearch: false,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                showSearch: true,
                label: this.props.t("PRODUCT"),
                filters: [
                  {key: "PRODUCT", value: this.state.productList, defaultValue: this.state.selectedProduct},
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}
              onDownloadClick={() => {
              }}/>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{selectedApp.name + this.props.t("PRODUCT_SALES_STATISTICS_HEADER")}</strong></h2>
          </div>
          <Table data={tableRows}/>
          <Switcher
              types={types}
              selectType={this.handleSelectType}/>
          <Graph
              typeDetails={typeDetail}
              showTypeSwitcher={true}
              activeType={this.state.activeType}
              activeInterval={this.state.activeInterval}
              handleSelectInterval={this.handleSelectInterval}
              handleDownloadClick={this.handleDownloadClick}
              isFooter={false}
              isMonthly={isDurationLessThan1Month(this.state.searchParams.dateStart, this.state.searchParams.dateEnd)}
              rows={this.state.rowData}
          />
        </div>
    );
  }

  componentDidMount() {
    this.fetchProductList()
        .then(() => this.handleSearchClick({
          startDate: startDateString,
          endDate: endDateString,
          selectedFilter: [{value: this.state.selectedProduct}]
        }))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchProductList()
          .then(() => this.handleSearchClick({
            startDate: startDateString,
            endDate: endDateString,
            selectedFilter: [{value: this.state.selectedProduct}]
          }))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ProductSalesStatisticsPanel)));
