import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../css/bootstrap-extend.css';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {getCountriesInformation} from '../../ducks/countriesInformation';
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';

class DataTableWrapperForProductAddModify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false
    };
  }

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  getTaxCategoriesForCountry = (categoryList, country) => {
    let list = [];
    categoryList.forEach(item => {
      if (item.country === country) {
        list.push({id: item.taxCategoryId, value: item.taxCategoryName});
      }
    });
    return list;
  };

  render() {
    let countryEmpty = this.props.productCountryData.length === 0;
    let allCountrySelected = this.props.productCountryData.every(item => item.isSelected);
    return (
        <table className="table table-bordered table-hover table-data">
          <caption>Product Detail List</caption>
          <colgroup>
            <col width="60"/>
            <col width={this.props.isDynamic ? "180" : "200"}/>
            <col width={this.props.isDynamic ? "260" : "280"}/>
            <col width="110"/>
            {!this.props.isDynamic &&
            <col width="180"/>}
            {this.props.isDynamic &&
            <>
              <col width="150"/>
              <col width="150"/>
            </>}
            <col width="160"/>
            <col width="130"/>
            <col width="250"/>
          </colgroup>
          <thead>
          <tr>
            <th className="none-sort" scope="col">
              <div className="btn-group-toggle btn-group-checkbox btn-check" data-toggle="buttons">
                <label className={"btn-checkbox checkbox-all".concat(!countryEmpty && allCountrySelected ? " checked" : "")}><input onClick={() => this.props.onCheckAll()} type="checkbox"/></label>
              </div>
            </th>
            <th scope="col">{this.props.t("COUNTRY")}</th>
            <th scope="col">{this.props.t("PRODUCT_NAME")}</th>
            <th scope="col">{this.props.t("CURRENCY")}</th>
            {!this.props.isDynamic &&
            <th scope="col">{this.props.t("PRICE")}</th>}
            {this.props.isDynamic &&
            <>
              <th scope="col">{this.props.t("MIN_PRICE")}</th>
              <th scope="col">{this.props.t("MAX_PRICE")}</th>
            </>}
            <th scope="col">{this.props.t("TAX_RATE_%")}</th>
            <th scope="col">{this.props.t("VAT")}
              <span className={`tooltips right ${this.state.isTooltipOpen && "show"}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
                    <div className="tooltips_body">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a onClick={this.handleTooltipCloseClick} className="tooltip-toggle close"/>
                      <br/>
                      {this.props.t("VAT_TOOLTIP1")}<br/>
                      <br/>
                      {this.props.t("VAT_TOOLTIP2")}<br/>
                      <br/>
                      {this.props.t("VAT_TOOLTIP3")}
                    </div>
                  </span>
            </th>
            <th scope="col">{this.props.t("TAX_CATEGORY")}</th>
          </tr>
          </thead>
          <tbody className="text-center">
          {
            this.props.productCountryData.map((item) => {
              const countryInfo = getCountriesInformation(this.props.countriesInformation, item.country);
              if(!countryInfo) return <></>
              return (
                  <tr key={item.country}>
                    <td className="text-center">
                      <div className="btn-group-toggle btn-group-checkbox btn-check" data-toggle="buttons">
                        {this.props.readOnly ?
                            <label className={"btn-checkbox disabled"}/>
                            :
                            <label className={`btn-checkbox ${item.isSelected && "checked"}`}><input disabled={this.props.readOnly} onClick={() => this.props.onCheck(item.country)} type="checkbox"/></label>
                        }
                      </div>
                    </td>
                    <td>
                      {this.props.t(item.country)}
                    </td>
                    <td>
                      <input type="text"
                             maxLength="50"
                             value={item.productName}
                             disabled={this.props.readOnly}
                             className="form-control"
                             placeholder={this.props.t("PRODUCTNAME_COUNTRY_PLACEHOLDER")}
                             onChange={(event) => {
                               this.props.filterInput(event);
                               this.props.onCountryProductNameChange(item.country, event);
                             }}/>
                    </td>

                    <td>
                      {countryInfo.currency}
                      ({countryInfo.currencySymbol})
                    </td>
                    {!this.props.isDynamic &&
                    <td>
                      <input type="number" disabled={this.props.readOnly} className="form-control text-center" value={item.productPrice} placeholder={"(" +
                      countryInfo.minimumSaleableAmount
                      + " ~ " +
                      countryInfo.maximumSaleableAmount
                      + ")"}
                             min={countryInfo.minimumSaleableAmount}
                             max={countryInfo.maximumSaleableAmount}
                             onChange={(event) => this.props.onCountryProductPriceChange(item.country, event)}/>
                    </td>}
                    {this.props.isDynamic &&
                    <>
                      <td>
                        <input type="number" disabled={this.props.readOnly} className="form-control text-center" value={item.minimumDynamicProductPrice} placeholder={"(" +
                        countryInfo.minimumSaleableAmount
                        + " ~ " +
                        countryInfo.maximumSaleableAmount
                        + ")"}
                               min={countryInfo.minimumSaleableAmount}
                               max={countryInfo.maximumSaleableAmount}
                               onChange={(event) => this.props.onCountryProductMinPriceChange(item.country, event)}/>
                      </td>
                      <td>
                        <input type="number" disabled={this.props.readOnly} className="form-control text-center" value={item.maximumDynamicProductPrice} placeholder={"(" +
                        countryInfo.minimumSaleableAmount
                        + " ~ " +
                        countryInfo.maximumSaleableAmount
                        + ")"}
                               min={countryInfo.minimumSaleableAmount}
                               max={countryInfo.maximumSaleableAmount}
                               onChange={(event) => this.props.onCountryProductMaxPriceChange(item.country, event)}/>
                      </td>
                    </>}
                    <td><input type="number" min={0} max={100} value={item.taxRate} className="form-control text-center" disabled={!(item.priceTaxIncluded ? countryInfo.agentCountry : false) || this.props.readOnly || item.agentCountryTaxApplicable} placeholder="" onChange={(event) => this.props.onCountryProductTaxRateChange(item.country, event)}/>
                    </td>
                    <td>
                      <div className="btn-group-toggle btn-group-checkbox btn-check" data-toggle="buttons">
                        {(item.taxApplicable && !this.props.readOnly) ?
                            <label className={`btn-checkbox ${item.priceTaxIncluded && "checked"}`}><input type="checkbox" onClick={(event) => this.props.onCountryProductTaxIncludedChange(item.country, event)}/></label>
                            :
                            <label className={`btn-checkbox ${item.agentCountryTaxApplicable && "checked"} disabled`}/>
                        }
                      </div>
                    </td>
                    <td>
                      {countryInfo.taxCategoryApplicable &&
                      <SelectrWrapper
                          className="form-control"
                          clearable={false}
                          placeholder={this.props.t("SELECT")}
                          value={item.taxCategory}
                          onChange={(selected) => this.props.onCountryProductTaxCategoryChange(item.country, selected)}>
                        {this.getTaxCategoriesForCountry(this.props.taxcategoryData, item.country).map(v => <option key={v.id} value={v.id}>{v.value}</option>)}
                      </SelectrWrapper>}
                    </td>
                  </tr>
              )
            })
          }
          </tbody>
        </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countriesInformation: state.countriesInformation
  };
};

export default connect(mapStateToProps)(withTranslation()(DataTableWrapperForProductAddModify));