const TYPE_ACTION_START_LOADING = "TYPE_ACTION_START_LOADING";
const TYPE_ACTION_STOP_LOADING = "TYPE_ACTION_STOP_LOADING";

export function startLoading() {
  return {
    type: TYPE_ACTION_START_LOADING,
  };
}

export function stopLoading() {
  return {
    type: TYPE_ACTION_STOP_LOADING,
  };
}

const initialState = {
  isLoading: false,
};
export function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE_ACTION_START_LOADING:
      return {...state, isLoading: true};
    case TYPE_ACTION_STOP_LOADING:
      return {...state, isLoading: false};
    default:
      return state;
  }
}