import React, {Component} from 'react';
import {connect} from "react-redux";
import SHeader from "../SHeader";
import LeftNaviBar from "../lnb/LeftNaviBar";
import Content from "../Content";
import TopNaviBar from "../tnb/TopNaviBar";
import TabManager from "../TabManager";
import NotificationAddModifyPanel from "../panel/NotificationAddModifyPanel";
import {withRouter} from 'react-router-dom';
import {checkAdminPermission} from '../../utils/CommonUtils';

class NotificationAddModifyPage extends Component {

  componentDidMount() {
    checkAdminPermission(this.props);
  }

  render() {
    let wrapClassName = "clearfix";
    if (!this.props.sideDrawer.isSideDrawerOpen) {
      wrapClassName += " closeMenu";
    }

    return (
        <div id="wrap" className={wrapClassName}>
          <SHeader>
            <LeftNaviBar/>
          </SHeader>
          <Content>
            <TopNaviBar/>
            <TabManager content={<NotificationAddModifyPanel key={this.props.isModify ? "5" : "6"} isModify={this.props.isModify}/>}/>
          </Content>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    sideDrawer: state.sideDrawer
  });
}
export default connect(mapStateToProps)(withRouter(NotificationAddModifyPage));