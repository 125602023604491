import {combineReducers, createStore} from 'redux';
import {sessionDataReducer} from "./sessionData";
import {carouselReducer} from "./carousel";
import {sideDrawerReducer} from "./sideDrawer";
import {countriesInformationReducer} from './countriesInformation';
import {loadingReducer} from './loading';

const reducers = combineReducers({
  sessionData: sessionDataReducer,
  carousel: carouselReducer,
  sideDrawer: sideDrawerReducer,
  countriesInformation: countriesInformationReducer,
  loading: loadingReducer
});

export default createStore(reducers);
