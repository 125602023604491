import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import SelectrWrapper from "../pluginwrapper/SelectrWrapper";
import {noticeAttributes, noticeCategoryCode2Key, noticeCategoryKey2Code} from "../../utils/adminAttributes";
import {Editor} from '@tinymce/tinymce-react';
import {toast} from "react-toastify";
import BasePopup from "../popup/BasePopup";
import {API_ADMIN_DELETE_NOTICE, API_ADMIN_NOTIFICATION_DETAIL, API_ADMIN_NOTIFICATION_REGISTER, API_ADMIN_NOTIFICATION_UPDATE, URL_ADMIN_NOTIFICATIONMANAGEMENT} from "../../utils/urlConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from "../../ducks/loading";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from "../../utils/globalConstants";

class NotificationAddModifyPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      isTooltipOpen: false,
      hover: false,
      focus: false,
      alertModalOpen: false,
      category: "NOTICE",
      title: "",
      initialContent: "",
      content: "",
      url: "",
      fileList: [],
      // url: "https://sbox-dpi.samsungcheckout.com/work/contents",
      visibilityYN: "Y",
      fileName: null,
      noticeDetail: null,
      noticeId: -1,
      alertMessage: "",
      alertContext: 0, // FILE,NOTICE,CANCEL, SAVE
      selectedFile: null,
      isDataLoaded: false
    };
    this.Context = {
      FILE: 1,
      NOTICE: 2,
      CANCEL: 3,
      SAVE: 4
    };
    this.fileRef = React.createRef();
  }

  handleAlertPopupClose = (isSave, context) => {
    this.setState({alertModalOpen: false});
    if (!isSave) {
      return;
    }
    if (Object.is(context, this.Context.CANCEL)) {
      this.props.history.push(URL_ADMIN_NOTIFICATIONMANAGEMENT);
      return;
    }
    switch (context) {
      case this.Context.FILE:
        this.setState({fileName: null, selectedFile: null});
        return;
      case this.Context.NOTICE:
        break;
      default:
        break;
    }

    let ajaxUrl = null;
    let params;
    let toastSuccessMessage, toastFailMessage;
    if (context === this.Context.NOTICE) { //delete case
      ajaxUrl = API_ADMIN_DELETE_NOTICE;
      params = [this.state.noticeId];
      toastSuccessMessage = "NOTICE_DELETE_ALERT_SUCCESS";
      toastFailMessage = "NOTICE_DELETE_ALERT_FAIL";
    } else {
      const fd = new FormData();
      ajaxUrl = this.props.isModify ? API_ADMIN_NOTIFICATION_UPDATE : API_ADMIN_NOTIFICATION_REGISTER;

      toastSuccessMessage = this.props.isModify ? this.props.t("NOTIFICATION_MODIFY_ALERT_SUCCESS") : this.props.t("NOTIFICATION_ADD_ALERT_SUCCESS");
      toastFailMessage = this.props.isModify ? this.props.t("NOTIFICATION_MODIFY_ALERT_FAIL") : this.props.t("NOTIFICATION_ADD_ALERT_FAIL");

      if (this.state.title.trim().length === 0) {
        toast(this.props.t("FILL_TITLE"));
        return;
      }
      let text = this.state.content;
      text = text.replace(/\n/g, "");
      text = text.replace(new RegExp('<[^>]{0,10000}>', 'g'), '');
      text = text.replaceAll('&nbsp;', '');
      if (text.trim().length === 0) {
        toast(this.props.t("FILL_CONTENT"));
        return;
      }
      if (this.state.content.length > 5000) {
        toast(this.props.t("PLEASE_ENTER_LESS_CHARACTER"));
        return;
      }
      fd.append("noticeId", this.state.noticeId);
      fd.append("noticeTitle", this.state.title.trim());
      fd.append("noticeContent", this.state.content);
      fd.append("inUse", this.state.visibilityYN);
      if (!Object.is(this.state.selectedFile, null)) {
        fd.append("noticeFile", this.state.selectedFile);
      } else {
        if (this.state.fileName !== null) {
          fd.append("fileName", this.state.fileName);
        }
      }
      fd.append("category", noticeCategoryKey2Code[this.state.category]);
      params = fd;
    }

    return new Promise((resolve, reject) => {

      this.props.startLoading();
      this.httpClient
          .post(ajaxUrl, params)
          .then(response => {
            if (response.data !== SUCCESS) {
              toast(this.props.t(toastFailMessage));
            } else {
              toast(this.props.t(toastSuccessMessage));
              this.props.history.push(URL_ADMIN_NOTIFICATIONMANAGEMENT);
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
            toast(this.props.t(toastFailMessage));
          })
    })
  };

  getNoticeDetail = (noticeId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_ADMIN_NOTIFICATION_DETAIL, {
            params: {
              noticeId: noticeId
            }
          })
          .then(response => {
            this.setState({
              noticeId: response.data.noticeId,
              title: response.data.noticeTitle,
              initialContent: response.data.noticeContent,
              content: response.data.noticeContent,
              visibilityYN: response.data.inUse,
              fileName: response.data.fileName,
              category: noticeCategoryCode2Key[response.data.category],
              isDataLoaded: true
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });

  };

  handleFileChange = event => {
    event.preventDefault();
    const allowedExtensions = ['pdf', 'jpg', 'png'];
    if (event.target.files.length === 0) {
      return;
    }
    const {name: fileName} = event.target.files[0];
    let fileExtension = fileName ? fileName.split(".").pop() : '';
    fileExtension = fileExtension.toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast("FILE_NOT_ALLOWED");
      return;
    }
    this.setState({
      selectedFile: event.target.files[0],
      fileName: event.target.files[0].name
    });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      content: content,
    });
  };

  handleVisibilitySelect = (value) => {
    this.setState({
      visibilityYN: value
    });
  };

  handleDeleteNoticeClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "DO_YOU_WANT_TO_DELETE",
      alertContext: this.Context.NOTICE
    })
  };

  handleCancelClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "CONFIRM_EDIT_CANCEL_MESSAGE",
      alertContext: this.Context.CANCEL
    });
    if (this.props.isModify) {
      this.getNoticeDetail(this.state.noticeId);
    }
  };

  handleSaveClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: this.props.isModify ? "DO_YOU_WANT_TO_SAVE_THE_CHANGES" : "DO_YOU_WANT_TO_REGISTER_THE_INFORMATION",
      alertContext: this.Context.SAVE
    })
  };

  handleClickDeleteFile = (fileName) => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "CONFIRM_DELETE_FILE_MESSAGE",
      alertContext: this.Context.FILE,
      selectedFile: fileName
    })
  };

  componentDidMount() {
    if (this.props.isModify) {
      this.getNoticeDetail(this.props.match.params.notificationId);
    } else {
      this.setState({isDataLoaded: true})
    }
  }

  render() {
    if (!this.state.isDataLoaded) {
      return <div/>
    }
    return (
        <div className="tab-pane active" role="tabpanel">
          <p className="text-right text-success small"><span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}</p>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t(noticeAttributes.categoryName.key)}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="row">
                  <div className="col-5">
                    <SelectrWrapper
                        className="form-control"
                        clearable={false}
                        placeholder={this.props.t(noticeCategoryCode2Key[this.state.category])}
                        value={this.state.category}
                        onChange={(selectedTag) => this.setState({category: selectedTag.value})}>
                      {noticeAttributes.categoryName.value.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                    </SelectrWrapper>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("TITLE")} <span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="input-group textarea_count">
                  <input maxLength="100"
                         value={this.state.title}
                         placeholder={this.props.t("PLEASE_ENTER_TITLE")} required
                         onMouseOver={() => this.setState({hover: true})}
                         onMouseOut={() => this.setState({hover: false, focus: false})}
                         onBlur={() => this.setState({hover: false, focus: false})}
                         onFocus={() => this.setState({focus: true})}
                         onChange={(event) => this.setState({title: event.target.value})}
                         className="form-control text_counter rounded"/>
                  <div className="input-group-append counts text-center">
                    <span className={`text_count ${this.state.hover && "hover"} ${this.state.focus && "active"}`}>{this.state.title.length}</span> / 100{this.props.t("BYTE")}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("CONTENT")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <Editor apiKey='75hhu7t6jplfm6zeso9vvvkdggn92rv9ov6gkgxvl5n5lmun'
                        initialValue={this.state.initialContent}
                        value={this.state.content}
                        init={{
                          height: 400,
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                        }}
                        onEditorChange={this.handleEditorChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("FILE")}</label>
              <div className="col">
                <div className="row">
                  <div className="col-5">
                    <div className="input-group">
                      <input
                          type="text"
                          value={this.state.fileName ? this.state.fileName : ""}
                          className="form-control bg-white text-100"
                          placeholder={this.props.t("PLEASE_UPLOAD_FILE")}
                          readOnly="readonly"
                          style={{outline: "transparent", width: "100%", cursor: "pointer"}}
                          onClick={() => this.fileRef.current.click()}/>
                      <input type="file" style={{display: "none"}} accept="image/png, image/jpg, image/jpeg,.pdf" onChange={this.handleFileChange} ref={this.fileRef}/>
                      <div className="input-group-append upload-btn-wrapper">
                        <button className="btn btn-primary" type="button" onClick={() => this.handleUploadButtonClick()}>{this.props.t("UPLOAD")}</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-7">
                    {this.state.fileName &&
                    <button type="button" className="btn" onClick={() => this.handleClickDeleteFile(this.state.fileName)}>
                      {this.state.fileName} <i className="icon sm icon-del ml-2"/>
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("VISIBILITY")} <span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                  <label className={`btn btn-radio ${Object.is(this.state.visibilityYN, 'Y') && "checked"}`}><input type="radio" onClick={() => this.handleVisibilitySelect('Y')}/>{this.props.t("SHOW")}</label>
                  <label className={`btn btn-radio ${!Object.is(this.state.visibilityYN, 'Y') && "checked"}`}><input type="radio" onClick={() => this.handleVisibilitySelect('N')}/>{this.props.t("HIDE")}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-6">
              {
                this.props.isModify &&
                <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={() => this.handleDeleteNoticeClick()}>{this.props.t("DELETE")}</button>
              }
            </div>
            <div className="col-6 text-right">
              <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={() => this.handleCancelClick()}>{this.props.t("CANCEL")}</button>
              <button type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.handleSaveClick()}>{this.props.isModify ? this.props.t("SAVE") : this.props.t("REGISTER")}</button>
            </div>
          </div>
          <BasePopup
              open={this.state.alertModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleAlertPopupClose(false)}
              body={
                <p>{this.state.category === "NOTICE" ? this.props.t(this.state.alertMessage) : this.props.t("POPUP_MESSAGE_WHEN_GUIDE_CATEGORY_IS_SELECTED")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleAlertPopupClose(true, this.state.alertContext)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleAlertPopupClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>

        </div>
    )
  }

  handleUploadButtonClick = () => {
    this.fileRef.current.click();
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(NotificationAddModifyPanel)));