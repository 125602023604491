import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {SimpleRow} from "../SimpleRow";
import SelectrWrapper from "../pluginwrapper/SelectrWrapper";
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from '../../utils/globalConstants';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';
import {URL_CREATE_MEMBER_API, URL_GET_GROUP_LIST_DATA_API, URL_GET_INACTIVE_MEMBER_LIST_API, URL_GET_MEMBER_DETAIL_API, URL_MEMBER_MEMBERSHIPMANAGEMENT, URL_MODIFY_MEMBER_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import BasePopup from '../popup/BasePopup';

class PermissionDetailsPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      isTooltipOpen: false,
      isTooltipSettlementOpen: false,
      isStatusActive: true,
      hasSettlementAccess: false,
      memberDetail: null,
      selectedGroups: ["", ""],
      groupList: [],
      memberCPList: [],
      savePopup: false
    }
  }

  onAddRemoveButtonClickHandler = (group, isAddGroup, groupIndex) => {
    if (isAddGroup) {
      if (this.state.selectedGroups.length === this.state.groupList.length) {
        return;
      }
      const selected = this.state.selectedGroups;
      selected.push(this.state.groupList.find(group => selected.indexOf(group) < 0));
      this.setState({selectedGroups: selected});

    } else {
      this.setState(prevState => {
        return {
          selectedGroups: prevState.selectedGroups.filter((item, index) => index !== groupIndex)
        }
      });
    }
  };

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipSettlementOpenClick = () => {
    this.setState(prevState => {
      return {isTooltipSettlementOpen: !prevState.isTooltipSettlementOpen}
    });
  };

  setStatus = (isActive) => {
    this.setState({isStatusActive: isActive});
    if (!isActive) {
      this.setState({hasSettlementAccess: false});
    }
  };

  setSettlementAccess = (hasAccess) => {
    this.setState({hasSettlementAccess: hasAccess});
  }

  handleListClick = (e) => {
    e.preventDefault();
    this.props.history.push(URL_MEMBER_MEMBERSHIPMANAGEMENT);
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.setState({savePopup: false});
    if (this.state.memberDetail.userName.length === 0) {
      toast(this.props.t("PLEASE_SELECT_USER_ID"));
      return;
    }
    const atLeastOneGroupNotSelected = this.state.selectedGroups.includes('');
    if (atLeastOneGroupNotSelected) {
      toast(this.props.t("PLEASE_SELECT_GROUP"));
      return;
    }
    const params = {
      userId: parseInt(this.state.memberDetail.userId),
      userStatus: this.state.isStatusActive ? "Active" : "Inactive",
      groupIds: this.state.selectedGroups.filter(group => group).map(groupId => parseInt(groupId)),
      settlementAccess: this.state.hasSettlementAccess,
    };
    if (this.props.isModify) {
      this.props.startLoading();
      this.httpClient
          .post(URL_MODIFY_MEMBER_API, params)
          .then(response => {
            if (response.data.status !== SUCCESS) {
              toast(this.props.t("ERROR_ADDING_MEMBER"));
            } else {
              toast(this.props.t("MEMBER_INFORMATION_UPDATED"));
              this.props.history.push(URL_MEMBER_MEMBERSHIPMANAGEMENT);
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    } else {
      this.props.startLoading();
      this.httpClient
          .post(URL_CREATE_MEMBER_API, params)
          .then(response => {
            if (response.data.status !== SUCCESS) {
              toast(this.props.t("ERROR_ADDING_MEMBER"));
            } else {
              toast(this.props.t("MEMBER_SUCCESSFULLY_ADDED"));
              this.props.history.push(URL_MEMBER_MEMBERSHIPMANAGEMENT);
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  groupChangeHandler = (selected, index) => {
    if (selected) {
      const newList = this.state.selectedGroups;
      newList[index] = selected.value;
      this.setState({selectedGroups: newList});
    } else {
      this.setState({selectedGroups: []});
    }
  };

  userSelectHandler = (selected, index) => {
    if (selected) {
      const memberCPDetail = this.state.memberCPList.find(item => item.userId === selected.value);
      this.setStatus(true)
      this.setState({memberDetail: memberCPDetail, selectedGroups: memberCPDetail.groups ? memberCPDetail.groups : ["", ""]});
    }
  };

  getGroupList = () => {
    return new Promise((resolve, reject) => {
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        sortOrder: "DESC",
        pageNumber: 1,
        limit: 999, // TODO maybe change
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_GROUP_LIST_DATA_API, {
            params: params
          })
          .then(response => {
            this.setState(prevState => {
              return {
                groupList: response.data.data.map((group) => {
                  return {
                    id: group.groupId,
                    name: group.groupName
                  }
                })
              }
            });
            this.props.stopLoading();
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  initData = () => {
    if (this.props.isModify) {
      this.fetchMemberPermissions(this.props.match.params.userId)
          .then(this.getGroupList)
          .catch(error => {
            console.log(error); // Log why chain has stopped
          });
    } else {
      this.fetchInactiveMemberCPList()
          .then(this.getGroupList)
          .catch(error => {
            console.log(error); // Log why chain has stopped
          });
    }
  };

  componentDidMount() {
    this.initData();
  }

  render() {
    if (this.state.memberDetail === null || this.state.groupList.length === 0) {
      return <></>;
    }

    const {userName, userSecAcc, userJoinDate, userLastAccessDate, userId} = this.state.memberDetail;
    const user = userSecAcc || userId;
    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t("MEMBER_INFORMATION")}</strong></h2>
            <div className="text-right text-success small float-right mt-1 mb-0">
              <span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}
              <span className={`tooltips right ml-6 ${this.state.isTooltipOpen && "show"}`}>
                <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
                <div className="tooltips_body">
                  <a className="tooltip-toggle close" onClick={() => this.setState({isTooltipOpen: false})}/>
					        <div className="text-100 h6">{this.props.t("HOW_TO_EDIT_PERMISSIONS")}</div>
					        <ol className="pl-15 mb-0">
						        <li>{this.props.t("PERMISSION_DETAIL_PANEL_TOOLTIP_LINE_1")}</li>
						        <li>{this.props.t("PERMISSION_DETAIL_PANEL_TOOLTIP_LINE_2")}</li>
						        <li>{this.props.t("PERMISSION_DETAIL_PANEL_TOOLTIP_LINE_3")}</li>
					        </ol>
                </div>
              </span>
            </div>
          </div>
          <div className="form-group-wrap">
            <div className="row mb-20">
              <div className="col-6">
                {this.props.isModify ?
                    <SimpleRow title={this.props.t("USER_ID")} value={user} isMandatory={true}/>
                    :
                    <div className="form-group row">
                      <label className="col col-form-label">{this.props.t("USER_ID")}</label>
                      <div className="col">
                        <SelectrWrapper
                            className="form-control"
                            placeholder="Select User ID."
                            clearable={false}
                            value={userId}
                            onChange={(selected) => this.userSelectHandler(selected)}>
                          {this.state.memberCPList.map(v => <option key={v.userId} value={v.userId}>{v.userSecAcc || v.userId}</option>)}
                        </SelectrWrapper>
                      </div>
                    </div>
                }
              </div>
              <div className="col-6">
                <SimpleRow title={this.props.t("USER_NAME")} value={userName} isMandatory={true}/>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-6">
                <SimpleRow title={this.props.t("JOINED_DATE")} value={userJoinDate} isMandatory={true}/>
              </div>
              <div className="col-6">
                <SimpleRow title={this.props.t("ACCESS_DATE")} value={userLastAccessDate} isMandatory={true}/>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-6">
                <div className="form-group row">
                  <label className="col col-form-label">{this.props.t("STATUS")}<span className="text-success align-middle">*</span></label>
                  <div className="col btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                    <label className={`btn btn-radio ${this.state.isStatusActive && "checked"} ${!userName && "disabled"}`}><input type="radio" onClick={() => this.setStatus(true)}/>{this.props.t("ACTIVE")}</label>
                    <label className={`btn btn-radio ${!this.state.isStatusActive && "checked"} ${!userName && "disabled"}`}><input type="radio" onClick={() => this.setStatus(false)}/>{this.props.t("INACTIVE")}</label>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6 form-group row">
                    <label className="col col-form-label">
                      {this.props.t("MONTHLY_SETTLEMENT_REPORT_ACCESS")}
                      <span className={`tooltips right ${this.state.isTooltipSettlementOpen && "show"} ml-6`}>
                      <a onClick={this.handleTooltipSettlementOpenClick} className="tooltip-toggle">?</a>
                    <div className="tooltips_body">
                      <a onClick={() => this.setState({isTooltipSettlementOpen: false})} className="tooltip-toggle close"/>
                      {this.props.t("MEMBER_SETTLEMENT_TOOLTIP")}
                    </div>
                  </span>
                    </label>
                  </div>
                  <div className="col-6">
                    <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                      <label className={`btn btn-radio ${this.state.hasSettlementAccess && "checked"} ${!userName && "disabled"} ${!this.state.isStatusActive && "disabled"}`}><input type="radio" onClick={() => this.setSettlementAccess(true)}/>{this.props.t("YES")}</label>
                      <label className={`btn btn-radio ${!this.state.hasSettlementAccess && "checked"} ${!userName && "disabled"}`}><input type="radio" onClick={() => this.setSettlementAccess(false)}/>{this.props.t("NOPE")}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix mb-20 mt-60">
            <h2 className="float-left mb-0"><strong>{this.props.t("EDIT_PERMISSIONS")}</strong></h2>
            <table className="table table-bordered table-hover table-top-border">
              <colgroup>
                <col width="220"/>
                <col width="*"/>
                <col width="110"/>
              </colgroup>
              <tbody className="text-center">
              {
                this.state.groupList.length !== 0 && this.state.selectedGroups.map((group, index) => {
                  return (
                      <tr>
                        {index === 0 && <th className="bg-100" rowSpan="30">{this.props.t("GROUP")}</th>}
                        <td className="text-left">
                          <SelectrWrapper
                              className="form-control"
                              clearable={false}
                              placeholder={!this.props.isModify && "Select Group"}
                              isDisable={!this.state.isStatusActive}
                              value={group}
                              onChange={(selected) => this.groupChangeHandler(selected, index)}>
                            {this.state.groupList.map(v => <option key={v.id} value={v.id}>{this.props.t(v.name)}</option>)}
                          </SelectrWrapper>
                        </td>
                        <td>
                          <button className="btn btn-icon btn-icon-sm" type="button" onClick={() => this.onAddRemoveButtonClickHandler(group, index === 0, index)}>
                            <i className={`icon ${index === 0 ? "icon-plus" : "icon-minus"}`}/>
                          </button>
                        </td>
                      </tr>
                  )
                })
              }
              </tbody>
            </table>
            <div className="row mt-30">
              <div className="col-6"/>
              <div className="col-6 text-right">
                <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={(e) => this.handleListClick(e)}>{this.props.t("LIST")}</button>
                <button type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.setState({savePopup: true})}>{this.props.isModify ? this.props.t("SAVE") : this.props.t("REGISTER")}</button>
              </div>
            </div>
          </div>
          <BasePopup
              open={this.state.savePopup}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({savePopup: false})}
              body={
                <p>{this.props.isModify ? this.props.t("DO_YOU_WANT_TO_SAVE_THE_CHANGES") : this.props.t("DO_YOU_WANT_TO_REGISTER_THE_INFORMATION")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={(e) => this.handleSaveClick(e)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({savePopup: false})}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    )
  }

  fetchInactiveMemberCPList = () => {
    return new Promise((resolve, reject) => {
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_INACTIVE_MEMBER_LIST_API, {
            params: params
          })
          .then(response => {
            this.props.stopLoading();
            this.setState({
              memberCPList: response.data,
              memberDetail: {cpLoginId: "", userName: "", joinedDate: "", accessDate: ""},
              isStatusActive: false,
              selectedGroups: ["", ""],
              hasSettlementAccess: false,
            });
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });

  };

  fetchMemberPermissions = (userId) => {
    return new Promise((resolve, reject) => {
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        userId: userId,
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_MEMBER_DETAIL_API, {
            params: params
          })
          .then(response => {
            this.props.stopLoading();
            const memberDetail = response.data;
            this.setState({
              memberDetail: memberDetail,
              isStatusActive: Object.is(memberDetail.userStatus, 'Active'),
              selectedGroups: memberDetail.groups.map(e => e.groupId),
              hasSettlementAccess: memberDetail.hasSettlementAccess,
            });
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(PermissionDetailsPanel)));