import React from 'react';
import {withTranslation} from 'react-i18next';
import {Chart} from 'react-google-charts';
import {URL_STATISTICS_APPSALESANALYSIS} from '../../utils/urlConstants';
import {chartDetails} from '../../utils/dashboardUtils';
import {Link, withRouter} from 'react-router-dom';

class DashboardGraph extends React.Component {

  render() {
    const chartDetail = chartDetails[this.props.name];
    return (
        <div className={`info info-wide rounded ${chartDetail.classes}`}>
          <div className={`${this.props.table ? "co-table-scroll-y" : "w-100"}`}>
            <h3 className={`text-center ${this.props.border ? "border-0" : ""}`}>
              {this.props.t(chartDetail.title)}
              {this.props.dateRange &&
              <small className="text-muted align-middle">{this.props.dateRange}</small>}
              <Link to={{}} className="read_more" title="read more" onClick={() => this.props.history.push(URL_STATISTICS_APPSALESANALYSIS)}><span/></Link>
            </h3>
            {this.props.table &&
            <>
              <table className="table table-bordered table-hover">
                <caption>Country Sales Ranking</caption>
                <colgroup>
                  <col width="*"/>
                </colgroup>
                <thead>
                <tr>
                  <th scope="col">{this.props.t("RANKING")}</th>
                  <th scope="col">{this.props.t("COUNTRY")}</th>
                  <th scope="col">{this.props.t("SALES_REVENUE")}</th>
                </tr>
                </thead>
                <tbody className="text-center">
                {this.props.data.map((e, index, array) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{e[0]}</td>
                      <td>${e[1]}</td>
                    </tr>
                ))}
                </tbody>
              </table>
              {this.props.data.length === 0 &&
              <div className="dashboard-nodata"><p>{this.props.t("NO_DATA")}</p></div>
              }
            </>
            }
            {!this.props.table &&
            <>
              {this.props.data.length === 0 &&
              <div className={"relative"}>
                <div className="statistics">
                  <Chart chartType={chartDetail.chartType}
                         data={chartDetail.noData}
                         options={chartDetail.options}/>
                </div>
                <div className="no-data">
                  <span className={chartDetail.noDataClass}>{this.props.t("NO_DATA")}</span>
                </div>
              </div>}
              {this.props.data.length > 0 &&
              <div className="statistics">
                <Chart chartType={chartDetail.chartType}
                       data={this.props.data}
                       options={chartDetail.options}/>
              </div>}
            </>}
          </div>
        </div>
    )
  }
}

export default withTranslation()(withRouter(DashboardGraph));
