import React from 'react';
import {withTranslation} from "react-i18next";
import {URL_SA_CREATE_ACCOUNT, URL_SELLER_OFFICE, URL_SIGNIN_PAGE} from "../utils/urlConstants";

function SignInGuide(props) {
  return (
      <div className="row no-gutters mt-35 mb-30">
        <div className="col-3 align-items-center justify-content-center d-flex">
          <div className="sign_step01" title="Step 01">
            <span className="text-nowrap">
              {props.t('LOGIN_GUIDE_STEP01_1')}<br/>{props.t('LOGIN_GUIDE_STEP01_2')}
              <a href={URL_SA_CREATE_ACCOUNT} className="underline">{props.t('LOGIN_GUIDE_STEP01_3')}</a>
              {props.t('LOGIN_GUIDE_STEP01_4')}
            </span>
          </div>
        </div>
        <div className="col-1 align-items-center justify-content-center d-flex">
          <span className="next-step sm"/>
        </div>
        <div className="col-3 align-items-center justify-content-center d-flex">
          <div className="sign_step02" title="Step 02">
            <span className="text-nowrap">
              {props.t('LOGIN_GUIDE_STEP02_1')}<br/>{props.t('LOGIN_GUIDE_STEP02_2')}
              <a href={URL_SELLER_OFFICE} className="underline">{props.t('LOGIN_GUIDE_STEP02_3')}</a>.
            </span>
          </div>
        </div>
        <div className="col-1 align-items-center justify-content-center d-flex">
          <span className="next-step sm"></span>
        </div>
        <div className="col-4 align-items-center justify-content-center d-flex">
          <div className="sign_step03" title="Step 03">
            <span className="text-nowrap">
              {props.t('LOGIN_GUIDE_STEP03_1')}
              <a href={URL_SIGNIN_PAGE} className="underline">{props.t('LOGIN_GUIDE_STEP03_2')}</a>
              {props.t('LOGIN_GUIDE_STEP03_3')}<br/>{props.t('LOGIN_GUIDE_STEP03_4')}
            </span>
          </div>
        </div>
      </div>

  )
}

export default withTranslation()(SignInGuide);