import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import BasePopup from './BasePopup';
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {API_SUBSCRIPTION_EXPIRATION_DATE_MODIFICATION_HISTORY} from "../../utils/urlConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from '../../ducks/loading';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class SubscriptionExpiryDateChangeHistoryPopup extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      hover: false,
      focus: false,
      pageNumber: 1,
      rowsPerPage: 15,
      totalListSize: 0,
      sortOrder: 'ASC',
      sortName: 'modificationDate',
      fetchedSubscriptionExpiryDateModificationHistoryList: []
    }
  }

  fetchSubscriptionExpiryDateModificationHistory = (page, sizePerPage) => {
    let params = {
      subscriptionId: this.props.currentSubscriptionId.subscriptionId,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName,
      pageNumber: page,
      limit: sizePerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUBSCRIPTION_EXPIRATION_DATE_MODIFICATION_HISTORY, {
            params: params
          })
          .then(response => {
            this.setState({
              fetchedSubscriptionExpiryDateModificationHistoryList: response.data.data,
              pageNumber: page,
              totalListSize: response.data.records,
              rowsPerPage: sizePerPage,
            });
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  getSubscriptionExpiryDateModificationHistory = () => {
    let params = {
      subscriptionId: this.props.currentSubscriptionId.subscriptionId,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName,
      pageNumber: this.state.pageNumber,
      limit: this.state.rowsPerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUBSCRIPTION_EXPIRATION_DATE_MODIFICATION_HISTORY, {
            params: params
          })
          .then(response => {
            this.setState({
              fetchedSubscriptionExpiryDateModificationHistoryList: response.data.data,
              pageNumber: this.state.pageNumber,
              totalListSize: response.data.length,
              rowsPerPage: this.state.rowsPerPage,
            });
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      pageNumber: page,
      rowsPerPage: sizePerPage,
    }, () => this.getSubscriptionExpiryDateModificationHistory())
  };
  handleTableSort = (sortOrder, sortField) => {
    const productHeaderField2SortName = {
      "modificationDate": "modificationDate",
      "expiryDate": "expiryDate",
      "changeReason": "changeReason"
    };
    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: productHeaderField2SortName[sortField] ? productHeaderField2SortName[sortField] : "",
      productListPage: 1,
    }, () => this.getSubscriptionExpiryDateModificationHistory())
  };

  componentDidMount() {
    this.fetchSubscriptionExpiryDateModificationHistory(this.state.pageNumber, this.state.rowsPerPage)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open !== prevProps.open) {
      this.fetchSubscriptionExpiryDateModificationHistory(1, 15)
    }
  }

  render() {
    return (
        <BasePopup
            className={"modal-lg"}
            open={this.props.open}
            title={this.props.t("MODIFICATION_HISTORY_OF_SUBSCRIPTION_EXPIRATION_DATE")}
            onClose={() => this.props.handlePopupClose(false)}
            body={
              <DataTableWrapperForListing
                  keyField="modificationDate" // one of 'dataField'
                  tableStyle="table table-bordered"
                  hideSelectColumn={true}
                  columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                    {dataField: "modificationDate", text: this.props.t("MODIFICATION_DATE"), headerStyle: {width: "25.00000%"}, sort: true, classes: () => "text-truncate"},
                    {dataField: "expiryDate", text: this.props.t("EXPIRATION_DATE"), headerStyle: {width: "25.00000%"}, sort: true, classes: () => "text-left text-truncate"},
                    {dataField: "changeReason", text: this.props.t("REASON"), headerStyle: {width: "20.00000%"}, sort: true, classes: () => "text-truncate"},
                  ]}
                  page={this.state.pageNumber}
                  sizePerPage={this.state.rowsPerPage}
                  onTableSort={this.handleTableSort}
                  totalSize={this.state.totalListSize}
                  rows={this.state.fetchedSubscriptionExpiryDateModificationHistoryList.map(subscriptionExpiryDateModificationHistory => {
                    return {
                      ...subscriptionExpiryDateModificationHistory,
                    };
                  })}
                  onTableChange={this.handleSizePerPageChange}
              />
            }/>
    )
  };
}

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(0, mapDispatchToProps)(withTranslation()(withRouter(SubscriptionExpiryDateChangeHistoryPopup)));