import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./css/bootstrap.css";
import "./css/style.css";
import {Provider} from "react-redux";
import store from "./ducks/store";
import App from "./components/App";
import {I18nextProvider} from "react-i18next";
import 'core-js/features/object/is';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/es/set';
import i18next from "./translations/i18n";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>, document.getElementById('root'));
