import React, {Component} from 'react';
import ChangeInfoPanel from "../panel/ChangeInfoPanel";
import Form from "../Form";
import {getProperty} from "../../utils/propertiesByDomain";
import {DPI_PORTAL_SERVICE_ID} from "../../utils/globalConstants";
import {URL_AFTER_SA_ACCOUNT_LOGOUT} from "../../utils/urlConstants";
import {connect} from "react-redux";

class SellerBankInformationPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  submitForm = () => {
    this.formRef.current.submit();
  };

  render() {
    const actionUrl = getProperty("samsungAccountSignOutGateUrl");
    const clientId = DPI_PORTAL_SERVICE_ID;
    const logoutUrl = getProperty("urlHome") + URL_AFTER_SA_ACCOUNT_LOGOUT;

    return (
        <>
          <div id="wrap" className="clearfix"/>
          <div className="modal fade show">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title">Company Information</h3>
                  <button type="button" className="close" onClick={this.submitForm}/>
                </div>
                <div className="modal-body">
                  <ChangeInfoPanel isPopup={true} deeplink={this.props.deeplink} cancelHandler={this.submitForm}/>
                </div>
              </div>
            </div>
          </div>
          <Form method="get" action={actionUrl} ref={this.formRef}>
            <input type="hidden" name="client_id" value={clientId}/>
            <input type="hidden" name="signOutURL" value={logoutUrl}/>
            <input type="hidden" name="state" value={this.props.sessionData.csrfToken}/>
          </Form>
        </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData
  };
};

export default connect(mapStateToProps)(SellerBankInformationPage);