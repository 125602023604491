import React from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

class Switcher extends React.Component {

  render() {
    return (
        <ul className="nav nav-pills justify-content-center mt-20 mb-20">
          {this.props.types.map(item =>
              <li className="nav-item" key={item.type}>
                <Link to={{}} className={`nav-link tabs-toggle ${item.isActive && "active"}`} onClick={() => {
                  this.props.selectType(item.type)
                }}>{this.props.t(item.type)}</Link>
              </li>
          )}
        </ul>
    );
  }
}

export default (withTranslation()(Switcher));
