import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import ApplyAllBasePopup from './ApplyAllBasePopup';

class ApplyAllProductPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      price: "",
      minPrice: "",
      maxPrice: "",
    };
  }

  handleChangeName(value) {
    this.setState({
      productName: value
    });
  }

  handleChangePrice(value) {
    this.setState({
      price: value
    });
  }

  handleChangeMinPrice(value) {
    this.setState({
      minPrice: value
    });
  }

  handleChangeMaxPrice(value) {
    this.setState({
      maxPrice: value
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        productName: "",
        price: "",
        minPrice: "",
        maxPrice: "",
      });
    }
  }

  render() {
    const defaultCountryInfo = this.props.productInformationByCountries[0];
    return (
      <ApplyAllBasePopup
        open={this.props.open}
        handleModalClose={this.props.handleModalClose}
        readOnly={this.props.readOnly}
        defaultCountryInformation={this.props.productInformationByCountries}
        applyBody={<>
          <div className="row mb-3">
            <div className="col-12">
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  {this.props.t("PRODUCT_NAME")}
                </label>
                <div className="col">
                  <div className="input-group">
                    <input
                      type="text"
                      value={this.state.productName}
                      className="form-control"
                      maxLength={50}
                      placeholder={this.props.t("PRODUCTNAME_COUNTRY_PLACEHOLDER")}
                      onChange={event => {
                        this.props.filterInput(event);
                        this.handleChangeName(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  {this.props.t("CURRENCY")}
                </label>
                <div className="col">
                  <div className="input-group">
                    <input type="text" className="form-control" value={defaultCountryInfo.currency + " (" + defaultCountryInfo.currencySymbol + ")"}
                      readOnly="readonly" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!this.props.isDynamic &&
            <div className="row mb-3">
              <div className="col-12">
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    {this.props.t("PRICE")}
                  </label>
                  <div className="col">
                    <div className="input-group">
                      <input
                        type="number"
                        disabled={this.props.readOnly}
                        value={this.state.price}
                        className="form-control text-center"
                        placeholder={"(" + defaultCountryInfo.minimumSaleableAmount + " ~ " + defaultCountryInfo.maximumSaleableAmount + ")"}
                        min={defaultCountryInfo.minimumSaleableAmount}
                        max={defaultCountryInfo.maximumSaleableAmount}
                        onChange={event => this.handleChangePrice(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.props.isDynamic &&
            <>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("MIN_PRICE")}
                    </label>
                    <div className="col">
                      <div className="input-group">
                        <input
                          type="number"
                          disabled={this.props.readOnly}
                          value={this.state.minPrice}
                          className="form-control text-center"
                          placeholder={"(" + defaultCountryInfo.minimumSaleableAmount + " ~ " + defaultCountryInfo.maximumSaleableAmount + ")"}
                          min={defaultCountryInfo.minimumSaleableAmount}
                          max={defaultCountryInfo.maximumSaleableAmount}
                          onChange={event => this.handleChangeMinPrice(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      {this.props.t("MAX_PRICE")}
                    </label>
                    <div className="col">
                      <div className="input-group">
                        <input
                          type="number"
                          disabled={this.props.readOnly}
                          value={this.state.maxPrice}
                          className="form-control text-center"
                          placeholder={"(" + defaultCountryInfo.minimumSaleableAmount + " ~ " + defaultCountryInfo.maximumSaleableAmount + ")"}
                          min={defaultCountryInfo.minimumSaleableAmount}
                          max={defaultCountryInfo.maximumSaleableAmount}
                          onChange={event => this.handleChangeMaxPrice(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
        }
        buttonApplyAll={
          <button
            type="button"
            className="btn btn-120 btn-primary"
            onClick={() => this.props.handleModalClose(true, this.state)}>
            {this.props.t("APPLY_ALL")}
          </button>
        }
      />
    )
  }

}

export default withTranslation()(ApplyAllProductPopup);
