import React, {Component} from "react";
import ReactDOM from 'react-dom'
import Popout from 'react-popout-v2'

class PreviewPopup extends Component {
  render(props) {
    const width = this.props.size ? this.props.size.width : "350";
    const height = this.props.size ? this.props.size.height : "350";
    return (
        <Popout title='Preview Popup'
                reactDom={ReactDOM}
                onClose={this.props.handleClose}
                options={{
                  width: width, height: height
                }}
                children={<div className="modal-content co-ad-content">
                  <div className="modal-header">
                    <h3 className="modal-title" style={{textAlign: "center"}}>{this.props.title}</h3>
                  </div>
                  <div className="modal-body accordion_group">
                    <img src={this.props.imgSrc} alt={this.props.altText} style={{width: width - 18, height: height - 60}}/>
                  </div>
                </div>}
        />
    )
  }
}

export default PreviewPopup;