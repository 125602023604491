export const noticeAttributes = {
  categoryName: {
    key: 'CATEGORY',
    value: [
      'NOTICE',
      'GUIDE'
    ]
  },
  status: {
    key: 'VISIBILITY',
    value: [
      'SHOW',
      'HIDE'
    ]
  },
  title: {
    key: 'TITLE',
    value: null
  },
  author: {
    key: 'POSTED_BY',
    value: null
  },
  visibility: {
    key: 'VISIBILITY',
    value: null
  },
  registrationDate: {
    key: 'REGISTRATION_DATE',
    value: null
  }
};

export const noticeCategoryCode2Key = {
  "001": "NOTICE",
  "002": "GUIDE"
};

export const noticeCategoryKey2Code = {
  "NOTICE": "001",
  "GUIDE": "002"
};

export const noticeVisibilityCode2Key = {
  "Y": "SHOW",
  "N": "HIDE"
};

export const noticeVisibilityKey2Code = {
  "SHOW": "Y",
  "HIDE": "N"
};

export const popupAttributes = {
  title: {
    key: 'POP_UP',
    value: null
  },
  author: {
    key: 'POSTED_BY',
    value: null
  },
  visibility: {
    key: 'VISIBILITY',
    value: [
      'SHOW',
      'HIDE'
    ]
  },
  registrationDate: {
    key: 'REGISTRATION_DATE',
    value: null
  },
  displayPeriod: {
    key: 'DISPLAY_PERIOD',
    value: null
  }
};

export const FAQAttributes = {
  title: {
    key: "TITLE",
    value: null
  },
  author: {
    key: 'POSTED_BY',
    value: null
  },
  visibility: {
    key: 'VISIBILITY',
    value: [
      'SHOW',
      'HIDE'
    ]
  },
  registrationDate: {
    key: 'REGISTRATION_DATE',
    value: null
  },
};

export const CPAttributes = {
  userID: {
    key: "USER_ID",
    value: null
  },
  userName: {
    key: "USER_NAME",
    value: null
  },
  memberType: {
    key: "MEMBER_TYPE",
    value: null
  },
  status: {
    key: "STATUS",
    value: [
      "ACTIVE",
      "INACTIVE"
    ]
  }
};

export const cpUserStatusCode2Key = {
  "01": "ACTIVE",
  "02": "INACTIVE"
};

export const cpUserStatusKey2Code = {
  "ACTIVE": "01",
  "INACTIVE": "02"
};

export const cpUserMemberTypeCode2Key = {
  "manager": "MANAGER",
  "developer": "DEVELOPER"
}

export const faqVisibilityKey2Code = {
  "SHOW": "Y",
  "HIDE": "N"
};

export const faqVisibilityCode2Key = {
  "Y": "SHOW",
  "N": "HIDE"
};

export const faqKeywordOptionKey2Value = {
  "TITLE": "FAQ_TITL_VAL",
  "POSTED_BY": "REGR_ID"
};

export const popupUseKey2Code = {
  "SHOW": "Y",
  "HIDE": "N"
};