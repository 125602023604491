import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {memberAttributes} from "../../utils/memberAndGroupAttributes";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {URL_GET_MEMBER_LIST_DATA_API, URL_MEMBER_EDITPERMISSIONS, URL_MEMBER_GROUPMANAGEMENT, URL_MEMBER_MEMBERSHIPMANAGEMENT} from "../../utils/urlConstants";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, DESC_SORTING_ORDER} from "../../utils/globalConstants";
import SearchBar from "../searchbar/SearchBar";
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import {isSelectedAppChanged} from "../../ducks/carousel";

class MemberListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      memberListPage: 0,
      totalMemberListSize: 0,
      memberListSizePerPage: 0,
      fetchedMemberList: [],
      selectedMemberIdList: [],
      searchParams: {},
    }
  }

  handleTableSort = (sortOrder, sortField) => {
    const memberHeaderField2SortName = {
      "userIdLink": "userSecAcc",
      "userName": "userName",
      "groupCount": "groupCount",
      "userStatus": "userStatus",
      "userLastAccessDate": "userLastAccessDate",
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: memberHeaderField2SortName[sortField] ? memberHeaderField2SortName[sortField] : sortField,
      memberListPage: 1,
    }, () => this.memberSearch())
  };

  handleSearchClick = (searchParams) => {
    const params = {
      searchText: searchParams.keywordText,
    };
    this.setState({searchParams: params, memberListPage: 1}, () => this.memberSearch())
  };

  memberSearch = () => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName,
      pageNumber: this.state.memberListPage,
      limit: this.state.memberListSizePerPage,
      searchText: this.state.searchParams.searchText
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_MEMBER_LIST_DATA_API, {
            params: params,
            headers: {'Accept': 'application/json'}
          })
          .then(response => {
            this.setState({
              totalMemberListSize: response.data.records,
              fetchedMemberList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: "Keyword",
                placeholder: "Please enter Keywords.",
                showDownload: false,
              }}
              datePanel={{
                show: false,
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              noSelect
              keyField="userId" // one of 'dataField'
              hideSelectColumn={true}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "userId", hidden: true},
                {dataField: "userIdLink", text: this.props.t(memberAttributes.userId.key), headerStyle: {width: "19.9475%"}, sort: false, titleHeader: "userSecAcc"},
                {dataField: "userName", text: this.props.t(memberAttributes.userName.key), headerStyle: {width: "19.9475%"}, sort: false, classes: () => "text-left"},
                {dataField: "groupCount", text: this.props.t(memberAttributes.groupCount.key), headerStyle: {width: "20.03519%"}, sort: true},
                {dataField: "userStatus", text: this.props.t(memberAttributes.status.key), headerStyle: {width: "20.03519%"}, sort: true},
                {dataField: "settlementAccess", text: this.props.t(memberAttributes.settlementAccess.key), headerStyle: {width: "20.03519%"}, sort: true},
                {dataField: "userLastAccessDate", text: this.props.t(memberAttributes.accessDate.key), headerStyle: {width: "20.03519%"}, sort: true},
              ]}
              page={this.state.memberListPage}
              sizePerPage={this.state.memberListSizePerPage}
              totalSize={this.state.totalMemberListSize}
              rows={this.state.fetchedMemberList.map(member => {
                const user = member.userSecAcc || member.userId;
                return {
                  ...member,
                  userIdLink:
                      Object.is(member.userStatus, "Active") ? <Link to={{}}
                                                                     onClick={(e) => {
                                                                       e.preventDefault();
                                                                       this.props.history.push(URL_MEMBER_MEMBERSHIPMANAGEMENT + "/" + member.userId);
                                                                     }}>
                        {user}
                      </Link> : user,
                  userStatus: member.userStatus,
                  settlementAccess: member.hasSettlementAccess ? 'Yes' : 'No',
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}/>
          <div className="pagenationGroup clearfix max">
            {/*<div className="float-left">*/}
            <Link to={URL_MEMBER_GROUPMANAGEMENT}>
              <button
                  type="button" className="btn btn-sm btn-success float-left">
                {this.props.t("GROUP_MANAGEMENT")}
              </button>
            </Link>
            <Link to={URL_MEMBER_EDITPERMISSIONS}>
              <button type="button" className="btn btn-sm btn-primary float-right">
                {this.props.t("EDIT_PERMISSIONS")}
              </button>
            </Link>
          </div>
        </div>
    );
  }

  fetchMemberList = (page, sizePerPage) => {
    return new Promise((resolve, reject) => {
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        sortOrder: DESC_SORTING_ORDER,
        pageNumber: page,
        limit: sizePerPage,
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_MEMBER_LIST_DATA_API, {
            params: params,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          })
          .then(response => {
            this.setState({
              memberListPage: page,
              memberListSizePerPage: sizePerPage,
              searchParams: params,
              totalMemberListSize: response.data.records,
              fetchedMemberList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  componentDidMount() {
    this.fetchMemberList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedContsId, prevProps.carousel.selectedContsId)) {
      this.searchBarRef.current.resetState();
      this.fetchMemberList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(MemberListPanel)));