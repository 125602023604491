import React from 'react';

class SelectrList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hover: false};
  }

  render() {
    return (
        <li className={`selectr-option ${this.state.hover && "active"}`}
            onMouseEnter={(e) => this.setState({hover: true})}
            onMouseLeave={(e) => this.setState({hover: false})}
            onClick={this.props.onClick}>
          {this.props.children}
        </li>
    );
  }
}

// TODO "this.props.isDisable" Needs to be implemented
class SelectrWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedOption: this.props.children.find(e => Object.is(e.props.value, this.props.value)) || null,
    };

    this.ref = React.createRef();
  }

  handleSelectrClick = (e) => {
    if (this.props.isDisable) {
      return;
    }
    this.setState({open: !this.state.open});
  };

  handleListClick = (e, selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
      open: false
    }, () => {
      if (this.props.onChange) {
        this.props.onChange({
          key: selectedOption.key,
          value: selectedOption.props.value
        });
      }
    });
  };

  handleClearButtonClick = (e) => {
    this.setState({selectedOption: null, open: false}, () => {
      if (this.props.onChange) {
        this.props.onChange({
          key: null,
          value: null
        });
      }
    });
  };

  render() {
    let selectrClassName = "selectr-container selectr-desktop";

    if (this.state.selectedOption) {
      selectrClassName += " has-selected";
    }

    if (this.state.open) {
      selectrClassName += " open";
    }

    if (this.props.dataClassName) {
      selectrClassName += ` ${this.props.dataClassName}`;
    }

    const clearable = this.props.isDisable ? false : this.props.clearable;
    if (clearable) {
      selectrClassName += " clearable";
    }

    if (this.props.isDisable) {
      selectrClassName += " selectr-disabled"
    }

    return (
        <div className={selectrClassName} style={{width: "100%"}} ref={this.ref}>
          {clearable && <button className="selectr-clear" type="button" onClick={this.handleClearButtonClick}/>}
          <div className="selectr-selected" aria-expanded="true" onClick={this.handleSelectrClick}>
            <span className="selectr-label">{this.state.selectedOption && this.state.selectedOption.props.children}</span>
            <div className="selectr-placeholder">{!this.state.selectedOption && this.props.placeholder}</div>
          </div>
          <div className="selectr-options-container">
            <ul className="selectr-options" role="tree" aria-hidden="false" aria-expanded="true">
              {this.state.open &&
              this.props.children.map(option =>
                  <SelectrList
                      key={option.key || option.props.value}
                      type={option.type}
                      onClick={e => this.handleListClick(e, option)}>
                    {option.props.children}
                  </SelectrList>
              )}
            </ul>
          </div>
        </div>
    );
  }

  handleClickOutside = (e) => {
    if (this.ref && !this.ref.current.contains(e.target)) {
      this.setState({open: false});
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({selectedOption: this.props.children.find(e => Object.is(e.props.value, this.props.value)) || null});
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.setState({selectedOption: this.props.children.find(e => Object.is(e.props.value, this.props.value)) || null});
    }
  }

}

export default SelectrWrapper;