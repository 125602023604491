import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import BasePopup from './BasePopup';
import {toast} from 'react-toastify';
import {API_SALES_SUBSCRIPTION_PAYMENT_PERIOD, API_SUBSCRIPTION_DETAIL, URL_GET_COUNTRY_INFORMATION_BY_CODE_API} from '../../utils/urlConstants';
import DatePickerX from '../common/DatePickerX';
import {getDayDifference} from '../../utils/utils';
import {AXIOS_CLIENT_TIMEOUT, PRODUCT_TYPE_CODE, subscriptionStatusCodeIsActive, subscriptionStatusCodeIsCanceled, subscriptionStatusCodeIsExpired} from '../../utils/globalConstants';
import {startLoading, stopLoading} from '../../ducks/loading';
import {connect} from 'react-redux';
import axios from 'axios';

class RequestRefundPopUp extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeOut: AXIOS_CLIENT_TIMEOUT})
    this.state = {
      reason: '',
      hover: false,
      focus: false,
      refundType: "FULL_REFUND",
      partialRefundAmount: "0",
      partialRefundSamsungRewardsAmount: "0",

      getProratedDate: undefined,
      getSamsungRewardsProratedDate: undefined,

      isSubscription: false,
      isSubscriptionActive: false,
      isSubscriptionCanceled: false,
      isSubscriptionExpired: false,
      currentSubscription: undefined,
      countryInformation: {},
    }
  }

  componentDidMount() {
    this.initCountryData();
    this.fetchSubscriptionDetail();
  }

  initCountryData = () => {
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_COUNTRY_INFORMATION_BY_CODE_API, {
          params: {
            countryCode: this.props.currentInvoice.countryCode,
          }
        })
        .then((countryInfo => {
          this.props.stopLoading();
          this.setState({countryInformation: countryInfo.data[0]})
        }));
  };

  getSubscriptionPaymentPeriod = (subs) => {
    this.httpClient
        .get(API_SALES_SUBSCRIPTION_PAYMENT_PERIOD, {
          params: {
            firstInvoinceId: this.props.currentInvoice.firstInvoiceId,
            invoiceId: this.props.currentInvoice.invoiceId,
          }
        })
        .then(response => {
          const period = response.data;
          subs.subscriptionStartDate = period.subsStartDate;
          subs.subscriptionEndDate = period.subsEndDate;
        })
  }

  fetchSubscriptionDetail = () => {
    const isSubscription = this.props.currentInvoice.productTypeCd === PRODUCT_TYPE_CODE.SUBSCRIPTION;
    const isRefunded = this.props.currentInvoice.invoiceCancelId !== null;

    if (!isSubscription || isRefunded) {
      this.setState({
        isSubscription: false,
        isSubscriptionActive: false,
        isSubscriptionCanceled: false,
        isSubscriptionExpired: false,
        currentSubscription: undefined,
      });
      return;
    }

    this.props.startLoading();
    this.httpClient
        .get(API_SUBSCRIPTION_DETAIL, {
          params: {
            cpAppId: this.props.currentInvoice.appId,
            subscriptionId: this.props.currentInvoice.firstInvoiceId,
          }
        })
        .then(response => {
          let currentSubscription = response.data;
          this.getSubscriptionPaymentPeriod(currentSubscription); //change subscription period according to payment period
          this.setState({
            isSubscription: true,
            isSubscriptionActive: subscriptionStatusCodeIsActive(currentSubscription.subscriptionStatusCode),
            isSubscriptionCanceled: subscriptionStatusCodeIsCanceled(currentSubscription.subscriptionStatusCode),
            isSubscriptionExpired: subscriptionStatusCodeIsExpired(currentSubscription.subscriptionStatusCode),
            currentSubscription: currentSubscription,
          });
        })
        .catch(error => {
          this.setState({
            isSubscription: false,
            isSubscriptionActive: false,
            isSubscriptionCanceled: false,
            isSubscriptionExpired: false,
            currentSubscription: undefined,
          });
        })
        .finally(() => {
          this.props.stopLoading();
        });
  };

  setRefundType = (option) => {
    this.setState({refundType: option});
  }

  handlePartialRefundAmountInput = (event, isSR) => {
    let input = event.target.value;
    if (isSR) {
      // TODO : if this actually used, make sure the decimal place is correct
      this.setState({partialRefundSamsungRewardsAmount: input});
    } else {
      this.setState({partialRefundAmount: input});
    }
  }

  checkNumber = () => {
    let values = [this.state.partialRefundAmount, this.state.partialRefundSamsungRewardsAmount];
    for (const value of values) {
      let isInvalid = false;
      if (value.indexOf(".") === -1 && value.length > 10) {
        isInvalid = true;
      }
      if (value.indexOf(".") !== -1) {
        if (value.indexOf(".") > 10) {
          isInvalid = true;
        }
        if (value.length - value.indexOf(".") - 1 > 5) {
          isInvalid = true;
        }
      }
      if (parseFloat(value) != parseFloat(value).toFixed(this.state.countryInformation.currencyDecimalRoundingLength)) {
        isInvalid = true;
      }

      if (isInvalid) {
        this.setState({partialRefundAmount: "0", partialRefundSamsungRewardsAmount: "0"}, () => {
          toast(this.props.t("REFUND_AMOUNT_NOT_IN_ALLOWED_RANGE"));
        })
      }
    }
  }

  calculateProratedRefundAmount = (startDate, currentDate, endDate, price) => {
    const totalPeriod = getDayDifference(startDate, endDate) + 1;
    const unusedPeriod = getDayDifference(currentDate, endDate);
    if (!currentDate || !totalPeriod || !unusedPeriod || unusedPeriod < 0 || totalPeriod <= 0 || unusedPeriod > totalPeriod) {
      toast(this.props.t("INVALID_PRORATED_REFUND_DATE"));
      return 0;
    }
    if (isNaN(Number(price))) {
      toast(this.props.t("UNABLE_TO_CALCULATE_PRORATED_REFUND_AMOUNT"));
      return 0;
    }
    if (price === 0.00) {
      return 0;
    }
    const refundAmount = Number(price) * unusedPeriod / totalPeriod;
    return Math.max(1, Math.round(refundAmount * 100)) / 100; // round to 2 decimal places and minimum value of 0.01
  }

  calculateProratedRefund = (date) => {
    const startDate = this.state.currentSubscription?.subscriptionStartDate;
    const endDate = this.state.currentSubscription?.subscriptionEndDate;
    const price = this.state.currentSubscription? this.props.paymentAmountNumber : undefined;
    const refundAmount = this.calculateProratedRefundAmount(startDate, date, endDate, price);
    this.setState({partialRefundAmount: refundAmount});
  }

  calculateSamsungRewardsProratedRefund = (date) => {
    const startDate = this.state.currentSubscription?.subscriptionStartDate;
    const endDate = this.state.currentSubscription?.subscriptionEndDate;
    const price = this.state.currentSubscription? this.props.samsungRewardsUsedAmount : undefined;
    const refundAmount = this.calculateProratedRefundAmount(startDate, date, endDate, price);
    this.setState({partialRefundSamsungRewardsAmount: refundAmount});
  }

  subtractOneDay = (date) => {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date.setDate(date.getDate() - 1);
    return date;
  }

  render() {
    const {buyer, invoiceId, paymentAmount, paymentMethod, additionalPaymentInfo, handlePopupClose, samsungRewardsUsedAmount, samsungRewardsUsedAmountDisplay} = this.props;
    const {reason, hover, focus} = this.state;
    const paymentMethodCode = this.props.currentInvoice.paymentMethodCode;
    const isPartialRefundApplicable = ["KCC", "GCC" ,"PP", "WSP", "IWP"].includes(paymentMethodCode);

    const proratedRefundStartDate = this.state.currentSubscription?.subscriptionStartDate;
    const proratedRefundEndDate = this.subtractOneDay(this.state.currentSubscription?.subscriptionEndDate);

    const isProratedRefundApplicable = this.state.isSubscription && isPartialRefundApplicable && this.state.refundType === "PARTIAL_REFUND";
    const isProratedRefundActive = isProratedRefundApplicable && (this.state.isSubscriptionCanceled || this.state.isSubscriptionExpired) && !this.state.isSubscriptionActive
        && getDayDifference(proratedRefundStartDate, proratedRefundEndDate) >= 0;

    return (
        <BasePopup
            open={this.props.open || this.props.alertPopup}
            title={this.props.alertPopup ? this.props.t("ALERT") : this.props.t("REQUEST_REFUND")}
            onClose={() => this.setState({refundType: "FULL_REFUND", partialRefundAmount: "0", partialRefundSamsungRewardsAmount: "0", reason: ''}, () => handlePopupClose(false))}
            body={
              <>
                {this.props.alertPopup ?
                    <p>{this.props.t("ALERT_REFUND_BR_300")}</p> :
                    <div className="search_field m-0 p-0 border-0" id="request-refund-pop-up">
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("INVOICE_ID")}</label>
                        <div className="col">
                          <input type="text" className="form-control" value={invoiceId} readOnly="readonly"/>
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("BUYER")}</label>
                        <div className="col">
                          <input type="text" className="form-control" value={buyer} readOnly="readonly"/>
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("REFUND_TYPE")}</label>
                        <div className="col">
                          <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                            <label className={`btn btn-radio ${this.state.refundType === "FULL_REFUND" && "checked"}`}>
                              <input type="radio" onClick={() => this.setRefundType('FULL_REFUND')}/>
                              {this.props.t("FULL_REFUND")}
                            </label>
                            {isPartialRefundApplicable &&
                                <label className={`btn btn-radio ${this.state.refundType === "PARTIAL_REFUND" && "checked"}`}>
                                  <input type="radio" onClick={() => this.setRefundType('PARTIAL_REFUND')}/>
                                  {this.props.t("PARTIAL_REFUND")}
                                </label>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("AMOUNT")}</label>
                        <div className="col">
                          <input type="text" className="form-control" value={paymentAmount} readOnly="readonly"/>
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("REFUND_AMOUNT")}</label>
                        <div className="col">
                          {this.state.refundType === "FULL_REFUND" && <input type="text" className="form-control" value={paymentAmount} readOnly="readonly"/>}
                          {this.state.refundType === "PARTIAL_REFUND" && <input type="number" className="form-control" value={this.state.partialRefundAmount} onChange={(e) => {
                            this.handlePartialRefundAmountInput(e, false)
                          }} onBlur={() => this.checkNumber()}
                          />}
                        </div>
                      </div>
                      {isProratedRefundApplicable &&
                          <div className="form-group row align-items-center">
                            <label className="col col-form-label">{this.props.t("PRORATED_REFUND")}<br/>{this.props.t("AMOUNT_CALCULATION")}</label>
                            <div className="col d-flex align-items-center">
                              <DatePickerX
                                  key={this.state.currentSubscription?.subscriptionId}
                                  disabled={!isProratedRefundActive}
                                  placeholder={this.props.t("PRORATED_REFUND_DATE")}
                                  minDate={proratedRefundStartDate}
                                  maxDate={proratedRefundEndDate}
                                  onInit={datePicker => this.setState({
                                    getProratedDate: datePicker.getValue
                                  })}
                              />
                              <button
                                  type="button"
                                  className="btn btn-outline-secondary ml-2"
                                  disabled={!isProratedRefundActive}
                                  onClick={() => this.calculateProratedRefund(this.state.getProratedDate())}>
                                {this.props.t("CALCULATE")}
                              </button>
                            </div>
                          </div>
                      }
                      {samsungRewardsUsedAmount !== 0.00 &&
                          <>
                            <hr/>
                            <div className="form-group row align-items-center">
                              <label className="col col-form-label">{this.props.t("SAMSUNG_REWARDS")}<br/>{this.props.t("AMOUNT")}</label>
                              <div className="col">
                                <input type="text" className="form-control" value={samsungRewardsUsedAmountDisplay} readOnly="readonly"/>
                              </div>
                            </div>
                            <div className="form-group row align-items-center">
                              <label className="col col-form-label">{this.props.t("SAMSUNG_REWARDS")}<br/>{this.props.t("REFUND_AMOUNT")}</label>
                              <div className="col">
                                {this.state.refundType === "FULL_REFUND" && <input type="text" className="form-control" value={samsungRewardsUsedAmountDisplay} readOnly="readonly"/>}
                                {this.state.refundType === "PARTIAL_REFUND" && <input type="number" className="form-control" value={this.state.partialRefundSamsungRewardsAmount} onChange={(e) => {
                                  this.handlePartialRefundAmountInput(e, true)
                                }} onBlur={() => this.checkNumber()}
                                />}
                              </div>
                            </div>
                            {isProratedRefundApplicable &&
                                <div className="form-group row align-items-center">
                                  <label className="col col-form-label">{this.props.t("SAMSUNG_REWARDS")}<br/>{this.props.t("PRORATED_REFUND")}<br/>{this.props.t("AMOUNT_CALCULATION")}</label>
                                  <div className="col d-flex align-items-center">
                                    <DatePickerX
                                        key={this.state.currentSubscription?.subscriptionId}
                                        disabled={!isProratedRefundActive}
                                        placeholder={this.props.t("PRORATED_REFUND_DATE")}
                                        minDate={proratedRefundStartDate}
                                        maxDate={proratedRefundEndDate}
                                        onInit={datePicker => this.setState({
                                          getSamsungRewardsProratedDate: datePicker.getValue
                                        })}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary ml-2"
                                        disabled={!isProratedRefundActive}
                                        onClick={() => this.calculateSamsungRewardsProratedRefund(this.state.getSamsungRewardsProratedDate())}>
                                      {this.props.t("CALCULATE")}
                                    </button>
                                  </div>
                                </div>
                            }
                            <hr/>
                          </>
                      }
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("PAYMENT_METHOD")}</label>
                        <div className="col">
                          <input type="text" className="form-control" value={paymentMethod} readOnly="readonly"/>
                        </div>
                      </div>
                      <div className="form-group row  align-items-center">
                        <label className="col col-form-label">{this.props.t("ADDITIONAL")}<br/>{this.props.t("PAYMENT_INFO")}</label>
                        <div className="col">
                          <input type="text" className="form-control" value={samsungRewardsUsedAmount !== 0.00 ? "Samsung Rewards" : additionalPaymentInfo} readOnly="readonly"/>
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col col-form-label">{this.props.t("REASON_FOR")}<br/>{this.props.t("REFUND")}</label>
                        <div className="col">
                          <div className="textarea_count">
                        <textarea maxLength="100"
                                  onMouseOver={() => this.setState({hover: true})}
                                  onMouseOut={() => this.setState({hover: false, focus: false})}
                                  onBlur={() => this.setState({hover: false, focus: false})}
                                  onFocus={() => this.setState({focus: true})}
                                  onChange={(event) => {
                                    this.setState({reason: event.target.value})
                                  }}
                                  value={this.state.reason}
                                  className="form-control text_counter"
                                  placeholder={this.props.t("CANCEL_REASON_PLACEHOLDER")}>
                        </textarea>
                            <div className="counts text-right"><span className={`text_count ${hover && "hover"} ${focus && "active"}`}>{reason.length}</span> / 100{this.props.t("BYTE")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </>
            }
            footer={
              <div className="modal-footer">
                {this.props.alertPopup ?
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({refundType: "FULL_REFUND", partialRefundAmount: "0", partialRefundSamsungRewardsAmount: "0", reason: ""}, () => handlePopupClose(false))}>
                      {this.props.t("OK")}
                    </button> :
                    <>
                      <button type="button" className="btn btn-secondary" onClick={() => {
                        handlePopupClose(true, reason, this.state.refundType, this.state.partialRefundAmount, this.state.partialRefundSamsungRewardsAmount)
                        this.setState({reason: ""})
                      }}>
                        {this.props.t("CONFIRM")}
                      </button>
                      <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({refundType: "FULL_REFUND", partialRefundAmount: "0", partialRefundSamsungRewardsAmount: "0", reason: ''}, () => handlePopupClose(false))}>
                        {this.props.t("CANCEL")}
                      </button>
                    </>
                }
              </div>
            }/>
    )
  }
}

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading,
};

export default connect(0, mapDispatchToProps)(withTranslation()(RequestRefundPopUp));
