import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {getCookie, setCookie} from '../utils/simpleCookieUtils';
import {alwaysShowMenuKeys, getMenuEntityByUri, getMenuEntityForDynamicUri, isDynamicUri, menuKeys, settlementAppMenuKeys} from '../utils/dpiPortalMenusAndUtils';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {URL_SETTINGS_TESTBUYER_REGISTRATION} from '../utils/urlConstants';

const TAB_MANAGER_COOKIE_NAME = "tabKeys";
const MAXIMUM_TAB_LENGTH = 10;
/**
 * TabManager Rules.
 *
 * 1. All Tabs should have X Button except when there is only one tab
 * 2. If active tab is closed, open the Left most Tab
 * 3. If existing tab is chosen, tab order does not change
 * 4. If tab is more then 10, right most tab disappears
 * 5. TODO Up to 10 menu tabs cab be displayed. Reduce the width of the tabs as more are added.
 */
class TabManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabKeys: []
    }
  }

  handleClose = (e, menu, isActiveMenu) => {
    e.preventDefault(); // To not let the <Link> process
    const newTabKeys = this.state.tabKeys.filter(tab => !Object.is(menu.key, tab.key));
    this.setStateAndSyncToCookie(newTabKeys);
    if (isActiveMenu) {
      this.props.history.push(newTabKeys[0].url);
    }
  };

  setStateAndSyncToCookie = (newTabKeys) => {
    this.setState({tabKeys: newTabKeys});
    setCookie(TAB_MANAGER_COOKIE_NAME, JSON.stringify(newTabKeys));
  };

  filterPermittedTabs = (tabMenuKeysFromCookie) => {
    let tabMenuKeys;
    const {selectedAppId} = this.props.carousel;
    // When cpUser is switched (CP Management)
    // during loadSessionData call in App.js, selectedAppId in carousel is from different cp (as updateSelectedAppIdInCarousel is not called yet),
    // apps.find may return undefined here, hence updated logic to select first app (this.props.sessionData.apps[0])
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, selectedAppId)) || this.props.sessionData.apps[0];

    if (selectedApp.settlementApp) {
      return tabMenuKeysFromCookie.filter(tabMenu => settlementAppMenuKeys.includes(tabMenu.key) && !isDynamicUri(tabMenu.url));
    }

    if (this.props.sessionData.isManager) {
      return tabMenuKeysFromCookie.filter(tabMenu => !isDynamicUri(tabMenu.url));
    } else { //developer
      tabMenuKeys = tabMenuKeysFromCookie.filter(tabMenu => selectedApp.permittedMenus.find(menuItem => {
        if (alwaysShowMenuKeys.includes(tabMenu.key)) {
          return true;
        } else {
          return menuItem === tabMenu.key && !isDynamicUri(tabMenu.url);
        }
      }));
      return tabMenuKeys;
    }
  };

  isTabMenuFull = (tabMenuKeys) => {
    return tabMenuKeys.length > MAXIMUM_TAB_LENGTH;
  }
  
  menuCreation = (tabMenuKeys, currentMenu,) => {
    const currentMenuKey = currentMenu.key;
    const tabKeys = tabMenuKeys.map(menukey => menukey.key);
    if (!tabKeys.includes(currentMenuKey)) {
      tabMenuKeys = [currentMenu, ...tabMenuKeys]; // push at front
    } else {
      const tabUrls = tabMenuKeys.map(menukey => menukey.url);
      if (!tabUrls.includes(currentMenu.url)) {
        tabMenuKeys[tabKeys.indexOf(currentMenuKey)].url = currentMenu.url;
      }
    }
    if (this.isTabMenuFull(tabMenuKeys)) {
      tabMenuKeys.pop();
    }
    this.setStateAndSyncToCookie(tabMenuKeys);
  }

  addThisUriToStateAndCookie = (uri) => {
    const keyTabFromCookie = getCookie(TAB_MANAGER_COOKIE_NAME) || "[]";
    let tabMenuKeys = JSON.parse(keyTabFromCookie);

    tabMenuKeys = this.filterPermittedTabs(tabMenuKeys);
    if (uri === URL_SETTINGS_TESTBUYER_REGISTRATION) {
      const testRegistrationMenu = {key: menuKeys.SETTINGS_TESTBUYER_REGISTRATION, url: URL_SETTINGS_TESTBUYER_REGISTRATION, subMenus: null};
      this.menuCreation(tabMenuKeys, testRegistrationMenu);
    } else {
      let currentMenu = isDynamicUri(uri) ? getMenuEntityForDynamicUri(uri) : getMenuEntityByUri(uri);

      if (currentMenu) {
        this.menuCreation(tabMenuKeys, currentMenu);
      }
    }
  };

  renderTab(menu, showClose) {
    const isActive = Object.is(this.props.location.pathname, menu.url);
    const activeLink = 'nav-link active';
    const inactiveLink = 'nav-link';
    const elemStyle = isActive ? activeLink : inactiveLink;

    return (
        <li key={menu.key} className={"nav-item"}>
          <Link to={menu.url} className={elemStyle}>
            {this.props.t(menu.key) + '  '}{showClose && <button className="closed" title="close" onClick={(e) => this.handleClose(e, menu, isActive)}/>}
          </Link>
        </li>
    )
  }

  renderTabs(keys) {
    return keys.map((key) => this.renderTab(key, keys.length > 1));
  }

  render() {

    return (
        <main id="contentMain">
          <ul className="nav nav-tabs nav-content-tabs">
            {this.state.tabKeys.length !== 0 && this.renderTabs(this.state.tabKeys)}
          </ul>
          <div className="tab-content">
            {this.props.content}
          </div>
        </main>
    )
  }

  componentDidMount() {
    // init
    this.addThisUriToStateAndCookie(this.props.location.pathname);

    // listen to uri change
    this.unlisten = this.props.history.listen((location) => {
      this.addThisUriToStateAndCookie(location.pathname);
    });
  }

  componentWillUnmount() {
    // un-listen when unmount
    if (this.unlisten) {
      this.unlisten();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
    carousel: state.carousel,
  };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(TabManager)));