import React from 'react';
import {withTranslation} from 'react-i18next';

class IntervalSwitcher extends React.Component {

  render() {
    return (
        <div className="text-right mb-10">
          <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
            {this.props.intervals.map(item =>
                <label key={item.value} className={`btn btn-outline-500 rounded btn-auto ${!item.isLast && "mr-2"} tabs-in-toggle ${item.isActive && "checked"}`}>
                  <input type="radio" onClick={() => this.props.selectInterval(item.value)}/>{this.props.t(item.value)}
                </label>
            )}
          </div>
        </div>
    );
  }
}

export default (withTranslation()(IntervalSwitcher));
