import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import 'react-toastify/dist/ReactToastify.css';
import {startLoading, stopLoading} from '../../ducks/loading';
import {withRouter} from 'react-router-dom';
import {durationKey, getDefaultTimePeriod_1Month, isDateFutureThan} from "../../utils/searchBarUtils";
import SearchBar from "../searchbar/SearchBar";
import {keywordTypeCode, requestAttributes} from "../../utils/requestUtils";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {API_CSV_REQUEST_LIST, S3_PRE_SIGNED_URL} from "../../utils/urlConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {isSelectedAppChanged} from "../../ducks/carousel";
import {handleDownloadFileFromS3} from "../../utils/multipleAddModifyProductAttributes";
import {toast} from "react-toastify";

class RequestListPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      requestListPage: 1,
      totalRequestListSize: 0,
      requestListSizePerPage: 15,
      fetchRequestList: []
    }
  }

  fetchCsvList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod_1Month();
    const defaultParams = {
      dateStart: searchPeriod.startDate,
      dateEnd: searchPeriod.endDate,
    };
    this.setState({searchParams: defaultParams}, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        sortName: this.state.sortName,
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.requestListPage,
        limit: this.state.requestListSizePerPage,
        cpAppId: this.props.carousel.selectedAppId,
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_CSV_REQUEST_LIST, {
              params: params
            })
            .then(response => {
              this.setState({
                requestListPage: page,
                requestListSizePerPage: sizePerPage,
                totalRequestListSize: response.data.records,
                fetchRequestList: response.data.data,
                searchParams: params
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    })
  }
  handleSearch = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      keywordType: keywordTypeCode[searchParams.keywordOption],
      searchText: searchParams.keywordText,
      pageNumber: 1,
      limit: this.state.requestListSizePerPage,
      cpAppId: this.props.carousel.selectedAppId,
    }
    this.setState({
      searchParams: params,
      requestListPage: 1,
      sortOrder: "DESC",
      sortName: "regDate"
    }, () => {
      this.triggerSearch();
    })
  }

  triggerSearch = () => {
    const {searchParams} = this.state;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.requestListPage;
    searchParams.limit = this.state.requestListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_CSV_REQUEST_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalRequestListSize: response.data.records,
                fetchRequestList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }
  };
  handleTableSort = (sortOrder, sortField) => {
    const requestHeaderField2SortName = {
      "requestId": "requestID",
      "source": "requestSource",
      "apiCall": "apiName",
      "state": "requestState",
      "regDate": "regDate",
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: requestHeaderField2SortName[sortField] ? requestHeaderField2SortName[sortField] : sortField
    }, () => this.triggerSearch())
  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      requestListPage: page,
      requestListSizePerPage: sizePerPage,
    }, () => this.triggerSearch());
  };

  handleDownload = (requestId) => {
    let params = {
      requestID: requestId,
      cpAppId: this.props.carousel.selectedAppId,
    }
    handleDownloadFileFromS3(S3_PRE_SIGNED_URL, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  }

  componentDidMount() {
    this.fetchCsvList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchCsvList(this.state.requestListPage, this.state.requestListSizePerPage)
    }
  }

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                label: "Keyword",
                placeholder: "Please enter Keywords.",
                options: [requestAttributes.requestId.key,
                  requestAttributes.apiName.key,
                  requestAttributes.requestStatus.key
                ],
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t(requestAttributes.dates.key), // Order Date
                defaultDuration: durationKey.DURATION_ONE_MONTH,
                durationOptions: [],
                maxDate: true
              }}
              filterPanel={{
                show: false,
                label: this.props.t("FILTERS"),
                filters: []
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearch}
              onDownloadClick={this.handleDownloadClick}/>
          <DataTableWrapperForListing
              wrapperClasses="table-responsive"
              keyField="invoiceId"
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "requestId", text: this.props.t("REQUEST_ID"), headerStyle: {width: "15%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "source", text: this.props.t("SOURCE"), headerStyle: {width: "15%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "apiCall", text: this.props.t("API_CALL"), headerStyle: {width: "25%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "state", text: this.props.t("STATE"), headerStyle: {width: "15%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "regDate", text: this.props.t("REGISTER_DATE"), headerStyle: {width: "15%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "isDownloadable", text: this.props.t("IS_DOWNLOADABLE"), headerStyle: {width: "15%"}, sort: false, classes: () => "text-truncate"},
              ]}
              hideSelectColumn={true}
              page={this.state.requestListPage}
              sizePerPage={this.state.requestListSizePerPage}
              totalSize={this.state.totalRequestListSize}
              rows={this.state.fetchRequestList.map(request => {
                return {
                  ...request,
                  requestId: request.requestID,
                  source: request.requestSource,
                  apiCall: request.apiName,
                  state: request.requestState,
                  regDate: request.regDate,
                  isDownloadable: <button type="button" className="btn btn-outline-primary"
                                          disabled={request.requestState !== "COMPLETED"}
                                          onClick={() => {
                                            this.handleDownload(request.requestID)
                                          }}
                  >{this.props.t("DOWNLOAD")} </button>
                }

              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleSizePerPageChange}
          />
        </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(RequestListPanel)));
