import React, {Component} from 'react';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {startLoading, stopLoading} from '../../ducks/loading';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import SearchBar from '../searchbar/SearchBar';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {buyerRedemptionAttributes, filterTypeKey2Code} from '../../utils/buyerRedemptionAttributes';
import {toast} from 'react-toastify';
import {API_COUPON_REDEEM_LIST, API_COUPON_REDEEM_LIST_DOWNLOAD} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';

class BuyerRedemptionStatusPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      searchParams: {},
      fetchBuyerRedemptionStatusList: [],
      buyerRedemptionListPage: 0,
      buyerRedemptionListSizePerPage: 0,
      totalBuyerRedemptionListSize: 0
    }
  }

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      couponStatus: searchParams.selectedFilter[0].value ? filterTypeKey2Code[searchParams.selectedFilter[0].value] : "",
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      buyerRedemptionListPage: 1
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.cpAppId = this.props.carousel.selectedAppId;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.buyerRedemptionListPage;
    searchParams.limit = this.state.buyerRedemptionListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      this.props.startLoading();
      this.httpClient
          .get(API_COUPON_REDEEM_LIST, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              fetchBuyerRedemptionStatusList: response.data.data,
              totalBuyerRedemptionListSize: response.data.records
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  handleTableSort = (sortOrder, sortField) => {
    const buyerRedemptionHeaderField2SortName = {
      "couponCode": "couponNumber",
      "couponName": "couponName",
      "buyerEmail": "buyerEmail",
      "zip": "zipCode",
      "validPeriod": "validEndDate",
      "registeredDate": "issueDate",
      "installmentInformation": "useDate",
      "invoiceId": "invoiceId"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: buyerRedemptionHeaderField2SortName[sortField] ? buyerRedemptionHeaderField2SortName[sortField] : "",
      buyerRedemptionListPage: 1,
    }, () => this.search())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      buyerRedemptionListPage: page,
      buyerRedemptionListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  fetchDefaultBuyerRedemptionList = (page, sizePerPage) => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      pageNumber: page,
      limit: sizePerPage,
      dateStart: this.subtractOneYears().toISOString().split('T')[0],
      dateEnd: new Date().toISOString().split('T')[0]
    };
    this.props.startLoading();
    this.httpClient
        .get(API_COUPON_REDEEM_LIST, {
          params: params
        })
        .then(response => {
          this.setState({
            buyerRedemptionListPage: page,
            buyerRedemptionListSizePerPage: sizePerPage,
            searchParams: params,
            fetchBuyerRedemptionStatusList: response.data.data,
            totalBuyerRedemptionListSize: response.data.records
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  returnInstallmentInformation = (data) => {
    if (data.useDate === "-") {
      return "-";
    } else {
      if (data.couponUsedCycle === 0) {
        return data.useDate;
      } else {
        return data.couponUsedCycle + " of " + data.couponTotalCycle + " on " + data.useDate;
      }
    }
  }

  handleDownloadClick = () => {
    const {searchParams} = this.state;
    const params = {
      cpAppId: searchParams.cpAppId,
      dateStart: searchParams.dateStart ? searchParams.dateStart : this.subtractOneYears().toISOString().split('T')[0],
      dateEnd: searchParams.dateEnd ? searchParams.dateEnd : new Date().toISOString().split('T')[0]
    }
    handleDownloadFile(API_COUPON_REDEEM_LIST_DOWNLOAD, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  subtractOneYears = (date = new Date()) => {
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: [buyerRedemptionAttributes.couponCode.key, buyerRedemptionAttributes.buyerEmail.key]
              }}
              datePanel={{
                show: true,
                label: this.props.t("REGISTERED_DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: buyerRedemptionAttributes.couponStatus.key, value: buyerRedemptionAttributes.couponStatus.value}
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}
          />
          <DataTableWrapperForListing
              noSelect
              keyField="couponCode"
              hideSelectColumn={true}
              columns={[
                {dataField: "couponCode", text: this.props.t(buyerRedemptionAttributes.couponCode.key), headerStyle: {width: "9.1810%"}, sort: true},
                {dataField: "couponName", text: this.props.t(buyerRedemptionAttributes.couponName.key), headerStyle: {width: "13.1160%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "buyerEmail", text: this.props.t(buyerRedemptionAttributes.buyerEmail.key), headerStyle: {width: "15.1597%"}, sort: false},
                {dataField: "zip", text: this.props.t(buyerRedemptionAttributes.zip.key), headerStyle: {width: "5%"}, sort: false},
                {dataField: "validPeriod", text: this.props.t(buyerRedemptionAttributes.validPeriod.key), headerStyle: {width: "10.9086%"}, sort: true},
                {dataField: "registeredDate", text: this.props.t(buyerRedemptionAttributes.registeredDate.key), headerStyle: {width: "10.2267%"}, sort: true},
                {dataField: "installmentInformation", text: this.props.t(buyerRedemptionAttributes.installmentInformation.key), headerStyle: {width: "14.2267%"}, sort: true},
                {dataField: "invoiceId", text: this.props.t(buyerRedemptionAttributes.invoiceId.key), headerStyle: {width: "13.2267%"}, sort: true},
              ]}
              page={this.state.buyerRedemptionListPage}
              sizePerPage={this.state.buyerRedemptionListSizePerPage}
              totalSize={this.state.totalBuyerRedemptionListSize}
              rows={this.state.fetchBuyerRedemptionStatusList.map(item => {
                return {
                  ...item,
                  couponCode: item.couponNumber,
                  couponName: item.couponName,
                  buyerEmail: item.buyerEmail,
                  zip: item.zipCode,
                  validPeriod: item.validStartDate + "-" + item.validEndDate,
                  registeredDate: item.issueDate,
                  installmentInformation: this.returnInstallmentInformation(item),
                  invoiceId: item.invoiceId
                }
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
          />
          <div className="col text-right">
            <button type="button" className="btn btn-sm btn-auto btn-primary" onClick={() => this.handleDownloadClick()}>{this.props.t("DOWNLOAD")}</button>
          </div>
        </div>
    )
  }

  componentDidMount() {
    this.fetchDefaultBuyerRedemptionList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedContsId, prevProps.carousel.selectedContsId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultBuyerRedemptionList(1, 15);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(BuyerRedemptionStatusPanel)));
