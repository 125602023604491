import React from "react";
import {withTranslation} from 'react-i18next';
import BasePopup from './BasePopup';

export const countryKey = {
  US: "TAXANDPRICING_GUIDE2_COUNTRY_US",
  EU: "TAXANDPRICING_GUIDE2_COUNTRY_EU",
  KO: "TAXANDPRICING_GUIDE2_COUNTRY_KO",
  BR: "TAXANDPRICING_GUIDE2_COUNTRY_BR",
  AU: "TAXANDPRICING_GUIDE2_COUNTRY_AU",
  OTHER: "TAXANDPRICING_GUIDE2_COUNTRY_OTHER"
};

const countries = [
  {
    name: countryKey.US,
    text: countryKey.US + "_TEXT"
  },
  {
    name: countryKey.EU,
    text: countryKey.EU + "_TEXT"
  },
  {
    name: countryKey.KO,
    text: countryKey.KO + "_TEXT"
  },
  {
    name: countryKey.BR,
    text: countryKey.BR + "_TEXT"
  },
  {
    name: countryKey.AU,
    text: countryKey.AU + "_TEXT"
  },
  {
    name: countryKey.OTHER,
    text: countryKey.OTHER + "_TEXT"
  }
];

function CountryDropDown(props) {
  return (
      <div className={`accordion mb-10 ${props.isOpen && "show"}`}>
        <div className="accordion-header">
          {/* eslint-disable-next-line */}
          <a onClick={() => props.toggleDropDown(props.countryName)} className="questions none-before accordion-toggle">
            {props.t(props.countryName)}
          </a>
        </div>
        <div className="accordion-body pb-0 pt-6 mt-9">
          <div className="answer none-before">
            {props.t(props.countryText)}
          </div>
        </div>
      </div>
  );
}

class TaxPricingGuidePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitState()
  }

  getInitState = () => {
    const countryOpenStatus = countries.map(country => {
      return {
        key: country.name,
        isOpen: false
      };
    });
    return {
      countryOpenStatus: countryOpenStatus
    }
  };

  toggleDropDown = (element) => {
    this.setState({
      countryOpenStatus: this.state.countryOpenStatus.map((country => {
        if (country.key === element) {
          country.isOpen = !country.isOpen;
        }
        return country
      }))
    });
  };

  render() {
    return (
        <BasePopup
            className="modal-xl"
            open={this.props.open}
            title={this.props.t("TAXANDPRICING_GUIDE_TITLE")}
            onClose={() => this.props.handleModalClose(false)}
            body={
              <ol className="h3 pl-15">
                <li className="mb-40">
                  <strong>{this.props.t("TAXANDPRICING_GUIDE1_HEADING")}</strong>
                  <ul className="disc h6 pl-17 mt-15 line-height-default">
                    <li className="mb-10">{this.props.t("TAXANDPRICING_GUIDE1_TEXT1")}</li>
                    <li className="mb-10">{this.props.t("TAXANDPRICING_GUIDE1_TEXT2")}</li>
                    <li>{this.props.t("TAXANDPRICING_GUIDE1_TEXT3")}</li>
                  </ul>
                </li>
                <li className="mb-40">
                  <strong>{this.props.t("TAXANDPRICING_GUIDE2_HEADING")}</strong>
                  <div className="mt-15 h6 line-height-default">
                    {countries.map(country =>
                        <CountryDropDown key={country.name}
                                         isOpen={this.state.countryOpenStatus.filter(item => item.key === country.name)[0].isOpen}
                                         countryName={country.name}
                                         countryText={country.text}
                                         t={this.props.t}
                                         toggleDropDown={this.toggleDropDown}/>
                    )}
                  </div>
                </li>
                <li>
                  <strong>{this.props.t("TAXANDPRICING_GUIDE3_HEADING")}</strong>
                  <ul className="disc h6 pl-17 mt-15 line-height-default mb-20">
                    <li>{this.props.t("TAXANDPRICING_GUIDE3_TEXT1")}<br/>
                      {this.props.t("TAXANDPRICING_GUIDE3_TEXT2")}<br/>
                      {this.props.t("TAXANDPRICING_GUIDE3_TEXT3")}
                    </li>
                  </ul>
                  <div className="border-top border-bottom bg-100 h6 p-15 mb-20">
                    {this.props.t("TAXANDPRICING_GUIDE3_EXAMPLE")}
                    <strong>{this.props.t("TAXANDPRICING_GUIDE3_EXAMPLE_HEADING")}</strong>
                  </div>
                  <div className="row ml-0 mr-0">
                    <div className="col-6">
                      <h6 className="mb-10"><strong>{this.props.t("TAXANDPRICING_GUIDE3_CASE1_HEADING")}</strong></h6>
                      <ul className="disc h6 pl-17 line-height-default text-muted">
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE1_TEXT1")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE1_TEXT2")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE1_TEXT3")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE1_TEXT4")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE1_TEXT5")}</li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <h6 className="mb-10"><strong>{this.props.t("TAXANDPRICING_GUIDE3_CASE2_HEADING")}</strong></h6>
                      <ul className="disc h6 pl-17 line-height-default text-muted">
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE2_TEXT1")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE2_TEXT2")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE2_TEXT3")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE2_TEXT4")}</li>
                        <li>{this.props.t("TAXANDPRICING_GUIDE3_CASE2_TEXT5")}</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ol>
            }/>
    );
  }
}

export default withTranslation()(TaxPricingGuidePopup);