//
// actions
//

const TYPE_ACTION_LOAD_COUNTRIES_INFORMATION = "TYPE_ACTION_LOAD_COUNTRIES_INFORMATION";

export function loadCountriesInformation(countriesInformation) {
  return {
    type: TYPE_ACTION_LOAD_COUNTRIES_INFORMATION,
    countriesInformation: countriesInformation
  };
}

//
// reducer
//

const initialState = [];

export function countriesInformationReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE_ACTION_LOAD_COUNTRIES_INFORMATION:
      return action.countriesInformation;
    default:
      return state;
  }
}

export function getCountriesInformation(countriesInformation, country) {
  return countriesInformation.find(countryInformation => countryInformation.country === country);
}

export function getAllCountriesList(countriesInformation) {
  return countriesInformation.map(countryInformation => countryInformation.country);
}

export function getAllCurrencyList(countriesInformation) {
  return [...new Set(countriesInformation.map(countryInformation => countryInformation.currency))];
}

export function isCountriesInformationChanged(currentCountriesInformation, prevCountriesInformation) {
  return currentCountriesInformation.length !== prevCountriesInformation.length
}