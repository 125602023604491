import React from 'react';
import DatepickerxWrapper from '../pluginwrapper/DatepickerxWrapper';
import {withTranslation} from 'react-i18next';
import {durations} from '../../utils/searchBarUtils';

class DateRangePanel extends React.Component {
  render() {
    const durationPresets = this.props.durations ? this.props.durations : durations
    return (
        <div className="form-group row">
          <label className="col col-form-label">{this.props.label}</label>
          <div className="col">
            <div className="row">
              <div className={this.props.showSearch ? "col-7" : "col-8"}>
                <div className="input-group">
                  <div className="input-group-append flex-100">
                    <DatepickerxWrapper className="form-control rounded" startDate={this.props.startDatePicker} endDate={this.props.endDatePicker} maxToday={this.props.maxDate} initOneMonth={this.props.initOneMonth} isDateStart={true} onInit={(datepicker) => this.props.onDatepickerInit(true, datepicker)} duration = {this.props.selectedDuration}/>
                  </div>
                  <div className="input-group-append">
                    <span className="input-group-text bg-white border-0">~</span>
                  </div>
                  <div className="input-group-append flex-100">
                    <DatepickerxWrapper className="form-control rounded" startDate={this.props.startDatePicker} endDate={this.props.endDatePicker} maxToday={this.props.maxDate} isDateStart={false} onInit={(datepicker) => this.props.onDatepickerInit(false, datepicker)}/>
                  </div>
                </div>
              </div>
              <div className={this.props.showSearch ? "col-5" : "col-4"}>
                <div className="clearfix">
                  <div className="btn-group btn-group-toggle btn-group-radio float-left" data-toggle="buttons">
                    {durationPresets.map(duration =>
                        <label key={duration} className={`btn btn-outline-500 rounded btn-auto mr-2 ${duration === this.props.selectedDuration && "checked"}`}>
                          <input type="radio" name="options" checked={duration === this.props.selectedDuration} onChange={() => this.props.onDurationSelect(duration)}/>
                          {this.props.t(duration)}
                        </label>
                    )}
                  </div>
                  {this.props.showSearch &&
                  <div className="float-right">
                    <button className="btn btn-primary ml-10" type="button" onClick={this.props.onSearchClick}>{this.props.t("SEARCH")}</button>
                    <button type="button" className="btn btn-icon btn-refrash" onClick={this.props.onResetClick}><i className="icon icon-refrash"/></button>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withTranslation()(DateRangePanel);
