import React, {Component} from 'react';

// Placeholder for Left Nav Bar
class SHeader extends Component {
  render() {
    return (
        <header id="header">
          {this.props.children}
        </header>
    );
  }
}

export default SHeader;
