export const testBuyerAttributes = {
  testBuyerId: {
    key: 'TEST_BUYER_ID',
    value: null
  },
  testBuyerName: {
    key: 'TEST_BUYER_NAME',
    value: null
  },
  contact: {
    key: 'CONTACT',
    value: null
  },
  registrationDate: {
    key: 'REGISTRATION_DATE',
    value: null
  }
};