export const AXIOS_CLIENT_TIMEOUT = 1000 * 300;
export const DPI_PORTAL_SERVICE_ID = "3d46kc9n11";
export const ERR_CODE_GENERAL = "130998";
export const SUCCESS = "success";
export const DUPLICATE = "duplicate";
export const FREE_TRIAL_SUBSCRIPTION = "FREE_TRIAL_SUBSCRIPTION";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const DYNAMIC_PRODUCT = "DYNAMIC_PRODUCT";
export const LIMITED_PERIOD = "LIMITED_PERIOD";
export const TAX_CATEGORY_PICTURE_DOWNLOAD_TO_LOCAL_MEMORY = "0000000546"; // tax_prod_cd - "TCG001" (tax_ctg_id)
export const TAX_CATEGORY_VISUAL_OR_STREAMING_PRODUCT = "0000006158"; // tax_prod_cd -m"TCG004" (tax_ctg_id)
export const TAX_CATEGORY_PAID_ITEM_OR_APP = "0000018881"; // tax_prod_cd - "TCG005" (tax_ctg_id)
export const VALID_END_DATE_YEAR = 4999;
export const BILLING_SERVICE_START_DATE = "2015-01-01";
export const DESC_SORTING_ORDER = "DESC";
export const ASC_SORTING_ORDER = "ASC";
export const PAGE_NUMBER = 1;
export const LIMIT = 999;
export const MONTH = "M";
export const WEEK = "W";
export const DAY = "D";
export const YEAR = "Y";
export const MONTHLY = "Monthly";
export const WEEKLY = "Weekly";
export const ANNUALLY = "Annually";
export const SETTLEMENTMONTH_SORTNAME = "settlementMonth";
export const REGISTERED_DATE_SORTNAME = "registeredDate";

export const PRODUCT_TYPE_CODE = {
  CONSUMABLE: "1",
  NON_CONSUMABLE: "2",
  LIMITED_PERIOD: "3",
  SUBSCRIPTION: "4",
  PAID_APP: "5",
  FREE_TRIAL_SUBSCRIPTION: "6",
  DYNAMIC: "7",
}

export const SUBSCRIPTION_STATUS_CODE = {
  ACTIVE: "00",
  EXPIRED: "01",
  CANCELED_BY_BUYER: "02",
  CANCELED_PAYMENT_FAILURE: "03",
  CANCELED_BY_CP: "04",
  CANCELED_BY_ADMIN: "05",
  CANCELED_BY_GDPR: "06",
  CANCELED_BY_WITHDRAWAL: "07",
  CANCELED_BY_LONG_TERM_UNUSED: "08",
  CANCELED_BY_MISUSE_ID: "09",
  CANCELED_BY_BLACKLIST_ID: "10",
  TERMINATED_BY_ADMIN: "11",
}

export const SUBSCRIPTION_STATUS_CODE_CANCELLED = [
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_BUYER,
  SUBSCRIPTION_STATUS_CODE.CANCELED_PAYMENT_FAILURE,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_CP,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_ADMIN,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_GDPR,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_WITHDRAWAL,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_LONG_TERM_UNUSED,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_MISUSE_ID,
  SUBSCRIPTION_STATUS_CODE.CANCELED_BY_BLACKLIST_ID,
]

export const subscriptionStatusCodeIsCanceled = (statusCode) => {
  return SUBSCRIPTION_STATUS_CODE_CANCELLED.includes(statusCode);
}

export const subscriptionStatusCodeIsActive = (statusCode) => {
  return SUBSCRIPTION_STATUS_CODE.ACTIVE === statusCode;
}

export const subscriptionStatusCodeIsExpired = (statusCode) => {
  return SUBSCRIPTION_STATUS_CODE.EXPIRED === statusCode;
}

export const errorCode2Msg = (errorCode) => {
  if (errorCode === "130998") {
    return "ERROR_MESSAGE_GENERAL";
  } else {
    return errorCode;
  }
};

export const transformRequestOptions = params => {
  let options = '';
  for (const key in params) {
    if (typeof params[key] !== 'object' && params[key]) {
      options += `${key}=${params[key]}&`;
    } else if (typeof params[key] === 'object' && params[key] && params[key].length) {
      params[key].forEach(el => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};

export const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validAccountNumber = account_number => {
  const re = /^[a-zA-Z0-9]{4,35}$/;
  return re.test(String(account_number).toLowerCase());
}