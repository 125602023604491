import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, DPI_PORTAL_SERVICE_ID, SUCCESS} from '../../utils/globalConstants';
import BasePopup from '../popup/BasePopup';
import {getProperty} from '../../utils/propertiesByDomain';
import {URL_AFTER_SA_ACCOUNT_LOGOUT, URL_DSA_SELLER_INFORMATION, URL_GET_COMPANY_DETAIL_API, URL_INDEX_PAGE, URL_SET_CONTRACT_API} from '../../utils/urlConstants';
import Form from '../Form';
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import {triggerGetSessionData} from '../App';
import "./DsaPage.css"

class DsaPage extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.formRef = React.createRef();
    this.state = {
      authenticated: props.sessionData.isAuthenticated,
      info: null,
      dataLoaded: false,
      dsaSign: false,
      dtaSign: false,
      effectiveDate: (new Date()).toLocaleDateString()  //"02/07/2020"
    };
  }

  fetchUserCompanyData = () => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COMPANY_DETAIL_API, {
            params: {sellerGroupId: this.props.sessionData.sellerGroupId}
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              dsaSign: this.props.sessionData.dsaConsent,
              dtaSign: false,
              companyName: response.data.companyName,
              companyAddress: response.data.companyAddress,
              emailAddress: response.data.groupManagerEmail,
              infoSavedYN: response.data.infoSavedYN === 'Y'
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
            reject(error);
          })
    });
  };

  handleAlertPopupClose = (option) => {
    if (option) {
      // Open company page
      this.props.history.push(URL_DSA_SELLER_INFORMATION)
    } else {
      // signout
      this.formRef.current.submit();
    }
  };

  handleSubmitButtonClick = () => {
    // if (!this.state.infoSavedYN) {
    //  this.setState({alertModalOpen: true});
    // return;
    // }
    let params = {
      dsaYN: this.state.dsaSign ? "Y" : "N",
      dtaYN: this.state.dtaSign ? "Y" : "N"
    };
    this.props.startLoading();
    this.httpClient
        .post(URL_SET_CONTRACT_API, params)
        .then(response => {
          // we need to check whether it has apps or not, since dsa scenario is executed before normal login scenario in App.js
          if (response.data.status === SUCCESS && this.props.sessionData.apps.length) {
            triggerGetSessionData();
            this.props.history.push(URL_INDEX_PAGE);
          } else {
            // signout, since no apps or status not success
            this.formRef.current.submit();
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleCheckboxChange = (type) => {
    switch (type) {
      case 0:
        this.setState(prevState => {
          return {
            dsaSign: !prevState.dsaSign
          }
        });
        break;
      case 1:
        this.setState(prevState => {
          return {
            dtaSign: !prevState.dtaSign
          }
        });
        break;
      default:
        break;
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    this.formRef.current.submit();
  };

  render() {
    const actionUrl = getProperty("samsungAccountSignOutGateUrl");
    const clientId = DPI_PORTAL_SERVICE_ID;
    const logoutUrl = getProperty("urlHome") + URL_AFTER_SA_ACCOUNT_LOGOUT;

    if (!this.state.dataLoaded) {
      return <div/>
    }
    return (
        <>
          <div className="modal-content co-ad-content">
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLabel">Agreement</h2>
              <p className="mt-15">Updated 02/07/2020</p>
            </div>
            <div className="form-group-wrap">
              <div className="form-group">
                {!this.props.sessionData.dsaConsent &&
                    <div className="col">
                      <div className="btn-group-toggle btn-group-checkbox col ag_check01" data-toggle="buttons">
                        <label className={`btn-checkbox checkbox-text mb-15 ${this.state.dsaSign && "checked"}`}><input type="checkbox" onClick={() => this.handleCheckboxChange(0)}/>Data Sharing Agreement</label>
                      </div>
                      <div className="ag_text_area mb-15">
                        <h2 className="sharing_ag_center mb-30 mt-30 h2custom">Data Sharing Agreement</h2>
                        <p>
                          This Data Sharing Agreement (this "<strong>DSA</strong>") is between Samsung Electronics Co., Ltd., with an office at 129 Samsung-ro, Yeongtong-gu, Suwon-si, Gyeonggi-do,
                          16687 the Republic of Korea (“<strong>Samsung</strong>”) and {this.state.companyName ? <strong>{this.state.companyName}</strong> : <em className="ag_text_gray"> [ Fill in your company name ] </em>} with an office at {this.state.companyAddress ? <strong>{this.state.companyAddress}</strong> : <em className="ag_text_gray"> [
                          Fill in your company Address ] </em>}, ("<strong>Company</strong>"), and is effective as of {this.state.effectiveDate ? this.state.effectiveDate + ' ' : <em className="ag_text_gray"> [ date of clicking this agreement ] </em>}
                          ("<strong>Effective Date</strong>").
                          The individuals signing this DSA represent that they are authorized to sign on behalf of their respective companies. Company and Samsung are each a "Party,"
                          and together the "Parties."
                        </p>
                        <p>
                          This DSA is incorporated into <strong>Samsung Apps TV seller office Terms and Conditions or any related Samsung Smart TV application agreement (“Agreement”).</strong>
                          Any capitalized terms not defined in this DSA shall have the meaning stated in the Agreement.
                          Except as modified by this DSA, all terms and conditions of the Agreement shall remain in full force and effect.
                          In the event of conflict between the provisions of this DSA and those of the Agreement, the provisions of this DSA will control.
                        </p>
                        <ol>
                          <li>
                            <b> Scope, Definitions and Purposes.</b>
                            <p className="mt-10">
                              This DSA will apply to any and all data Company receives from Samsung or that Samsung transfers, shares,
                              or otherwise makes available to Company (“<strong>Samsung Personal Data</strong>”), and which are set out in Exhibit A.
                              Terms and expressions used herein that are not otherwise defined, including without limitation “personal data”, “controller”, “processing”,
                              and “processor” shall, to the extent applicable, have the meanings set forth in the privacy and data protection laws,
                              regulations, and decisions applicable to Company (“<strong>DP Laws</strong>”). Upon Samsung’s request,
                              the Parties will execute an addendum in response to new or amended DP Laws to further address the Parties’ rights and responsibilities with respect to Samsung Personal Data.
                            </p>
                          </li>
                          <li>
                            <b> Roles.</b>
                            <p className="mt-10">
                              Samsung is the sole owner of all Samsung Personal Data.
                              For the purposes of this DSA and Agreement, in relation to the Samsung Personal Data each Party (a) is an independent controller under DP Laws;
                              and (b) will individually determine the means and purposes of processing the Samsung Personal Data.
                              Company shall at all times comply with all DP Laws, including obtaining proper consent and providing appropriate notices and/or disclosures.
                              Further, Company shall process Samsung Personal Data solely and exclusively for the purposes set forth in the Agreement and for no other purpose,
                              and shall not retain Samsung Personal Data for longer than is necessary to carry out the aforementioned purposes.
                            </p>
                          </li>
                          <li>
                            <b> Security of Samsung Personal Data.</b>
                            <ul className="mt-15">
                              <li>
                                (A) Company shall at all times, in accordance with DP Laws, implement appropriate security measures, including without limitation administrative, technical,
                                and physical measures, designed to ensure the security, confidentiality, and integrity of Samsung Personal Data prior to and during processing of Samsung Personal Data.
                                Such safeguards should be commensurate with the type and amount of Samsung Personal Data being processed by Company and should, at a minimum, protect Samsung Personal
                                Data against reasonably anticipated threats or hazards, including from unauthorized access, loss, destruction, use, modification, or disclosure.
                                Prior to having access to any Samsung Personal Data, the Company shall provide Samsung with a written certification that it has implemented such a written information security program.
                              </li>
                              <li>
                                (B) Without limitation to any other obligation, Company and its employees, subcontractors, and agents shall keep the Samsung Personal Data strictly confidential.
                                Company shall ensure that any employees, subcontractors, or agents processing Samsung Personal Data are aware of this DSA and are themselves subject to a confidentiality
                                obligation no less restrictive than the confidentiality obligations set forth herein and the Agreement.
                              </li>
                              <li>
                                (C) Company shall provide to Samsung written notice of any Data Security Breach affecting Samsung Personal Data immediately upon becoming aware and in no event later than one
                                (1) business day following the occurrence of such Data Security Breach.
                                Such notice shall summarize in reasonable detail the impact of such Data Security Breach upon Samsung and
                                data subjects whose Samsung Personal Data is affected by such Data Security Breach and the corrective action to be taken by Company.
                                In the event of any Data Security Breach affecting Samsung Personal Data, Company shall also take or, at Samsung’s request,
                                assist Samsung in taking all remediation efforts that are required by applicable DP Laws as a consequence of any Data Security Breach or
                                that have been required by any governmental authority in similar circumstances, regardless of whether applicable DP Laws explicitly impose such remediation obligations
                                on Company or Samsung or both.
                                Such remediation efforts may include, without limitation
                                (a) development and delivery of notices to individuals whose Samsung Personal Data may have been affected;
                                (b) establishment of a toll-free telephone number or numbers (or where not available, a dedicated telephone number or numbers) where affected individuals may receive
                                individual-specific assistance and information relating to the Data Security Breach;
                                (c) provision of free credit reports, credit monitoring/repair and/or identity restoration/insurance for affected individuals;
                                (d) reimbursement for the costs of placing a freeze on a consumer credit file and likewise for the costs of unfreezing the Samsung consumer credit file;
                                (e) investigation and resolution of the causes and impacts of the Data Security Breach and
                                (f) such other measures that Samsung determines are reasonable and commensurate with the nature and level of severity of the Data Security Breach.
                                For the purposes of this section, “<strong>Data Security Breach</strong>” means potential or actual losses resulting from
                                (i) the loss or misuse (by any means) of Samsung Personal Data;
                                (ii) the inadvertent, unauthorized, and/or unlawful processing, disclosure, access, alteration, corruption, transfer, sale, rental, destruction, or use of Samsung Personal Data; or
                                (iii) any other act or omission that compromises or may compromise the security, confidentiality, or integrity of Samsung Personal Data.
                              </li>
                            </ul>
                          </li>
                          <li className="mt-15">
                            <b> Cooperation.</b>
                            <p className="mt-10">
                              Company shall promptly give written notice to and/or fully cooperate with Samsung:
                            </p>
                            <ul>
                              <li>
                                (A) if Company cannot comply, or has not complied, with any portion of this DSA, including the reasons for noncompliance;
                              </li>
                              <li>
                                (B) if Company has breached, or if processing of Samsung Personal Data were to continue would breach, any DP Laws,
                                in such case Company will take all reasonable and appropriate steps to remedy such noncompliance,
                                or cease further processing of Samsung Personal Data and Samsung may terminate the Agreement immediately,
                                cease access to Samsung Personal Data, and/or take any other reasonable action;
                              </li>
                              <li>
                                (C) if Company receives any complaint, inquiry, or request from a data subject or governmental or regulatory authority regarding Samsung Personal Data,
                                in which case Company shall notify Samsung within one
                                (1) business day from receipt of such complaint, inquiry, or request. Company shall not respond to such third party or any third party for or
                                on behalf of Samsung unless Samsung provides express written permission.
                              </li>
                              <li>
                                (D) relating to any obligations Samsung may have under applicable DP laws, including without limitation responding to regulatory or governmental authorities or data subjects.
                              </li>
                              <li>
                                (E) Company shall provide Samsung with all necessary materials, documents, and any other information to enable Samsung to confirm that Company has complied
                                with its obligations under this DSA.
                                Company `shall also allow Samsung, or an auditor mandated by Samsung, at any time and at Company’s sole cost and expense, to carry out audits to determine
                                whether or not any Samsung Personal Data is being or has been processed in compliance with DP Laws and the terms of this DSA, and Company shall comply with such request.
                                Following completion of any audit conducted pursuant to this DSA,
                                Samsung shall have the right to notify Company in writing of any alleged risks or threats identified during such audit
                                and/or non-conformance to generally accepted trade practice in the industry (each a “Security Issue”).
                                To the extent that such Security Issues exist and are Company’s responsibility, Company shall, within ten (10) days of receipt of such written notification,
                                either correct such Security Issues or provide Samsung with a plan acceptable to Samsung for remediating the Security Issues.
                                If (i) the Security Issues are not corrected,
                                or (ii) if an acceptable plan for correcting them is not agreed to during such period,
                                or (iii) if an acceptable plan is not executed according to its schedule, in addition to any other rights and remedies available to Samsung, Samsung may,
                                by giving Company written notice thereof, immediately terminate this DSA and/or the Agreement, in whole or in part,
                                and demand from Company a pro rata of any fees paid or payable under the Agreement, which Company shall deliver to Samsung within thirty (30) days.
                              </li>
                            </ul>
                          </li>
                          <li className="mt-15">
                            <b> Data Sharing.</b>
                            <ul>
                              <li>
                                (A) Company shall not share, transfer, or otherwise make available the Samsung Personal Data to a third party without the express written permission of Samsung.
                                In the event express written permission has been granted under this section, Company shall not share, transfer, or otherwise make available the Samsung Personal Data
                                without ensuring that adequate and equivalent protections, including without limitations any requirements under DP Laws and this DSA,
                                are afforded to the Samsung Personal Data.
                                Company shall enter into written, valid and enforceable agreements with its processors or contractors, with such agreements including terms that are the same as
                                the obligations applicable to Samsung Personal Data as contained in this DSA prior to the sharing of Samsung Personal Data (“<strong>Third Party Agreement</strong>”).
                              </li>
                              <li>
                                (B) If requested by Samsung in order to enable Samsung to comply with any DP Laws,
                                Company will execute a supplementary addendum to this DSA in a form acceptable to Samsung(such addendum being a “<strong>Transfer Agreement</strong>”)
                                to offer sufficient data protection safeguards in relation to any transfer of Personal Data out of the jurisdiction from
                                which the applicable Samsung Personal Data is collected.
                                For Samsung Personal Data on which the General Data Protection Regulation applies, in accordance with DP Laws, the model contract, as set out in Exhibit B,
                                in relation to any transfer of Samsung Personal Data out of the European Economic Area, the United Kingdom or Switzerland shall apply,
                                unless the Samsung Personal Data will be transferred to a country which is considered to provide an adequate level of protection for the Samsung Personal Data
                                under DP Laws.
                                Company shall have the right to propose, in good faith, alternatives to executing any agreements contemplated herein and the Parties agree to discuss in good faith
                                any such alternatives prior to Company being required to execute any such agreement, provided however, Samsung shall have the sole option to decline any such alternatives.
                              </li>
                            </ul>
                          </li>
                          <li className="mt-15">
                            <b> Indemnification.</b>
                            <ul>
                              <li>
                                (A) Company shall indemnify, defend, and hold harmless Samsung, its affiliates, and each of their respective officers, directors, employees,
                                and agents (collectively, the “<strong>Samsung Indemnitees</strong>”) from and against any and all costs, charges, damages, expenses, penalties, fines,
                                fees (including without limitation reasonable attorney’s fees) and losses (including without limitation fees and costs incurred in recovering the same) incurred by
                                any Samsung Indemnitee that arises from Company’s negligence, gross negligence or willful misconduct, or a breach by Company or any of its employees, processors,
                                or agents of this DSA or DP Laws, or a breach by Company’s processors, contractors, or agents of any Third Party Agreements.
                              </li>
                              <li>
                                (B) The rights and remedies of Samsung under this DSA shall not be subject to any limitation of actions, arbitration,
                                or any other limiting provisions set forth in the Agreement, including conditions of force majeure.
                                Without limiting the generality of the foregoing, and notwithstanding anything herein nor in the Agreement to the contrary,
                                (i) there shall be no exclusions or limitations of Company’s liability arising under this DSA, and
                                (ii) Samsung shall not be precluded from immediately pursuing any rights or remedies it may have under or relating to this DSA.
                              </li>
                            </ul>
                          </li>
                          <li className="mt-15">
                            <b> Miscellaneous.</b>
                            <p>
                              This DSA shall be governed by the same governing laws as that of the Agreement.
                              All disputes, controversies or claims between the parties arising out of or in connection with this DSA(including its existence, validity or termination)
                              shall be finally resolved in the same manner as the manner in which the Agreement is resolved.
                            </p>
                            <p>
                              IN WITNESS WHEREOF, the Parties hereto have caused this Data Sharing Agreement to be executed by their duly authorized representatives as of the Effective Date.
                            </p>
                          </li>
                        </ol>
                        <p className="sharing_ag_center mt-100"><strong>EXHIBIT A</strong></p>
                        <p className="sharing_ag_center">List of Samsung Personal Data</p>
                        <p>Samsung Personal Data to be shared, transferred or otherwise made available to Company include:</p>
                        <p>The Samsung Personal Data concern the following categories of personal data:</p>
                        <p>Invoice ID, Product ID, Product name, Buyer’s email address (i.e., Samsung Account ID), Buyer
                          UID, Order Customer ID, country and location, Zip Code, currency, payment amount, payment
                          method, additional payment information (e.g., coupon code, coupon amount, gift card amount,
                          transaction status and transaction type), reflection status, order date, refund, and refund date.</p>
                        <p>The Samsung Personal Data concern the following categories of sensitive personal data:</p>
                        <p>N/A</p>
                      </div>
                    </div>}
                {this.props.sessionData.sellerServiceEU &&
                    <div className="col">
                      <div className="btn-group-toggle btn-group-checkbox col ag_check01" data-toggle="buttons">
                        <label className={`btn-checkbox checkbox-text mb-15 ${this.state.dtaSign && "checked"}`}><input type="checkbox" onClick={() => this.handleCheckboxChange(1)}/>EXHIBIT B. Standard Contractual Clauses Exhibit B applies when you process EU personal data to provide the service in Europe
                          and data transfer occurs from one jurisdiction to another.
                        </label>
                      </div>
                      <div className="ag_text_area">
                        <h2 className="sharing_ag_center mb-30 mt-30 h2custom"><strong>EXHIBIT B</strong></h2>
                        <p className="sharing_ag_center">
                          <strong>
                            Standard contractual clauses for the transfer of personal data from the Community to third countries
                            (controller to controller transfers)
                            Data transfer agreement
                          </strong>
                        </p>
                        <p className="sharing_ag_center">between<br/>
                          <strong>Samsung Electronics Co., Ltd</strong>.(“Samsung”)<br/>
                          a company existing under the laws of the Republic of Korea, with its place of business at 129 Samsung-ro, Yeongtong-gu, Suwon-si, Gyeonggi-do, 443-772 Korea
                        </p>
                        <p className="sharing_ag_center ag_text_lh">hereinafter “data exporter”<br/>and<br/>
                          {this.state.companyName ? <strong>{this.state.companyName}</strong> : <em className="ag_text_gray"> [ Fill in your company name ] </em>} with an office at
                          {this.state.companyAddress ? <strong>{' ' + this.state.companyAddress}</strong> : <em className="ag_text_gray"> [ Fill in your company address ] </em>}<br/>hereinafter “data importer”<br/>each a “party”; together “the parties”.
                        </p>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">SECTION I</h2></strong>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 1</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Purpose and scope</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) The purpose of these standard contractual clauses is to ensure compliance with the
                            requirements of Regulation (EU) 2016/679 of the European Parliament and of the
                            Council of 27 April 2016 on the protection of natural persons with regard to the
                            processing of personal data and on the free movement of such data (General Data
                            Protection Regulation) (1) for the transfer of personal data to a third country.
                          </li>
                          <li>
                            (b) The Parties:
                            <li class="internallist">
                              (i) the natural or legal person(s), public authority/ies, agency/ies or other body/ies
                              (hereinafter ‘entity/ies’) transferring the personal data, as listed in Annex I.A
                              (hereinafter each ‘data exporter’), and
                            </li>
                            <li class="internallist">
                              (ii) the entity/ies in a third country receiving the personal data from the data exporter,
                              directly or indirectly via another entity also Party to these Clauses, as listed in
                              Annex I.A (hereinafter each ‘data importer’)
                            </li>
                          </li>
                          <li class="internallist">
                            have agreed to these standard contractual clauses (hereinafter: ‘Clauses’).
                          </li>
                          <li>
                            (c) These Clauses apply with respect to the transfer of personal data as specified in Annex I.B.
                          </li>
                          <li>
                            (d) The Appendix to these Clauses containing the Annexes referred to therein forms an integral part of
                            these Clauses.

                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 2</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Effect and invariability of the Clauses</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) These Clauses set out appropriate safeguards, including enforceable data subject rights
                            and effective legal remedies, pursuant to Article 46(1) and Article 46(2)(c) of Regulation
                            (EU) 2016/679 and, with respect to data transfers from controllers to processors and/or
                            processors to processors, standard contractual clauses pursuant to Article 28(7) of
                            Regulation (EU) 2016/679, provided they are not modified, except to select the
                            appropriate Module(s) or to add or update information in the Appendix. This does not
                            prevent the Parties from including the standard contractual clauses laid down in these
                            Clauses in a wider contract and/or to add other clauses or additional safeguards, provided
                            that they do not contradict, directly or indirectly, these Clauses or prejudice the
                            fundamental rights or freedoms of data subjects.
                          </li>
                          <li>
                            (b) These Clauses are without prejudice to obligations to which the data exporter is subject
                            by virtue of Regulation (EU) 2016/679.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 3</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Third-party beneficiaries</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) Data subjects may invoke and enforce these Clauses, as third-party beneficiaries, against
                            the data exporter and/or data importer, with the following exceptions:

                            <li class="internallist">
                              (i) Clause 1, Clause 2, Clause 3, Clause 6, Clause 7;
                            </li>
                            <li class="internallist">
                              (ii) Clause 8.5 (e) and Clause 8.9(b);
                            </li>
                            <li class="internallist">
                              (iii) N/A
                            </li>
                            <li class="internallist">
                              (iv) Clause 12(a) and (d);
                            </li>
                            <li class="internallist">
                              (v) Clause 13;
                            </li>
                            <li class="internallist">
                              (vi) Clause 15.1(c), (d) and (e);
                            </li>
                            <li class="internallist">
                              (vii) Clause 16(e);
                            </li>
                            <li class="internallist">
                              (viii) Clause 18(a) and (b).
                            </li>
                          </li>
                          <li>
                            (b) Paragraph (a) is without prejudice to rights of data subjects under Regulation (EU)
                            2016/679.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 4</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Interpretation</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) Where these Clauses use terms that are defined in Regulation (EU) 2016/679, those terms
                            shall have the same meaning as in that Regulation.
                          </li>
                          <li>
                            (b) These Clauses shall be read and interpreted in the light of the provisions of Regulation
                            (EU) 2016/679.
                          </li>
                          <li>
                            (c) These Clauses shall not be interpreted in a way that conflicts with rights and obligations
                            provided for in Regulation (EU) 2016/679.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 5</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Hierarchy</h2></strong>
                        <p>
                          <ul class="ulcustom">
                            <li>
                              In the event of a contradiction between these Clauses and the provisions of related agreements
                              between the Parties, existing at the time these Clauses are agreed or entered into thereafter, these
                              Clauses shall prevail.
                            </li>
                          </ul>
                        </p>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 6</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Description of the transfer(s)</h2></strong>
                        <p>
                          <ul class="ulcustom">
                            <li>
                              The details of the transfer(s), and in particular the categories of personal data that are transferred
                              and the purpose(s) for which they are transferred, are specified in Annex I.B.
                            </li>
                          </ul>
                        </p>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 7 – Optional</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Docking clause</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) An entity that is not a Party to these Clauses may, with the agreement of the Parties,
                            accede to these Clauses at any time, either as a data exporter or as a data importer, by
                            completing the Appendix and signing Annex I.A.
                          </li>
                          <li>
                            (b) Once it has completed the Appendix and signed Annex I.A, the acceding entity shall
                            become a Party to these Clauses and have the rights and obligations of a data exporter or
                            data importer in accordance with its designation in Annex I.A.
                          </li>
                          <li>
                            (c) The acceding entity shall have no rights or obligations arising under these Clauses from
                            the period prior to becoming a Party.
                          </li>
                        </ul>
                        <br></br>
                        <br></br>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">SECTION II – OBLIGATIONS OF THE PARTIES</h2></strong>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 8</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Data protection safeguards</h2></strong>
                        <p>
                          <ul class="ulcustom">
                            <li>
                              The data exporter warrants that it has used reasonable efforts to determine that the data importer
                              is able, through the implementation of appropriate technical and organisational measures, to
                              satisfy its obligations under these Clauses.
                            </li>
                          </ul>
                        </p>
                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.1 Purpose limitation</h2></strong>
                            The data importer shall process the personal data only for the specific purpose(s) of the
                            transfer, as set out in Annex I.B. It may only process the personal data for another purpose:

                            <li class="internallist">
                              (i) where it has obtained the data subject’s prior consent;
                            </li>
                            <li class="internallist">
                              (ii) where necessary for the establishment, exercise or defence of legal claims in the
                              context of specific administrative, regulatory or judicial proceedings; or
                            </li>
                            <li class="internallist">
                              (iii) where necessary in order to protect the vital interests of the data subject or of
                              another natural person.
                            </li>
                          </li>
                        </ul>
                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.2 Transparency</h2></strong>
                            (a) In order to enable data subjects to effectively exercise their rights pursuant to
                            Clause 10, the data importer shall inform them, either directly or through the data
                            exporter:

                            <li class="internallist">
                              (i) of its identity and contact details;
                            </li>
                            <li class="internallist">
                              (ii) of the categories of personal data processed;
                            </li>
                            <li class="internallist">
                              (iii) of the right to obtain a copy of these Clauses;
                            </li>
                            <li class="internallist">
                              (iv) where it intends to onward transfer the personal data to any third party/ies,
                              of the recipient or categories of recipients (as appropriate with a view to
                              providing meaningful information), the purpose of such onward transfer
                              and the ground therefore pursuant to Clause 8.7.
                            </li>
                          </li>
                          <li>
                            (b) Paragraph (a) shall not apply where the data subject already has the information,
                            including when such information has already been provided by the data exporter,
                            or providing the information proves impossible or would involve a
                            disproportionate effort for the data importer. In the latter case, the data importer
                            shall, to the extent possible, make the information publicly available.
                          </li>
                          <li>
                            (c) On request, the Parties shall make a copy of these Clauses, including the
                            Appendix as completed by them, available to the data subject free of charge. To
                            the extent necessary to protect business secrets or other confidential information,
                            including personal data, the Parties may redact part of the text of the Appendix
                            prior to sharing a copy, but shall provide a meaningful summary where the data
                            subject would otherwise not be able to understand its content or exercise his/her
                            rights. On request, the Parties shall provide the data subject with the reasons for
                            the redactions, to the extent possible without revealing the redacted information.
                          </li>
                          <li>
                            (d) Paragraphs (a) to (c) are without prejudice to the obligations of the data exporter
                            under Articles 13 and 14 of Regulation (EU) 2016/679.
                          </li>
                        </ul>


                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.3 Accuracy and data minimisation</h2></strong>

                            (a) Each Party shall ensure that the personal data is accurate and, where necessary,
                            kept up to date. The data importer shall take every reasonable step to ensure that
                            personal data that is inaccurate, having regard to the purpose(s) of processing, is
                            erased or rectified without delay.
                          </li>
                          <li>
                            (b) If one of the Parties becomes aware that the personal data it has transferred or
                            received is inaccurate, or has become outdated, it shall inform the other Party
                            without undue delay.
                          </li>
                          <li>
                            (c) The data importer shall ensure that the personal data is adequate, relevant and
                            limited to what is necessary in relation to the purpose(s) of processing.
                          </li>
                        </ul>

                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.4 Storage limitation</h2></strong>

                            The data importer shall retain the personal data for no longer than necessary for the
                            purpose(s) for which it is processed. It shall put in place appropriate technical or
                            organisational measures to ensure compliance with this obligation, including erasure or
                            anonymisation (2) of the data and all back-ups at the end of the retention period.
                          </li>
                        </ul>

                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.5 Security of processing</h2></strong>

                            (a) The data importer and, during transmission, also the data exporter shall
                            implement appropriate technical and organisational measures to ensure the
                            security of the personal data, including protection against a breach of security
                            leading to accidental or unlawful destruction, loss, alteration, unauthorised
                            disclosure or access (hereinafter ‘personal data breach’). In assessing the
                            appropriate level of security, they shall take due account of the state of the art, the
                            costs of implementation, the nature, scope, context and purpose(s) of processing
                            and the risks involved in the processing for the data subject. The Parties shall in
                            particular consider having recourse to encryption or pseudonymisation, including
                            during transmission, where the purpose of processing can be fulfilled in that
                            manner.
                          </li>
                          <li>
                            (b) The Parties have agreed on the technical and organisational measures set out in
                            Annex II. The data importer shall carry out regular checks to ensure that these
                            measures continue to provide an appropriate level of security.
                          </li>
                          <li>
                            (c) The data importer shall ensure that persons authorised to process the personal data
                            have committed themselves to confidentiality or are under an appropriate
                            statutory obligation of confidentiality.
                          </li>
                          <li>
                            (d) In the event of a personal data breach concerning personal data processed by the
                            data importer under these Clauses, the data importer shall take appropriate
                            measures to address the personal data breach, including measures to mitigate its
                            possible adverse effects.
                          </li>
                          <li>
                            (e) In case of a personal data breach that is likely to result in a risk to the rights and
                            freedoms of natural persons, the data importer shall without undue delay notify
                            both the data exporter and the competent supervisory authority pursuant to Clause
                            13. Such notification shall contain i) a description of the nature of the breach
                            (including, where possible, categories and approximate number of data subjects
                            and personal data records concerned), ii) its likely consequences, iii) the measures
                            taken or proposed to address the breach, and iv) the details of a contact point from
                            whom more information can be obtained. To the extent it is not possible for the
                            data importer to provide all the information at the same time, it may do so in
                            phases without undue further delay.
                          </li>
                          <li>
                            (f) In case of a personal data breach that is likely to result in a high risk to the rights
                            and freedoms of natural persons, the data importer shall also notify without undue
                            delay the data subjects concerned of the personal data breach and its nature, if
                            necessary in cooperation with the data exporter, together with the information
                            referred to in paragraph (e), points ii) to iv), unless the data importer has
                            implemented measures to significantly reduce the risk to the rights or freedoms of
                            natural persons, or notification would involve disproportionate efforts. In the
                            latter case, the data importer shall instead issue a public communication or take a
                            similar measure to inform the public of the personal data breach.
                          </li>
                          <li>
                            (g) The data importer shall document all relevant facts relating to the personal data
                            breach, including its effects and any remedial action taken, and keep a record
                            thereof.
                          </li>
                        </ul>

                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.6 Sensitive data</h2></strong>

                            <p>
                              Where the transfer involves personal data revealing racial or ethnic origin, political
                              opinions, religious or philosophical beliefs, or trade union membership, genetic data, or
                              biometric data for the purpose of uniquely identifying a natural person, data concerning
                              health or a person’s sex life or sexual orientation, or data relating to criminal convictions
                              or offences (hereinafter ‘sensitive data’), the data importer shall apply specific
                              restrictions and/or additional safeguards adapted to the specific nature of the data and the
                              risks involved. This may include restricting the personnel permitted to access the
                              personal data, additional security measures (such as pseudonymisation) and/or additional
                              restrictions with respect to further disclosure.
                            </p>
                          </li>
                        </ul>
                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.7 Onward transfers</h2></strong>

                            <p>
                              The data importer shall not disclose the personal data to a third party located outside the
                              European Union (3) (in the same country as the data importer or in another third country,
                              hereinafter ‘onward transfer’) unless the third party is or agrees to be bound by these
                              Clauses, under the appropriate Module. Otherwise, an onward transfer by the data
                              importer may only take place if:
                            </p>

                            <li class="internallist">
                              (i) it is to a country benefitting from an adequacy decision pursuant to Article 45 of
                              Regulation (EU) 2016/679 that covers the onward transfer;
                            </li>
                            <li class="internallist">
                              (ii) the third party otherwise ensures appropriate safeguards pursuant to Articles 46 or
                              47 of Regulation (EU) 2016/679 with respect to the processing in question;
                            </li>
                            <li class="internallist">
                              (iii) the third party enters into a binding instrument with the data importer ensuring the
                              same level of data protection as under these Clauses, and the data importer
                              provides a copy of these safeguards to the data exporter;
                            </li>
                            <li class="internallist">
                              (iv) it is necessary for the establishment, exercise or defence of legal claims in the
                              context of specific administrative, regulatory or judicial proceedings;
                            </li>
                            <li class="internallist">
                              (v) it is necessary in order to protect the vital interests of the data subject or of
                              another natural person; or
                            </li>
                            <li class="internallist">
                              (vi) where none of the other conditions apply, the data importer has obtained the
                              explicit consent of the data subject for an onward transfer in a specific situation,
                              after having informed him/her of its purpose(s), the identity of the recipient and
                              the possible risks of such transfer to him/her due to the lack of appropriate data
                              protection safeguards. In this case, the data importer shall inform the data exporter
                              and, at the request of the latter, shall transmit to it a copy of the information
                              provided to the data subject.
                            </li>
                            <li class="internallist">
                              Any onward transfer is subject to compliance by the data importer with all the other
                              safeguards under these Clauses, in particular purpose limitation.
                            </li>

                          </li>
                        </ul>
                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.8 Processing under the authority of the data importer</h2></strong>
                            <p>
                              The data importer shall ensure that any person acting under its authority, including a
                              processor, processes the data only on its instructions.
                            </p>
                          </li>
                        </ul>
                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">8.9 Documentation and compliance</h2></strong>
                            <ul class="ulcustom">
                              <li>
                                (a) Each Party shall be able to demonstrate compliance with its obligations under
                                these Clauses. In particular, the data importer shall keep appropriate
                                documentation of the processing activities carried out under its responsibility.
                              </li>
                              <li>
                                (b) The data importer shall make such documentation available to the competent
                                supervisory authority on request.
                              </li>
                            </ul>
                          </li>

                        </ul>

                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 9</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Use of sub-processors</h2></strong>
                        <p>
                          N/A
                        </p>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 10</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Data subject rights</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) The data importer, where relevant with the assistance of the data exporter, shall deal with
                            any enquiries and requests it receives from a data subject relating to the processing of
                            his/her personal data and the exercise of his/her rights under these Clauses without undue
                            delay and at the latest within one month of the receipt of the enquiry or request. (4) The
                            data importer shall take appropriate measures to facilitate such enquiries, requests and the
                            exercise of data subject rights. Any information provided to the data subject shall be in an
                            intelligible and easily accessible form, using clear and plain language.
                          </li>
                          <li>
                            (b) In particular, upon request by the data subject the data importer shall, free of charge:
                            <li class="internallist">
                              (i) provide confirmation to the data subject as to whether personal data concerning
                              him/her is being processed and, where this is the case, a copy of the data relating
                              to him/her and the information in Annex I; if personal data has been or will be
                              onward transferred, provide information on recipients or categories of recipients
                              (as appropriate with a view to providing meaningful information) to which the
                              personal data has been or will be onward transferred, the purpose of such onward
                              transfers and their ground pursuant to Clause 8.7; and provide information on the
                              right to lodge a complaint with a supervisory authority in accordance with Clause 12(c)(i);
                            </li>
                            <li class="internallist">
                              (ii) rectify inaccurate or incomplete data concerning the data subject;
                            </li>
                            <li class="internallist">
                              (iii) erase personal data concerning the data subject if such data is being or has been
                              processed in violation of any of these Clauses ensuring third-party beneficiary
                              rights, or if the data subject withdraws the consent on which the processing is
                              based.
                            </li>
                          </li>
                          <li>
                            (c) Where the data importer processes the personal data for direct marketing purposes, it
                            shall cease processing for such purposes if the data subject objects to it.
                          </li>
                          <li>
                            (d) The data importer shall not make a decision based solely on the automated processing of
                            the personal data transferred (hereinafter ‘automated decision’), which would produce
                            legal effects concerning the data subject or similarly significantly affect him/her, unless
                            with the explicit consent of the data subject or if authorised to do so under the laws of the
                            country of destination, provided that such laws lays down suitable measures to safeguard
                            the data subject’s rights and legitimate interests. In this case, the data importer shall,
                            where necessary in cooperation with the data exporter:
                            <li class="internallist">
                              (i) inform the data subject about the envisaged automated decision, the envisaged
                              consequences and the logic involved; and
                            </li>
                            <li class="internallist">
                              (ii) implement suitable safeguards, at least by enabling the data subject to contest the
                              decision, express his/her point of view and obtain review by a human being.
                            </li>
                          </li>
                          <li>
                            (e) Where requests from a data subject are excessive, in particular because of their repetitive
                            character, the data importer may either charge a reasonable fee taking into account the
                            administrative costs of granting the request or refuse to act on the request.
                          </li>
                          <li>
                            (f) The data importer may refuse a data subject’s request if such refusal is allowed under the
                            laws of the country of destination and is necessary and proportionate in a democratic
                            society to protect one of the objectives listed in Article 23(1) of Regulation (EU)
                            2016/679.
                          </li>
                          <li>
                            (g) If the data importer intends to refuse a data subject’s request, it shall inform the data
                            subject of the reasons for the refusal and the possibility of lodging a complaint with the
                            competent supervisory authority and/or seeking judicial redress.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 11</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Redress</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) The data importer shall inform data subjects in a transparent and easily accessible format,
                            through individual notice or on its website, of a contact point authorised to handle
                            complaints. It shall deal promptly with any complaints it receives from a data subject.
                          </li>
                          <li>
                            (b) In case of a dispute between a data subject and one of the Parties as regards compliance
                            with these Clauses, that Party shall use its best efforts to resolve the issue amicably in a
                            timely fashion. The Parties shall keep each other informed about such disputes and,
                            where appropriate, cooperate in resolving them.
                          </li>
                          <li>
                            (c) Where the data subject invokes a third-party beneficiary right pursuant to Clause 3, the
                            data importer shall accept the decision of the data subject to:
                            <li class="internallist">
                              (i) lodge a complaint with the supervisory authority in the Member State of his/her
                              habitual residence or place of work, or the competent supervisory authority
                              pursuant to Clause 13;
                            </li>
                            <li class="internallist">
                              (ii) refer the dispute to the competent courts within the meaning of Clause 18.
                            </li>
                          </li>
                          <li>
                            (d) The Parties accept that the data subject may be represented by a not-for-profit body,
                            organisation or association under the conditions set out in Article 80(1) of Regulation
                            (EU) 2016/679.
                          </li>
                          <li>
                            (e) The data importer shall abide by a decision that is binding under the applicable EU or
                            Member State law.
                          </li>
                          <li>
                            (f) The data importer agrees that the choice made by the data subject will not prejudice
                            his/her substantive and procedural rights to seek remedies in accordance with applicable laws.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 12</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Liability</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) Each Party shall be liable to the other Party/ies for any damages it causes the other
                            Party/ies by any breach of these Clauses.
                          </li>
                          <li>
                            (b) Each Party shall be liable to the data subject, and the data subject shall be entitled to
                            receive compensation, for any material or non-material damages that the Party causes the
                            data subject by breaching the third-party beneficiary rights under these Clauses. This is
                            without prejudice to the liability of the data exporter under Regulation (EU) 2016/679.
                          </li>
                          <li>
                            (c) Where more than one Party is responsible for any damage caused to the data subject as a
                            result of a breach of these Clauses, all responsible Parties shall be jointly and severally
                            liable and the data subject is entitled to bring an action in court against any of these
                            Parties.
                          </li>
                          <li>
                            (d) The Parties agree that if one Party is held liable under paragraph (c), it shall be entitled to
                            claim back from the other Party/ies that part of the compensation corresponding to
                            its/their responsibility for the damage.
                          </li>
                          <li>
                            (e) The data importer may not invoke the conduct of a processor or sub-processor to avoid its
                            own liability.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 13</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Supervision</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) [Where the data exporter is not established in an EU Member State, but falls within the
                            territorial scope of application of Regulation (EU) 2016/679 in accordance with its
                            Article 3(2) and has appointed a representative pursuant to Article 27(1) of Regulation
                            (EU) 2016/679:] The supervisory authority of the Member State in which the
                            representative within the meaning of Article 27(1) of Regulation (EU) 2016/679 is
                            established, as indicated in Annex I.C, shall act as competent supervisory authority.
                          </li>
                          <li>
                            (b) The data importer agrees to submit itself to the jurisdiction of and cooperate with the
                            competent supervisory authority in any procedures aimed at ensuring compliance with
                            these Clauses. In particular, the data importer agrees to respond to enquiries, submit to
                            audits and comply with the measures adopted by the supervisory authority, including
                            remedial and compensatory measures. It shall provide the supervisory authority with
                            written confirmation that the necessary actions have been taken.
                          </li>
                          <br></br>
                          <li>
                            <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">SECTION III – LOCAL LAWS AND OBLIGATIONS IN CASE OF ACCESS BY
                              PUBLIC AUTHORITIES</h2></strong>
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 14</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Local laws and practices affecting compliance with the Clauses</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) The Parties warrant that they have no reason to believe that the laws and practices in the
                            third country of destination applicable to the processing of the personal data by the data
                            importer, including any requirements to disclose personal data or measures authorising
                            access by public authorities, prevent the data importer from fulfilling its obligations
                            under these Clauses. This is based on the understanding that laws and practices that
                            respect the essence of the fundamental rights and freedoms and do not exceed what is
                            necessary and proportionate in a democratic society to safeguard one of the objectives
                            listed in Article 23(1) of Regulation (EU) 2016/679, are not in contradiction with these
                            Clauses.
                          </li>
                          <li>
                            (b) The Parties declare that in providing the warranty in paragraph (a), they have taken due
                            account in particular of the following elements:
                            <li class="internallist">
                              (i) the specific circumstances of the transfer, including the length of the processing
                              chain, the number of actors involved and the transmission channels used; intended
                              onward transfers; the type of recipient; the purpose of processing; the categories
                              and format of the transferred personal data; the economic sector in which the
                              transfer occurs; the storage location of the data transferred;
                            </li>
                            <li class="internallist">
                              (ii) the laws and practices of the third country of destination– including those
                              requiring the disclosure of data to public authorities or authorising access by such
                              authorities – relevant in light of the specific circumstances of the transfer, and the
                              applicable limitations and safeguards (5);
                            </li>
                            <li class="internallist">
                              (iii) any relevant contractual, technical or organisational safeguards put in place to
                              supplement the safeguards under these Clauses, including measures applied
                              during transmission and to the processing of the personal data in the country of
                              destination.
                            </li>
                          </li>
                          <li>
                            (c) The data importer warrants that, in carrying out the assessment under paragraph (b), it has
                            made its best efforts to provide the data exporter with relevant information and agrees
                            that it will continue to cooperate with the data exporter in ensuring compliance with these
                            Clauses.
                          </li>
                          <li>
                            (d) The Parties agree to document the assessment under paragraph (b) and make it available
                            to the competent supervisory authority on request.
                          </li>
                          <li>
                            (e) The data importer agrees to notify the data exporter promptly if, after having agreed to
                            these Clauses and for the duration of the contract, it has reason to believe that it is or has
                            become subject to laws or practices not in line with the requirements under paragraph (a),
                            including following a change in the laws of the third country or a measure (such as a
                            disclosure request) indicating an application of such laws in practice that is not in line
                            with the requirements in paragraph (a).
                          </li>
                          <li>
                            (f) Following a notification pursuant to paragraph (e), or if the data exporter otherwise has
                            reason to believe that the data importer can no longer fulfil its obligations under these
                            Clauses, the data exporter shall promptly identify appropriate measures (e.g. technical or
                            organisational measures to ensure security and confidentiality) to be adopted by the data
                            exporter and/or data importer to address the situation. The data exporter shall suspend the
                            data transfer if it considers that no appropriate safeguards for such transfer can be
                            ensured, or if instructed by the competent supervisory authority to do so. In this case, the
                            data exporter shall be entitled to terminate the contract, insofar as it concerns the
                            processing of personal data under these Clauses. If the contract involves more than two
                            Parties, the data exporter may exercise this right to termination only with respect to the
                            relevant Party, unless the Parties have agreed otherwise. Where the contract is terminated
                            pursuant to this Clause, Clause 16(d) and (e) shall apply.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 15</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Obligations of the data importer in case of access by public authorities</h2></strong>
                        <ul class="ulcustom">

                          <li>
                            <strong><h3 class="h3custom">15.1 Notification</h3></strong>
                            (a) The data importer agrees to notify the data exporter and, where possible, the data
                            subject promptly (if necessary with the help of the data exporter) if it:
                            <li class="internallist">
                              (i) receives a legally binding request from a public authority, including
                              judicial authorities, under the laws of the country of destination for the
                              disclosure of personal data transferred pursuant to these Clauses; such
                              notification shall include information about the personal data requested,
                              the requesting authority, the legal basis for the request and the response
                              provided; or
                            </li>
                            <li class="internallist">
                              (ii) becomes aware of any direct access by public authorities to personal data
                              transferred pursuant to these Clauses in accordance with the laws of the\
                              country of destination; such notification shall include all information
                              available to the importer.
                            </li>
                          </li>
                          <li>
                            (b) If the data importer is prohibited from notifying the data exporter and/or the data
                            subject under the laws of the country of destination, the data importer agrees to
                            use its best efforts to obtain a waiver of the prohibition, with a view to
                            communicating as much information as possible, as soon as possible. The data
                            importer agrees to document its best efforts in order to be able to demonstrate
                            them on request of the data exporter.
                          </li>
                          <li>
                            (c) Where permissible under the laws of the country of destination, the data importer
                            agrees to provide the data exporter, at regular intervals for the duration of the
                            contract, with as much relevant information as possible on the requests received
                            (in particular, number of requests, type of data requested, requesting authority/ies,
                            whether requests have been challenged and the outcome of such challenges, etc.).
                          </li>
                          <li>
                            (d) The data importer agrees to preserve the information pursuant to paragraphs (a) to
                            (c) for the duration of the contract and make it available to the competent
                            supervisory authority on request.
                          </li>
                          <li>
                            (e) Paragraphs (a) to (c) are without prejudice to the obligation of the data importer
                            pursuant to Clause 14(e) and Clause 16 to inform the data exporter promptly
                            where it is unable to comply with these Clauses.
                          </li>


                          <li>
                            <br></br>
                            <strong><h3 class="h3custom">15.2 Review of legality and data minimisation</h3></strong>
                            (a) The data importer agrees to review the legality of the request for disclosure, in
                            particular whether it remains within the powers granted to the requesting public
                            authority, and to challenge the request if, after careful assessment, it concludes
                            that there are reasonable grounds to consider that the request is unlawful under the
                            laws of the country of destination, applicable obligations under international law
                            and principles of international comity. The data importer shall, under the same
                            conditions, pursue possibilities of appeal. When challenging a request, the data
                            importer shall seek interim measures with a view to suspending the effects of the
                            request until the competent judicial authority has decided on its merits. It shall not
                            disclose the personal data requested until required to do so under the applicable
                            procedural rules. These requirements are without prejudice to the obligations of
                            the data importer under Clause 14(e).
                          </li>
                          <li>
                            (b) The data importer agrees to document its legal assessment and any challenge to
                            the request for disclosure and, to the extent permissible under the laws of the
                            country of destination, make the documentation available to the data exporter. It
                            shall also make it available to the competent supervisory authority on request.
                          </li>
                          <li>
                            (c) The data importer agrees to provide the minimum amount of information permissible
                            when responding to a request for disclosure, based on a reasonable
                            interpretation of the request.
                          </li>
                        </ul>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">SECTION IV – FINAL PROVISIONS</h2></strong>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 16</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Non-compliance with the Clauses and termination</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) The data importer shall promptly inform the data exporter if it is unable to comply with
                            these Clauses, for whatever reason.
                          </li>
                          <li>
                            (b) In the event that the data importer is in breach of these Clauses or unable to comply with
                            these Clauses, the data exporter shall suspend the transfer of personal data to the data
                            importer until compliance is again ensured or the contract is terminated. This is without
                            prejudice to Clause 14(f).
                          </li>
                          <li>
                            (c) The data exporter shall be entitled to terminate the contract, insofar as it concerns the
                            processing of personal data under these Clauses, where:
                            <li class="internallist">
                              (i) the data exporter has suspended the transfer of personal data to the data importer
                              pursuant to paragraph (b) and compliance with these Clauses is not restored
                              within a reasonable time and in any event within one month of suspension;
                            </li>
                            <li class="internallist">
                              (ii) the data importer is in substantial or persistent breach of these Clauses; or
                            </li>
                            <li class="internallist">
                              (iii) the data importer fails to comply with a binding decision of a competent court or
                              supervisory authority regarding its obligations under these Clauses.
                              In these cases, it shall inform the competent supervisory authority of such non-
                              compliance. Where the contract involves more than two Parties, the data exporter may
                              exercise this right to termination only with respect to the relevant Party, unless the Parties
                              have agreed otherwise.
                            </li>
                          </li>
                          <li>
                            (d) Personal data that has been transferred prior to the termination of the contract pursuant to
                            paragraph (c) shall at the choice of the data exporter immediately be returned to the data
                            exporter or deleted in its entirety. The same shall apply to any copies of the data. The
                            data importer shall certify the deletion of the data to the data exporter. Until the data is
                            deleted or returned, the data importer shall continue to ensure compliance with these
                            Clauses. In case of local laws applicable to the data importer that prohibit the return or
                            deletion of the transferred personal data, the data importer warrants that it will continue
                            to ensure compliance with these Clauses and will only process the data to the extent and
                            for as long as required under that local law.
                          </li>
                          <li>
                            (e) Either Party may revoke its agreement to be bound by these Clauses where (i)
                            the European Commission adopts a decision pursuant to Article 45(3) of Regulation (EU)
                            2016/679 that covers the transfer of personal data to which these Clauses apply; or (ii)
                            Regulation (EU) 2016/679 becomes part of the legal framework of the country to which
                            the personal data is transferred. This is without prejudice to other obligations applying to
                            the processing in question under Regulation (EU) 2016/679.
                          </li>
                        </ul>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 17</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Governing law</h2></strong>
                        <p>
                          <ul class="ulcustom">
                            <li>
                              These Clauses shall be governed by the law of one of the EU Member States, provided such law
                              allows for third-party beneficiary rights. The Parties agree that this shall be the law of Ireland.
                            </li>
                          </ul>
                        </p>
                        <strong><h3 class="sharing_ag_center mb-30 mt-30 h3custom">Clause 18</h3></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">Choice of forum and jurisdiction</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            (a) Any dispute arising from these Clauses shall be resolved by the courts of an EU Member
                            State.
                          </li>
                          <li>
                            (b) The Parties agree that those shall be the courts of Ireland.
                          </li>
                          <li>
                            (c) A data subject may also bring legal proceedings against the data exporter and/or data
                            importer before the courts of the Member State in which he/she has his/her habitual
                            residence.
                          </li>
                          <li>
                            (d) The Parties agree to submit themselves to the jurisdiction of such court.
                          </li>

                        </ul>

                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                          Classified
                        </p>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">ANNEX I</h2></strong>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">A. LIST OF PARTIES</h2></strong>

                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">Data exporter(s): </h2></strong>
                            <h2 class="h2custom">As specified in Exhibit B</h2>
                          </li>
                          <br></br>
                          <br></br>
                          <li>
                            <strong><h2 class="h2custom">Data importer(s): </h2></strong>
                            <h2 class="h2custom">As specified in Exhibit B</h2>
                          </li>
                        </ul>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>Classified</p>
                        <strong><h2 class="sharing_ag_center mb-30 mt-30 h2custom">B. DESCRIPTION OF TRANSFER</h2></strong>
                        <br></br>
                        <ul class="ulcustom">
                          <li>
                            <strong><h2 class="h2custom">Data subjects</h2></strong>
                            The personal data transferred concern the following categories of data subjects :
                            Samsung Smart TV device users who manage transactions with Samsung
                          </li>
                          <li>
                            <strong><h2 class="h2custom">Purposes of the transfer(s)</h2></strong>
                            The transfer is made for the following purposes : To let Company participate in Samsung’s Partner
                            -on-boarding program
                          </li>
                          <li>
                            <strong><h2 class="h2custom">Categories of data</h2></strong>

                            The personal data transferred concern the following categories of data:
                            The personal data outlined in Exhibit A of the DSA.
                          </li>
                          <li>
                            <strong><h2 class="h2custom">Recipients</h2></strong>

                            The personal data transferred may be disclosed only to the following recipients or categories of
                            recipients :
                            Data importer’s authorized personnel who have a reasonable need to know the information in
                            order to fulfill the purposes of the Agreement and any other third parties consistent with this DSA.
                          </li>
                          <li>
                            <strong><h2 class="h2custom">Sensitive data(if appropriate)</h2></strong>

                            The personal data transferred concern the following categories of sensitive data :
                            The sensitive personal data outlined Exhibit A of the DSA, if any.
                          </li>
                        </ul>
                        <br></br>
                        <br></br>
                        <strong><h2 class=" mb-30 mt-30 h2custom">C. COMPETENT SUPERVISORY AUTHORITY</h2></strong>
                        <ul class="ulcustom">
                          <li>
                            <h4>As defined in Clause 13.</h4>
                            <hr className="endline"></hr>
                          </li>
                        </ul>
                      </div>
                    </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button type="button" className="btn btn-outline-primary col-3 ag_bottom_btn" onClick={this.submitForm}>{this.props.t("CANCEL")}<br/>(You cannot use DPI site)</button>
                <button type="button" className="btn btn-primary ml-10 col-3 ag_bottom_btn" disabled={!(this.state.dsaSign && !(this.props.sessionData.sellerServiceEU && !this.state.dtaSign))} onClick={() => this.handleSubmitButtonClick()}>{this.props.t("AGREE")}</button>
              </div>
            </div>
            <div className="row mt-15">
              <div className="col">
                <p className="ag_text_red">BY CLICKING THE "I AGREE" BUTTON, YOU ACKNOWLEDGE THAT YOU HAVE REVIEWED AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT AND EXHIBIT B.</p>
              </div>
            </div>
          </div>
          <BasePopup
              open={this.state.alertModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleAlertPopupClose(false)}
              body={
                <p>{this.props.t("COMPANY_INFO_REQUIRED")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleAlertPopupClose(true)}>
                    {this.props.t("YES")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleAlertPopupClose(false)}>
                    {this.props.t("DECLINE")}
                  </button>
                </>
              }/>
          <Form method="get" action={actionUrl} ref={this.formRef}>
            <input type="hidden" name="client_id" value={clientId}/>
            <input type="hidden" name="signOutURL" value={logoutUrl}/>
            <input type="hidden" name="state" value={this.props.sessionData.csrfToken}/>
          </Form>
        </>
    )
  }

  componentDidMount() {
    this.fetchUserCompanyData();
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(DsaPage)));