import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import SearchBar from "../searchbar/SearchBar";
import {invoiceAttributes} from "../../utils/invoiceAttributes";
import {connect} from "react-redux";
import {isCountriesInformationChanged} from "../../ducks/countriesInformation";
import {isSelectedAppChanged} from "../../ducks/carousel";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {bizModelCode2Key, bizModelKey2Code, settlementAttributes, settlementPaymentMethodCode2Key, settlementPaymentMethodKey2Code} from "../../utils/settlementReportAttributes";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {API_MONTHLY_SETTLEMENT_REPORT, API_MONTHLY_SETTLEMENT_REPORT_DOWNLOAD} from "../../utils/urlConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from '../../ducks/loading';
import {convertToCountryCode, sortCountryNames} from '../../utils/CountryListUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';

class SettlementDetailPanel extends Component {
  constructor(props) {
    super(props);

    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      pageNumber: 1,
      listSizePerPage: 15,
      totalListSize: 0,
      modalOpen: false,
      settlementReportList: [],
      countries: [],
      bizModelCode: '',
      paymentMethod: '',
      dateStart: ''
    }
  }

  fetchSettlementReport = (page, sizePerPage) => {

    const params = {
      dateStart: this.state.dateStart,
      lang: this.props.i18n.language,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_MONTHLY_SETTLEMENT_REPORT, {
            params: params
          })
          .then(response => {
            this.setState({
              pageNumber: page,
              listSizePerPage: sizePerPage,
              totalListSize: response.data.length,
              settlementReportList: response.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  getRequestParams = (searchParams) => {
    return {
      dateStart: this.state.dateStart,
      lang: this.props.i18n.language,
      // countries: this.state.countries,
      countries: searchParams.selectedFilter[0].value ? searchParams.selectedFilter[0].value : [],
      bizModelCode: searchParams.selectedFilter[1].value ? bizModelKey2Code[searchParams.selectedFilter[1].value] : "",
      paymentMethodCodes: searchParams.selectedFilter[2].value ? settlementPaymentMethodKey2Code[searchParams.selectedFilter[2].value] : ""
    };
  };
  handleSearchSettlementReport = (searchParams) => {

    const params = this.getRequestParams(searchParams);
    const countryCode = convertToCountryCode(this.props.countriesInformation,params.countries,this.props.t);
    if(countryCode.length) params.countries =  countryCode;
    this.setState({
      searchParams: params,
      pageNumber: 1,
    }, () => {
      const {searchParams} = this.state;
      searchParams.pageNumber = this.state.pageNumber;
      searchParams.rowsPerPage = this.state.listSizePerPage;
      searchParams.sortName = this.state.sortName;
      searchParams.sortOrder = this.state.sortOrder;

      if (searchParams.pageNumber && searchParams.rowsPerPage) {
        return new Promise((resolve, reject) => {
          this.props.startLoading();
          this.httpClient
              .get(API_MONTHLY_SETTLEMENT_REPORT, {
                params: searchParams
              })
              .then(response => {
                this.setState({
                  totalListSize: response.data.length,
                  settlementReportList: response.data
                });
                this.props.stopLoading();
              })
              .catch(error => {
                handleHttpError(this.props.t, this.props.history, error);
                reject(error);
                this.props.stopLoading();
              })
        });
      }
    });
  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      pageNumber: page,
      listSizePerPage: sizePerPage,
    });
  };
  handleTableSort = (sortOrder, sortField) => {
    const settlementHeaderField2SortName = {
      "countryName": "countryName",
      "sellCount": "sellCount",
      "productName": "productName",
      "cancelCount": "cancelCount",
      "totalSalesAmount": "paymentCurrencyBaseSaleSumAmount",
      "totalCancelAmount": "paymentCurrencyBaseCancelSumAmount",
      "cpDiscountAmount": "paymentCurrencyBaseCpDiscountAmount",
      "vdDiscountAmount": "paymentCurrencyBaseVdDiscountAmount",
      "cpCouponAmount": "paymentCurrencyBaseCpCouponAmount",
      "vdCouponAmount": "paymentCurrencyBaseVdCouponAmount",
      "totalTaxAmount": "paymentCurrencyBaseTotalTaxAmount",
      "totalPayoutAmount": "paymentCurrencyBaseTotalPaymentAmount",
      "totalSettlementAmount": "paymentCurrencyBaseTotalSettlementAmount",
      "rsCPAmount": "paymentCurrencyBaseCpRsTotalAmount",
      "rsVDAmount": "paymentCurrencyBaseVdRsTotalAmount",
      "totalCpPgFeeAmount": "paymentCurrencyBaseCpPgFeeAmount"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: settlementHeaderField2SortName[sortField] ? settlementHeaderField2SortName[sortField] : "",
      productListPage: 1,
    }, () => this.fetchSettlementReport())
  };

  handleDownloadClick = (searchParams) => {
    const params = this.getRequestParams(searchParams);
    handleDownloadFile(API_MONTHLY_SETTLEMENT_REPORT_DOWNLOAD, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  componentDidMount() {
    this.setState({dateStart: this.props.match.params.settlementMonth},
        () => this.fetchSettlementReport(1, 15));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId) || isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)) {
      this.fetchSettlementReport(1, 15);
    }
  }

  render() {
    if (this.props.countriesInformation.length === 0) {
      return (<></>);
    }
    return (
        <div className="tab-pane active" id="nav-home" role="tabpanel">
          <SearchBar
              datePanel={{
                show: false,
              }}
              filterPanel={{
                show: true,
                showSearch: true,
                showDownload: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: invoiceAttributes.country.key, value: sortCountryNames(this.props.countriesInformation,this.props.t)}, //['A', 'B', 'C']
                  {key: settlementAttributes.taxHandlingType.key, value: settlementAttributes.taxHandlingType.value},
                  {key: settlementAttributes.paymentMethod.key, value: settlementAttributes.paymentMethod.value},
                ]
              }}
              keywordPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchSettlementReport}
              onDownloadClick={this.handleDownloadClick}/>
          <DataTableWrapperForListing
              wrapperClasses="table-responsive"
              tableStyle="table-width2000"
              keyField="randomKey" // one of 'dataField'
              hideSelectColumn={true}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "randomKey", hidden: true},
                {dataField: "countryName", text: this.props.t("COUNTRY"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "bizModelDisplay", text: this.props.t("TAX_HANDLING_TYPE"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "productName", text: this.props.t("PRODUCT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "paymentMethod", text: this.props.t("PAYMENT_METHOD"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "rsRate", text: this.props.t("CP_R_S_RATE"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "pgFeeHolder", text: this.props.t("PG_FEE_HOLDER"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "sellCount", text: this.props.t("SALES_COUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "cancelCount", text: this.props.t("CANCEL_COUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "targetDate", text: this.props.t("SETTLEMENT_PERIOD"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "priceStandard", text: this.props.t("CURRENCY_TYPE"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "totalSalesAmount", text: this.props.t("TOTAL_SALES_AMOUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "totalCancelAmount", text: this.props.t("TOTAL_CANCEL_AMOUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "cpDiscountAmount", text: this.props.t("CP_DISCOUNT_AMOUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "vdDiscountAmount", text: this.props.t("VD_DISCOUNT_AMOUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "cpCouponAmount", text: this.props.t("CP_COUPON_AMOUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "vdCouponAmount", text: this.props.t("VD_COUPON_AMOUNT"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "totalTaxAmount", text: this.props.t("TAX"), headerStyle: {width: "5.8665%"}, sort: true},
                {dataField: "totalPayoutAmount", text: this.props.t("TOTAL_PAYMENT_AMOUNT"), headerStyle: {width: "5.9335%"}, sort: true},
                {dataField: "totalSettlementAmount", text: this.props.t("TOTAL_SETTLEMENT_AMOUNT"), headerStyle: {width: "5.9335%"}, sort: true},
                {dataField: "rsCPAmount", text: this.props.t("RS_CP_AMOUNT"), headerStyle: {width: "5.9335%"}, sort: true},
                {dataField: "rsVDAmount", text: this.props.t("RS_VD_AMOUNT"), headerStyle: {width: "5.9335%"}, sort: true},
                {dataField: "totalCpPgFeeAmount", text: this.props.t("TRANSACTION_FEE_TOTAL"), headerStyle: {width: "5.9335%"}, sort: true},
              ]}
              page={this.state.pageNumber}
              sizePerPage={this.state.listSizePerPage}
              totalSize={this.state.totalListSize}
              rows={this.state.settlementReportList.map(settlementData => {
                return {
                  ...settlementData,
                  randomKey: settlementData.countryCode && settlementData.bizModelCode && settlementData.paymentMethodCode && settlementData.firstPaymentDate ?
                      settlementData.countryCode.concat(settlementData.bizModelCode).concat(settlementData.paymentMethodCode).concat(settlementData.firstPaymentDate) : Math.random,
                  bizModelDisplay: this.props.t(bizModelCode2Key[settlementData.bizModelCode]),
                  paymentMethod: this.props.t(settlementPaymentMethodCode2Key[settlementData.paymentMethodCode]),
                  priceStandard: <>{this.props.t("PAYOUT_CURRENCY")}<br/>({settlementData.paymentCurrencyCode})</>,
                  targetDate: <>{settlementData.firstPaymentDate}<br/>~<br/> {settlementData.lastPaymentDate}</>
                };
              })}
              onTableChange={this.fetchSettlementReport}
              onTableSort={this.handleTableSort}
              onSizePerPageChange={this.handleSizePerPageChange}/>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SettlementDetailPanel)));