import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {URL_ADD_TESTBUYER_API, URL_DELETE_TESTBUYER_API, URL_GET_TESTBUYER_CHECK_ID_API, URL_GET_TESTBUYER_DETAIL_API, URL_MODIFY_TESTBUYER_API, URL_SETTINGS_TESTBUYER} from '../../utils/urlConstants';
import {startLoading, stopLoading} from '../../ducks/loading';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, SUCCESS, transformRequestOptions} from '../../utils/globalConstants';
import BasePopup from '../popup/BasePopup';
import {toast} from 'react-toastify';
import {handleHttpError} from '../../utils/ErrorUtils';

class TestBuyerAddModifyPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      alertModalOpen: false,
      title: "",
      alertMessage: "",
      alertContext: 0, // POPUP,CANCEL, SAVE
      testBuyerEmail: "",
      testBuyerName: "",
      testBuyerGuid: "",
      contact: "",
      registrationDate: "",
      isValidEmail: null,
      isDataLoaded: false,
    };
    this.Context = {
      DELETE: 1,
      CANCEL: 2,
      SAVE: 3
    };
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
  }

  handleAlertPopupClose = (isSave, context) => {
    this.setState({alertModalOpen: false, previewPopupOpen: false});
    if (!isSave) {
      return;
    }
    switch (context) {
      case this.Context.SAVE:
        this.saveTestBuyer();
        break;
      case this.Context.DELETE:
        this.deleteTestBuyer();
        break;
      case this.Context.CANCEL:
        this.cancelTestBuyer();
        break;
      default :
        console.log("not possible");
    }
  };

  getTestBuyerDetail = (testBuyerId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_TESTBUYER_DETAIL_API, {
            params: {
              testBuyerId: testBuyerId,
            }
          })
          .then(response => {
            this.setState({
              isDataLoaded: true,
              testBuyerEmail: response.data.testBuyerEmail,
              testBuyerGuid: response.data.testBuyerGuid,
              testBuyerName: response.data.testBuyerName,
              contact: response.data.testBuyerTelephone,
              registrationDate: response.data.registeredDate,
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          })
    });
  };

  handleDeleteClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "DO_YOU_WANT_TO_DELETE",
      alertContext: this.Context.DELETE
    })
  };

  handleSaveClick = () => {
    // TODO validations
    if (!this.props.isModify && !this.state.isValidEmail) {
      toast(this.props.t("CHECK_SAMSUNG_ACCOUNT_REGISTRATION_FIRST"));
      return;
    }
    let testBuyerNameFlag = false;
    if (this.state.testBuyerName) {
      if (Object.is(this.state.testBuyerName.trim(), "")) {
        testBuyerNameFlag = true;
      }
    } else {
      testBuyerNameFlag = true;
    }
    if (testBuyerNameFlag) {
      toast(this.props.t("TEST_BUYER_NAME_NOT_VALID"));
      return;
    }

    this.setState({
      alertModalOpen: true,
      alertMessage: this.props.isModify ? "DO_YOU_WANT_TO_SAVE_THE_CHANGES" : "DO_YOU_WANT_TO_REGISTER_THE_INFORMATION",
      alertContext: this.Context.SAVE
    })
  };

  componentDidMount() {
    if (this.props.isModify) {
      this.getTestBuyerDetail(this.props.match.params.testBuyerId);
    } else {
      this.setState({isDataLoaded: true})
    }
  }

  saveTestBuyer = () => {
    let params = {
      testBuyerEmail: this.state.testBuyerEmail.trim(),
      testBuyerName: this.state.testBuyerName.trim(),
      testBuyerTelephone: this.state.contact.trim(),
      cpAppId: this.props.carousel.selectedAppId,
    };
    return new Promise((resolve, reject) => {
      if (this.props.isModify) {
        params.testBuyerGuid = this.state.testBuyerGuid;
        params.testBuyerId = this.props.match.params.testBuyerId;
        this.props.startLoading();
        this.httpClient
            .post(URL_MODIFY_TESTBUYER_API, params)
            .then(response => {
              if (response.data.status !== SUCCESS) {
                toast(this.props.t("TESTBUYER_MODIFY_ALERT_FAIL"));
              } else {
                toast(this.props.t("TESTBUYER_MODIFY_ALERT_SUCCESS"));
                this.props.history.push(URL_SETTINGS_TESTBUYER);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
            })
      } else {
        this.props.startLoading();
        this.httpClient
            .post(URL_ADD_TESTBUYER_API, params)
            .then(response => {
              if (response.data.status !== SUCCESS) {
                toast(this.props.t("TESTBUYER_CREATE_ALERT_FAIL"));
              } else {
                toast(this.props.t("TESTBUYER_CREATE_ALERT_SUCCESS"));
                this.props.history.push(URL_SETTINGS_TESTBUYER);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
            });
      }
    })
  };

  deleteTestBuyer = () => {
    this.props.startLoading();
    this.httpClient
        .post(URL_DELETE_TESTBUYER_API, [this.props.match.params.testBuyerId])
        .then(response => {
          if (response.data.status !== SUCCESS) {
            toast(this.props.t("TESTBUYER_DELETE_ALERT_FAIL"));
          } else {
            toast(this.props.t("TESTBUYER_DELETE_ALERT_SUCCESS"));
            this.props.history.push(URL_SETTINGS_TESTBUYER);
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  cancelTestBuyer = () => {
    this.props.history.push(URL_SETTINGS_TESTBUYER);
  };

  checkAccount = () => {
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_TESTBUYER_CHECK_ID_API, {
          params: {
            testBuyerEmail: this.state.testBuyerEmail,
            cpAppId: this.props.carousel.selectedAppId,
          },
          paramsSerializer: transformRequestOptions
        })
        .then(response => {
          if (response.data.testBuyerEmailCheck === "ok") {
            this.setState({isValidEmail: true});
          } else {
            this.setState({isValidEmail: false});
            if (response.data.testBuyerEmailCheck === "alreadyExists") {
              toast(this.props.t("TESTBUYER_ALREADY_EXISTS"));
            } else if (response.data.testBuyerEmailCheck === "noAccount") {
              toast(this.props.t("TESTBUYER_NO_ACCOUNT"));
            }
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  render() {
    if (!this.state.isDataLoaded) {
      return <div/>
    }
    return (
        <div className="tab-pane active" role="tabpanel">
          <form className="needs-validation_" noValidate>
            <div className="clearfix mb-20">
              <h2 className="float-left mb-0"><strong>{this.props.isModify ? this.props.t("TESTBUYER_DETAILS") : this.props.t("TESTBUYER_REGISTRATION")}</strong></h2>
              <div className="float-right">
                <span className="text-success small mr-6"> <span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}</span>
              </div>
            </div>
            <div className="form-group-wrap">
              <div className="row mb-3">
                <div className="col">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TEST_BUYER_ID")}<span className="text-success align-middle">*</span></label>
                    {!this.props.isModify &&
                    <div className="col">
                      <div className="input-group">
                        <input type="text"
                               value={this.state.testBuyerEmail}
                               className="form-control col-6"
                               placeholder={this.props.t("TEST_BUYER_EMAIL_PLACEHOLDER")}
                               onChange={(event) => this.setState({testBuyerEmail: event.target.value, isValidEmail: null})}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" disabled={!this.state.testBuyerEmail} type="button" onClick={() => this.checkAccount()}>{this.props.t("CHECK_ACCOUNT_REGISTRATION")}</button>
                        </div>
                        <div className="col-6">
                          {this.state.isValidEmail === true &&
                          <span className="text-success mt-10 d-inline-block"><i className="icon icon-check align-middle mb-1"/>{this.props.t("EMAIL_ID_VALID")}</span>
                          }
                          {this.state.isValidEmail === false &&
                          <span className="text-danger mt-10 d-inline-block"><i className="icon icon-error align-middle mb-1"/>{this.props.t("EMAIL_ID_INVALID")}</span>
                          }
                        </div>
                      </div>
                      <span className="text-muted">※ {this.props.t("ONLY_SAMSUNG_ACCOUNT_TESTBUYER")}</span>
                    </div>}
                    {this.props.isModify &&
                    <div className="col">
                      <div className="input-group">
                        <input type="text" className="form-control col-5" placeholder="Test Buyer ID를 입력해주세요." value={this.state.testBuyerEmail} readOnly/>
                        <div className="input-group-append">
                          <div className="col-3 mt-10">
                            <span className="text-muted">※ {this.props.t("ONLY_SAMSUNG_ACCOUNT_TESTBUYER")}</span>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TEST_BUYER_NAME")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             value={this.state.testBuyerName}
                             className={`form-control col-${this.props.isModify ? "5" : "6"}`}
                             placeholder={this.props.t("TEST_BUYER_NAME_PLACEHOLDER")}
                             onChange={(event) => this.setState({testBuyerName: event.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className={`${this.props.isModify ? "col-6" : "col"}`}>
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("CONTACT")}</label>
                    <div className="col">
                      <input type="text"
                             value={this.state.contact}
                             className={`form-control ${this.props.isModify ? "" : "col-6"}`}
                             placeholder={this.props.t("TEST_BUYER_CONTACT_PLACEHOLDER")}
                             onChange={(event) => this.setState({contact: event.target.value})}
                      />
                    </div>
                  </div>
                </div>
                {this.props.isModify &&
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("REGISTRATION_DATE")}</label>
                    <div className="col">
                      <input type="text" className="form-control" value={this.state.registrationDate} readOnly/>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
            <div className="pagenationGroup clearfix max">
              <div className="float-left">
                {!this.props.isModify &&
                <p className="text-muted">※ {this.props.t("TEST_BUYER_FOOTER")} </p>}
                {this.props.isModify &&
                <button type="button" className="btn btn-sm btn-outline-primary ml-6" onClick={() => this.handleDeleteClick()}>{this.props.t("DELETE")}</button>}
              </div>
              <div className="float-right">
                <button type="button" className="btn btn-sm btn-outline-primary ml-6" onClick={() => this.props.history.push(URL_SETTINGS_TESTBUYER)}>{this.props.t("LIST")}</button>
                <button type="button" className="btn btn-sm btn-primary ml-6" onClick={() => this.handleSaveClick()}>{this.props.isModify ? this.props.t("SAVE") : this.props.t("REGISTER")}</button>
              </div>
            </div>
          </form>
          <BasePopup
              open={this.state.alertModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleAlertPopupClose(false)}
              body={
                <p>{this.props.t(this.state.alertMessage)}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleAlertPopupClose(true, this.state.alertContext)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleAlertPopupClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TestBuyerAddModifyPanel)));