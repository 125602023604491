//
// actions
//

const TYPE_ACTION_LOAD_SESSION_DATA = "TYPE_ACTION_LOAD_SESSION_DATA";
const TYPE_ACTION_SELECT_TNB_BAR_APP = "TYPE_ACTION_SELECT_TNB_BAR_APP";
const TYPE_ACTION_SINGED_IN = "TYPE_ACTION_SINGED_IN";
// const TYPE_ACTION_SINGED_OUT = "TYPE_ACTION_SINGED_OUT";
const TYPE_ACTION_SET_DEFAULT_COUNTRY = "TYPE_ACTION_SET_DEFAULT_COUNTRY";

export function loadSessionData(guid, emailAddress, sellerGroupId, apps, isAuthenticated, csrfToken, requestFromSellerOffice,
    isBankInfoSaved, sellerServiceEU, isContractSkipByAdmin, dsaConsent, dtaConsent, isManager, isSuperUser) {
  return {
    type: TYPE_ACTION_LOAD_SESSION_DATA,
    guid: guid,
    emailAddress: emailAddress,
    sellerGroupId: sellerGroupId,
    apps: apps,
    isAuthenticated: isAuthenticated,
    csrfToken: csrfToken,
    requestFromSellerOffice: requestFromSellerOffice,
    isBankInfoSaved: isBankInfoSaved,
    sellerServiceEU: sellerServiceEU,
    isContractSkipByAdmin: isContractSkipByAdmin,
    dsaConsent: dsaConsent,
    dtaConsent: dtaConsent,
    isManager: isManager,
    isSuperUser: isSuperUser
  };
}

export function updateDefaultCountry(countryCode, appId) {
  return {
    type: TYPE_ACTION_SET_DEFAULT_COUNTRY,
    countryCode: countryCode,
    appId: appId
  };
}

export function selectTobNaviBarApp(app) {
  return {
    type: TYPE_ACTION_SELECT_TNB_BAR_APP,
    app: app
  };
}

export function userSignedIn() {
  return {
    type: TYPE_ACTION_SINGED_IN, isAuthenticated: true
  };
}

export function userSignedOut() {
  return {
    type: TYPE_ACTION_SINGED_IN, isAuthenticated: false
  };
}

//
// reducer
//

const initialState = {
  guid: null,
  emailAddress: null,
  sellerGroupId: null,
  isAuthenticated: false,
  selectedTobNaviBarApp: null,
  apps: [],
  csrfToken: null,
  requestFromSellerOffice: false,
  dsaConsent: false,
  isManager: false,
  isSuperUser: false
};

export function sessionDataReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE_ACTION_LOAD_SESSION_DATA:
      return {
        ...state,
        guid: action.guid,
        emailAddress: action.emailAddress,
        sellerGroupId: action.sellerGroupId,
        apps: action.apps,
        isAuthenticated: action.isAuthenticated,
        csrfToken: action.csrfToken,
        requestFromSellerOffice: action.requestFromSellerOffice,
        isBankInfoSaved: action.isBankInfoSaved,
        sellerServiceEU: action.sellerServiceEU,
        isContractSkipByAdmin: action.isContractSkipByAdmin,
        dsaConsent: action.dsaConsent,
        dtaConsent: action.dtaConsent,
        isManager: action.isManager,
        isSuperUser: action.isSuperUser
      };
    case TYPE_ACTION_SELECT_TNB_BAR_APP:
      if (state.apps.length && state.apps.find(e => e === action.app)) {
        throw new Error("selected app should be one of state.apps array element in SessionData");
      }
      return {...state, selectedTobNaviBarApp: action.app};
    case TYPE_ACTION_SINGED_IN:
      return {...state, isAuthenticated: action.isAuthenticated};
      // case TYPE_ACTION_SINGED_OUT:
    case TYPE_ACTION_SET_DEFAULT_COUNTRY:
      return {
        ...state,
        apps: state.apps.map((e) => {
          if (e.appId === action.appId) {
            e.defaultCountry = action.countryCode;
          }
          return e;
        })
      };
    default:
      return state;
  }
}

//
// Utils
//

export function getAppFromSessionData(sessionData, appId) {
  return sessionData.apps.find(app => app.appId === appId);
}