import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {ASC_SORTING_ORDER, AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {isSelectedAppChanged} from '../../ducks/carousel';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import SupportFooter from '../SupportFooter';
import {handleHttpError} from "../../utils/ErrorUtils";
import {API_SUPPORT_ERROR_LIST} from "../../utils/urlConstants";
import {startLoading, stopLoading} from '../../ducks/loading';

class ErrorCodePanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      errorCodeListPage: 0,
      totalErrorCodeListSize: 0,
      errorCodeListSizePerPage: 0,
      fetchedErrorCodeList: [],
      searchParams: {},
    }
  }

  fetchDefaultErrorCodeList = (page, sizePerPage) => {
    const params = {
      langCode: this.props.i18n.language,
      sortOrder: ASC_SORTING_ORDER,
      pageNumber: page,
      limit: sizePerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUPPORT_ERROR_LIST, {
            params: params
          })
          .then(response => {
            this.setState({
              errorCodeListPage: response.data.page,
              errorCodeListSizePerPage: sizePerPage,
              searchParams: params,
              fetchedErrorCodeList: response.data.data,
              totalErrorCodeListSize: response.data.records
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      errorCodeListPage: page,
      errorCodeListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  handleSearchClick = (searchParams) => {
    if (!searchParams.keywordText) {
      searchParams.keywordText = "";
    }
    const params = {
      searchText: searchParams.keywordText,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      errorCodeListPage: 1
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.pageNumber = this.state.errorCodeListPage;
    searchParams.limit = this.state.errorCodeListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_SUPPORT_ERROR_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                errorCodeListPage: response.data.page,
                fetchedErrorCodeList: response.data.data,
                totalErrorCodeListSize: response.data.records
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            });
      });
    }
  };

  handleTableSort = (sortOrder, sortField) => {
    const params = {
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: sortField
    };
    this.setState({
      searchParams: params
    }, () => this.search())
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("ENTER_ERRORCODE"),
                showDownload: false,
              }}
              datePanel={{
                show: false,
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              keyField="countryCode_errorCode"
              columns={[
                {dataField: "countryCode_errorCode", hidden: true},
                {dataField: "errorCode", text: this.props.t("ERROR_CODE"), headerStyle: {width: "3%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "messageTitle", text: this.props.t("TV_MESSAGE"), headerStyle: {width: "10%"}, sort: true, classes: () => "text-truncate txt-Left"},
                {dataField: "messageDescription", text: this.props.t("DESCRIPTION"), headerStyle: {width: "10%"}, sort: true, classes: () => "text-truncate txt-Left"},
              ]}
              hideSelectColumn={true}
              page={this.state.errorCodeListPage}
              sizePerPage={this.state.errorCodeListSizePerPage}
              totalSize={this.state.totalErrorCodeListSize}
              rows={this.state.fetchedErrorCodeList.map(errorCode => {
                return {
                  ...errorCode,
                  countryCode_errorCode: errorCode.countryCode + "_" + errorCode.errorCode
                }
              })}
              onTableChange={this.handleTableChange}
              onTableSort={this.handleTableSort}/>
          <SupportFooter feedbackTitle={this.props.t("SEND_FEEDBACK")}
                         feedbackbutton={this.props.t("CUSTOMER_FEEDBACK")}/>
        </div>
    );
  }

  componentDidMount() {
    this.fetchDefaultErrorCodeList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultErrorCodeList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ErrorCodePanel)));
