import React, {Component} from "react";
import ReactDOM from 'react-dom'
import Popout from 'react-popout-v2'
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

class PopupWindow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isPopupDisabled: false
    }
  }

  handleCheckboxToggle = () => {
    this.setState(prevState => {
      return {
        isPopupDisabled: !prevState.isPopupDisabled
      }
    });
  };

  render(props) {
    return (
        <Popout title='Popup'
                reactDom={ReactDOM}
                options={{
                  width: this.props.size === "SQUARE_350_350" ? "350" : "500", height: this.props.size === "SQUARE_350_350" ? "350" : "800"
                }}
                id={this.props.img}
                children={<div className="modal-content co-ad-content">
                  <div className="modal-header">
                    <h3 className="modal-title">{this.props.title}</h3>
                  </div>
                  <div className="modal-body accordion_group">
                    <img src={this.props.img} alt={this.props.alt} onClick={(e) => {
                      window.open(this.props.link);
                    }}/>
                  </div>
                  {this.props.showCheckbox &&
                  <div className="btn-group-toggle btn-group-checkbox btn-check" data-toggle="buttons">
                    <label className={`btn-checkbox checkbox-text ${this.state.isPopupDisabled && "checked"}`}><input type="checkbox" onClick={() => this.handleCheckboxToggle()}/>{this.props.t("DONT_SEE_AGAIN_TODAY")}</label>
                  </div>}
                </div>}
                onClose={() => this.props.handleClosePopup(this.state.isPopupDisabled)}
        />
    )
  }
}

export default withTranslation()(withRouter(PopupWindow));