import React from 'react';

function SimpleRow(props) {
  return (
      <div className="form-group row">
        <label className="col col-form-label">{props.title}</label>
        <div className="col">
          <input type="text" className="form-control" value={props.value || undefined} readOnly="readonly" style={{marginBottom : "10px"}}/>
        </div>
      </div>
  );
}

function SimpleCheckBoxRow(props) {
  return (
      <div className="col-6">
        <div className="form-group row">
          <label className="col col-form-label">{props.title}</label>
          <div className="col">
            <div className="mt-2">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <div className="btn-group-toggle btn-group-checkbox" data-toggle="buttons">
                    <label className="btn-checkbox active disabled"><input type="checkbox"/></label>
                  </div>
                  <span className="ml-6 mr-10">{props.value}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
}

export {SimpleRow, SimpleCheckBoxRow};