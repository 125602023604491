import fileDownload from "js-file-download";
import {handleHttpError} from './ErrorUtils';
import {transformRequestOptions} from './globalConstants';

export function getFreeTrialCriteria(guid, duid) {
  if (guid === 'Y' && duid === 'Y') {
    return 'account|duid';
  } else if (guid === 'N' && duid === 'Y') {
    return 'duid';
  } else if (guid === 'Y' && duid === 'N') {
    return 'account';
  } else {
    return '';
  }
}

export const productTypes = {
  CONSUMABLE: "Consumable",
  NON_CONSUMABLE: "Non Consumable",
  LIMITED_PERIOD: "Limited period",
  SUBSCRIPTION: "Subscription",
  FREE_TRIAL_SUBSCRIPTION: "Free trial + Subscription",
  DYNAMIC_PRODUCT: "Dynamic Product",
};

export const productTypeKey2Code = {
  "Consumable": "1",
  "Non Consumable": "2",
  "Limited period": "3",
  "Subscription": "4",
  "Free trial + Subscription": "6",
  "Dynamic Product": "7",
};

export const productTypeCode2Key = {
  "1": "CONSUMABLE",
  "2": "NON_CONSUMABLE",
  "3": "LIMITED_PERIOD",
  "4": "SUBSCRIPTION",
  "6": "FREE_TRIAL_SUBSCRIPTION",
  "7": "DYNAMIC_PRODUCT",
};

export const multipleAddModifyProductAttributes = {
  productName: {
    key: "PRODUCT_NAME",
    value: null
  },
  productId: {
    key: "PRODUCT_ID",
    value: null
  },
  country: {
    key: "COUNTRY",
    value: null
  },
  description: {
    key: "DESCRIPTION",
    value: null
  },
  productType: {
    key: "PRODUCT_TYPE",
    value: null
  },
  expiration: {
    key: "EXPIRATION",
    value: null
  },
  period: {
    key: "PERIOD",
    value: null
  },
  frequency: {
    key: "FREQUENCY",
    value: null
  },
  cycles: {
    key: "CYCLES",
    value: null
  },
  freeTrialPeriod: {
    key: "FREE_TRIAL_PERIOD",
    value: null
  },
  freeTrialCriteria: {
    key: "FREE_TRIAL_CRITERIA",
    value: null
  },
  allowedDevices: {
    key: "ALLOWED_DEVICES",
    value: null
  },
  verifyUrl: {
    key: "VERIFY_URL",
    value: null
  },
  visibility: {
    key: "VISIBILITY",
    value: null
  },
  price: {
    key: "LOCAL_PRICE",
    value: null
  },
  minPrice: {
    key: "MIN_PRICE1",
    value: null
  },
  maxPrice: {
    key: "MAX_PRICE1",
    value: null
  },
  taxRate: {
    key: "TAX_RATE",
    value: null
  },
  taxApplicable: {
    key: "VAT_INCL",
    value: null
  },
  taxCategory: {
    key: "TAX_CATEGORY",
    value: null
  },
  alertReason: {
    key: "ALERT_REASON",
    value: null
  },
  subscriptionGroupName: {
    key: "SUBSCRIPTION_GROUP_NAME",
    value: null
  },
  subscriptionGroupId: {
    key: "SUBSCRIPTION_GROUP_ID",
    value: null
  },
  freeTrialDays: {
    key: "FREE_TRIAL_DAYS",
    value: null
  },
  duplicateBenefit: {
    key: "DUPLICATE_BENEFIT",
    value: null
  },
  productLevel: {
    key: "PRODUCT_LEVEL",
    value: null
  }
};

export function handleDownloadFile(url, params, t, history, httpClient, startLoading, stopLoading) {
  return new Promise((resolve, reject) => {
    startLoading();
    httpClient
        .get(url, {
          params: params,
          paramsSerializer: transformRequestOptions,
          responseType: 'blob'
        })
        .then(response => {
          let str = response.headers['content-disposition'].split('filename=')[1];
          const filename = str.substring(1, str.length - 2);
          fileDownload(response.data, filename);
          stopLoading();
        })
        .catch(error => {
          handleHttpError(t, history, error);
          reject(error);
          stopLoading();
        });
  })
}

const downloadURI = async (uri, name) => {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  //delete link;
}

export function handleDownloadFileFromS3(url, params, t, history, httpClient, startLoading, stopLoading) {
  return new Promise((resolve, reject) => {
    startLoading();

    httpClient
        .get(url, {
          params: params,
        })
        .then(response => {

          const downloadURL = response.data.url;
          const fileName = response.data.fileName + ".zip";
          stopLoading();
          downloadURI(downloadURL, fileName);

        })
        .catch(error => {
          handleHttpError(t, history, error);
          stopLoading();
          return reject(error);
        });

  })
}

