import React from 'react';
import {URL_INDEX_PAGE} from '../utils/urlConstants';
import {Link} from "react-router-dom";

function Logo() {
  return (
      <Link to={URL_INDEX_PAGE} className="logo logo-lg ml-auto mr-auto" title="Samsung SMART TV Digital Product Inventory">
        <span>Samsung SMART TV Digital Product Inventory</span>
      </Link>
  )
}

export default Logo;
