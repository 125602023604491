import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {isDateFutureThan, durationKey} from '../../utils/searchBarUtils';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {couponAllocationAttributes, couponTypeCode2Key, productTypeCode2Key, statusCode2Key} from '../../utils/couponAttributes';
import {URL_GET_COUPONALLOCATIONLIST_DATA_API, URL_GET_COUPONALLOCATIONLIST_DOWNLOAD_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';

class CouponAllocationListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      couponAllocationListPage: 0,
      totalCouponAllocationListSize: 0,
      couponAllocationListSizePerPage: 0,
      fetchedCouponAllocationList: [],
      searchParams: {},
    }
  }

  fetchDefaultCouponAllocationList = (page, sizePerPage) => {
    var date = new Date();
    var endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    const monthBefore12 = new Date();
    monthBefore12.setFullYear(monthBefore12.getFullYear() - 1);
    var startDateString = new Date(monthBefore12.getTime() - (monthBefore12.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];

    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: startDateString,
      dateEnd: endDateString,
      sortOrder: "DESC",
      pageNumber: page,
      limit: sizePerPage
    };
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_COUPONALLOCATIONLIST_DATA_API, {
          params: params
        })
        .then(response => {
          this.setState({
            couponAllocationListPage: page,
            couponAllocationListSizePerPage: sizePerPage,
            searchParams: params,
            fetchedCouponAllocationList: response.data.data,
            totalCouponAllocationListSize: response.data.records
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleTableSort = (sortOrder, sortField) => {
    const couponHeaderField2SortName = {
      "couponName": "couponName",
      "couponIssueId": "couponIssueId",
      "couponType": "couponType",
      "couponTargetRange": "couponTargetRange",
      "product": "productName",
      "country": "countryCode",
      "requestDate": "requestDate",
      "completeDate": "completeDate",
      "applicant": "applicant",
      "status": "statusCode",
      "downloadButton" : "result"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: couponHeaderField2SortName[sortField] ? couponHeaderField2SortName[sortField] : "",
      couponAllocationListPage: 1,
    }, () => this.search())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      couponAllocationListPage: page,
      couponAllocationListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      couponAllocationListPage: 1
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.contentId = this.props.carousel.selectedContsId;
    searchParams.cpAppId = this.props.carousel.selectedAppId;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.couponAllocationListPage;
    searchParams.limit = this.state.couponAllocationListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COUPONALLOCATIONLIST_DATA_API, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              searchParams: searchParams,
              fetchedCouponAllocationList: response.data.data,
              totalCouponAllocationListSize: response.data.records
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  handleDownloadClick = (couponAllocationListId) => {
    const params = {
      couponMappingRequestId: couponAllocationListId
    };
    handleDownloadFile(URL_GET_COUPONALLOCATIONLIST_DOWNLOAD_API, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                showSearch: true,
                maxDate: true
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              noSelect
              keyField="couponMappingRequestId"
              columns={[
                {dataField: "couponMappingRequestId", hidden: true},
                {dataField: "couponName", text: this.props.t(couponAllocationAttributes.couponName.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "couponIssueId", text: this.props.t(couponAllocationAttributes.couponIssueId.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "couponType", text: this.props.t(couponAllocationAttributes.couponType.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "couponTargetRange", text: this.props.t(couponAllocationAttributes.couponTargetRange.key), headerStyle: {width: "18.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "product", text: this.props.t(couponAllocationAttributes.product.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "country", text: this.props.t(couponAllocationAttributes.country.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "requestDate", text: this.props.t(couponAllocationAttributes.startDate.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "completeDate", text: this.props.t(couponAllocationAttributes.completeDate.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "applicant", text: this.props.t(couponAllocationAttributes.applicant.key), headerStyle: {width: "13.1810%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "status", text: this.props.t(couponAllocationAttributes.status.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate"},
                {dataField: "downloadButton", text: this.props.t(couponAllocationAttributes.result.key), headerStyle: {width: "13.1810%"}, sort: true, classes: () => "text-truncate", hideTitle: true},
              ]}
              hideSelectColumn={true}
              page={this.state.couponAllocationListPage}
              sizePerPage={this.state.couponAllocationListSizePerPage}
              totalSize={this.state.totalCouponAllocationListSize}
              rows={this.state.fetchedCouponAllocationList.map(couponAllocation => {
                return {
                  ...couponAllocation,
                  downloadButton:
                      <button
                          type="button"
                          className="btn btn-sm btn-outline-400"
                          onClick={(e) => this.handleDownloadClick(couponAllocation.couponMappingRequestId)}>
                        {this.props.t("DOWNLOAD")}
                      </button>,
                  country: this.props.t(couponAllocation.countryCode),
                  couponType: this.props.t(couponTypeCode2Key[couponAllocation.couponType]),
                  couponTargetRange: this.props.t(productTypeCode2Key[couponAllocation.couponTargetRange]),
                  product: couponAllocation.productName ? couponAllocation.productName : "-",
                  status: this.props.t(statusCode2Key[couponAllocation.statusCode]),
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}/>
        </div>
    );
  }

  componentDidMount() {
    this.fetchDefaultCouponAllocationList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultCouponAllocationList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CouponAllocationListPanel)));
