import React from 'react';
import standaloneAdapter from 'react-bootstrap-table2-paginator/lib/src/standalone-adapter';
import PaginationHandler from 'react-bootstrap-table2-paginator/lib/src/pagination-handler';
import paginationListAdapter from 'react-bootstrap-table2-paginator/lib/src/pagination-list-adapter';
import {Link} from 'react-router-dom';

const PaginationListStandalone = (paginationProps) => {
  const onClick = (e, page) => {
    e.preventDefault();
    if (!page.active && !page.disabled) {
      paginationProps.onPageChange(page.page)
    }
  };

  return (
      <ul className="react-pagination">
        {paginationProps.pages.map(page => {
          let liClassName = "page-item";

          switch (page.page) {
            case "<<":
              liClassName += " page-first";
              break;
            case "<":
              liClassName += " page-prev";
              break;
            case ">":
              liClassName += " page-next";
              break;
            case ">>":
              liClassName += " page-last";
              break;
            default:
              break;
          }

          if (page.active) {
            liClassName = "active " + liClassName;
          } else if (page.disabled) {
            liClassName = "disabled " + liClassName;
          }

          return (
              <li key={page.page} className={liClassName} onClick={(e) => onClick(e, page)}>
                <Link to={{}} className="page-link">
                  {Object.is(typeof page.page, "string") ? " " : page.page}
                </Link>
              </li>
          );
        })}
      </ul>
  )
};

export default standaloneAdapter(PaginationHandler(paginationListAdapter(PaginationListStandalone)));