import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {startLoading, stopLoading} from '../../ducks/loading';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, DUPLICATE, SUCCESS, validAccountNumber, validEmail} from '../../utils/globalConstants';
import BasePopup from '../popup/BasePopup';
import {toast} from 'react-toastify';
import {URL_DSA, URL_GET_COMPANY_DETAIL_API, URL_GET_COMPANY_SMART_BILL_CHECK_API, URL_INDEX_PAGE, URL_UPDATE_SETTINGS_SELLER_COMPANY_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import {withRouter} from 'react-router-dom';
import {companyTypeCode2Key, companyTypeKey2Code, partnerTypeCode2Key, partnerTypeKey2Code} from '../../utils/changeInfoAttributes';
import {sortCountryObjectNames} from '../../utils/CountryListUtils';
import {getAllCountriesList} from '../../ducks/countriesInformation';
import {triggerGetSessionData} from '../App';

function SimpleInputBox(props) {
  return (
      <div className="col">
        <input type="text"
               className="form-control"
               value={props.value}
               onChange={(e) => props.onTextChange(props.keyword, e.target.value)}
               onBlur={() => props.onBlurHandler(props.keyword)}
               disabled={props.disabled}/>
      </div>
  );
}

function encodeAccountNumber(accountNumber) {
  if (!accountNumber) {
    return "";
  }
  const length = accountNumber.length;
  if (length <= 4) {
    return accountNumber;
  }
  let first2Char = accountNumber.substring(0, 2);
  let last2Char = accountNumber.substring(length - 2, length);
  let hiddenPart = '*'.repeat(length - 4);
  return first2Char + hiddenPart + last2Char;
}

class ChangeInfoPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // init flag
      init: false,
      isTooltipOpen: false,
      isDataLoaded: false,
      isSmartBillStatusChecked: false,

      alertModalOpen: false,
      title: "",
      alertMessage: "",
      isBankAccountNumberChanged: false,
      isBankCodeChanged: false
    };
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
  }

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  getInformationDetail = () => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COMPANY_DETAIL_API, {
            params: {sellerGroupId: this.props.sessionData.sellerGroupId}
          })
          .then(response => {
            this.setState({
              isDataLoaded: true,
              //TODO set default values if null
              infoSavedYN: response.data.infoSavedYN,
              isKorea: response.data.countryCd === "KR",
              groupId: response.data.groupId,
              hqLocation: response.data.countryCd,
              businessType: companyTypeCode2Key[response.data.companyTypeCd],
              companyTypeCode: response.data.companyTypeCd,
              managerName: response.data.groupManagerName,
              companyName: response.data.companyName,
              ceo: response.data.ceoName,
              businessRegistrationNumber: response.data.companyRegistrationNumber,
              typeOfBusiness: response.data.businessType,
              typeOfIndustry: response.data.industryType,
              companyAddress: response.data.companyAddress,
              detailAddress: response.data.detailedAddress,
              zipCode: response.data.zipCode,
              mobileNumber: response.data.mobileNumber,
              partnerType: partnerTypeCode2Key[response.data.companyPartnerType],
              managerMail: response.data.groupManagerEmail,
              paymentType: response.data.paymentType,
              countryOfBank: response.data.bankCountryCd,
              accountHolder: response.data.accountOwnerName,
              accountNumber: response.data.accountNumber,
              bankName: response.data.bankName,
              bankCode: response.data.bankCode,
              branchName: response.data.bankBranchName,
              bankAddress: response.data.bankAddress,
              cprsRate: response.data.cpRsRate,
              minPaymentAmount: response.data.minimumPaymentAmount,
              vendorName: response.data.issuerName,
              vendorEmail: response.data.taxEmail,
              isSmartBillRegistered: response.data.smartBillYn,
              smartBillStatus: response.data.smartBillYn === "Y",
              pgFeeHolder: response.data.pgFeeHolder,
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
            reject(error);
          })
    });
  };

  inputTextHandler = (key, value) => {
    if (key === "mobileNumber") {
      const mobileRegex = new RegExp("^[0-9]{0,20}$");
      if (!mobileRegex.test(value)) {
        return;
      }
    }
    const re = new RegExp("[=\"<>]");
    if (re.test(value)) {
      return;
    }
    this.setState({
      [key]: value
    });
    if (key === "accountNumber") {
      this.setState({isBankAccountNumberChanged: true})
    }
    if (key === "bankCode") {
      this.setState({isBankCodeChanged: true})
    }
    if (key === "businessRegistrationNumber") {
      this.setState({isSmartBillStatusChecked: false})
    }
  };
  selecterChangeHandler = (selected, key) => {
    if (selected) {
      this.setState({[key]: selected.key});
    } else {
      this.setState({[key]: null});
    }
    if (key === "hqLocation") {
      if (selected.key === "KR") {
        this.setState({isKorea: true});
      } else {
        this.setState({isKorea: false, companyTypeCd: "01", businessType: companyTypeCode2Key["01"]});

      }
    }
  };

  trimInput = (key) => {
    if (this.state[key]) {
      this.setState({
        [key]: this.state[key].trim()
      });
    }
  };

  saveData = () => {
    let params = {
      groupId: this.state.groupId,
      countryCd: this.state.hqLocation,
      companyTypeCd: companyTypeKey2Code[this.state.businessType],
      groupManagerName: this.state.managerName,
      companyName: this.state.companyName,
      ceoName: this.state.ceo,
      companyRegistrationNumber: this.state.businessRegistrationNumber,
      businessType: this.state.typeOfBusiness,
      industryType: this.state.typeOfIndustry,
      companyAddress: this.state.companyAddress,
      detailedAddress: this.state.detailAddress,
      zipCode: this.state.zipCode,
      mobileNumber: this.state.mobileNumber,
      companyPartnerType: partnerTypeKey2Code[this.state.partnerType],
      groupManagerEmail: this.state.managerMail,
      paymentType: this.state.paymentType,
      bankCountryCd: this.state.countryOfBank,
      accountOwnerName: this.state.accountHolder,
      accountNumber: this.state.accountNumber,
      bankName: this.state.bankName,
      bankCode: this.state.bankCode,
      bankBranchName: this.state.branchName,
      bankAddress: this.state.bankAddress,
      cpRsRate: this.state.cprsRate,
      minimumPaymentAmount: this.state.minPaymentAmount,
      issuerName: this.state.vendorName,
      taxEmail: this.state.vendorEmail,
      smartBillYn: this.state.isSmartBillRegistered
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .post(URL_UPDATE_SETTINGS_SELLER_COMPANY_API, params)
          .then(response => {
            this.props.stopLoading();
            if (response.data.status !== SUCCESS && response.data.status !== DUPLICATE) {
              toast(this.props.t("CHANGECOMPANY_MODIFY_ALERT_FAIL"));
            } else {
              this.setState({isBankAccountNumberChanged: false, isBankCodeChanged: false})
              toast(this.props.t(response.data.status === SUCCESS ? "CHANGECOMPANY_MODIFY_ALERT_SUCCESS" : "CHANGECOMPANY_MODIFY_ALERT_DUPLICATE"));

              if (this.props.location.pathname.includes("dsa")) {
                const {sellerServiceEU, isContractSkipByAdmin, dsaConsent, dtaConsent} = this.props.sessionData;
                let isContractMandatory = sellerServiceEU && !isContractSkipByAdmin ;
                let isContractAccepted = dsaConsent && dtaConsent ;
                // we need to check whether it has apps or not,
                // since bank info scenario is executed before normal login scenario in App.js
                if(this.props.sessionData.apps.length){
                  if(isContractMandatory && !isContractAccepted){
                    this.props.history.push(URL_DSA);
                  }
                  triggerGetSessionData();
                  this.props.history.push(URL_INDEX_PAGE);
                } else {
                  if (this.props.cancelHandler) {
                    this.props.cancelHandler();
                  }
                }
              }
            }
            resolve();
          })
          .catch(error => {
            this.props.stopLoading();
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
          })
    });
  };

  handleAlertPopupClose = (isSave) => {
    this.setState({alertModalOpen: false});
    if (!isSave) {
      return;
    }
    if (this.state.alertType === "Save") {
      this.saveData();
    } else {
      if (this.props.cancelHandler) {
        this.props.cancelHandler();
      }
    }
  };

  openAlertOnCancel = () => {
    this.setState({
      alertType: "Cancel",
      alertModalOpen: true,
      alertMessage: "CANCEL_BANK_INFO_CHANGES",
    })
  };

  handleCheckSmartBillRegistration = () => {
    if (!this.state.businessRegistrationNumber) {
      toast(this.props.t("PLEASE_ENTER_BUSINESS_REGISTRATION_NUMBER"));
      return;
    }
    this.props.startLoading();
    return new Promise((resolve, reject) => {
      this.httpClient
          .get(URL_GET_COMPANY_SMART_BILL_CHECK_API, {
            params: {
              companyRegistrationNumber: this.state.businessRegistrationNumber
            }
          })
          .then(response => {
            this.props.stopLoading();

            if (response.data.status === SUCCESS) {
              this.setState({
                isSmartBillRegistered: "Y",
                smartBillStatus: true,
                isSmartBillStatusChecked: true,
              });
            } else {
              toast(this.props.t("SMARTBILL_UNREGISTER_ALERT"));
              this.setState({
                isSmartBillStatusChecked: true,
              });
            }
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    });
  };

  handleSaveClick = () => {
    if (!this.state.hqLocation) {
      toast(this.props.t("PLEASE_SELECT_HQ_LOCATION"));
      return;
    }

    if (!this.state.businessType) {
      toast(this.props.t("PLEASE_CHOOSE_TYPE_OF_COMPANY"));
      return;
    }

    if (!this.state.companyName) {
      toast(this.props.t("PLEASE_ENTER_COMPANY_NAME"));
      return;
    }

    if (!this.state.ceo) {
      toast(this.props.t("PLEASE_ENTER_CEO_NAME"));
      return;
    }

    if (!this.state.businessRegistrationNumber) {
      toast(this.props.t("PLEASE_ENTER_BUSINESS_REGISTRATION_NUMBER"));
      return;
    }

    if (!this.state.typeOfBusiness) {
      toast(this.props.t("PLEASE_ENTER_TYPE_OF_BUSINESS"));
      return;
    }

    if (!this.state.typeOfIndustry) {
      toast(this.props.t("PLEASE_ENTER_TYPE_OF_INDUSTRY"));
      return;
    }

    if (!this.state.companyAddress) {
      toast(this.props.t("PLEASE_ENTER_COMPANY_ADDRESS"));
      return;
    }

    if (!this.state.detailAddress) {
      toast(this.props.t("PLEASE_ENTER_DETAILED_ADDRESS"));
      return;
    }

    if (!this.state.zipCode) {
      toast(this.props.t("PLEASE_ENTER_ZIP_CODE"));
      return;
    }

    if (this.state.managerMail) {
      if (!validEmail(this.state.managerMail)) {
        toast(this.props.t("PLEASE_CHECK_EMAIL_FORMAT"));
        return;
      }
    } else {
      toast(this.props.t("PLEASE_ENTER_GROUP_MANAGER_EMAIL"));
      return;
    }

    if (Object.is(this.state.mobileNumber, null) || Object.is(this.state.mobileNumber, "")) {
      toast(this.props.t("PLEASE_ENTER_MOBILE_NUMBER"));
      return;
    }

    if (!this.state.countryOfBank) {
      toast(this.props.t("PLEASE_ENTER_BANK_COUNTRY"));
      return;
    }

    if (!this.state.accountHolder) {
      toast(this.props.t("PLEASE_ENTER_ACCOUNT_HOLDER"));
      return;
    }

    if (this.state.accountNumber) {
      if (!validAccountNumber(this.state.accountNumber)) {
        toast(this.props.t("PLEASE_CHECK_ACCOUNT_NUMBER_FORMAT"));
        return
      }
    } else {
      toast(this.props.t("PLEASE_ENTER_ACCOUNT_NUMBER"));
      return;
    }

    if (Object.is(this.state.businessType, "INCORPORATION")) {
      if (!this.state.bankName) {
        toast(this.props.t("PLEASE_ENTER_BANK_NAME"));
        return;
      }

      if (!this.state.bankCode) {
        toast(this.props.t("PLEASE_ENTER_BANK_CODE"));
        return;
      } else {
        if (this.state.bankCode.length < 4) {
          toast(this.props.t("PLEASE_CHECK_BANK_CODE_FORMAT"));
          return;
        }
      }

      if (!this.state.branchName) {
        toast(this.props.t("PLEASE_ENTER_BANK_BRANCH_NAME"));
        return;
      }

      if (!this.state.bankAddress) {
        toast(this.props.t("PLEASE_ENTER_BANK_ADDRESS"));
        return;
      }
    } else { //Simplified Tax Payer Case
      if (this.state.bankCode.length > 1 && this.state.bankCode.length < 4) {
        toast(this.props.t("PLEASE_CHECK_BANK_CODE_FORMAT"));
        return;
      }
    }

    if (this.state.bankName.localeCompare(this.state.accountHolder) === 0) {
      toast(this.props.t("ACCOUNT_BANK_SAME"));
      return;
    }

    if (this.state.isKorea) {
      let isVendorNameEmpty = Object.is(this.state.vendorName, null) || this.state.vendorName.length === 0;
      if (isVendorNameEmpty) {
        toast(this.props.t("PLEASE_ENTER_VENDOR_NAME"));
        return;
      }
      let isVendorEmailEmpty = Object.is(this.state.vendorEmail, null) || this.state.vendorEmail.length === 0;
      if (isVendorEmailEmpty) {
        toast(this.props.t("PLEASE_ENTER_VENDOR_EMAIL"));
        return;
      }
      if (!validEmail(this.state.vendorEmail)) {
        toast(this.props.t("PLEASE_CHECK_EMAIL_FORMAT"));
        return;
      }
    }

    if (!this.state.pgFeeHolder) {
      toast(this.props.t("PLEASE_ENTER_PG_FEE_HOLDER"));
      return;
    }

    this.setState({
      alertType: "Save",
      alertModalOpen: true,
      alertMessage: "DO_YOU_WANT_TO_SAVE_THE_CHANGES",
    })
  };

  render() {
    if (!this.state.isDataLoaded || Object.is(this.props.countriesInformation.length, 0)) {
      return <div/>
    }

    const {
      bankCode,
      groupId,
      infoSavedYN,
      hqLocation,
      businessType,
      managerName,
      companyName,
      ceo,
      businessRegistrationNumber,
      typeOfBusiness,
      typeOfIndustry,
      companyAddress,
      detailAddress,
      zipCode,
      mobileNumber,
      managerMail,
      partnerType,
      countryOfBank,
      accountHolder,
      accountNumber,
      bankName,
      branchName,
      bankAddress,
      cprsRate,
      pgFeeHolder,
      minPaymentAmount,
      isKorea,
      vendorName,
      vendorEmail,
      smartBillStatus,
      isSmartBillStatusChecked
    } = this.state;
    let countryListData = sortCountryObjectNames(getAllCountriesList(this.props.countriesInformation), this.props.t);
    return (
        <>
          <form className="needs-validation_" noValidate>
            <div className="clearfix mb-20">
              <h2 className="float-left mb-0"><strong>{this.props.t("BASIC_INFORMATION")}</strong></h2>
              <div className="float-right">
                <span className="text-success small mr-6"> <span className="align-middle">*</span>{this.props.t("REQUIRED_FIELD")}</span>
                <span className={`tooltips right ${this.state.isTooltipOpen && "show"}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                  <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
          <div className="tooltips_body">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
            <a onClick={this.handleTooltipCloseClick} className={"tooltip-toggle close"}/>
            {this.props.t("TOOLTIP_CONTENT0")} <span className="text-primary">{this.props.t("TOOLTIP_CONTENT1")}</span>{this.props.t("TOOLTIP_CONTENT2")}<br/><br/>
            {this.props.t("TOOLTIP_CONTENT3")}<br/><br/>
            {this.props.t("TOOLTIP_CONTENT4")}<a href="mailto:cs.checkout@partner.sec.co.kr" className="text-primary">cs.checkout@partner.sec.co.kr</a>
            {this.props.t("TOOLTIP_CONTENT5")}<br/>
										<hr/>
            {this.props.t("TOOLTIP_CONTENT6")}<br/>
            {this.props.t("TOOLTIP_CONTENT7")}
          </div>
          </span>
              </div>
            </div>
            <div className="form-group-wrap">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("GROUP_ID")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text" className="form-control" value={groupId} readOnly="readonly"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("HQ_LOCATION")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <SelectrWrapper
                          className="form-control"
                          isDisable={infoSavedYN === 'Y'}
                          clearable={false}
                          placeholder={this.props.t("SELECT")}
                          value={this.props.t(hqLocation)}
                          onChange={(selected) => this.selecterChangeHandler(selected, "hqLocation")}>
                        {countryListData.map(v => <option key={v.countryCode} value={v.countryName}>{v.countryName}</option>)}
                      </SelectrWrapper>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TYPE_OF_COMPANY")}<span className="text-success align-middle">*</span></label>
                    {Object.is(infoSavedYN, "Y") &&
                    <div className="col">
                      <div className="mt-2">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <div className="btn-group-toggle btn-group-checkbox" data-toggle="buttons">
                              <label className="btn-checkbox active checked disabled"><input type="checkbox"/></label>
                            </div>
                            <span className="ml-6 mr-10 text-muted">{this.props.t(businessType)}</span>
                          </li>
                        </ul>
                      </div>
                    </div>}
                    {Object.is(infoSavedYN, "N") &&
                    <div className="col">
                      <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                        <label className={`btn btn-outline-500 rounded btn-auto mr-2 ${businessType === "INCORPORATION" ? "checked" : ""}`}>
                          <input type="radio" name={"biz_type"} onChange={(e) => this.inputTextHandler("businessType", "INCORPORATION")}/>Incorporation
                        </label>
                        {isKorea &&
                        <label className={`btn btn-outline-500 rounded btn-auto mr-2 ${businessType === "SIMPLIFIED_TAXPAYER" ? "checked" : ""}`}>
                          <input type="radio" name={"biz_type"} onChange={(e) => this.inputTextHandler("businessType", "SIMPLIFIED_TAXPAYER")}/>Simplified Taxpayer
                        </label>}
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("GROUP_MANAGER_NAME")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={managerName}
                             readOnly={"readOnly"}/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("COMPANY_NAME")}<span className="text-success align-middle">*</span></label>
                    <SimpleInputBox
                        value={companyName}
                        keyword={"companyName"}
                        disabled={Object.is(infoSavedYN, "Y")}
                        onBlurHandler={this.trimInput}
                        onTextChange={this.inputTextHandler}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("CEO")}<span className="text-success align-middle">*</span></label>
                    <SimpleInputBox
                        value={ceo}
                        keyword={"ceo"}
                        onTextChange={this.inputTextHandler}
                        onBlurHandler={this.trimInput}/>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row align-items-center">
                    <label className="col col-form-label">{this.props.t("BUSINESS_REGISTRATION")}<br/> {this.props.t("NUMBER")}<span className="text-success align-middle">*</span></label>
                    <SimpleInputBox
                        value={businessRegistrationNumber}
                        keyword={"businessRegistrationNumber"}
                        onTextChange={this.inputTextHandler}
                        onBlurHandler={this.trimInput}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TYPE_OF_BUSINESS")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             onChange={(e) => this.inputTextHandler("typeOfBusiness", e.target.value)}
                             value={typeOfBusiness}
                             onBlur={() => this.trimInput("typeOfBusiness")}/>

                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TYPE_OF_INDUSTRY")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             onChange={(e) => this.inputTextHandler("typeOfIndustry", e.target.value)}
                             value={typeOfIndustry}
                             onBlur={() => this.trimInput("typeOfIndustry")}/>

                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col col-form-label">{this.props.t("COMPANY_ADDRESS")}<span className="text-success align-middle">*</span></label>
                <div className="col">
                  <input type="text"
                         className="form-control"
                         value={companyAddress}
                         onChange={(e) => this.inputTextHandler("companyAddress", e.target.value)}
                         placeholder={this.props.t("Please enter address.")}
                         onBlur={() => this.trimInput("companyAddress")}/>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("DETAILED_ADDRESS")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={detailAddress}
                             onChange={(e) => this.inputTextHandler("detailAddress", e.target.value)}
                             placeholder={this.props.t("Please enter address detail.")}
                             onBlur={() => this.trimInput("detailAddress")}/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("ZIPCODE")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             maxLength={100}
                             className="form-control"
                             value={zipCode}
                             onChange={(e) => this.inputTextHandler("zipCode", e.target.value)}
                             onBlur={() => this.trimInput("zipCode")}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("MOBILE_PHONE_NUMBER")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             placeholder="Please enter numbers only."
                             value={Object.is(mobileNumber, null) ? "" : mobileNumber}
                             onChange={(e) => this.inputTextHandler("mobileNumber", e.target.value)}
                             onBlur={() => this.trimInput("mobileNumber")}/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("PARTNER_TYPE")}</label>
                    <div className="col">
                      <input type="text" className="form-control" value={this.props.t(partnerType)} readOnly="readonly"/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col col-form-label">{this.props.t("GROUP_MANAGER_EMAIL")}<span className="text-success align-middle">*</span></label>
                <div className="col">
                  <input type="text"
                         className="form-control"
                         value={managerMail}
                         onChange={(e) => this.inputTextHandler("managerMail", e.target.value)}
                         placeholder={this.props.t("Please enter e-mail address.")}
                         onBlur={() => this.trimInput("managerMail")}/>
                </div>
              </div>
            </div>
            <div className="clearfix mb-20 mt-60">
              <h2 className="float-left mb-0"><strong>{this.props.t("ACCOUNT_INFORMATION")}</strong></h2>
            </div>
            <div className="form-group-wrap">
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("PAYMENT_TYPE")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <div className="mt-2">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <div className="btn-group-toggle btn-group-checkbox" data-toggle="buttons">
                              <label className="btn-checkbox active disabled"><input type="checkbox"/></label>
                            </div>
                            <span className="ml-6 mr-10 text-muted">{this.props.t("BANK")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row align-items-center">
                    <label className="col col-form-label">{this.props.t("COUNTRY_OF")}<br/>{this.props.t("BANK_ACCOUNT")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <SelectrWrapper
                          className="form-control"
                          isDisable={infoSavedYN === 'Y'}
                          clearable={false}
                          placeholder={this.props.t("COUNTRY")}
                          value={this.props.t(countryOfBank)}
                          onChange={(selected) => this.selecterChangeHandler(selected, "countryOfBank")}>
                        {countryListData.map(v => <option key={v.countryCode} value={v.countryName}>{v.countryName}</option>)}
                      </SelectrWrapper>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("BANK_NAME")}{businessType === "INCORPORATION" ? <span className="text-success align-middle">*</span> : ''}</label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={bankName}
                             onChange={(e) => this.inputTextHandler("bankName", e.target.value)}
                             placeholder="Please enter bank name."
                             onBlur={() => this.trimInput("bankName")}/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row align-items-center">
                    <label className="col col-form-label">{this.props.t("BANK_BRANCH_NAME")}{businessType === "INCORPORATION" ? <span className="text-success align-middle">*</span> : ''}</label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={branchName}
                             onChange={(e) => this.inputTextHandler("branchName", e.target.value)}
                             placeholder="Please enter bank branch name."
                             onBlur={() => this.trimInput("branchName")}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col col-form-label">{this.props.t("BANK_ADDRESS")}{businessType === "INCORPORATION" ? <span className="text-success align-middle">*</span> : ''}</label>
                <div className="col">
                  <input type="text"
                         className="form-control"
                         value={bankAddress}
                         onChange={(e) => this.inputTextHandler("bankAddress", e.target.value)}
                         placeholder="Please enter bank address."
                         onBlur={() => this.trimInput("bankAddress")}/>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("ACCOUNT_HOLDER")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={accountHolder}
                             onChange={(e) => this.inputTextHandler("accountHolder", e.target.value)}
                             placeholder="Please enter account holder."
                             onBlur={() => this.trimInput("accountHolder")}/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("ACCOUNT_NUMBER")}<span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={this.state.isBankAccountNumberChanged ? accountNumber : encodeAccountNumber(accountNumber)}
                             onChange={(e) => this.inputTextHandler("accountNumber", e.target.value)}
                             onFocus={() => this.inputTextHandler("accountNumber", "")}
                             onBlur={() => this.trimInput("accountNumber")}
                             placeholder="Please enter account number."/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="form-group row align-items-center">
                    <label className="col col-form-label line-height-1">{this.props.t("BANK_CODE")}{businessType === "INCORPORATION" ? <span className="text-success align-middle">*</span> : ''}<br/><small>(IBAN/SWIFT Code)</small></label>
                    <div className="col">
                      <input type="text"
                             className="form-control"
                             value={this.state.isBankCodeChanged ? bankCode : encodeAccountNumber(bankCode)}
                             onChange={(e) => this.inputTextHandler("bankCode", e.target.value)}
                             onFocus={() => this.inputTextHandler("bankCode", "")}
                             placeholder="Please enter bank code."
                             onBlur={() => this.trimInput("bankCode")}/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row align-items-center">
                    <label className="col col-form-label">{this.props.t("MINIMUM_PAYMENT")}<br/>{this.props.t("AMOUNT_USD")}</label>
                    <div className="col">
                      <input type="password" className="form-control" value={minPaymentAmount} placeholder="Minimum Payment Amount(USD)" readOnly="readonly"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("CP_R_S_RATE")}</label>
                    <div className="col">
                      <input type="text" className="form-control" value={cprsRate} placeholder="CP R/S Rate(%)" readOnly="readonly"/>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("PG_FEE_HOLDER")}</label>
                    <div className="col">
                      <input type="text" className="form-control" value={pgFeeHolder} placeholder="PG Fee Holder" readOnly="readonly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isKorea &&
                <>
                  <div className="clearfix mb-20 mt-60">
                    <h2 className="float-left mb-0"><strong>{this.props.t("TAX_INVOICE_INFORMATION")}</strong></h2>
                  </div>
                  <div className="form-group-wrap">
                    <div className="form-group row">
                      <label className="col col-form-label">{this.props.t("VENDOR_NAME")}<span className="text-success align-middle">*</span></label>
                      <SimpleInputBox
                          value={vendorName}
                          keyword={"vendorName"}
                          disabled={Object.is(infoSavedYN, "Y")}
                          onTextChange={this.inputTextHandler}
                          onBlurHandler={this.trimInput}/>
                    </div>
                    <div className="form-group row">
                      <label className="col col-form-label">{this.props.t("EMAIL")}<span className="text-success align-middle">*</span></label>
                      <SimpleInputBox
                          value={vendorEmail}
                          keyword={"vendorEmail"}
                          disabled={Object.is(infoSavedYN, "Y")}
                          onTextChange={this.inputTextHandler}
                          onBlurHandler={this.trimInput}/>
                    </div>
                    <div className="form-group row align-items-center">
                      <label className="col col-form-label">{this.props.t("SMART_BILL")}<br/>{this.props.t("REGISTRATION_STATUS")}</label>
                      <div className="col">
                        <div className="mt-2">
                          <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <div className="btn-group-toggle btn-group-checkbox" data-toggle="buttons">
                            <label className={`btn-checkbox disabled ${smartBillStatus ? "checked" : ""}`}><input type="checkbox"/></label>
                          </div>
                          <span className={`ml-6 mr-10 text${isSmartBillStatusChecked ? "" : "-muted"}`}>{this.props.t(smartBillStatus ? "Registered" : "Unregistered")}</span>
                          {this.props.isPopup && !isSmartBillStatusChecked && <button type="button" className="btn btn-primary" onClick={() => this.handleCheckSmartBillRegistration()}>{this.props.t("Check Registration")}</button>}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>}
            {!this.props.isPopup &&
            <div className="text-center mt-30">
              <button type="button"
                      className="btn btn-lg btn-primary"
                      onClick={() => this.handleSaveClick()}>
                {this.props.t("SAVE")}
              </button>
            </div>}
          </form>
          {this.props.isPopup &&
          <div className="modal-footer">
            <button type="button"
                    className="btn btn-120 btn-outline-primary"
                    onClick={() => this.openAlertOnCancel()}>
              {this.props.t("CANCEL")}
            </button>
            <button type="button"
                    className="btn btn-120 btn-primary"
                    disabled={isKorea && !smartBillStatus}
                    onClick={() => this.handleSaveClick()}>
              {this.props.t("SAVE")}
            </button>
          </div>
          }
          <BasePopup
              open={this.state.alertModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleAlertPopupClose(false)}
              body={
                <p>{this.props.t(this.state.alertMessage)}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleAlertPopupClose(true)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleAlertPopupClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </>
    );
  }

  componentDidMount() {
    this.getInformationDetail();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }
}

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ChangeInfoPanel)));