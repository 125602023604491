export const durationKey = {
  DURATION_ALL: "ALL",
  DURATION_YEAR: "YEAR",
  DURATION_SIX_MONTH: "SIX_MONTH",
  DURATION_ONE_MONTH: "ONE_MONTH"
};

export const durations = [
  durationKey.DURATION_ALL,
  durationKey.DURATION_YEAR,
  durationKey.DURATION_SIX_MONTH,
  durationKey.DURATION_ONE_MONTH
];

export function getDefaultTimePeriod_1Month() {
  const monthBefore1 = new Date();
  monthBefore1.setMonth(monthBefore1.getMonth() - 1);
  var date = new Date();
  var endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  var startDateString = new Date(monthBefore1.getTime() - (monthBefore1.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  return {startDate: startDateString, endDate: endDateString};
}

export function getDefaultTimePeriod() {
  const monthBefore12 = new Date();
  monthBefore12.setFullYear(monthBefore12.getFullYear() - 1);
  var date = new Date();
  var endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  var startDateString = new Date(monthBefore12.getTime() - (monthBefore12.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  return {startDate: startDateString, endDate: endDateString};
}

export function isDateFutureThan(startDate, endDate) {
  let start = Date.parse(startDate);
  let end = Date.parse(endDate);
  if (start > end) {
    return true;
  }
  return false;
}