import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../css/bootstrap-extend.css";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getCountriesInformation} from "../../ducks/countriesInformation";
import ValidPeriodDateTimePicker from './ValidPeriodDateTimePicker';

class DataTableWrapperForFlexibleOffer extends React.Component {

  render() {
    let allCountrySelected = this.props.productCountryData.every(item => item.isSelected);
    return (
        <table className="table table-bordered table-hover table-data">
          <caption>Flexible Offer List</caption>
          <colgroup>
            <col width="50" />
            <col width="130" />
            <col width="130" />
            <col width="70" />
            <col width="90" />
            <col width="110" />
            <col width="310" />
            <col width="110" />
          </colgroup>
          <thead>
          <tr>
            <th className="none-sort" scope="col">
              <div className="btn-group-toggle btn-group-checkbox btn-check" data-toggle="buttons">
                <label
                    className={"btn-checkbox checkbox-all".concat(allCountrySelected ? " checked" : "")}><input
                    onClick={() => this.props.onCheckAll()} type="checkbox"/></label>
              </div>
            </th>
            <th scope="col">{this.props.t("COUNTRY")}</th>
            <th scope="col">{this.props.t("PRODUCT_NAME")}</th>
            <th scope="col">{this.props.t("CURRENCY")}</th>
            <th scope="col">{this.props.t("ORIGINAL_PRICE")}</th>
            <th scope="col">{this.props.t("PROMOTION_PRICE")}</th>
            <th scope="col">{this.props.t("PROMOTION_PERIOD")}</th>
            <th scope="col">{this.props.t("BENEFIT_PERIOD")}</th>
          </tr>
          </thead>
          <tbody className="text-center">
          {
            this.props.productCountryData.map((item) => {
              const countryInfo = getCountriesInformation(this.props.countriesInformation, item.countryCode);
              if (!countryInfo || item.isActive === 'N') return <></>
              return (
                  <tr key={item.countryCode}>
                    <td className="text-center">
                      <div className="btn-group-toggle btn-group-checkbox btn-check"
                           data-toggle="buttons">
                        <label className={`btn-checkbox ${item.isSelected && "checked"}`}><input
                            onClick={() => this.props.onCheck(item.countryCode)}
                            type="checkbox"/></label>
                      </div>
                    </td>
                    <td>
                      {this.props.t(item.countryCode)}
                    </td>
                    <td>
                      {item.productName}
                    </td>
                    <td>
                      {item.currencyCode}
                    </td>
                    <td>
                      {item.productPrice}
                    </td>
                    <td>
                      <input type="number" className="form-control text-center"
                             value={item.promotionPrice} placeholder={"(" +
                          countryInfo.minimumSaleableAmount
                          + " ~ " +
                          countryInfo.maximumSaleableAmount
                          + ")"}
                             min={countryInfo.minimumSaleableAmount}
                             max={countryInfo.maximumSaleableAmount}
                             onChange={(event) => this.props.onCountryProductPriceChange(item.countryCode, event)}/>
                    </td>
                    <td>
                      <ValidPeriodDateTimePicker
                        startDatePicker={item.validPeriodStart}
                        endDatePicker={item.validPeriodEnd}
                        startTimePicker={item.promotionStartTime}
                        endTimePicker={item.promotionEndTime}
                        country={item.countryCode}
                        handleDatepickerInit={this.props.handleDatepickerInit}
                        onTimePickerChange={this.props.onTimePickerChange}
                      />
                    </td>
                    <td>
                      <div className="row">
                        <div className="col-md-12 input-group">
                          <div className="col-md-8 ml-n2 pl-n2 pr-n1">
                            <input
                              type="number"
                              style={{ paddingRight: "1px", paddingLeft: "8px" }}
                              className="form-control text-center"
                              value={item.promotionSubscriptionCycle} placeholder={"0"}
                              onChange={(event) => this.props.onCountryBenefitPeriodChange(item.countryCode, event)}
                            />
                          </div>
                          <div className="col-md-3 pl-n2">
                            <label className="col-form-label ml-n3">
                              {this.props.t(this.props.billingPeriodType)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
              )
            })
          }
          </tbody>
        </table>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countriesInformation: state.countriesInformation,
  };
};


export default connect(mapStateToProps)(
    withTranslation()(DataTableWrapperForFlexibleOffer)
);
