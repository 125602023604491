import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone} from 'react-bootstrap-table2-paginator';
import '../../css/react-bootstrap-table2.css';
import '../../css/bootstrap-extend.css';
import {withTranslation} from 'react-i18next';
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import PaginationListStandalone from './PaginationListStandalone';
import {Link} from 'react-router-dom';

function empty() {
}

// TODO styles should be changed
class DataTableWrapperForListing extends React.Component {

  // See https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html#ontablechange-function
  delegateTableChange = (type, newState) => {
    if (type === 'sort' && this.props.onTableSort) {
      this.props.onTableSort(newState.sortOrder, newState.sortField);
    } else {
      if (this.props.onTableChange) {
        this.props.onTableChange(newState.page, newState.sizePerPage);
      }
    }
  };

  // See https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Pagination&selectedStory=Pagination%20Hooks&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
  delegateSizePerPageChange = (sizePerPage, page) => {
    if (this.props.onSizePerPageChange) {
      this.props.onSizePerPageChange(page, sizePerPage);
    }
  };

  delegateOnSelect = (row, isSelect, rowIndex, e) => {
    if (this.props.onRowSelect) {
      this.props.onRowSelect(isSelect, [row]);
    }
    e.preventDefault();
  };

  delegateOnSelectAll = (isSelect, rows, e) => {
    if (this.props.onRowSelect) {
      this.props.onRowSelect(isSelect, rows);
    }

    e.preventDefault();
  };

  // See https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
  selectionHeaderRenderer = (props) => {
    return (
        <div className="btn-group-toggle btn-group-checkbox btn-check">
          <label className={`btn-all-checkbox checkbox-all ${(props.indeterminate || props.checked) && "checked"}`}>
            <input type={props.mode} checked={props.checked} onChange={empty}/>
          </label>
        </div>
    );
  };

  // See https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
  selectionRenderer = ({mode, checked, disabled}) => {
    return (
        <div className={`btn-group-toggle btn-group-checkbox btn-check ${disabled && "disabled"}`}>
          <label className={`btn-checkbox ${checked && "checked"} ${disabled && "disabled"}`}>
            <input type={mode} checked={checked} disabled={disabled} onChange={empty}/>
          </label>
        </div>
    );
  };

  // See https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Header%20Columns&selectedStory=Column%20Format%20with%20Filter%20and%20Sort&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
  columnHeaderRenderer = (column, colIndex, {sortElement}) => {
    return (
        // eslint-disable-next-line
        <Link to={{}} className="react-table-sorter">
          {column.text}
          {sortElement}
        </Link>
    );
  };

  sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}) => (
      <label>
        <SelectrWrapper
            value={currSizePerPage}
            onChange={(e) => {
              if (e) {
                onSizePerPageChange(e.value);
              }
            }}>
          {options.map(option => <option key={option.text} value={option.text}>{option.text}</option>)}
        </SelectrWrapper>
      </label>
  );

  paginationTotalRenderer = (from, to, size) => (
      <div className="react-bootstrap-table-pagination-total">
        View {from} - {to} of {size}
      </div>
  );

  render() {
    let injectedColumns = [];
    if (!this.props.injectedColumns) {
      injectedColumns = this.props.columns.map(column => {
        return {
          ...column,
          headerFormatter: this.columnHeaderRenderer,
          title: column.titleHeader ? function callback(cell, row, rowIndex, colIndex) { return row[column.titleHeader] } : !column.hideTitle,
        }
      });
    }
    return (
        // Explore https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
        // API doc https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html
        <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              showTotal: true,

              page: this.props.page,
              sizePerPage: this.props.sizePerPage,
              totalSize: this.props.totalSize,

              sizePerPageList: [
                {text: '15', value: 15},
                {text: '30', value: 30},
                {text: '60', value: 60},
                {text: '100', value: 100},
              ],

              onSizePerPageChange: this.delegateSizePerPageChange,
              sizePerPageRenderer: this.sizePerPageRenderer,
              paginationTotalRenderer: this.paginationTotalRenderer,

              alwaysShowAllBtns: true
            })}>
          {({paginationProps, paginationTableProps}) => {
            return (
                <>
                  <BootstrapTable
                      wrapperClasses={this.props.wrapperClasses}
                      classes={`table-hover table-data ${this.props.tableStyle} ${this.props.noSelect && "no-select"}`}
                      bodyClasses="text-center"
                      remote={{pagination: true, filter: false, sort: true, cellEdit: false}}
                      onTableChange={this.delegateTableChange}
                      onTableSort={this.delegateTableChange}
                      keyField={this.props.keyField}
                      columns={this.props.injectedColumns ? this.props.injectedColumns : injectedColumns}
                      data={this.props.rows}
                      headerClasses={this.props.headerClass}
                      rowStyle={this.props.rowStyle}
                      selectRow={{
                        mode: 'checkbox',
                        clickToSelect: false,
                        hideSelectColumn: this.props.hideSelectColumn,
                        nonSelectable: this.props.nonSelectableRows,
                        onSelect: this.delegateOnSelect,
                        onSelectAll: this.delegateOnSelectAll,
                        selectionHeaderRenderer: this.selectionHeaderRenderer,
                        selectionRenderer: this.selectionRenderer,
                        selected: this.props.selectedProductIdList
                      }}
                      {...paginationTableProps}/>
                  {!this.props.disableFooter &&
                  <div className="react-bootstrap-table-pagination">
                    <div className="react-bootstrap-table-pagination-list">
                      <PaginationListStandalone {...paginationProps}/>
                    </div>
                    <div className="react-bootstrap-table-pagination-dropdown">
                      <SizePerPageDropdownStandalone {...paginationProps}/>
                    </div>
                    <PaginationTotalStandalone {...paginationProps}/>
                  </div>}
                </>
            );
          }}
        </PaginationProvider>
    );
  }
}

export default withTranslation()(DataTableWrapperForListing);