import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {isSelectedAppChanged} from '../../ducks/carousel';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import SupportFooter from '../SupportFooter';
import {handleHttpError} from "../../utils/ErrorUtils";
import {API_SUPPORT_FAQ_LIST} from "../../utils/urlConstants";
import {startLoading, stopLoading} from "../../ducks/loading";

class FaqPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      faqListPage: 0,
      totalListSize: 0,
      faqListSizePerPage: 0,
      fetchedFaqList: [],
      searchParams: {},
    }
  }

  fetchDefaultFaqList = (page, sizePerPage) => {
    const params = {
      sortName: "faqTitle",
      sortOrder: "DESC",
      pageNumber: page,
      limit: sizePerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUPPORT_FAQ_LIST, {
            params: params
          })
          .then(response => {
            this.setState({
              faqListPage: page,
              faqListSizePerPage: sizePerPage,
              fetchedFaqList: response.data.data.map(item => {
                return {...item, faqShow: false}
              }),
              totalListSize: response.data.records
            });
            if (this.state.fetchedFaqList.length) {
              this.state.fetchedFaqList[0].faqShow = true;
            }
            this.props.stopLoading();
          })
          .catch(error => {
            this.props.stopLoading();
            handleHttpError(this.props.t, this.props.history, error);
          });
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      faqListPage: page,
      faqListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  handleSearchClick = (searchParams) => {
    if (!searchParams.keywordText) {
      searchParams.keywordText = "";
    }
    const params = {
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      faqListPage: 1
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.sortOrder = "DESC";
    searchParams.pageNumber = this.state.faqListPage;
    searchParams.limit = this.state.faqListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      this.props.startLoading();
      this.httpClient
          .get(API_SUPPORT_FAQ_LIST, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              fetchedFaqList: response.data.data,
              totalListSize: response.data.records
            });
            this.props.stopLoading();
          })
          .catch(error => {
            this.props.stopLoading();
            handleHttpError(this.props.t, this.props.history, error);
          });
    }
  };

  handleAccordion = (id) => {
    this.setState(prevState => {
      return {
        fetchedFaqList: prevState.fetchedFaqList.map((item) => {
          if (item.faqId === id) {
            item.faqShow = !item.faqShow;
          } else {
            item.faqShow = false;
          }
          return item;
        })
      }
    });
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: ["FAQ_TITLE", "FAQ_CONTENT"],
                showDownload: false,
              }}
              datePanel={{
                show: false,
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              keyField="faqId"
              injectedColumns={[
                {
                  dataField: "faqId", title: false, hidden: true, headerFormatter: () => {
                  }
                },
                {
                  dataField: "faqRow", title: false, classes: () => "text-left border-none", headerFormatter: () => {
                  }
                }
              ]}
              page={this.state.faqListPage}
              sizePerPage={this.state.faqListSizePerPage}
              totalSize={this.state.totalListSize}
              hideSelectColumn={true}
              headerClass={"col-hidden"}
              rows={this.state.fetchedFaqList.map(faq => {
                return {
                  faqId: faq.faqId,
                  faqRow: <div className={`accordion ${faq.faqShow && "show"}`}>
                    <div className="accordion-header">
                      <Link to={{}} onClick={() => this.handleAccordion(faq.faqId)} className="questions accordion-toggle">{faq.faqTitle}</Link>
                    </div>
                    <div className="accordion-body pb-0 pt-6 mt-9">
                      <div className="answer">
                        <div
                            dangerouslySetInnerHTML={{
                              __html: faq.faqContent
                            }}/>
                      </div>
                    </div>
                  </div>,
                };
              })}
              onTableChange={this.handleTableChange}/>
          <SupportFooter
              feedbackTitle={this.props.t("SEND_FEEDBACK")}
              feedbackbutton={this.props.t("CUSTOMER_FEEDBACK")}
          />
        </div>
    );
  }

  componentDidMount() {
    this.fetchDefaultFaqList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultFaqList(1, 15);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FaqPanel)));
