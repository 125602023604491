import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {couponAttributes, couponTypeKey2Code} from '../../utils/couponAttributes';
import {toast} from 'react-toastify';
import ApplyAllCouponPopup from '../popup/ApplyAllCouponPopup';
import CountryListPopUp from '../popup/CountryListPopUp';
import {getAppFromSessionData} from '../../ducks/sessionData';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {getCountriesInformation, isCountriesInformationChanged, loadCountriesInformation} from '../../ducks/countriesInformation';
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import DataTableWrapperForCouponIssue from '../datable/DataTableWrapperForCouponIssue';
import {URL_CAMPAIGN_COUPON, URL_GET_COUNTRY_DATA_API, URL_GET_COUNTRY_INFORMATION_API, URL_GET_COUPON_CREATE_API, URL_GET_COUPON_PRODUCT_COUNTRYLIST_DATA_API, URL_GET_COUPON_PRODUCTLIST_DATA_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {convertToProductId} from '../../utils/statisticsUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import {postAjaxCoupon, setAllCouponInformationStartEndPeriodAsDefaultCountry, setValidStartEndPeriodForCountry, closePopupApplyAllValidation} from "../../utils/CouponUtils";
import {fetchCountries} from '../../utils/CountryListUtils';

const DISCOUNT_RATE_CEIL = 100;
const DISCOUNT_RATE_FLOOR = 0;
const TOTAL_ISSUED_CEIL = 10000;
const TOTAL_ISSUED_FLOOR = 0;

class CouponIssuePanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      couponName: "",
      selectedCouponType: null,
      isApplyAllPopupIssueOpen: false,
      isProductTypeApp: true,
      isTooltipOpen: false,
      countryPricing: [],
      productList: [],
      dataLoaded: false,
      disabledCountries: []
    }
  }

  initData = () => {
    if (this.props.sessionData.apps.length && this.state.countryData.length && this.props.countriesInformation.length) {
      this.setState({
        couponInformationByCountries: [],
        selectedCountries: [],
        dataLoaded: true
      });
    }
  };

  fetchProductList = () => {
    return new Promise((resolve, reject) => {
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        couponType: couponTypeKey2Code[this.state.selectedCouponType]
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COUPON_PRODUCTLIST_DATA_API, {
            params: params
          })
          .then(response => {
            const maxFreeTrialPeriod = {};
            const productList = response.data.map((e) => {
              return {key: e.productId, value: e.productName + "(" + e.cpProductId + ")"}
            });
            if (this.state.selectedCouponType === "FREE_TRIAL") {
              response.data.forEach((e) => {
                maxFreeTrialPeriod[e.productId] = e.freeTrialPeriod;
              })
            }
            this.setState({
              productList: productList,
              maxFreeTrialPeriod: maxFreeTrialPeriod,
              selectedProduct: null
            }, () => {
              resolve()
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  filterInput = (event) => {
    let input = event.target.value;
    const regex = /[";:&<>,|]/gi;
    return input.replace(regex, "");
  };

  handleChangeCouponName = (event) => {
    let input = event.target.value;
    this.setState({
      couponName: input
    });
  };

  handleSelectedProductChange = (selected) => {
    if (selected) {
      this.setState({
        selectedProduct: selected,
        couponInformationByCountries: [],
        selectedCountries: [],
      });
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COUPON_PRODUCT_COUNTRYLIST_DATA_API, {
            params: {productId: selected.key}
          })
          .then(response => {
            let productCountries = response.data;
            this.setState({
              countryPricing: productCountries.reduce(function (map, obj) {
                map[obj.country] = obj.productPrice;
                return map
              }, {}),
              disabledCountries: this.props.countriesInformation.map(e => e.country)
                  .filter(function (val) {
                    return productCountries.map(e => e.country).indexOf(val) === -1;
                  }),
            }, () => this.setState(prevState => {
              let disabledCountries = prevState.disabledCountries;
              return {
                couponInformationByCountries: prevState.couponInformationByCountries.filter(function (val) {
                  return !disabledCountries.includes(val.country)
                }),
                selectedCountries: prevState.selectedCountries.filter(function (val) {
                  return !disabledCountries.includes(val)
                })
              }
            }));
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    } else {
      this.setState({
        selectedProduct: null,
        disabledCountries: []
      });
    }
  };

  handleSelectedCouponTypeChange = (selected) => {
    if (selected) {
      this.setState({selectedCouponType: selected.value}, () => {
        if (!this.state.isProductTypeApp) {
          this.fetchProductList();
        }
      });
    } else {
      this.setState({selectedCouponType: null});
    }

  };

  handleFreeTrialPeriodChange = (event) => {
    let input = event.target.value;
    this.setState({
      freeTrialPeriod: input
    });
  };

  handleFreeTrialPeriodBlur = (event) => {
    let input = event.target.value;
    if (!Number(input)) {
      toast(this.props.t("ENTER_VALID_NUMBER"));
      this.setState({
        freeTrialPeriod: ''
      });
      return
    }
    if (Number(input) <= 0) {
      toast(this.props.t("ENTER_VALID_NUMBER"));
      this.setState({
        freeTrialPeriod: ''
      });
      return
    }
    if (Number(input) > Number(this.state.maxFreeTrialPeriod[this.state.selectedProduct.key])) {
      toast(this.props.t("COUPON_FREE_TRIAL_PERIOD_ALERT"));
      this.setState({
        freeTrialPeriod: Number(this.state.maxFreeTrialPeriod[this.state.selectedProduct.key])
      });
      return
    }
    this.setState({
      freeTrialPeriod: input
    });
  };

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  checkNewCountryAdded = (country, couponInformationByCountries) => {
    let oldInfo = null;
    couponInformationByCountries.forEach(item => {
      if (item.country === country) {
        oldInfo = item;
      }
    });
    return oldInfo;
  };

  convertToCouponInformationByCountries = (selectedCountries, isDefault) => {
    return selectedCountries.map(country => {
      const isAlreadyAdded = this.checkNewCountryAdded(country, this.state.couponInformationByCountries);
      if (isAlreadyAdded) {
        return isAlreadyAdded;
      } else {
        return {
          country: country,
          isDefaultCountry: isDefault,
          isSelected: false,
          isDisabled: isDefault,
          discountAmount: 0,
          discountRate: 0,
          totalIssued: 0,
          validPeriodStart: {
            getValue: function () {
              return (new Date()).toISOString().slice(0, 10);
            }
          },
          validPeriodEnd: {
            getValue: function () {
              return (new Date()).toISOString().slice(0, 10);
            }
          },
        }
      }
    });
  };

  handleCountryListModalClose = (isSave, selectedCountries) => {
    this.setState({isCountryListPopupOpen: false});
    if (!isSave) {
      return;
    }
    this.setState({selectedCountries: selectedCountries});
    this.setState({couponInformationByCountries: this.convertToCouponInformationByCountries(selectedCountries, false)});
  };

  extractCountryList = (couponInformationByCountries) => {
    return couponInformationByCountries.map(item => item.country);
  };

  handleAddCountry = () => {
    this.setState({isCountryListPopupOpen: true});
  };

  handleDeleteCountry = () => {
    this.setState(prevState => {
      let newCouponInformationByCountries = prevState.couponInformationByCountries.filter(function (item) {
        return !item.isSelected;
      });
      return {
        couponInformationByCountries: newCouponInformationByCountries,
        selectedCountries: this.extractCountryList(newCouponInformationByCountries)
      }
    }, () => toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_COUNTRIES_DELETED")));
  };

  handleCheck = (country, isDisabled) => {
    if (!isDisabled) {
      this.setState(prevState => {
        return {
          couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
            if (item.country === country) {
              item.isSelected = !item.isSelected;
            }
            return item;
          })
        }
      });
    }
  };

  handleCheckAll = () => {
    const allChecked = this.state.couponInformationByCountries.filter(item => item.isDisabled === false).map(item => item.isSelected).every(item => item);
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (!item.isDisabled) {
            item.isSelected = !allChecked;
          }
          return item;
        })
      }
    });
  };

  handlePopupIssueApplyAll = () => {
    this.setState({ isApplyAllPopupIssueOpen: true });
  }

  handleClosePopupIssueApplyAll = (isSave, newValue) => {
    if (!isSave) {
      this.setState({ isApplyAllPopupIssueOpen: false });
      return;
    }
    let closePopupIssue = closePopupApplyAllValidation(newValue, TOTAL_ISSUED_CEIL, this.state.couponInformationByCountries, this.props.t);
    if (closePopupIssue) {
      this.setState({ isApplyAllPopupIssueOpen: false });
      this.handleApplyAllTotalIssued(newValue.noOfCoupon);
      this.handleApplyAllPeriod(newValue.validStart, newValue.validEnd);
    }
  }

  handleApplyAllTotalIssued = (noOfCoupon) => {
    if (noOfCoupon.length === 0) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_ENTER_NUMBER_OF_COUPON_FOR_DEFALUT_COUNTRY"));
      return;
    }

    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          item.totalIssued = noOfCoupon;
          return item;
        })
      }
    });
    toast(this.props.t("COUPON_CREATE_ALERT_ERROR_NUMBER_OF_COUPONS_APPLIED_TO_ALL_COUNTRIES"));
  };
  handleApplyAllPeriod = (validStart, validEnd) => {
    this.setState(prevState => setAllCouponInformationStartEndPeriodAsDefaultCountry(prevState.couponInformationByCountries, validStart, validEnd, this.props.t));
  }

  handleDiscountAmountChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            item.discountAmount = input;
          }
          return item;
        })
      }
    });
  };

  handleDiscountAmountBlur = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            if (Number(input)) {
              if (Number(input) <= 0) {
                toast(this.props.t("DISCOUNT_RATE_MUST_BE_GREATER_THAN_ZERO"));
                item.discountAmount = '';
              }
            } else {
              toast(this.props.t("ENTER_VALID_NUMBER"));
              item.discountAmount = '';
            }
          }
          return item;
        })
      }
    });
  };

  handleDiscountRateChange = (country, event) => {
    let input = event.target.value;
    this.setState({applyAllDiscountRate: false});
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            item.discountRate = input;
          }
          return item;
        })
      }
    });
  };

  handleDiscountRateBlur = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            if (Number(input)) {
              if (Number(input) > DISCOUNT_RATE_CEIL || Number(input) < DISCOUNT_RATE_FLOOR) {
                toast(this.props.t("DISCOUNT_RATE_MUST_BE_BETWEEN_ZERO_AND_HUNDRED"));
                item.discountRate = '';
              }
            } else {
              toast(this.props.t("ENTER_VALID_NUMBER"));
              item.discountRate = '';
            }
            this.setState({applyAllDiscountRate: false});
          }
          return item;
        })
      }
    });
  };

  handleTotalIssuedChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          if (item.country === country) {
            item.totalIssued = input;
          }
          return item;
        })
      }
    });
  };

  handleTotalIssuedBlur = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponInformationByCountries: prevState.couponInformationByCountries.map((item) => {
          const decimal_index = input.indexOf('.');
          ;
          if (item.country === country) {
            if (Number(input)) {
              if (Number(input) > TOTAL_ISSUED_CEIL) {
                toast(this.props.t("YOU_CAN_ISSUE_UP_TO_TEN_THOUSAND_COUPONS_PER_COUNTRY"));
                item.totalIssued = 10000;
              }
              if (decimal_index > -1) {
                toast(this.props.t("ENTER_VALID_NUMBER"));
                item.totalIssued = 0;
              }
              if (Number(input) <= TOTAL_ISSUED_FLOOR) {
                toast(this.props.t("NUMBER_OF_COUPONS_SHOULD_BE_GREATER_THAN_ZERO"));
                item.totalIssued = 0;
              }
            } else {
              toast(this.props.t("ENTER_VALID_NUMBER"));
              item.totalIssued = 0;
            }
          }
          return item;
        })
      }
    });
  };
  delegateDatePickerEvent = (isStart, datepicker, country) => {
    this.setState(prevState => setValidStartEndPeriodForCountry(isStart, datepicker, country, prevState.couponInformationByCountries))
  }

  handleSave = () => {
    if (this.state.couponName.trim().length === 0) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_ENTER_COUPON_NAME"));
      return
    }

    let totalIssuedFlag = false;
    let sumTotalIssued = 0;
    this.state.couponInformationByCountries.forEach((e) => {

      if (e.totalIssued === "0" || e.totalIssued <= TOTAL_ISSUED_FLOOR || e.totalIssued > TOTAL_ISSUED_CEIL) {
        totalIssuedFlag = true;
      } else {
        sumTotalIssued += parseInt(e.totalIssued);
      }
    });
    if (totalIssuedFlag) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_NUMBER_OF_COUPON_NOT_VALID"));
      return
    }
    if (sumTotalIssued > 100000) {
      toast(this.props.t("YOU_CAN_ISSUE_UP_TO_ONE_LAKH_COUPONS"));
      return
    }
    if (this.state.selectedCouponType === "DISCOUNT_AMOUNT") {
      let discountAmountFlag = false;
      this.state.couponInformationByCountries.forEach((e) => {
        if (e.discountAmount === "0" || e.discountAmount <= DISCOUNT_RATE_FLOOR) {
          discountAmountFlag = true;
        }
      });
      if (discountAmountFlag) {
        toast(this.props.t("COUPON_CREATE_ALERT_ERROR_DISCOUNT_AMOUNT_CANNOT_BE_ZERO"));
        return
      }
    }

    if (this.state.selectedCouponType === "DISCOUNT_RATE") {
      let discountRateFlag = false;
      this.state.couponInformationByCountries.forEach((e) => {
        if (e.discountRate === "0" || e.discountRate === 0 || e.discountRate <= DISCOUNT_RATE_FLOOR || e.discountRate >= DISCOUNT_RATE_CEIL) {
          discountRateFlag = true;
        }
      });
      if (discountRateFlag) {
        toast(this.props.t("COUPON_CREATE_ALERT_ERROR_DISCOUNT_RATE_CANNOT_BE_ZERO"));
        return
      }
    }

    let validPeriodFlag = false;
    let validPeriodEndFlag = false;
    this.state.couponInformationByCountries.forEach((e) => {
      if (new Date(e.validPeriodStart.getValue()) > new Date(e.validPeriodEnd.getValue())) {
        validPeriodFlag = true;
      }
      if (new Date() > new Date(e.validPeriodEnd.getValue())) {
        validPeriodEndFlag = true;
      }
    });
    if (validPeriodFlag) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_VALID_PERIOD_NOT_VALID"));
      return
    }
    if (validPeriodEndFlag) {
      toast(this.props.t("COUPON_CREATE_ALERT_ERROR_VALID_PERIOD_END_NOT_VALID"));
      return
    }

    let params = {
      cpAppId: this.props.carousel.selectedAppId,
      couponName: this.state.couponName.trim(),
      couponTargetRange: this.state.isProductTypeApp ? "APP" : "PRD",
      couponType: couponTypeKey2Code[this.state.selectedCouponType],
      productId: this.state.selectedProduct ? this.state.selectedProduct.key : "",
      cpProductId: this.state.selectedProduct ? convertToProductId(this.state.selectedProduct.value) : "",
      freeTrialPeriod: this.state.freeTrialPeriod,
      couponIssueList: this.state.couponInformationByCountries.map((e) => {
        return {
          country: e.country,
          discountAmount: parseFloat(e.discountAmount),
          discountRate: parseFloat(e.discountRate),
          totalIssued: parseInt(e.totalIssued),
          validStartDate: e.validPeriodStart.getValue(),
          validEndDate: e.validPeriodEnd.getValue(),
        }
      }),
    };
    postAjaxCoupon(URL_GET_COUPON_CREATE_API, params, this.props.t, this.props.history, this.props.startLoading, this.props.stopLoading,
        this.httpClient, URL_CAMPAIGN_COUPON, 'COUPON_CREATE_ALERT_ERROR_CREATING_COUPON', 'COUPON_CREATE_ALERT_COUPON_SUCCESSFULLY_CREATED');
  };

  initCountryData = () => {
    this.props.startLoading();
    fetchCountries(URL_GET_COUNTRY_DATA_API, this.props.t, this.props.history)
        .then(countryData => {
          this.setState({countryData: countryData});
          fetchCountries(URL_GET_COUNTRY_INFORMATION_API, this.props.t, this.props.history)
              .then((countryInformation => {
                this.props.stopLoading();
                this.props.loadCountriesInformation(countryInformation);
                this.initData();
              }));
        });
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    let couponTypeOptions = couponAttributes.couponType.value;
    if (this.state.isProductTypeApp) {
      couponTypeOptions = couponTypeOptions.filter((e) => e !== "FREE_TRIAL");
    }
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t("ENTER_COUPON_DETAILS")}</strong></h2>
            <p className="text-right text-success small float-right mt-1 mb-0">
              <span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}
            </p>
          </div>
          <form className="needs-validation" noValidate>
            <div className="form-group-wrap">
              <div className="form-group row">
                <label className="col col-form-label">{this.props.t("COUPON_NAME")}<span className="text-success align-middle">*</span></label>
                <div className="col">
                  <div className="textarea_count">
                    <textarea maxLength="100"
                              style={{minHeight: "90px"}}
                              className="form-control text_counter"
                              value={this.state.couponName}
                              onChange={(event) => {
                                event.target.value = this.filterInput(event);
                                this.handleChangeCouponName(event);
                              }}
                              placeholder={this.props.t("COUPON_NAME_PLACEHOLDER")}/>
                    <div className="counts text-right"><span className="text_count">{this.state.couponName.length}</span> / 100Byte</div>
                  </div>
                </div>
              </div>
              <div className="row mb-20">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TARGET_RANGE")}</label>
                    <div className="col">
                      <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                        <label className={`btn btn-outline-500 rounded btn-auto mr-2 ${this.state.isProductTypeApp && "checked"}`}>
                          <input type="radio" name="options" onClick={() => {
                            this.setState({isProductTypeApp: true, selectedProduct: null, selectedCouponType: null, disabledCountries: []})
                          }}/>{this.props.t("APP")}
                        </label>
                        <label className={`btn btn-outline-500 rounded btn-auto mr-2 ${!this.state.isProductTypeApp && "checked"}`}>
                          <input type="radio" name="options" onClick={() => {
                            this.setState({isProductTypeApp: false, selectedProduct: null, selectedCouponType: null})
                          }}/>{this.props.t("PRODUCT")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("COUPON_TYPE")}</label>
                    <div className="col">
                      <div className="input-group-inline-block">
                        <div className="input-group">
                          <div className="input-group-append flex-100">
                            <SelectrWrapper
                                className="form-control"
                                clearable={false}
                                isDisable={false}
                                placeholder={this.props.t("SELECT")}
                                value={this.state.selectedCouponType}
                                onChange={(selected) => this.handleSelectedCouponTypeChange(selected)}>
                              {couponTypeOptions.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                            </SelectrWrapper>
                          </div>
                          <div className="input-group-append d-flex align-items-center ml-10">
                            <span className={`tooltips right ${this.state.isTooltipOpen && "show"}`}>
                              <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
                              <div className="tooltips_body" style={{width: "550px"}}>
                                <a onClick={this.handleTooltipCloseClick} className="tooltip-toggle close"/>
                                {this.props.t("COUPON_ISSUE_TOOLTIP1")}<br/>
                                {this.props.t("COUPON_ISSUE_TOOLTIP2")}<br/>
                                {this.props.t("COUPON_ISSUE_TOOLTIP3")}<br/>
                                {this.props.t("COUPON_ISSUE_TOOLTIP4")}<br/>
                                {this.props.t("COUPON_ISSUE_TOOLTIP5")}
                              </div>
									          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-20">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TARGET_APP")}<span className="text-muted">(ID)</span></label>
                    <div className="col">
                      <input type="text" className="form-control" value={app.name + " (" + app.appId + ")"} readOnly="readonly"/>
                    </div>
                  </div>
                </div>
                {this.state.selectedCouponType === "FREE_TRIAL" && this.state.selectedProduct &&
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("FREE_TRIAL_PERIOD")}</label>
                    <div className="col">
                      <input type="number"
                             className="form-control"
                             placeholder={"Maximum value : " + this.state.maxFreeTrialPeriod[this.state.selectedProduct.key]}
                             min={0}
                             max={this.state.maxFreeTrialPeriod[this.state.selectedProduct.key]}
                             value={this.state.freeTrialPeriod}
                             onBlur={(event) => {
                               this.handleFreeTrialPeriodBlur(event);
                             }}
                             onChange={(event) => this.handleFreeTrialPeriodChange(event)}/>
                    </div>
                  </div>
                </div>}
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col col-form-label">{this.props.t("TARGET_PRODUCT")}<span className="text-muted">(ID)</span><span className="text-success align-middle">*</span></label>
                    <div className="col">
                      <SelectrWrapper
                          className="form-control"
                          clearable={false}
                          isDisable={this.state.isProductTypeApp || !this.state.selectedCouponType}
                          placeholder={this.props.t("SELECT")}
                          value={this.state.selectedProduct ? this.state.selectedProduct.value : ""}
                          onChange={(selected) => this.handleSelectedProductChange(selected)}>
                        {this.state.productList.map(v => <option key={v.key} value={v.value}>{v.value}</option>)}
                      </SelectrWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.selectedCouponType === "DISCOUNT_AMOUNT" && this.props.t("DISCLAIMER_TEXT_WHEN_COUPON_TYPE_IS_DISCOUNT_AMOUNT")}
            {(this.state.isProductTypeApp || this.state.selectedProduct) && this.state.selectedCouponType &&
            <div className="clearfix mb-10 mt-60">
              <h2 className="float-left mb-0"><strong>{this.props.t("COUNTRY_SETTING")}</strong></h2>
              <div className="float-right">
                <div className="btn-group-toggle btn-group-checkbox ml-7" data-toggle="buttons">
                  <button
                    type="button"
                    className={`btn btn-sm btn-auto btn-success ${this.state.readOnly && "disabled"}`}
                    disabled={this.state.readOnly}
                    onClick={this.handlePopupIssueApplyAll}>
                    {this.props.t("APPLY_ALL")}
                  </button>
                </div>
              </div>
            </div>}
            {(this.state.isProductTypeApp || this.state.selectedProduct) && this.state.selectedCouponType &&
              (this.state.couponInformationByCountries.length > 0 ?
              <div className="table-overlay">
                <DataTableWrapperForCouponIssue couponCountryData={this.state.couponInformationByCountries}
                  couponType={this.state.selectedCouponType}
                  showPrice={!this.state.isProductTypeApp}
                  countryPricing={this.state.countryPricing}
                  handleDatepickerInit={this.delegateDatePickerEvent}
                  handleCheck={this.handleCheck}
                  handleCheckAll={this.handleCheckAll}
                  handleDiscountAmountChange={this.handleDiscountAmountChange}
                  handleDiscountAmountBlur={this.handleDiscountAmountBlur}
                  handleDiscountRateChange={this.handleDiscountRateChange}
                  handleDiscountRateBlur={this.handleDiscountRateBlur}
                  handleTotalIssuedChange={this.handleTotalIssuedChange}
                  handleTotalIssuedBlur={this.handleTotalIssuedBlur} />
              </div>
              :
              <div className="form-group-wrap d-flex justify-content-center">
                <div>
                  <button type="button"
                    className="btn btn-sm btn-auto btn-outline-primary"
                    onClick={this.handleAddCountry}>{this.props.t("ADD_COUNTRY")}
                  </button>
                </div>
              </div>)
            }
            {(this.state.isProductTypeApp || this.state.selectedProduct) && this.state.selectedCouponType &&
            <div className="row mt-30">
              <div className="col-6">
                <button type="button" className="btn btn-sm btn-auto btn-success" onClick={this.handleAddCountry}>{this.props.t("ADD_COUNTRY")}</button>
                <button type="button" className="btn btn-sm btn-auto btn-outline-success ml-8"
                        disabled={!(this.state.couponInformationByCountries.filter(item => item.isDisabled === false).map(item => item.isSelected).some(item => item))}
                        onClick={this.handleDeleteCountry}>{this.props.t("DELETE_COUNTRY")}</button>
              </div>
              <div className="col-6 text-right">
                <button type="button"
                        className={`btn btn-sm btn-auto ml-10 ${this.state.couponInformationByCountries <= 0 ? "btn-success disabled" : "btn-primary"}`}
                        onClick={() => this.handleSave()}
                        disabled={this.state.couponInformationByCountries <= 0}
                >{this.props.t("SAVE")}</button>
              </div>
            </div>}
          </form>
          <CountryListPopUp
              countryData={this.state.countryData}
              open={this.state.isCountryListPopupOpen}
              title={this.props.t("ADD_COUNTRY")}
              readOnly={false}
              initCountries={this.state.selectedCountries}
              handleModalClose={this.handleCountryListModalClose}
              disabledCountry={[...this.state.disabledCountries]}
              isMultipleSelect={true}
              isContinentSelect={true}
              disableIndiaSelection={true}
          />

          <ApplyAllCouponPopup
            open={this.state.isApplyAllPopupIssueOpen}
            handleModalClose={this.handleClosePopupIssueApplyAll}
            readOnly={false}
            couponCountryData={[getCountriesInformation(this.props.countriesInformation, app.defaultCountry)]}
          />
        </div>
    );
  }

  componentDidMount() {
    this.initCountryData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)
        || isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)) {
      this.initCountryData();
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  loadCountriesInformation: loadCountriesInformation,
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CouponIssuePanel)));
