import React, {Component} from 'react';
import Content from "../Content";
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from "react-router-dom";
import Logo from "../Logo";
import {URL_INDEX_PAGE} from "../../utils/urlConstants";
import {connect} from 'react-redux';
import {triggerGetSessionData} from '../App';

class ErrorPage extends Component {
  handleGoToMainPageButtonClick = () => {
    triggerGetSessionData();
    this.props.history.push(URL_INDEX_PAGE);
  }

  render() {
    let code = this.props.location.search.split("=")[1];
    if (!code) {
      code = '404';
    }
    return (
        <div id="wrap" className={"clearfix"}>
          <Content className={"w-100"}>
            <main id="contentMain">
              <div className="container">
                <Logo/>
                {Object.is(code, '500') &&
                <div className="error-page error-page-500 text-center">
                  <h2 className="error-title text-primary">{this.props.t("HTTP_STATUS_500")}</h2>
                  <h3 className="error-sub-title text-primary">{this.props.t("HTTP_STATUS_500_MSG")}</h3>
                  <p>{this.props.t("ERROR_MESSAGE_500_LINE1")}</p>
                  <p>{this.props.t("ERROR_MESSAGE_500_LINE2")}</p>
                  {!this.props.sessionData.requestFromSellerOffice &&
                  <div className="mb-40">
                    <Link to={URL_INDEX_PAGE}>
                      <button type="button" className="btn btn-lg btn-primary btn-error-in" onClick={() => this.handleGoToMainPageButtonClick()}>{this.props.t("GO_TO_MAIN_PAGE")}</button>
                    </Link>
                  </div>}
                </div>
                }
                {(Object.is(code, '400') || Object.is(code, '404')) &&
                <div className={`error-page text-center error-page-${code}`}>
                  <h2 className="error-title text-primary">{Object.is(code, '400') ? this.props.t("HTTP_STATUS_400") : this.props.t("HTTP_STATUS_404")}</h2>
                  <p>{this.props.t("APOLOGY_FOR_INCONVENIENCE")}</p>
                  <p>{Object.is(code, '400') ? this.props.t("ERROR_MESSAGE_400_LINE2") : this.props.t("ERROR_MESSAGE_404_LINE2")}</p>
                  {!this.props.sessionData.requestFromSellerOffice &&
                  <div className="mb-40">
                      <button type="button" className="btn btn-lg btn-primary btn-error-in" onClick={() => this.handleGoToMainPageButtonClick()}>{this.props.t("GO_TO_MAIN_PAGE")}</button>
                  </div>}
                </div>
                }
                {Object.is(code, 'check') &&
                <div className="error-page error-page-check text-center">
                  <h2 className="error-title text-primary">{this.props.t("SERVER_INSPECTION")}</h2>
                  <h3 className="error-sub-title text-primary">{this.props.t("SERVER_CHECK_ERROR_MSG_LINE1")}</h3>
                  <p>{this.props.t("SERVER_CHECK_ERROR_MSG_LINE2")}</p>
                </div>
                }
              </div>
            </main>
          </Content>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
  };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(ErrorPage)));