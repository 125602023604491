import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import SearchBar from "../searchbar/SearchBar";
import {groupAttributes} from "../../utils/memberAndGroupAttributes";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {URL_DELETE_GROUP_API, URL_GET_GROUP_LIST_DATA_API, URL_MEMBER_CREATEGROUP, URL_MEMBER_GROUPMANAGEMENT} from "../../utils/urlConstants";
import BasePopup from "../popup/BasePopup";
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {isSelectedAppChanged} from "../../ducks/carousel";

class GroupListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupListPage: 0,
      totalGroupListSize: 0,
      groupListSizePerPage: 0,
      fetchedGroupList: [],
      selectedGroupIdList: [],
      searchParams: {},
      modal: false,
    };
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
  }

  triggerGroupSearch = () => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName,
      pageNumber: this.state.groupListPage,
      limit: this.state.groupListSizePerPage,
      searchText: this.state.searchParams.searchText
    };
    this.props.startLoading();
    this.httpClient
        .get(URL_GET_GROUP_LIST_DATA_API, {
          params: params
        })
        .then(response => {
          this.setState({
            searchParams: params,
            totalGroupListSize: response.data.records,
            fetchedGroupList: response.data.data
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleSearchClick = (searchParams) => {
    const params = {
      searchText: searchParams.keywordText,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      groupListPage: 1
    }, () => this.triggerGroupSearch())
  };

  handleRowSelect = (isSelect, rows) => {
    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedGroupIdList.find(groupId => row["groupId"] === groupId)) {
            return {
              selectedGroupIdList: [...prevState.selectedGroupIdList, row["groupId"]]
            };
          }
        });
      } else {
        this.setState(prevState => {
          return {
            selectedGroupIdList: prevState.selectedGroupIdList.filter(groupId => row["groupId"] !== groupId)
          };
        })
      }
    });
  };

  handleTableSort = (sortOrder, sortField) => {
    const groupHeaderField2SortName = {
      "groupNameLink": "groupName",
      "menuCount": "menuCount",
      "userCount": "userCount",
      "createdDate": "createdDate"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: groupHeaderField2SortName[sortField] ? groupHeaderField2SortName[sortField] : sortField,
      groupListPage: 1,
    }, () => this.triggerGroupSearch())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      groupListPage: page,
      groupListSizePerPage: sizePerPage,
    }, () => this.triggerGroupSearch());
  };

  handleDeleteGroup = () => {
    this.setState({modal: false});
    const groupIds = this.state.selectedGroupIdList;
    this.props.startLoading();
    this.httpClient
        .post(URL_DELETE_GROUP_API, groupIds)
        .then(() => {
          groupIds.forEach(groupId => {
            this.setState(prevState => {
              return {
                fetchedGroupList: prevState.fetchedGroupList.filter(group => group.groupId !== groupId),
                selectedGroupIdList: prevState.selectedGroupIdList.filter(item => item !== groupId)
              }
            });
          });
          toast(this.props.t("GROUP_DELETED"));
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  render() {
    const nonSelected = this.state.selectedGroupIdList.length === 0;
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("GROUP_NAME"),
                placeholder: this.props.t("PLEASE_ENTER_GROUP_NAME"),
                showDownload: false,
              }}
              datePanel={{
                show: false,
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}
              onDownloadClick={() => console.log("Download clicked from keywordPanel!!")}/>
          <DataTableWrapperForListing
              keyField="groupId" // one of 'dataField'
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "groupId", hidden: true},
                {dataField: "groupName", hidden: true},
                {dataField: "groupNameLink", text: this.props.t(groupAttributes.groupName.key), headerStyle: {width: "40%"}, sort: true, classes: () => "text-left", titleHeader: "groupName"},
                {dataField: "menuCount", text: this.props.t(groupAttributes.menuCount.key), headerStyle: {width: "20%"}, sort: true},
                {dataField: "userCount", text: this.props.t(groupAttributes.memberCount.key), headerStyle: {width: "20%"}, sort: true},
                {dataField: "createdDate", text: this.props.t(groupAttributes.createdDate.key), headerStyle: {width: "20%"}, sort: true},
              ]}
              page={this.state.groupListPage}
              sizePerPage={this.state.groupListSizePerPage}
              totalSize={this.state.totalGroupListSize}
              rows={this.state.fetchedGroupList.map(group => {
                return {
                  ...group,
                  createdDate: group.createdDate.split(' ')[0],
                  groupNameLink: <Link to={{}}
                                       onClick={(e) => {
                                         e.preventDefault();
                                         this.props.history.push(URL_MEMBER_GROUPMANAGEMENT + "/" + group.groupId);
                                       }}>
                    {group.groupName}
                  </Link>
                };
              })}
              nonSelectableRows={ // Should use 'keyField' value
                this.state.fetchedGroupList
                    .map(group => group.userCount ? group.groupId : null)
                    .filter(e => e != null)}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            {/*<div className="float-left">*/}
            <button
                type="button" className={`btn btn-sm btn-success float-left ${nonSelected && "disabled"}`}
                onClick={() => this.setState({modal: true})}>
              {this.props.t("DELETE")}
            </button>
            <Link to={URL_MEMBER_CREATEGROUP}>
              <button type="button" className="btn btn-sm btn-primary float-right">
                {this.props.t("CREATE_GROUP")}
              </button>
            </Link>
          </div>
          <BasePopup
              open={this.state.modal}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modal: false})}
              body={
                <p>{this.props.t("DO_YOU_WANT_TO_DELETE_SELECTED_GROUPS")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleDeleteGroup()}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modal: false})}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    );
  }

  fetchGroupList = (page, sizePerPage) => {
    return new Promise((resolve, reject) => {
      const params = {
        cpAppId: this.props.carousel.selectedAppId,
        sortOrder: "DESC",
        pageNumber: page,
        limit: sizePerPage,
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_GROUP_LIST_DATA_API, {
            params: params
          })
          .then(response => {
            this.setState({
              groupListPage: page,
              groupListSizePerPage: sizePerPage,
              searchParams: params,
              totalGroupListSize: response.data.records,
              fetchedGroupList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  componentDidMount() {
    this.fetchGroupList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedContsId, prevProps.carousel.selectedContsId)) {
      this.searchBarRef.current.resetState();
      this.fetchGroupList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(GroupListPanel)));