import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {URL_AFTER_SA_ACCOUNT_LOGIN, URL_INDEX_PAGE} from '../utils/urlConstants';
import Form from './Form';
import {DPI_PORTAL_SERVICE_ID} from "../utils/globalConstants";
import {getProperty} from '../utils/propertiesByDomain';

class SignInButtonWrapper extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e) {
    e.preventDefault();
    this.formRef.current.submit();
  }

  componentDidMount() {
    if (this.props.autoLogin) {
      this.formRef.current.submit();
    }
  }

  render() {
    const actionUrl = getProperty("samsungAccountSignInGateUrl");
    const clientId = DPI_PORTAL_SERVICE_ID;
    const redirectUrI = getProperty("urlHome") + URL_AFTER_SA_ACCOUNT_LOGIN;
    const goBackURL = getProperty("urlHome") + URL_INDEX_PAGE;
    const state = this.props.csrfToken;
    let pageTitle = "";
    if (this.props.viaSignIn) {
      pageTitle = 'LOGIN_WITH_SAMSUNG_ACCOUNT';
    } else {
      if (this.props.sessionExpired) {
        pageTitle = 'SESSION_EXPIRED';
      } else {
        pageTitle = 'SIGN_OUT_SUCCESS';
      }
    }

    return (
        <Fragment>
          <Form method="get" action={actionUrl} ref={this.formRef}>
            <input type="hidden" name="client_id" value={clientId}/>
            <input type="hidden" name="redirect_uri" value={redirectUrI}/>
            <input type="hidden" name="goBackURL" value={goBackURL}/>
            <input type="hidden" name="state" value={state}/>
            <input type="hidden" name="response_type" value="code"/>
            <input type="hidden" name="acr_values" value="urn:samsungaccount:acr:aal1"/>
            <input type="hidden" name="acr_key" value="rotate"/>
          </Form>
          <div>
            <div className="f36 mb-35">{this.props.t(pageTitle)}</div>
            <div className={this.props.innerDivStyle}>
              <button type="button"
                      className="btn btn-lg btn-primary btn-sign-in"
                      onClick={this.submitForm}>
                {this.props.t('PLEASE_SIGN_IN')}
              </button>
            </div>
            {this.props.viaSignIn ?
                <div className="h4 mb-0 text-400 line-height-default">{this.props.t('DPI_REQUIRE_SA_ID')}</div>
                :
                <div className="h4 mb-0 text-400 line-height-default">{this.props.t('THANK_YOU_FOR_VISIT')}<br/>{this.props.t('PLEASE_VISIT_ANYTIME')}</div>
            }
          </div>
        </Fragment>
    );
  }

}

export default withTranslation()(SignInButtonWrapper);
