export const bizModelCode2Key = {
  "01": "AGENT",
  "02": "COMMISSIONAIRE"
};

export const bizModelKey2Code = {
  "AGENT": "01",
  "COMMISSIONAIRE": "02"
};

export const settlementPaymentMethodCode2Key = {
  "100": "PhoneBill(KR)",
  "200": "Credit Card(KR)",
  "200MR": "Credit Card(KR)",
  "260": "Samsung Pay",
  "400": "Credit Card",
  "400MR": "Credit Card",
  "400PB": "Credit Card",
  "430": "Samsung Pay",
  "900": "PayPal",
  "902": "PayPal"
};

export const settlementPaymentMethodKey2Code = {
  "PHONE_BILL_KR": "100",
  "CREDIT_CARD_KR": "200,200MR",
  "SAMSUNG_PAY": "260,430",
  "CREDIT_CARD": "400,400MR,400PB",
  "PAYPAL": "900,902"
};

export const settlementAttributes = {
  paymentMethod: {
    key: "PAYMENT_METHOD",
    value: [
      "PHONE_BILL_KR",
      "CREDIT_CARD_KR",
      "CREDIT_CARD",
      "PAYPAL", //PayPal
      "SAMSUNG_PAY",
      "CREDIT_CARD",
      "SAMSUNG_PAY"
    ]
  },
  taxHandlingType: {
    key: "TAX_HANDLING_TYPE",
    value: [
      "AGENT",
      "COMMISSIONAIRE"
    ]
  }
}