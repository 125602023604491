import React, {Component} from "react";
import Content from "../Content";
import SHeader from "../SHeader";
import LeftNaviBar from "../lnb/LeftNaviBar";
import TopNaviBar from '../tnb/TopNaviBar';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {isSelectedAppChanged} from '../../ducks/carousel';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {withTranslation} from 'react-i18next';
import DashBoardGraph from '../dashboard/DashBoardGraph';
import DashBoardItem from '../dashboard/DashBoardItem';
import {startLoading, stopLoading} from '../../ducks/loading';
import {URL_GET_DASHBOARD_DATA_API, URL_GET_POPUP_DATA_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {getCookie, setCookie} from '../../utils/simpleCookieUtils';
import PopupWindow from '../PopupWindow';

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      countryDetails: [],
      popupData: [],
      dataLoaded: false
    }
  }

  convertDate = (date) => {
    return date.split("-")[1] + "-" + date.split("-")[2]
  };

  fetchData = () => {
    return new Promise((resolve, reject) => {
      const monthBefore = new Date();
      monthBefore.setMonth(monthBefore.getMonth() - 1);
      const date = new Date();
      const endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
          .toISOString()
          .split("T")[0];
      const startDateString = new Date(monthBefore.getTime() - (monthBefore.getTimezoneOffset() * 60000))
          .toISOString()
          .split("T")[0];

      let params = {
        cpAppId: this.props.carousel.selectedAppId,
        dateStart: startDateString,
        dateEnd: endDateString,

      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_DASHBOARD_DATA_API, {params: params})
          .then(response => {
            const data = response.data;
            this.setState({
              salesNumber: [['', ''],
                ['Number of Sales', data.monthly.totalSalesCount],
                ['Number of Refunds', data.monthly.totalRefundCount]],
              salesAmount: [['', '', {role: 'style'}],
                ['Sales Revenue', data.monthly.totalSalesAmount, '#acd443'],
                ['Refunded Amount', data.monthly.totalRefundAmount, '#bf99d2']],
              salesRefunds: [['', 'Number of Sales', 'Number of Refunds'], ...data.monthly.dataList.map(e => [this.convertDate(e.period), e.salesCount, e.refundCount])
              ],
              countryRanking: data.countryList.map(e => [this.props.t(e.countryCode), e.salesAmount]),
              dateRange: this.convertDate(startDateString) + "~" + this.convertDate(endDateString),
              summaryData: {
                testPaymentsCount: data.totalTestSalesCount,
                testPaymentsAmount: data.totalTestSalesAmount,
                todayRevenueCount: data.today.totalSalesCount,
                todayRevenueAmount: data.today.totalSalesAmount,
                numberOfSales: data.monthly.totalSalesCount,
                revenueAmount: data.monthly.totalSalesAmount,
                todayRefundCount: data.today.totalRefundCount,
                todayRefundAmount: data.today.totalRefundAmount,
                numberOfRefunds: data.monthly.totalRefundCount,
                refundAmount: data.monthly.totalRefundAmount,
              }
            });
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  fetchPopup = () => {
    const showPopup = getCookie('SHOW_POPUP') !== undefined ? Object.is(getCookie('SHOW_POPUP'), 'true') : true;
    if (showPopup) {
      const date = new Date();
      const endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
          .toISOString()
          .split("T")[0];
      let params = {
        date: endDateString,
      };
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_POPUP_DATA_API, {params: params})
          .then(response => {
            const popupData = response.data;
            this.setState({popupData: popupData, dataLoaded: true});
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    } else {
      this.setState({popupData: [], dataLoaded: true});
    }
  };

  handleClosePopup = (isPopupDisabled) => {
    if (isPopupDisabled) {
      const expirationDate = new Date();
      expirationDate.setHours(23, 59, 59, 999);
      setCookie('SHOW_POPUP', false, "expires=" + expirationDate)
    }
  };

  render() {
    let wrapClassName = "clearfix";
    if (!this.props.sideDrawer.isSideDrawerOpen) {
      wrapClassName += " closeMenu";
    }
    if (!this.state.dataLoaded) {
      return <div/>
    }
    return (
        <div id="wrap" className={wrapClassName}>
          <SHeader>
            <LeftNaviBar/>
          </SHeader>
          <Content>
            <TopNaviBar/>
            <div className="dashboard-info-wrap mb-20">
              <div className="dashboard-info">
                <DashBoardItem
                    name={"TEST_PAYMENTS"}
                    data={this.state.summaryData.testPaymentsCount + " / $" + this.state.summaryData.testPaymentsAmount}
                    dateRange={this.state.dateRange}/>
                <DashBoardItem
                    name={"TODAY_REVENUE"}
                    data={this.state.summaryData.todayRevenueCount + " / $" + this.state.summaryData.todayRevenueAmount}
                    dateRange={this.state.dateRange}/>
                <DashBoardItem
                    name={"NUMBER_SALES"}
                    data={this.state.summaryData.numberOfSales}
                    dateRange={this.state.dateRange}/>
                <DashBoardItem
                    name={"SALES_AMOUNT"}
                    data={this.state.summaryData.revenueAmount}
                    dateRange={this.state.dateRange}/>
                <DashBoardItem
                    name={"TODAY_REFUND"}
                    data={this.state.summaryData.todayRefundCount + " / $" + this.state.summaryData.todayRefundAmount}
                    dateRange={this.state.dateRange}/>
                <DashBoardItem
                    name={"NUMBER_REFUND"}
                    data={this.state.summaryData.numberOfRefunds}
                    dateRange={this.state.dateRange}/>
                <DashBoardItem
                    name={"REFUND_AMOUNT"}
                    data={this.state.summaryData.refundAmount}
                    dateRange={this.state.dateRange}/>
              </div>
            </div>
            <div className="dashboard-info-wrap mb-20">
              <div className="dashboard-info">
                <DashBoardGraph name={"APP_SALES"}
                                dateRange={this.state.dateRange}
                                data={this.state.salesNumber}/>
                <DashBoardGraph name={"SALES_AMOUNT"}
                                dateRange={this.state.dateRange}
                                data={this.state.salesAmount}/>
                <DashBoardGraph name={"COUNTRY_SALES"}
                                dateRange={this.state.dateRange}
                                table={true}
                                data={this.state.countryRanking}/>
              </div>
            </div>
            <div className="dashboard-info-wrap">
              <div className="dashboard-info">
                <DashBoardGraph name={"APP_SALES_REFUNDS"}
                                border={true}
                                data={this.state.salesRefunds}/>
              </div>
            </div>
          </Content>
          {this.state.popupData.map((e, index) => {
                if (index === 0) {
                  return (
                      <PopupWindow title={e.popUpName}
                                   key={e.s3FilePath}
                                   size={e.popUpMaxSize}
                                   img={e.s3FilePath}
                                   alt={e.alternateMessage}
                                   link={e.linkURL}
                                   showCheckbox={true}
                                   handleClosePopup={this.handleClosePopup}
                      />)
                } else {
                  return (
                      <PopupWindow title={e.popUpName}
                                   key={e.s3FilePath}
                                   size={e.popUpMaxSize}
                                   img={e.s3FilePath}
                                   alt={e.alternateMessage}
                                   link={e.linkURL}
                                   showCheckbox={false}
                                   handleClosePopup={this.handleClosePopup}
                      />)
                }
              }
          )}
        </div>
    )
  }

  componentDidMount() {
    this.fetchData().then(() => this.fetchPopup());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    sideDrawer: state.sideDrawer,
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(DashboardPage)))

