import React, {Component, Fragment} from 'react';
import {withTranslation} from "react-i18next";
import BasePopup from './BasePopup';
import DatepickerxWrapper from "../pluginwrapper/DatepickerxWrapper";

class SubscriptionExpiryDateChangePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      hover: false,
      focus: false,
      expirationDate: null,
      reasonNotEntered: false
    }
  }

  handleDatepickerInit = (datepicker) => {
    this.setState({expirationDate: datepicker});
  };

  handleModalClose = (isSave) => {
    if (this.state.reason === '') {
      this.setState({reasonNotEntered: true});
      return;
    }
    this.props.handlePopupClose(true, this.state.reason, this.state.expirationDate)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open !== this.props.open) {
      this.setState({reasonNotEntered: false});
    }
  }

  render() {
    const {subscriptionEndDate} = this.props.currentSubscriptionId;
    const {reason, hover, focus, reasonNotEntered} = this.state;
    return (
        <BasePopup
            open={this.props.open}
            title={this.props.t("REQUEST_CHANGE_IN_SUBSCRIPTION_EXPIRATION_DATE")}
            onClose={() => this.props.handlePopupClose(false)}
            body={
              <Fragment>
                <div className="text-right small text-success mb-10">
                  <span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}
                </div>
                <div className="search_field m-0 p-0 border-0">
                  <div className="form-group row align-items-center">
                    <label className="col w-25 col-form-label">{this.props.t("CURRENT")}<br/>{this.props.t("EXPIRATION_DATE")}</label>
                    <div className="col w-75">
                      <input type="text" className="form-control" value={subscriptionEndDate} readOnly="readonly"/>
                    </div>
                  </div>
                  <div className="form-group row align-items-center">
                    <label className="col w-25 col-form-label">{this.props.t("CHANGE")}<br/>{this.props.t("EXPIRATION_DATE")} </label>
                    <div className="col w-75">
                      <div>
                        {/*TODO: Write appropriate DatePickerWrapper for single date*/}
                        <DatepickerxWrapper className="form-control rounded" initDate={subscriptionEndDate} isDateStart={false} onInit={(datepicker) => this.handleDatepickerInit(datepicker)}/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col w-25 col-form-label">
                      Reason for <br/>change<span className="text-success align-middle">*</span>
                    </label>
                    <div className="col w-75">
                      <div className="textarea_count">
                      <textarea maxLength="100"
                                onMouseOver={() => this.setState({hover: true})}
                                onMouseOut={() => this.setState({hover: false, focus: false})}
                                onBlur={() => this.setState({hover: false, focus: false})}
                                onFocus={() => this.setState({focus: true})}
                                onKeyUp={(event) => this.setState({reason: event.target.value, reasonNotEntered: false})}
                                className="form-control text_counter"
                                placeholder={this.props.t("CHANGE_REASON")}>
                        </textarea>
                        <p style={{display: "block", color: '#ff4800'}}>{reasonNotEntered ? this.props.t("PLEASE_ENTER_REASON_FOR_CHANGE") : ' '}</p>
                        <div className="counts text-right">
                          <span className={`text_count ${hover && "hover"} ${focus && "active"}`}>{reason.length}</span> / 100{this.props.t("BYTE")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            }
            footer={
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => this.handleModalClose(true)}>
                  {this.props.t("CONFIRM")}
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={() => this.props.handlePopupClose(false)}>
                  {this.props.t("CANCEL")}
                </button>
              </div>
            }/>
    )
  };
}

export default withTranslation()(SubscriptionExpiryDateChangePopup);