import React, {Component} from 'react';
import {connect} from "react-redux";
import {toggleCarousel} from "../../ducks/carousel";
import {Img} from 'react-image'
import default_icon from '../../images/icon-app.png';

class AllAppsButton extends Component {

  handleClick = (e) => {
    if (this.props.sessionData.apps.length > 1) {
      this.props.toggleCarousel();
      e.preventDefault();
    }
  };

  render() {
    const {selectedAppId, isCarouselOpen} = this.props.carousel;
    if (!this.props.sessionData.apps || !selectedAppId) {
      return (<></>);
    }

    // When cpUser is switched (CP Management)
    // during loadSessionData call in App.js, selectedAppId in carousel is from different cp (as updateSelectedAppIdInCarousel is not called yet),
    // apps.find will return undefined here, hence updated logic to select first app (this.props.sessionData.apps[0])
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, selectedAppId)) || this.props.sessionData.apps[0];

    return (
        <button type="button"
                ref={this.props.allAppsButtonRef}
                className={isCarouselOpen ? "h3 mb-0 view_app open" : "h3 mb-0 view_app"}
                onClick={this.handleClick}>
          <span className="app_img"><Img src={[selectedApp.image, default_icon]} alt="app"/></span>
          <span>{selectedApp.name || selectedAppId}</span>
        </button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData
  };
};

export const mapDispatchToProps = {
  toggleCarousel: toggleCarousel
};

export default connect(mapStateToProps, mapDispatchToProps)(AllAppsButton);
