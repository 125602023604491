export const getProperty = (key) => {
  switch (window.location.host) {
    case host.test:
      return propertiesByDomain.test[key];
    case host.dev:
      return propertiesByDomain.dev[key];
    case host.stag:
      return propertiesByDomain.stag[key];
    case host.prod:
      return propertiesByDomain.prod[key];
    default: // If not match above, its all local
      return propertiesByDomain.local[key];
  }
}

const host = {
  local: "dpi-portal.local.com",
  dev: "dev-dpi.samsungcheckout.com",
  stag: "sbox-dpi.samsungcheckout.com",
  prod: "dpi.samsungcheckout.com",
}

const propertiesByDomain = {
  local: {
    urlHome: "http://" + host.local + ":8080",
    samsungAccountSignInGateUrl: "https://stg-us.account.samsung.com/accounts/v1/CHECKOUT_DPI/signInGate",
    samsungAccountSignOutGateUrl: "https://stg-us.account.samsung.com/accounts/v1/CHECKOUT_DPI/signOutGate"
  },
  test: {
    urlHome: "http://" + host.test,
    samsungAccountSignInGateUrl: "https://stg-us.account.samsung.com/accounts/v1/CHECKOUT_DPI/signInGate",
    samsungAccountSignOutGateUrl: "https://stg-us.account.samsung.com/accounts/v1/CHECKOUT_DPI/signOutGate"
  },
  dev: {
    urlHome: "https://" + host.dev,
    samsungAccountSignInGateUrl: "https://account.samsung.com/accounts/v1/CHECKOUT_DPI/signInGate",
    samsungAccountSignOutGateUrl: "https://account.samsung.com/accounts/v1/CHECKOUT_DPI/signOutGate"
  },
  stag: {
    urlHome: "https://" + host.stag,
    samsungAccountSignInGateUrl: "https://account.samsung.com/accounts/v1/CHECKOUT_DPI/signInGate",
    samsungAccountSignOutGateUrl: "https://account.samsung.com/accounts/v1/CHECKOUT_DPI/signOutGate"
  },
  prod: {
    urlHome: "https://" + host.prod,
    samsungAccountSignInGateUrl: "https://account.samsung.com/accounts/v1/CHECKOUT_DPI/signInGate",
    samsungAccountSignOutGateUrl: "https://account.samsung.com/accounts/v1/CHECKOUT_DPI/signOutGate"
  }
}