export const companyTypeKey2Code = {
  INCORPORATION: "01",
  SIMPLIFIED_TAXPAYER: "02",
  PRIVATE: "03",
};

export const companyTypeCode2Key = {
  "01": "INCORPORATION",
  "02": "SIMPLIFIED_TAXPAYER",
  "03": "PRIVATE"
};

export const partnerTypeKey2Code = {
  PRIVATE_SELLER: "01",
  CORPORATE_SELLER: "02",
  PARTNER: "03",
  CARRIER: "04",
};

export const partnerTypeCode2Key = {
  "01": "PRIVATE_SELLER",
  "02": "CORPORATE_SELLER",
  "03": "PARTNER",
  "04": "CARRIER"
};