import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import ApplyAllBasePopup from './ApplyAllBasePopup';
import ValidPeriodDatePickers from '../datable/ValidPeriodDatePickers';

class ApplyAllCouponPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      noOfCoupon: "",
      validStart: null,
      validEnd: null,
    };
  }

  handleChangeNoOfCoupon(value) {
    this.setState({
      noOfCoupon: value
    });
  }

  handleChangeValidDate = (isStart, datepicker) => {
    if (isStart) {
      datepicker.setValue(new Date());
      this.setState({
        validStart: datepicker
      });
    } else {
      const monthAfter12 = new Date();
      monthAfter12.setFullYear(monthAfter12.getFullYear() + 1);
      datepicker.setValue(new Date(monthAfter12));
      this.setState({
        validEnd: datepicker
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        noOfCoupon: "",
      });
      this.state.validStart.setValue(new Date());
      const monthAfter12 = new Date();
      monthAfter12.setFullYear(monthAfter12.getFullYear() + 1);
      this.state.validEnd.setValue(new Date(monthAfter12));
    }
  }

  render() {
    const defaultCountryInfo = this.props.couponCountryData[0];
    return (
      <ApplyAllBasePopup
        open={this.props.open}
        handleModalClose={this.props.handleModalClose}
        readOnly={this.props.readOnly}
        defaultCountryInformation={this.props.couponCountryData}
        applyBody={<>
          <div className="row mb-3">
            <div className="col-12">
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  {this.props.t("NO_OF_COUPONS")}
                </label>
                <div className="col">
                  <div className="input-group">
                    <input
                      type="number"
                      min={1}
                      max={10000}
                      value={this.state.noOfCoupon}
                      className="form-control"
                      onChange={event => {
                        this.handleChangeNoOfCoupon(event.target.value);
                      }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  {this.props.t("VALID_PERIOD")}
                </label>
                <div className="col col-form-label">
                  <ValidPeriodDatePickers
                    startDatePicker={this.state.validStart}
                    endDatePicker={this.state.validEnd}
                    country={defaultCountryInfo.country}
                    handleDatepickerInit={this.handleChangeValidDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        }
        buttonApplyAll={
          <button
            type="button"
            className="btn btn-120 btn-primary"
            onClick={() => this.props.handleModalClose(true, this.state)}>
            {this.props.t("APPLY_ALL")}
          </button>
        }
      />
    )
  }

}


export default withTranslation()(ApplyAllCouponPopup);
