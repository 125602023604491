import React from 'react';
import DatepickerxWrapper from '../pluginwrapper/DatepickerxWrapper';
import TimePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../css/react-datepicker-custom.css";

function ValidPeriodDateTimePicker(props) {
    return (
        <div className="row">
            <div className="col-md-12 input-group input-group-datepicker">
                <div className="input-group-append flex-100">
                    <DatepickerxWrapper
                        className="form-control rounded"
                        startDate={props.startDatePicker}
                        endDate={props.endDatePicker}
                        isDateStart={true}
                        onInit={(datepicker) => props.handleDatepickerInit(true, datepicker, props.country)}
                    />
                </div>
                <TimePicker
                    showTimeSelect
                    showTimeSelectOnly
                    fixedHeight
                    wrapperClassName="col-2"
                    selected={props.startTimePicker ? props.startTimePicker : new Date().setHours(0,0)}
                    onChange={(event) => props.onTimePickerChange(true, props.country, event)}
                    timeIntervals={1}
                    timeFormat="HH:mm"
                    timeCaption=""
                    dateFormat="HH:mm"
                />
                <div className="input-group-append">
                    <span className="input-group-text bg-white border-0">~</span>
                </div>
                <div className="input-group-append flex-100">
                    <DatepickerxWrapper
                        className="form-control rounded"
                        startDate={props.startDatePicker}
                        endDate={props.endDatePicker}
                        isDateStart={false}
                        onInit={(datepicker) => props.handleDatepickerInit(false, datepicker, props.country)}
                    />
                </div>
                <TimePicker
                    showTimeSelect
                    showTimeSelectOnly
                    fixedHeight
                    wrapperClassName="col-2"
                    selected={props.endTimePicker ? props.endTimePicker : new Date().setHours(23, 59)}
                    onChange={(event) => props.onTimePickerChange(false, props.country, event)}
                    timeIntervals={1}
                    timeFormat="HH:mm"
                    timeCaption=""
                    dateFormat="HH:mm"
                />
            </div>
        </div>
    )
}

export default ValidPeriodDateTimePicker;
