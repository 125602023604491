import React, {Component} from 'react';
import {connect} from 'react-redux';
import Content from "../Content";
import {withTranslation} from 'react-i18next';
import {userSignedIn} from "../../ducks/sessionData";
import {withRouter} from "react-router-dom";
import Logo from "../Logo";
import SignInGuide from "../SignInGuide";
import SignInButtonWrapper from "../SignInButtonWrapper";

class SignOutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: props.sessionData.isAuthenticated,
    }
  }

  render() {
    let {csrfToken} = this.props.sessionData;
    const status = this.props.match.params.status;
    return (
        <div id="wrap" className={"clearfix"}>
          <Content className={"w-100"}>
            <main id="contentMain">
              <div className="container">
                <Logo/>
                <div className="sign-box bg-white rounded d-flex align-items-center mt-50 justify-content-center">
                  <div className="sign-box-content text-center">
                    {!(Object.is(status, "success") || Object.is(status, "session_expired")) ?
                        <div>
                          <div className="f36 mb-35">{this.props.t('SIGN_OUT_SUCCESS')}</div>
                          <div className="f22 text-400 line-height-default">
                            {Object.is(status, "cp_not_registered") &&
                                <div>
                                  {this.props.t('CP_IS_NOT_REGISTERED_AS_SELLER_1')}<br/>
                                  {this.props.t('CP_IS_NOT_REGISTERED_AS_SELLER_2')}
                                </div>
                            }
                            {Object.is(status, "cp_no_apps") &&
                                <div>
                                  {this.props.t('CP_HAS_NO_REGISTERED_APP_1')}<br/>
                                  {this.props.t('CP_HAS_NO_REGISTERED_APP_2')}
                                </div>
                            }
                            {Object.is(status, "cp_no_permission") &&
                                <div>
                                  {this.props.t('CP_HAS_NO_PERMISSION_IN_ANY_APP_1')}<br/>
                                  {this.props.t('CP_HAS_NO_PERMISSION_IN_ANY_APP_2')}
                                </div>
                            }
                            {Object.is(status, "cp_gdpr_restricted") &&
                                <div>
                                  {this.props.t('CP_IS_GDPR_RESTRICTED_1')}<br/>
                                  {this.props.t('CP_IS_GDPR_RESTRICTED_2')}
                                </div>
                            }
                          </div>
                        </div>
                        :
                        <SignInButtonWrapper
                            innerDivStyle={'mb-28'}
                            csrfToken={csrfToken}
                            viaSignIn={false}
                            sessionExpired={Object.is(status, "session_expired")}/>
                    }
                  </div>
                </div>
                <SignInGuide/>
              </div>
            </main>

          </Content>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
  };
};

const mapDispatchToProps = {
  doSignIn: userSignedIn,
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SignOutPage)));