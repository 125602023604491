export const buyerRedemptionAttributes = {
  couponCode: {
    key: "COUPON_CODE",
    value: null
  },
  couponName: {
    key: "COUPON_NAME",
    value: null
  },
  buyerEmail: {
    key: "BUYER_EMAIL",
    value: null
  },
  zip: {
    key: "ZIP",
    value: null
  },
  validPeriod: {
    key: "VALID_PERIOD",
    value: null
  },
  registeredDate: {
    key: "REGISTERED_DATE",
    value: null
  },
  installmentInformation: {
    key: "INSTALLMENT_INFORMATION",
    value: null
  },
  invoiceId: {
    key: "INVOICE_ID",
    value: null
  },
  used: {
    key: "USED",
    value: null
  },
  registered: {
    key: "REGISTERED",
    value: null
  },
  reserved: {
    key: "RESERVED",
    value: null
  },
  issued: {
    key: "ISSUED",
    value: null
  },
  couponStatus: {
    key: "COUPONSTATUS",
    value: [
      "USED",
      "REGISTERED",
      "RESERVED",
      "ISSUED"
    ]
  }

}
export const filterTypeKey2Code = {
  "ISSUED": "01",
  "REGISTERED": "03",
  "RESERVED": "05",
  "USED": "06"
};