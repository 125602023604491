import React, {Component} from 'react';
import {getMenuEntityByKey, menuKeys} from "../../utils/dpiPortalMenusAndUtils";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {URL_CREATE_GROUP_API, URL_DELETE_GROUP_API, URL_GET_GROUP_DETAIL_API, URL_MEMBER_GROUPMANAGEMENT, URL_MODIFY_GROUP_API} from "../../utils/urlConstants";
import BasePopup from "../popup/BasePopup";
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from '../../utils/globalConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';

class GroupAddModifyPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      isTooltipOpen: false,
      hover: false,
      focus: false,
      saveModalOpen: false,
      groupDetail: [],

    };
    this.presetPermissionGroup = ["Operation", "Finance", "CS", "Developer", "Reset"];
    this.allMenus = [
      {key: menuKeys.SETTINGS, depth: 1, open: true, checked: false},
      {key: menuKeys.SETTINGS_APPDETAILS, depth: 2, open: true, checked: false},
      {key: menuKeys.SETTINGS_CHANGEINFORMATION, depth: 2, open: true, checked: false},
      {key: menuKeys.SETTINGS_TESTBUYER, depth: 2, open: true, checked: false},
      {key: menuKeys.SETTINGS_AGREEMENT, depth: 2, open: true, checked: false},
      {key: menuKeys.PRODUCT, depth: 1, open: true, checked: false},
      {key: menuKeys.PRODUCT_LIST, depth: 2, open: true, checked: false},
      {key: menuKeys.PRODUCT_ADDNEW, depth: 2, open: true, checked: false},
      {key: menuKeys.PRODUCT_ADDMULTIPLE, depth: 2, open: true, checked: false},
      {key: menuKeys.PRODUCT_MODIFYMULTIPLE, depth: 2, open: true, checked: false},
      {key: menuKeys.PRODUCT_SUBSCRIPTIONGROUP_LIST, depth: 2, open: true, checked: false},
      {key: menuKeys.PRODUCT_SUBSCRIPTIONGROUP_ADDNEW, depth: 3, open: true, checked: false},
      {key: menuKeys.SALES, depth: 1, open: true, checked: false},
      {key: menuKeys.SALES_PRODUCT, depth: 2, open: true, checked: false},
      {key: menuKeys.SALES_SUBSCRIPTION, depth: 2, open: true, checked: false},
      {key: menuKeys.CAMPAIGN, depth: 1, open: true, checked: false},
      {key: menuKeys.CAMPAIGN_COUPONLIST, depth: 2, open: true, checked: false},
      {key: menuKeys.CAMPAIGN_ISSUECOUPON, depth: 3, open: true, checked: false},
      {key: menuKeys.CAMPAIGN_COUPON_ALLOCATIONLIST, depth: 2, open: true, checked: false},
      {key: menuKeys.CAMPAIGN_BUYERREDEMPTIONSTATUS, depth: 2, open: true, checked: false},
      {key: menuKeys.STATISTICS, depth: 1, open: true, checked: false},
      {key: menuKeys.STATISTICS_APPSALESANALYSIS, depth: 2, open: true, checked: false},
      {key: menuKeys.STATISTICS_ANALYSISBYCOUNTRY, depth: 2, open: true, checked: false},
      {key: menuKeys.STATISTICS_ANALYSISBYPRODUCT, depth: 2, open: true, checked: false},
      {key: menuKeys.STATISTICS_SUBSCRIBERANALYSIS, depth: 2, open: true, checked: false},
      {key: menuKeys.STATISTICS_CAMPAIGNANALYSIS, depth: 2, open: true, checked: false},
      {key: menuKeys.REQUEST_MANAGEMENT, depth: 1, open: true, checked: false},
      {key: menuKeys.REQUEST_LIST, depth: 2, open: true, checked: false},
    ];
    this.subMenuClass = ['sub-1', 'sub-2', 'sub-3', 'sub-4', 'sub-5','sub-6'];
    this.subMenu2Class = ['', 'sub-2-1', '', 'sub-4-1', '',''];
    this.presetPermission = [
      {
        name: "Operation",
        permittedMenus: [
          menuKeys.SETTINGS_APPDETAILS,
          menuKeys.PRODUCT_LIST,
          menuKeys.PRODUCT_ADDNEW,
          menuKeys.PRODUCT_ADDMULTIPLE,
          menuKeys.PRODUCT_MODIFYMULTIPLE,
          menuKeys.SALES_PRODUCT,
          menuKeys.SALES_SUBSCRIPTION,
          menuKeys.STATISTICS_ANALYSISBYCOUNTRY,
          menuKeys.STATISTICS_ANALYSISBYPRODUCT,
          menuKeys.STATISTICS_APPSALESANALYSIS,
          menuKeys.STATISTICS_CAMPAIGNANALYSIS,
          menuKeys.STATISTICS_SUBSCRIBERANALYSIS,
          menuKeys.CAMPAIGN_COUPONLIST,
          menuKeys.CAMPAIGN_ISSUECOUPON,
          menuKeys.CAMPAIGN_COUPON_ALLOCATIONLIST,
          menuKeys.REQUEST_LIST
        ]
      },
      {
        name: "Finance",
        permittedMenus: [
          menuKeys.STATISTICS_ANALYSISBYCOUNTRY,
          menuKeys.STATISTICS_ANALYSISBYPRODUCT,
          menuKeys.STATISTICS_APPSALESANALYSIS,
          menuKeys.STATISTICS_CAMPAIGNANALYSIS,
          menuKeys.STATISTICS_SUBSCRIBERANALYSIS
        ]
      },
      {
        name: "CS",
        permittedMenus: [
          menuKeys.SALES_PRODUCT,
          menuKeys.SALES_SUBSCRIPTION,
          menuKeys.CAMPAIGN_COUPON_ALLOCATIONLIST,
          menuKeys.REQUEST_LIST
        ]
      },
      {
        name: "Developer",
        permittedMenus: [
          menuKeys.SETTINGS_APPDETAILS,
          menuKeys.PRODUCT_LIST,
          menuKeys.PRODUCT_ADDNEW,
          menuKeys.PRODUCT_ADDMULTIPLE,
          menuKeys.PRODUCT_MODIFYMULTIPLE,
          menuKeys.SALES_PRODUCT,
          menuKeys.SALES_SUBSCRIPTION,
          menuKeys.CAMPAIGN_COUPONLIST,
          menuKeys.REQUEST_LIST
        ]
      },
      {
        name: "Reset",
        permittedMenus: []
      }
    ];
    this.focusId = React.createRef();
  }

  handlePresetPermission = (index, presetName) => {
    if (Object.is(presetName, "Reset")) {
      this.setState(prevState => {
        const allMenuStates = prevState.groupDetail[index].allMenuStates.map(menuState => {
          menuState.checked = false; // reset it first
          return menuState;
        });
        return {
          groupDetail: prevState.groupDetail.map((group, groupIndex) => {
                if (groupIndex !== index) {
                  return group;
                }
                group.allMenuStates = allMenuStates; //reset it first
                return group;
              }
          )
        }
      })
    } else {
      const selectedPresetPermission = this.presetPermission.find(preset => preset.name === presetName);
      this.setState(prevState => {
        const allMenuStates = prevState.groupDetail[index].allMenuStates.map(menuState => {
          menuState.checked = false; // reset it first
          selectedPresetPermission.permittedMenus.map(presetMenuItem => {
            if (presetMenuItem === menuState.key) {
              menuState.checked = true;
            }
            return presetMenuItem;
          });
          return menuState;
        });
        return {
          groupDetail: prevState.groupDetail.map((group, groupIndex) => {
                if (groupIndex !== index) {
                  return group;
                }
                group.allMenuStates = allMenuStates; //reset it first
                return group;
              }
          )
        }
      })
    }
  };

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleListClick = (e) => {
    e.preventDefault();
    this.props.history.push(URL_MEMBER_GROUPMANAGEMENT);
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    let groupNameFlag = false;
    let groupPermissionsFlag = false;
    this.state.groupDetail.forEach((group) => {
      if (!group.groupName || !group.groupName.trim()) {
        groupNameFlag = true;
      }
      if (this.getMenuIds(group.allMenuStates).length <= 0) {
        groupPermissionsFlag = true;
      }
    });
    if (groupNameFlag) {
      toast(this.props.t("GROUP_NAME_IS_NOT_VALID"));
      return;
    }
    if (groupPermissionsFlag) {
      toast(this.props.t("GROUP_PERMISSIONS_IS_NOT_VALID"));
      return;
    }
    this.setState({saveModalOpen: true});
  };

  handleConfirmPopupClose = (isSave) => {
    this.setState({saveModalOpen: false});
    if (isSave) {
      if (this.props.isModify) {
        const params = {
          contsId: this.props.carousel.selectedContsId,
          groupId: this.props.match.params.groupId,
          groupName: this.state.groupDetail[0].groupName.trim(),
          menuIds: this.getMenuIds(this.state.groupDetail[0].allMenuStates)
        };
        this.props.startLoading();
        return new Promise((resolve, reject) => {
          this.httpClient
              .post(URL_MODIFY_GROUP_API, params)
              .then(response => {
                if (response.data.status !== SUCCESS) {
                  toast(this.props.t("ERROR_MODIFYING_GROUP"));
                } else {
                  toast(this.props.t("GROUP_SUCCESSFULLY_MODIFIED"));
                  this.props.history.push(URL_MEMBER_GROUPMANAGEMENT);
                }
                this.props.stopLoading();
              })
              .catch(error => {
                handleHttpError(this.props.t, this.props.history, error);
                this.props.stopLoading();
              });
        })
      } else {
        const params = [];
        this.state.groupDetail.forEach((e) => {
          params.push({
            cpAppId: this.props.carousel.selectedAppId,
            groupName: e.groupName.trim(),
            menuIds: this.getMenuIds(e.allMenuStates)
          });
        });
        this.props.startLoading();
        this.httpClient
            .post(URL_CREATE_GROUP_API, params)
            .then(response => {
              if (response.data.status !== SUCCESS) {
                toast(this.props.t("ERROR_CREATING_GROUP"));
              } else {
                toast(this.props.t("GROUP_SUCCESSFULLY_CREATED"));
                this.props.history.push(URL_MEMBER_GROUPMANAGEMENT);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
            });
      }
    }
  };

  getMenuIds = (menuStates) => {
    return menuStates.filter((e) => {
      return e.checked;
    }).map(e => e.key)
  };

  handleMenuOpenCloseAndCheckUncheck = (isSelection, index, menuItem) => {
    this.setState(prevState => {
      const allMenuStates = prevState.groupDetail[index].allMenuStates.map(menuState => {
        if (menuItem.key === menuState.key) {
          if (isSelection) {
            menuState.checked = !menuState.checked;
          } else {
            menuState.open = !menuState.open;
          }
        }
        return menuState;
      });
      return {
        groupDetail: prevState.groupDetail.map((group, groupIndex) => {
          if (groupIndex !== index) {
            return group;
          }
          group.allMenuStates = allMenuStates;
          return group;
        })
      }
    })
  };

  getGroupDetail = (groupId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_GROUP_DETAIL_API, {
            params: {
              groupId: groupId
            }
          })
          .then(response => {
            const groupDetail = response.data;
            console.log('ggg',groupDetail);
            groupDetail.allMenuStates = JSON.parse(JSON.stringify(this.allMenus));
            groupDetail.allMenuStates.forEach(firstDepthMenuitem => {
              groupDetail.menuIds.map(menuItem => {
                if (menuItem === firstDepthMenuitem.key) {
                  firstDepthMenuitem.checked = true;
                }
                return firstDepthMenuitem;
              })
            });
            this.setState(prevState => {
              return {
                groupDetail: [groupDetail],
              }
            });
            this.props.stopLoading();
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  initGroupDetail = () => {
    const selectedPresetPermission = this.presetPermission.find(preset => preset.name === "Developer");
    const allMenuStates = this.allMenus.map(menuState => {
      selectedPresetPermission.permittedMenus.map(menu => {
        if (menu === menuState.key) {
          menuState.checked = true;
        }
        return menuState;
      });
      return menuState;
    });
    const newGroup = {
      open: true,
      groupId: '',
      groupName: '',
      menuCount: 0,
      memberCount: 0,
      members: [],
      createdDate: '',
      modifiedDate: '',
      permittedMenus: [],
      allMenuStates: JSON.parse(JSON.stringify(allMenuStates))
    };
    this.setState(prevState => {

      return {
        groupDetail: [...prevState.groupDetail.map((group, groupIndex) => {
          group.open = false;
          return group;
        }), newGroup]
      }
    })
  };

  handleGroupNameInput = (groupIdx, name) => {
    this.setState(prevState => {
      return {
        groupDetail: prevState.groupDetail.map((group, groupIndex) => {
          if (groupIndex === groupIdx) {
            group.groupName = name;
          }
          return group;
        })
      }
    })
  };

  handleTableToggleButtonClick = (groupIdx) => {
    this.setState(prevState => {
      return {
        groupDetail: prevState.groupDetail.map((group, groupIndex) => {
          if (groupIndex === groupIdx) {
            group.open = !group.open;
          } else {
            group.open = false;
          }
          return group;
        })
      }
    })
  };

  onDeleteGroupClick = (index) => {
    if (!this.props.isModify) {
      this.setState(prevState => {
        return {
          groupDetail: prevState.groupDetail.filter((groupDetail, idx) => idx !== index)
        }
      })
    } else {
      this.props.startLoading();
      this.httpClient
          .post(URL_DELETE_GROUP_API, [this.props.match.params.groupId])
          .then(response => {
            this.props.stopLoading();
            if (response.data.status === SUCCESS) {
              toast(this.props.t("GROUP_DELETED"));
              this.props.history.push(URL_MEMBER_GROUPMANAGEMENT);
            } else {
              toast(this.props.t("ERROR_DELETING_GROUP"));
            }
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  comparePreset = (presetName, permissions) => {
    const presetPermissions = this.presetPermission.find(preset => preset.name === presetName).permittedMenus;
    const selectedPermissions = this.getMenuIds(permissions);
    if (presetPermissions.length !== selectedPermissions.length) {
      return false;
    }
    let a1 = presetPermissions.map(e => JSON.stringify(e)).sort();
    let a2 = selectedPermissions.map(e => JSON.stringify(e)).sort();
    return !a1.map((e, i) => e === a2[i]).includes(false)
  };

  componentDidMount() {
    if (this.props.isModify) {
      this.getGroupDetail(this.props.match.params.groupId);
    } else {
      this.initGroupDetail();
    }
  }

  render() {
    const {groupDetail, hover, focus} = this.state;
    if (this.props.isModify && groupDetail.length === 0) {
      return <div/>
    }

    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.isModify ? this.props.t("GROUP_DETAILS") : this.props.t("GROUP_CONFIGURATION")}</strong></h2>
            <div className="text-right text-success small float-right mt-1 mb-0">
              <span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}
              <span className={`tooltips right ml-6 ${this.state.isTooltipOpen && "show"}`}>
                <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
			          <div className="tooltips_body">
				          <a className="tooltip-toggle close" onClick={() => this.setState({isTooltipOpen: false})}/>
				          <div className="text-100 h6">{this.props.isModify ? this.props.t("NOTE") : this.props.t("HOW_TO_CREATE_NEW_GROUP") + " :"}</div>
                  {
                    this.props.isModify ?
                        <ol className="pl-15 mb-0">
                          <li>{this.props.t("GROUP_ADD_MODIFY_PANEL_TOOLTIP_LINE_1")}.</li>
                          <li>{this.props.t("GROUP_ADD_MODIFY_PANEL_TOOLTIP_LINE_2")}</li>
                          <li>{this.props.t("GROUP_ADD_MODIFY_PANEL_TOOLTIP_LINE_3")}</li>
                        </ol> :
                        <ol className="pl-15 mb-0">
                          <li>{this.props.t("CREATE_GROUP_PANEL_TOOLTIP_LINE_1")}.</li>
                          <li>{this.props.t("CREATE_GROUP_PANEL_TOOLTIP_LINE_2")}</li>
                          <li>{this.props.t("CREATE_GROUP_PANEL_TOOLTIP_LINE_3")}</li>
                          <br/>
                          <li>{this.props.t("CREATE_GROUP_PANEL_TOOLTIP_LINE_4")}</li>
                          <li>{this.props.t("CREATE_GROUP_PANEL_TOOLTIP_LINE_5")}</li>
                          <br/>
                        </ol>
                  }
			          </div>
		          </span>
            </div>
          </div>
          {
            this.props.isModify &&
            <div className="info-table mb-60">
              <div className="row">
                <div className="col-2 text-center bg-100"><strong>{this.props.t("USER_IDS")}</strong></div>
                <div className="col-10">
                  {groupDetail[0].userIds.join(', ')}<span className="text-success"> ({groupDetail[0].userCount} {this.props.t("MEMBERS")})</span></div>
                <div className="col-2 text-center bg-100"><strong>{this.props.t("CREATED_DATE")}</strong></div>
                <div className="col-4">{groupDetail[0].createdDate}</div>
                <div className="col-2 text-center bg-100"><strong>{this.props.t("MODIFIED_DATE")}</strong></div>
                <div className="col-4">{groupDetail[0].modifiedDate}</div>
              </div>
            </div>
          }
          <ul className="group">
            {
              groupDetail.map((group, groupIdx) => {
                return (
                    <li key={groupIdx} className={`${this.props.isModify ? "group-list-view" : "group-list"} ${!this.props.isModify && group.open && "group-list-active"}`}>
                      <table className="table-group">
                        <colgroup>
                          <col width="200"/>
                          <col width="*"/>
                        </colgroup>
                        <thead>
                        <tr>
                          <th>{this.props.t("GROUP_NAME")}<span className="text-success">*</span></th>
                          <td>
                            <div className="input-group textarea_count">
                              <input type="text" ref={this.focusId} maxLength="30" value={group.groupName}
                                     onMouseOver={() => this.setState({hover: true})}
                                     onMouseOut={() => this.setState({hover: false, focus: false})}
                                     onBlur={() => this.setState({hover: false, focus: false})}
                                     onFocus={() => this.setState({focus: true})}
                                     onKeyUp={(event) => this.setState({reason: event.target.value})}
                                     onChange={(event) => this.handleGroupNameInput(groupIdx, event.target.value)}
                                     className="form-control text_counter rounded"
                                     placeholder={this.props.t("PLEASE_ENTER_GROUP_NAME")} required/>
                              <div className="input-group-append counts text-center">
                                <span className={`text_count ${hover && "hover"} ${focus && "active"}`}>{group.groupName.length}</span> / 30{this.props.t("BYTE")}
                              </div>
                              <a className="toggle-table-btn" onClick={() => this.handleTableToggleButtonClick(groupIdx)}/>
                            </div>
                          </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>{this.props.t("PERMISSIONS")}<span className="text-success">*</span></th>
                          <td colSpan="2" className="text-100 pt-15">
                            <div className="clearfix mb-15">
                              <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                                {
                                  this.presetPermissionGroup.map((preset) => {
                                    return (
                                        <label key={preset} className={`btn btn-outline-500 rounded btn-auto mr-2 ${this.comparePreset(preset, group.allMenuStates) && "checked"}`} onClick={() => this.handlePresetPermission(groupIdx, preset)}>{preset}</label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            <div className="menu_map bg-100">
                              {
                                group.allMenuStates.filter(menuItem => menuItem.depth === 1).map((menuItem, index) => {
                                  const menuEntity = getMenuEntityByKey(menuItem.key);
                                  const menuHasGrandChildren = menuEntity.subMenus[0].subMenus; //TODO: Improve this logic
                                  return (
                                      <div key={index} className="row">
                                        <div className="col-2">
                                          <ul className="list-unstyled">
                                            <li>
                                              <button type="button"
                                                      className={`btn btn-icon btn-icon-sm accordion_menu ${menuItem.open && "open"}`}
                                                      data-targets="sub-1"
                                                      onClick={() => this.handleMenuOpenCloseAndCheckUncheck(false, groupIdx, menuItem)}>
                                              </button>
                                              {this.props.t(menuItem.key)}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className={"col-10 subs ".concat(this.subMenuClass[index])}>
                                          {
                                            menuItem.open && menuHasGrandChildren ?
                                                menuEntity.subMenus.map((subMenu) => {
                                                  const secondDepthMenu = group.allMenuStates.find(menu => menu.key === subMenu.key);
                                                  return (
                                                      <div key={subMenu.key} className="row">
                                                        <div className="col-3">
                                                          <ul className="list-unstyled">
                                                            <li className="clearfix">
                                                              {!Object.is(subMenu.subMenus, null) &&
                                                              <button type="button"
                                                                      onClick={() => this.handleMenuOpenCloseAndCheckUncheck(false, groupIdx, secondDepthMenu)}
                                                                      className={`btn btn-icon btn-icon-sm accordion_menu ${secondDepthMenu.open && "open"}`}>
                                                              </button>}
                                                              {this.props.t(subMenu.key)}
                                                              <div className="btn-group-toggle btn-group-checkbox btn-check float-right" data-toggle="buttons">
                                                                <label className={`btn-checkbox ${secondDepthMenu.checked && "checked"}`} onClick={() => this.handleMenuOpenCloseAndCheckUncheck(true, groupIdx, secondDepthMenu)}/>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                        {
                                                          secondDepthMenu.open && subMenu.subMenus &&
                                                          <div className={"col-3 subs ".concat(this.subMenu2Class[index])}>
                                                            <ul className="list-unstyled mt-30">
                                                              {
                                                                subMenu.subMenus.map(menu => {
                                                                  const depth3Menu = group.allMenuStates.find(menuItem => menuItem.key === menu.key);
                                                                  return (
                                                                      <li key={menu.key} className="clearfix">{this.props.t(menu.key)}
                                                                        <div className="btn-group-toggle btn-group-checkbox btn-check float-right" data-toggle="buttons">
                                                                          <label className={`btn-checkbox checkbox-text ${depth3Menu.checked && "checked"}`} onClick={() => this.handleMenuOpenCloseAndCheckUncheck(true, groupIdx, depth3Menu)}/>
                                                                        </div>
                                                                      </li>
                                                                  )
                                                                })
                                                              }
                                                            </ul>
                                                          </div>
                                                        }
                                                      </div>
                                                  )
                                                }) :
                                                <div className="row">
                                                  <div className="col-3">
                                                    <ul className="list-unstyled">
                                                      {
                                                        menuItem.open && menuEntity.subMenus.map((subMenu) => {
                                                          const secondDepthMenu = group.allMenuStates.find(menu => menu.key === subMenu.key);
                                                          return (
                                                              <li key={subMenu.key} className="clearfix">
                                                                {!Object.is(subMenu.subMenus, null) &&
                                                                <button type="button"
                                                                        onClick={() => this.handleMenuOpenCloseAndCheckUncheck(false, groupIdx, secondDepthMenu)}
                                                                        className={`btn btn-icon btn-icon-sm accordion_menu ${secondDepthMenu.open && "open"}`}>
                                                                </button>}
                                                                {this.props.t(subMenu.key)}
                                                                <div className="btn-group-toggle btn-group-checkbox btn-check float-right" data-toggle="buttons">
                                                                  <label className={`btn-checkbox ${secondDepthMenu.checked && "checked"}`} onClick={() => this.handleMenuOpenCloseAndCheckUncheck(true, groupIdx, secondDepthMenu)}/>
                                                                </div>
                                                              </li>
                                                          )
                                                        })
                                                      }
                                                    </ul>
                                                  </div>
                                                </div>
                                          }
                                        </div>
                                      </div>
                                  )
                                })
                              }
                            </div>
                          </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        {
                          !group.memberCount &&
                          <tr>
                            <td colSpan="2">
                              <div className="text-center mb-20 mt-10">
                                {
                                  !this.props.isModify ? this.state.groupDetail.length > 1 &&
                                      <button
                                          type="button"
                                          className={`btn btn-sm ${this.props.isModify ? "btn-auto btn-outline-success" : "btn-outline-primary"}`}
                                          onClick={() => this.onDeleteGroupClick(groupIdx)}>{this.props.t("DELETE_GROUP")}
                                      </button> :
                                      <button
                                          type="button"
                                          className={`btn btn-sm ${this.props.isModify ? "btn-auto btn-outline-success" : "btn-outline-primary"}`}
                                          onClick={() => this.onDeleteGroupClick(groupIdx)}>{this.props.t("DELETE_GROUP")}
                                      </button>
                                }
                                {
                                  !this.props.isModify &&
                                  <button
                                      type="button"
                                      className="btn btn-sm btn-primary ml-6"
                                      onClick={() => this.initGroupDetail()}>
                                    {this.props.t("ADD_GROUP")}
                                  </button>
                                }
                              </div>
                            </td>
                          </tr>
                        }
                        </tfoot>
                      </table>
                    </li>
                )
              })
            }

          </ul>
          <div className="mt-30 text-right">
            <button
                type="button"
                className="btn btn-sm btn-auto btn-outline-primary"
                onClick={(e) => this.handleListClick(e)}>{this.props.t("LIST")}
            </button>
            <button
                type="button"
                className="btn btn-sm btn-auto btn-primary ml-10"
                onClick={(e) => this.handleSaveClick(e)}>{this.props.t("SAVE")}
            </button>
          </div>
          <BasePopup
              open={this.state.saveModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleConfirmPopupClose(false)}
              body={
                <p>{this.props.isModify ? this.props.t("DO_YOU_WANT_TO_SAVE_THE_CHANGES") : this.props.t("DO_YOU_WANT_TO_REGISTER_THE_INFORMATION")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleConfirmPopupClose(true)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleConfirmPopupClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(GroupAddModifyPanel)));