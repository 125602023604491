import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {getCountriesInformation, isCountriesInformationChanged, loadCountriesInformation} from '../../ducks/countriesInformation';
import DataTableWrapperForCouponAllocate from '../datable/DataTableWrapperForCouponAllocate';
import {couponTypeCode2Key} from '../../utils/couponAttributes';
import {URL_CAMPAIGN_ALLOCATE_API, URL_CAMPAIGN_ALLOCATE_DOWNLOAD_TEMPLATE_API, URL_CAMPAIGN_ALLOCATE_UPLOAD_API, URL_CAMPAIGN_COUPON, URL_GET_COUNTRY_DATA_API, URL_GET_COUNTRY_INFORMATION_API, URL_GET_COUPON_ISSUE_DETAIL_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import Papa from 'papaparse';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import BasePopup from '../popup/BasePopup';
import {fetchCountries} from '../../utils/CountryListUtils';
import {isWafResponse} from '../../utils/CommonUtils';

function Stepitem(props) {
  return (
      <div className={`${props.colClass} align-items-center justify-content-center d-flex`}>
        <div className={props.class} title={props.title}>
          <span>{props.step}</span>
        </div>
      </div>
  )
}

class CouponAllocatePanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      couponAllocateUsersData: [],
      isUploadMode: false,
      dataLoaded: false,
      maxId: 0,
      csvFile: null,
      accountsCount: 0,
      modalAllocate: false,
      modalAccountNotValid: false
    };

    this.fileRef = React.createRef();
  }

  initData = () => {
    if (this.props.sessionData.apps.length && this.state.countryData.length && this.props.countriesInformation.length) {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_COUPON_ISSUE_DETAIL_API, {params: {couponIssueId: this.props.match.params.couponIssueId}})
          .then(response => {
            this.props.stopLoading();
            this.setState({
              dataLoaded: true,
              couponInfo: response.data
            })
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  increaseMaxId = () => {
    this.setState(prevState => {
      return {
        maxId: prevState.maxId + 1
      }
    });
  };

  addNewRow = () => {
    if (this.state.couponAllocateUsersData.length >= 10) {
      toast(this.props.t("ENTER_UP_TO_10_ACCOUNTS_CHECK_ACCOUNT"));
      return;
    }
    this.setState(prevState => {
      this.increaseMaxId();
      return {
        couponAllocateUsersData: [{
          id: this.state.maxId,
          accountName: "",
          couponCount: 0
        }, ...prevState.couponAllocateUsersData]
      }
    });
  }

  handleAddNewRow = () => {
    this.addNewRow();
  }

  removeRow = (id) => {
    this.setState(prevState => {
      return {
        couponAllocateUsersData: prevState.couponAllocateUsersData.filter(item => item.id !== id)
      }
    });
  }

  handleRemoveRow = (id) => {
    this.removeRow(id);
  }

  handleAccountNameChange = (id, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponAllocateUsersData: prevState.couponAllocateUsersData.map((item) => {
          if (item.id === id) {
            item.accountName = input;
          }
          return item;
        })
      }
    });
  };

  handleCouponCountChange = (id, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        couponAllocateUsersData: prevState.couponAllocateUsersData.map((item) => {
          if (item.id === id) {
            item.couponCount = input;
          }
          return item;
        })
      }
    });
  };

  handleDownloadTemplateClick = () => {
    handleDownloadFile(URL_CAMPAIGN_ALLOCATE_DOWNLOAD_TEMPLATE_API, {couponIssueId: this.state.couponInfo.couponIssueId}, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  handleFileChange = event => {
    this.setState({
      csvFile: event.target.files[0]
    });
  };

  updateUploadData = (result) => {
    const data = result.data;
    let parsedData = data.map((e) => {
      this.increaseMaxId();
      return {
        id: this.state.maxId,
        accountName: e["Samsung Account"],
        couponNumber: e["Coupon Number"],
        couponCount: 1,
      }
    }).filter(e => e.accountName && e.couponNumber);
    this.setState({
      couponAllocateUsersData: parsedData,
      accountsCount: parsedData.length
    });
  };

  handleUploadButtonClick = () => {
    const {csvFile} = this.state;
    Papa.parse(csvFile, {
      complete: this.updateUploadData,
      header: true,
      skipEmptyLines: true
    });
  };

  validateInputData = () => {
    let totalCouponCount = 0;
    const flag = this.state.couponAllocateUsersData.every(e => {
      const couponCount = parseInt(e.couponCount);
      if (!couponCount || couponCount <= 0) {
        return false;
      }
      if (!e.accountName || e.accountName.length === 0) {
        return false;
      }
      totalCouponCount += couponCount;
      return true;
    });
    if (totalCouponCount > this.state.couponInfo.remaining) {
      return null;
    }
    return flag;
  };

  handleAllocateButtonClick = () => {
    const valid = this.validateInputData();
    if (valid === true) {
      if (!this.state.isUploadMode && this.state.couponAllocateUsersData.length >= 10) {
        toast(this.props.t("ENTER_UP_TO_10_ACCOUNTS_CHECK_ACCOUNT"));
        return;
      }
      this.setState({modalAllocate: true});
    }
    else if (valid === false) {
      toast(this.props.t("PLEASE_FILL_IN_ALL_REQUIRED_FIELDS_CORRECTLY"));
    }
    else if (valid === null) {
      toast(this.props.t("NOT_ENOUGH_COUPONS"));
    }
  };

  allocateCoupon = () => {
    let params;
    if (!this.state.isUploadMode) {   //Simple method
      if (this.state.couponAllocateUsersData.length >= 10) {
        toast(this.props.t("ENTER_UP_TO_10_ACCOUNTS_CHECK_ACCOUNT"));
        return;
      }
      params = {
        coupnIssId: this.state.couponInfo.couponIssueId,
        coupnMappTypeCd: "SP",
        accountList: this.state.couponAllocateUsersData.map((e) => {
          return {
            account: e.accountName,
            couponQuantity: parseInt(e.couponCount),
          }
        }),
      };
    } else {                          //Manual method
      params = {
        coupnIssId: this.state.couponInfo.couponIssueId,
        coupnMappTypeCd: "ML",
        accountList: this.state.couponAllocateUsersData.map((e) => {
          return {
            account: e.accountName,
            couponNumber: e.couponNumber,
          }
        }),
      };
    }
    this.props.startLoading();
    const allocateUrl = this.state.isUploadMode ? URL_CAMPAIGN_ALLOCATE_UPLOAD_API : URL_CAMPAIGN_ALLOCATE_API;
    this.httpClient
        .post(allocateUrl, params)
        .then(response => {
          if (response.data === SUCCESS) {
            toast(this.props.t('COUPON_ALLOCATE_IN_PROGRESS'));
            this.props.history.push(URL_CAMPAIGN_COUPON);
          } else {
            toast(this.props.t('COUPON_ALLOCATE_FAILED'));
          }
          this.props.stopLoading();
        })
        .catch(error => {
          if (error.response.data.message === 'Invalid Samsung Account') {
            this.setState({modalAccountNotValid: true});
          } else if (isWafResponse(error.response)) {
            toast(this.props.t('TOO_MANY_REQUEST_TRY_AGAIN_LATER'));
          } else {
            toast(this.props.t('COUPON_ALLOCATE_FAILED'));
            handleHttpError(this.props.t, this.props.history, error);
          }
          this.props.stopLoading();
        });
  };

  initCountryData = () => {
    this.props.startLoading();
    fetchCountries(URL_GET_COUNTRY_DATA_API, this.props.t, this.props.history)
        .then(countryData => {
          this.setState({countryData: countryData});
          fetchCountries(URL_GET_COUNTRY_INFORMATION_API, this.props.t, this.props.history)
              .then((countryInformation => {
                this.props.stopLoading();
                this.props.loadCountriesInformation(countryInformation);
                this.initData();
              }));
        });
  };

  resetUploadData = () => {
    this.setState(prevState => {
      return {
        csvFile: null,
        accountsCount: 0,
      }
    });
  }

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const {couponInfo} = this.state;
    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="info-table mb-50">
            <div className="row">
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_NAME")}</strong></div>
              <div className="col-4">{couponInfo.couponName}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_ISSUE_ID")}</strong></div>
              <div className="col-4">{couponInfo.couponIssueId}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_ID")}</strong></div>
              <div className="col-4">{couponInfo.couponId}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUNTRY")}</strong></div>
              <div className="col-4">{this.props.t(couponInfo.country)}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TARGET_RANGE")}</strong></div>
              {couponInfo.productName &&
              <div className="col-4">{this.props.t("PRODUCT")}</div>
              }
              {!couponInfo.productName &&
              <div className="col-4">{this.props.t("APP")}</div>
              }
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TOTAL_ISSUED")}</strong></div>
              <div className="col-4">{couponInfo.totalIssued}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TARGET_APP")}</strong> <span className="text-muted">(ID)</span></div>
              <div className="col-4">{couponInfo.appName}<span className="text-muted">({couponInfo.cpAppId})</span></div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("REMAINING")}</strong></div>
              <div className="col-4">{couponInfo.remaining}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("TARGET_PRODUCT")}</strong> <span className="text-muted">(ID)</span></div>
              {couponInfo.productName &&
              <div className="col-4">{couponInfo.productName}<span className="text-muted">({couponInfo.cpProductId})</span></div>
              }
              {!couponInfo.productName &&
              <div className="col-4"/>
              }
              <div className="col-2 text-center bg-100"><strong>{this.props.t("VALID_PERIOD")}</strong></div>
              <div className="col-4">{couponInfo.validStartDate} ~ {couponInfo.validEndDate}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("COUPON_TYPE")}</strong></div>
              <div className="col-4">{this.props.t(couponTypeCode2Key[couponInfo.couponType])}</div>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("ISSUE_DATE")}</strong></div>
              <div className="col-4">{couponInfo.issueDate}</div>
              {couponInfo.couponType === "4" &&
              <>
                <div className="col-2 text-center bg-100"><strong>{this.props.t("FREE_TRIAL_PERIOD")}</strong></div>
                <div className="col-10">{couponInfo.freeTrialPeriod}</div>
              </>
              }
              {couponInfo.couponType === "2" &&
              <>
                <div className="col-2 text-center bg-100"><strong>{this.props.t("DISCOUNT_RATE_%")}</strong></div>
                <div className="col-10">{couponInfo.discountRate}</div>
              </>
              }{couponInfo.couponType === "1" &&
            <>
              <div className="col-2 text-center bg-100"><strong>{this.props.t("DISCOUNT_AMOUNT")} ({getCountriesInformation(this.props.countriesInformation, couponInfo.country).currency})</strong></div>
              <div className="col-10">{couponInfo.discountAmount} </div>
            </>
            }
            </div>
          </div>
          <form className="needs-validation" noValidate>
            <div className="clearfix mb-10">
              <h2 className="float-left mb-0"><strong>{this.props.t("ALLOCATE_COUPON")}</strong></h2>
            </div>
            <div className="info-table border-bottom-0 rounded-bottom-0">
              <div className="row">
                <div className="col-2 text-center d-flex align-items-center justify-content-center" data-col=""><strong>{this.props.t("COUPON_ALLOCATION_SETTING")} </strong></div>
                <div className="col-10">
                  <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                    <label className={`btn btn-radio tabs-toggle ${!this.state.isUploadMode && "checked"}`}><input type="radio" onClick={() => {
                      this.resetUploadData();
                      this.setState({isUploadMode: false})
                    }}/>{this.props.t("ENTER_ACCOUNT")}</label>
                    <label className={`btn btn-radio tabs-toggle ${this.state.isUploadMode && "checked"}`}><input type="radio" onClick={() => {
                      this.setState({isUploadMode: true})
                    }}/>{this.props.t("UPLOAD_ACCOUNT")}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-toggle-container">
              {!this.state.isUploadMode && (
                  <div className={"tabs-toggle-content show"}>
                    <div className="info-table rounded-top-0 mb-40">
                      <div className="row">
                        <div className="col-12 bg-100 border-0">
                          <div className="row">
                            <Stepitem colClass={"col-5"}
                                      class={"step_info"}
                                      title={"Step 01"}
                                      step={this.props.t("COUPON_ALLOCATE_MODE1_STEP1")}/>
                            <div className="col-2 align-items-center justify-content-center d-flex">
                              <span className="next-step"/>
                            </div>
                            <Stepitem colClass={"col-5"}
                                      class={"step_info"}
                                      title={"Step 02"}
                                      step={this.props.t("COUPON_ALLOCATE_MODE1_STEP2")}/>
                          </div>
                        </div>
                      </div>
                      <DataTableWrapperForCouponAllocate couponAllocateUsersData={this.state.couponAllocateUsersData}
                                                         handleAccountNameChange={this.handleAccountNameChange}
                                                         handleCouponCountChange={this.handleCouponCountChange}
                                                         handleAddNewRow={this.handleAddNewRow}
                                                         handleRemoveRow={this.handleRemoveRow}/>
                    </div>
                  </div>)}
              {this.state.isUploadMode && (
                  <div className={"tabs-toggle-content show"}>
                    <div className="info-table rounded-top-0 mb-40">
                      <div className="row">
                        <div className="col-12 bg-100 border-0">
                          <div className="row">
                            <div className="col-3 align-items-center justify-content-center d-flex">
                              <div className="step_download" title="Step 01">
                                <Link to={{}} className="underline" onClick={() => this.handleDownloadTemplateClick()}>{this.props.t("COUPON_ALLOCATE_MODE2_STEP1")}</Link>
                              </div>
                            </div>
                            <div className="col-1 align-items-center justify-content-center d-flex">
                              <span className="next-step"/>
                            </div>
                            <Stepitem colClass={"col-4"}
                                      class={"step_upload"}
                                      title={"Step 02"}
                                      step={this.props.t("COUPON_ALLOCATE_MODE2_STEP2")}/>
                            <div className="col-1 align-items-center justify-content-center d-flex">
                              <span className="next-step"/>
                            </div>
                            <Stepitem colClass={"col-3"}
                                      class={"step_pad"}
                                      title={"Step 03"}
                                      step={this.props.t("COUPON_ALLOCATE_MODE2_STEP3")}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered table-hover">
                      <caption>List</caption>
                      <colgroup>
                        <col width="70%"/>
                        <col width="30%"/>
                      </colgroup>
                      <thead>
                      <tr>
                        <th scope="col">{this.props.t("FILE")}</th>
                        <th scope="col">{this.props.t("NO_OF_ACCOUNTS")}</th>
                      </tr>
                      </thead>
                      <tbody className="text-center">
                      <tr>
                        <td>
                          <div className="input-group">
                            <input type="text" className="form-control bg-white text-100" value={this.state.csvFile ? this.state.csvFile.name : ""} readOnly={true} style={{border: "none", outline: "transparent", width: "100%"}} onClick={() => this.fileRef.current.click()} placeholder={this.props.t("PLEASE_UPLOAD_FILE")}/>
                            <div className="input-group-append upload-btn-wrapper">
                              <input type="file" style={{display: "none"}} onChange={this.handleFileChange} ref={this.fileRef}/>
                              <button className="btn btn-primary" disabled={this.state.csvFile === null} onClick={() => this.handleUploadButtonClick()} type="button">{this.props.t("UPLOAD")}</button>
                            </div>
                          </div>
                        </td>
                        <td>{this.state.accountsCount}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>)}
            </div>
            <div className="row mt-30">
              <div className="col-6">
              </div>
              <div className="col-6 text-right">
                <button type="button"
                        disabled={!(this.state.isUploadMode && this.state.accountsCount > 0) && this.state.isUploadMode}
                        className="btn btn-sm btn-auto btn-primary ml-10"
                        onClick={() => this.handleAllocateButtonClick()}>
                  {this.props.t("ALLOCATE")}
                </button>
              </div>
            </div>
          </form>
          <BasePopup
              open={this.state.modalAccountNotValid}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modalAccountNotValid: false})}
              body={
                <p>{this.props.t("PLEASE_CHECK_THE_ACCOUNTS_AND_TRY_AGAIN")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.setState({modalAccountNotValid: false})}>
                    {this.props.t("CONFIRM")}
                  </button>
                </>
              }/>
          <BasePopup
              open={this.state.modalAllocate}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modalAllocate: false})}
              body={
                <p>{this.props.t("WOULD_YOU_LIKE_TO_ALLOCATE_COUPONS")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => {
                    this.setState({modalAllocate: false});
                    this.allocateCoupon();
                  }}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modalAllocate: false})}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)
        || isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)) {
      this.initCountryData();
    }
  }

  componentDidMount() {
    this.initCountryData();
    this.addNewRow();
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  loadCountriesInformation: loadCountriesInformation,
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CouponAllocatePanel)));
