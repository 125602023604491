import {getAllCountriesList} from '../ducks/countriesInformation';
import {handleHttpError} from './ErrorUtils';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from './globalConstants';

export const sortCountryObjectNames = (countriesInformation, translator) => {
  let countryObjectArr = [];
  for (let country of countriesInformation) {
    countryObjectArr.push({countryName: translator(country), countryCode: country});
  }
  return countryObjectArr.sort((a, b) => (a.countryName > b.countryName) ? 1 : -1);
};

export const sortCountryNames = (countriesInformation, translator) => {
  return getAllCountriesList(countriesInformation).map(v => translator(v)).sort();
};

export const convertToCountryCode = (countriesInformation, countryName, translator) => {
  let countryListData = getAllCountriesList(countriesInformation);
  const map = new Map();
  for (let countryCode of countryListData) {
    map.set(translator(countryCode), countryCode);
  }
  if (map.has(countryName)) {
    return map.get(countryName);
  }
  return "";
};

export const fetchCountries = (url, translator, history) => {
  return new Promise((resolve, reject) => {
    axios.create({timeout: AXIOS_CLIENT_TIMEOUT})
        .get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleHttpError(translator, history, error);
          reject(error);
        });
  });
};

export const fetchTaxCategoryList = (url, translator, history) => {
  return new Promise((resolve, reject) => {
    axios.create({timeout: AXIOS_CLIENT_TIMEOUT})
        .get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          handleHttpError(translator, history, error);
          reject(error);
        });
  });
};

