import React from "react";

function Footer() {
  return (
      <footer className="d-flex justify-content-center">
        <div className="footer_wrap">
          <div className="text-200">
            <a href="https://seller.samsungapps.com/tv/contract/tnc" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/pp/global" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/pp/us" target="_blank" rel="noopener noreferrer">Privacy Policy (USA)</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/pp/eu" target="_blank" rel="noopener noreferrer">Privacy Policy (EU)</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/pp/tr" target="_blank" rel="noopener noreferrer">Privacy Notice (Turkey)</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/pp/br" target="_blank" rel="noopener noreferrer">Privacy Policy (Brazil)</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/pp/cn" target="_blank" rel="noopener noreferrer">Privacy Policy (China)</a>
            <span>|</span>
            <a href="https://seller.samsungapps.com/tv/contract/cookie" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
          </div>
          <div className="text-500">Copyright Samsung Electronics Co., Ltd. All rights reserved.</div>
        </div>
      </footer>
  )
}

export default Footer;