import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {closeCarousel, updateDefaultAppIdInCarousel, updateSelectedAppIdInCarousel} from '../../ducks/carousel';
import {Img} from 'react-image'
import empty_app from '../../images/bg-empty-app.png';
import {handleHttpError} from "../../utils/ErrorUtils";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {API_APP_SELECTION_UPDATE} from '../../utils/urlConstants';

const NUMBER_OF_LISTED_APPS = 5;

class AppList extends Component {
  constructor(props) {
    super(props);

    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      appStartIndex: 0,
    };
    this.ref = React.createRef();
  }

  handleClickAppSelect = (app) => {
    return new Promise((resolve, reject) => {
      this.httpClient
          .get(API_APP_SELECTION_UPDATE, {
            params: {cpAppId: app.appId}
          })
          .then(response => {
            this.props.updateSelectedAppIdInCarousel(app.appId, app.contsId);
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
          });
    });
  };

  moveLeft = () => {
    if (this.state.appStartIndex > 0) {
      this.setState(prevState => ({
        appStartIndex: prevState.appStartIndex - 1,
      }));
    }
  };

  moveRight = () => {
    if (this.state.appStartIndex + NUMBER_OF_LISTED_APPS < this.props.sessionData.apps.length) {
      this.setState(prevState => ({
        appStartIndex: prevState.appStartIndex + 1
      }));
    }
  };

  renderApps = () => {
    const apps = this.props.sessionData.apps;
    if (!apps) {
      return (<></>);
    }

    if (apps.length <= 0) {
      throw new Error("There must be at least one apps");
    }

    const currentAppList = apps.slice(this.state.appStartIndex, this.state.appStartIndex + NUMBER_OF_LISTED_APPS);
    const defaultAppId = this.props.carousel.defaultAppId;
    const selectedAppId = this.props.carousel.selectedAppId;

    return (
        <div className="row">
          {currentAppList.map(app => (
              <div key={app.appId} className="col-2">
                <div className="card border-0">
                  <div className={`card-img border rounded-0 ${Object.is(app.appId, selectedAppId) && "active"}`}
                       onClick={() => this.handleClickAppSelect(app)}>
                    <Img src={[app.image, empty_app]} className="img-fluid-new"/>
                  </div>
                  <div className={`card-body text-center ${Object.is(app.appId, defaultAppId) && "active"}`}>
                    <p className="text-truncate">{app.name || app.appId}</p>
                    <button
                        type="button"
                        className="btn btn-xs btn-outline-primary"
                        onClick={() => {
                          this.props.updateDefaultAppIdInCarousel(app.appId);
                        }}>
                      {this.props.t('DEFAULT_APP')}
                    </button>
                  </div>
                </div>
              </div>
          ))}
        </div>
    );
  };

  render() {
    let divClassName = "app_list";
    if (this.props.carousel.isCarouselOpen) {
      divClassName += " open";
    }

    return (
        <div className={divClassName} ref={this.ref}>
          <div className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                {this.renderApps()}
              </div>
            </div>
            {/* eslint-disable-next-line */}
            <a className="carousel-control-prev" onClick={this.moveLeft}>
              <span className="carousel-control-prev-icon" aria-hidden="true"/>
              <span className="sr-only">{this.props.t('PREV')}</span>
            </a>
            {/* eslint-disable-next-line */}
            <a className="carousel-control-next" onClick={this.moveRight}>
              <span className="carousel-control-next-icon" aria-hidden="true"/>
              <span className="sr-only">{this.props.t('NEXT')}</span>
            </a>
          </div>
          <div className="text-muted small">{this.props.t('APP_LIST_GUIDE')}</div>
        </div>
    );
  }

  handleClickOutside = (e) => {
    if (this.ref && !this.ref.current.contains(e.target) && (this.props.allAppsButtonRef.current ? !this.props.allAppsButtonRef.current.contains(e.target) : true) && this.props.carousel.isCarouselOpen) {
      this.props.closeCarousel();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  updateSelectedAppIdInCarousel: updateSelectedAppIdInCarousel,
  updateDefaultAppIdInCarousel: updateDefaultAppIdInCarousel,
  closeCarousel: closeCarousel,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AppList))