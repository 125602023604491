import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import RequestRefundPopUp from "../popup/RequestRefundPopUp";
import {withRouter} from "react-router-dom";
import {API_SALES_INVOICE_DETAIL, API_SALES_INVOICE_PRODUCT_STATUS_HISTORY, API_SALES_SINGLE_INVOICE_REFUND, URL_SALES_SALESPRODUCT} from "../../utils/urlConstants";
import {connect} from "react-redux";
import {changeSystemCode2Key, findDays, paymentMethodCode2Key, transactionCancelCode2Key} from "../../utils/invoiceAttributes";
import BasePopup from "../popup/BasePopup";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {SimpleCheckBoxRow, SimpleRow} from "../SimpleRow";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';
import {checkEmptyRefundAmountForPartialRefund, checkRefundAmountExceedPaymentAmount, checkRefundAmountValidForPartialRefund, setZeroRefundAmountForPartialRefund} from '../../utils/RefundUtils';

class TransactionDetailPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      refundModalOpen: false,
      productStatusHistoryModelOpen: false,
      currentInvoiceLoaded: false,
      invoiceDetail: null,
      productReflectionHistoryListPage: 0,
      productReflectionHistoryListSizePerPage: 0,
      totalProductReflectionHistoryListSize: 0,
      fetchedProductReflectionHistoryList: [],
      alertPopup: false
    }
  }

  handleRefundModalToggle = (isOpen) => {
    this.setState({refundModalOpen: isOpen}, () => {
      let result = findDays(new Date(this.state.invoiceDetail.orderDate), this.state.invoiceDetail.countryCode);
      this.setState({alertPopup: result});
    });
  };

  handleProductStatusHistoryModalToggle = (isOpen) => {
    this.setState({productStatusHistoryModelOpen: isOpen});
  };

  handleRequestRefundPopupClose = (isSave, reason, refundType, refundAmount, samsungRewardsRefundAmount) => {
    this.setState({refundModalOpen: false, alertPopup: false});
    if (refundType === "PARTIAL_REFUND") {
      if (checkEmptyRefundAmountForPartialRefund(refundAmount, samsungRewardsRefundAmount)) {
        toast(this.props.t("PLEASE_ENTER_THE_REFUND_AMOUNT"));
        return;
      }
      let partialRefundAmountFloat = parseFloat(refundAmount);
      let partialSamsungRewardsRefundAmountFloat = parseFloat(samsungRewardsRefundAmount);
      let paymentAmount = parseFloat(this.state.invoiceDetail.paymentAmount);
      let samsungRewardsUsedAmount = parseFloat(this.state.invoiceDetail.samsungRewardsAmount)
      if (checkRefundAmountValidForPartialRefund(partialRefundAmountFloat, partialSamsungRewardsRefundAmountFloat)) {
        toast(this.props.t("PLEASE_ENTER_VALID_REFUND_AMOUNT"));
        return;
      }
      if (checkRefundAmountExceedPaymentAmount(partialRefundAmountFloat, partialSamsungRewardsRefundAmountFloat, paymentAmount, samsungRewardsUsedAmount)) {
        toast(this.props.t("PARTIAL_REFUND_AMOUNT_GREATER_THAN_FULL_PAYMENT_AMOUNT"));
        return;
      }
    }
    const {invoiceId, buyerUId, buyerGuid, countryCode} = this.state.invoiceDetail;
    if (isSave) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_SALES_SINGLE_INVOICE_REFUND, {
              invoiceId: invoiceId,
              buyerGuid: buyerGuid,
              buyerUid: buyerUId,
              cancelDesc: reason,
              countryCode: countryCode,
              langCode: this.props.i18n.language,
              refundAmount: refundType === "FULL_REFUND" ? "" : setZeroRefundAmountForPartialRefund(refundAmount),
              samsungRewardsRefundAmount: refundType === "FULL_REFUND" ? "" : setZeroRefundAmountForPartialRefund(samsungRewardsRefundAmount)
            })
            .then((response) => {
              if (Object.is(response.data.code, '100000')) {
                toast(this.props.t("REFUND_SUCCESS"));
              } else {
                toast(response.data.name);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    }
  };

  render() {
    if (!this.state.currentInvoiceLoaded) {
      return <div/>
    }
    const {
      appName, appId, productName, cpProductId, countryCode, listPriceDisplay, currencyCode, currencySymbol, invoiceId, orderDate,
      buyer, payPalAccountAddress, paymentMethodCode, additionalPaymentInfo, finalSellPriceDisplay, taxRate, taxAmountDisplay,
      paymentAmountDisplay, orderId, showRefund, orderCancelDate, cancelDescriptionCode, cancelDetailDescription,
      cancelWorker, couponAmount, couponName, couponApplyTypeCd, couponApplyAmount, couponApplyValue, couponAmountDisplay,
      productTypeCd, dynamicProductId, dynamicProductName, invoiceOriginId, invoiceCancelId, refundAmountDisplay,
      samsungRewardsUsedAmount, samsungRewardsUsedAmountDisplay, samsungRewardsRefundAmount, samsungRewardsRefundAmountDisplay
    } = this.state.invoiceDetail;
    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t('PRODUCT_DETAILS')}</strong></h2>
            <div className="text-right float-right mt-1 mb-0">
              <a onClick={() => this.handleProductStatusHistoryModalToggle(true)} className="text-400">{this.props.t("PRODUCT_STATUS_HISTORY")}<i className="icon icon-modal align-top ml-1"/></a>
            </div>
          </div>
          <div className="form-group-wrap mb-60">
            <SimpleRow title={this.props.t("APP_NAME")} value={appName}/>
            <SimpleRow title={this.props.t("APP_ID")} value={appId}/>
            <SimpleRow title={this.props.t("REP_CNTR_PROD_NM")} value={productName}/>
            <SimpleRow title={this.props.t("CP_PRODUCT_ID")} value={cpProductId}/>
            {
                Object.is(productTypeCd, '7') &&
                <>
                  <SimpleRow title={this.props.t("DYNAMIC_PRODUCT_ID")} value={dynamicProductId}/>
                  <SimpleRow title={this.props.t("DYNAMIC_PRODUCT_NAME")} value={dynamicProductName}/>
                </>
            }

            <div className="form-group row mb-0">
              <label className="col col-form-label">{this.props.t("COUNTRY")}</label>
              <div className="col">
                <div className="mt-2">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="btn-group-toggle btn-group-checkbox" data-toggle="buttons">
                        <label className="btn-checkbox active disabled"><input type="checkbox"/></label>
                      </div>
                      <span className="ml-6 mr-10">{this.props.t(countryCode)}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <SimpleRow title={this.props.t("LIST_PRICE")} value={listPriceDisplay}/>
              </div>
              <SimpleCheckBoxRow title={this.props.t("CURRENCY")} value={`${currencyCode}` + `(${currencySymbol})`}/>
            </div>
          </div>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t('TRANSACTION_INFORMATION')}</strong></h2>
          </div>
          <div className="form-group-wrap mb-60">
            <div className="row mb-3">
              <div className="col-6">
                <SimpleRow title={this.props.t("TRANSACTION_ID")} value={invoiceCancelId ? invoiceCancelId : invoiceId}/>
              </div>
              <div className="col-6">
                <SimpleRow title={this.props.t("ORDER_DATE")} value={orderDate + " [GMT]"}/>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <SimpleRow title={this.props.t("BUYER")} value={buyer}/>
              </div>
              <div className="col-6">
                <SimpleRow title="PayPal Account" value={payPalAccountAddress}/>
              </div>
            </div>
            <div className="row">
              <SimpleCheckBoxRow title={this.props.t("PAYMENT_METHOD")} value={paymentMethodCode2Key[paymentMethodCode]}/>
              <SimpleCheckBoxRow title={this.props.t("ADDITION_PAYMENT_INFO")} value={additionalPaymentInfo}/>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <div className="form-group row">
                  <label className="col col-form-label d-flex align-items-center">{this.props.t("PAYMENT_INFO")}</label>
                  <div className="col">
                    <table className="table table-bordered table-top-border">
                      <colgroup>
                        <col width="33.4%"/>
                        <col width="33.3%"/>
                        <col width="33.3%"/>
                      </colgroup>
                      <tbody className="text-right">
                      <tr>
                        <th className="text-center bg-100">{this.props.t("BASIC_PRICE")}</th>
                        <td></td>
                        <td>{finalSellPriceDisplay}</td>
                      </tr>
                      {couponAmount !== 0.00 &&
                          <tr>
                            <th className="text-center bg-100">{this.props.t("COUPON")}<br/>{couponName}</th>
                            <td>{Object.is(couponApplyTypeCd, '1') ? Number.parseFloat(couponApplyAmount).toFixed(2) : Number.parseFloat(couponApplyValue).toFixed(2) + '%'}</td>
                            <td><span className="text-success">- {couponAmountDisplay}</span></td>
                          </tr>
                      }
                      <tr>
                        <th className="text-center bg-100">{this.props.t("TAX")}</th>
                        <td>{taxRate}%</td>
                        <td><span className="text-success">+ {taxAmountDisplay}</span></td>
                      </tr>
                      {samsungRewardsUsedAmount !== 0.00 &&
                          <tr>
                            <th className="text-center bg-100">{this.props.t("SAMSUNG_REWARDS")}</th>
                            <td></td>
                            <td><span className="text-success">- {samsungRewardsUsedAmountDisplay}</span></td>
                          </tr>
                      }
                      <tr className="bg-300">
                        <th className="text-center">{this.props.t("PAYMENT_AMOUNT")}</th>
                        <td></td>
                        <td><strong className="text-100">{paymentAmountDisplay}</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <SimpleRow title={this.props.t("CP_ORDER_ID")} value={orderId}/>
              </div>
            </div>
          </div>
          {(this.state.invoiceDetail.invoiceCancelId !== null) &&
              <RefundWrapper orderCancelDate={orderCancelDate}
                             reasonForRefund={transactionCancelCode2Key[cancelDescriptionCode]}
                             cancelDetailDescription={cancelDetailDescription}
                             cancelWorker={cancelWorker}
                             t={this.props.t}
                             refundAmount={refundAmountDisplay}
                             samsungRewardsRefundAmount={samsungRewardsRefundAmount}
                             samsungRewardsRefundAmountDisplay={samsungRewardsRefundAmountDisplay}
              />
          }
          <div className="row mt-30">
            <div className="col text-right">
              <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={(e) => this.handleListClick(e)}>{this.props.t("LIST")}</button>
              {this.state.invoiceDetail.refundButton === true &&
                  <button type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.handleRefundModalToggle(true)}>{this.props.t("REFUND")}</button>
              }
            </div>
          </div>
          <RequestRefundPopUp
              currentInvoice={this.state.invoiceDetail}
              invoiceId={this.state.invoiceDetail.invoiceId}
              buyer={this.state.invoiceDetail.buyer}
              paymentAmount={this.state.invoiceDetail.paymentAmountDisplay}
              paymentAmountNumber={this.state.invoiceDetail.paymentAmount}
              paymentMethod={paymentMethodCode2Key[this.state.invoiceDetail.paymentMethodCode]}
              additionalPaymentInfo={this.state.invoiceDetail.additionalPaymentDetail}
              open={this.state.refundModalOpen}
              alertPopup={this.state.alertPopup}
              handlePopupClose={this.handleRequestRefundPopupClose}
              samsungRewardsUsedAmount={this.state.invoiceDetail.samsungRewardsUsedAmount}
              samsungRewardsUsedAmountDisplay={this.state.invoiceDetail.samsungRewardsUsedAmountDisplay}
              key={invoiceId}
          />
          <BasePopup
              className={"modal-lg"}
              open={this.state.productStatusHistoryModelOpen}
              title={this.props.t("PRODUCT_STATUS_HISTORY")}
              onClose={() => this.handleProductStatusHistoryModalToggle(false)}
              body={
                <DataTableWrapperForListing
                    keyField="processedDate" // one of 'dataField'
                    tableStyle="table table-bordered"
                    hideSelectColumn={true}
                    columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                      {dataField: "processedDate", text: this.props.t("PROCESSED_DATE"), headerStyle: {width: "20.00000%"}, classes: () => "text-truncate"},
                      {dataField: "cpEmail", text: this.props.t("CP_EMAIL"), headerStyle: {width: "20.00000%"}, classes: () => "text-left text-truncate"},
                      {dataField: "reason", text: this.props.t("REASON"), headerStyle: {width: "20.00000%"}, classes: () => "text-truncate"},
                      {dataField: "changeSystem", text: this.props.t("CHANGE_SYSTEM"), headerStyle: {width: "20.00000%"}, classes: () => "text-left text-truncate"},
                    ]}
                    page={this.state.productReflectionHistoryListPage}
                    sizePerPage={this.state.productReflectionHistoryListSizePerPage}
                    totalSize={this.state.totalProductReflectionHistoryListSize}
                    rows={this.state.fetchedProductReflectionHistoryList.map(productReflectionHistory => {
                      return {
                        ...productReflectionHistory,
                        changeSystem: changeSystemCode2Key[productReflectionHistory.changeSystem]
                      };
                    })}
                    onTableChange={this.fetchProductReflectionHistoryList}
                    onSizePerPageChange={this.handleSizePerPageChange}
                />
              }/>
        </div>
    );
  }

  componentDidMount() {
    this.fetchInvoiceDetail(this.props.match.params.invoiceId)
        .then((invoiceId) => this.fetchProductReflectionHistoryList(invoiceId, 1, 15))
        .catch(error => {
          console.log(error);
        })
  }

  fetchProductReflectionHistoryList = (invoiceId, page, sizePerPage) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SALES_INVOICE_PRODUCT_STATUS_HISTORY, {
            params: {
              invoiceId: invoiceId,
              pageNumber: page,
              limit: sizePerPage
            }
          })
          .then(response => {
            this.setState({
              productReflectionHistoryListPage: page,
              productReflectionHistoryListSizePerPage: sizePerPage,
              totalProductReflectionHistoryListSize: response.data.records,
              fetchedProductReflectionHistoryList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  fetchInvoiceDetail = (invoiceId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SALES_INVOICE_DETAIL, {
            params: {
              invoiceId: invoiceId
            }
          })
          .then(response => {
            this.setState({
              invoiceDetail: response.data,
              currentInvoiceLoaded: true
            });
            resolve(invoiceId);
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });

  };

  handleListClick = (e) => {
    e.preventDefault();
    this.props.history.push(URL_SALES_SALESPRODUCT);
  }
}

function RefundWrapper(props) {
  return (
      <Fragment>
        <div className="clearfix mb-20">
          <h2 className="float-left mb-0"><strong>{props.t('REFUND_DETAILS')}</strong></h2>
        </div>
        <div className="form-group-wrap">
          <div className="row">
            <div className="col-6">
              <SimpleRow title={props.t("REFUND_DATE")} value={props.orderCancelDate}/>
            </div>
            <div className="col-6">
              <div className="form-group row">
                <label className="col col-form-label">{props.t("REASON_FOR") + " " + props.t("REFUND")}</label>
                <div className="col">
                  <div className="mt-2">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <div className="btn-group-toggle btn-group-checkbox" data-toggle="buttons">
                          <label className="btn-checkbox active disabled"><input type="checkbox"/></label>
                        </div>
                        <span className="ml-6 mr-10">{props.t(props.reasonForRefund)}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <SimpleRow title={props.t("REFUND_AMOUNT")} value={props.refundAmount}/>
            </div>
          </div>
          <div className="form-group row">
            <label className="col col-form-label d-flex align-items-center">{props.t("DETAIL_DESCRIPTION")}</label>
            <div className="col">
              <textarea className="form-control" readOnly="readonly" style={{minHeight: "75px"}} value={props.cancelDetailDescription}/>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {DataWrapper(props.t("REFUND_PROCESSED_BY"), props.cancelWorker)}
            </div>
          </div>
        </div>
      </Fragment>
  );
}

function DataWrapper(title, data) {
  return (
      <div className="form-group row">
        <label className="col col-form-label">{title}</label>
        <div className="col">
          <input type="text" className="form-control" value={data} readOnly="readonly"/>
        </div>
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TransactionDetailPanel)));