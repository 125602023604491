import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import 'react-toastify/dist/ReactToastify.css';
import {startLoading, stopLoading} from '../../ducks/loading';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {Link, withRouter} from 'react-router-dom';
import {URL_GET_AGREEMENT_LIST_DATA_API, URL_GET_AGREEMENT_LIST_DOWNLOAD_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';

class AgreementListPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      agreementList: [],
    }
  }

  handleDownloadClick = (contractType) => {
    const params = {
      contractType: contractType
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_AGREEMENT_LIST_DOWNLOAD_API, {
            params: params,
            responseType: 'blob'
          })
          .then(response => {
            let str = response.headers['content-disposition'].split('filename=')[1];
            const filename = str.substring(1, str.length - 2);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    })
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <DataTableWrapperForListing
              keyField="agreementId" // one of 'dataField'
              hideSelectColumn={true}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "agreementId", hidden: true},
                {dataField: "agreementName", text: this.props.t("TERMS_AND_CONDITIONS"), headerStyle: {width: "30%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "agreementDate", text: this.props.t("DATE_OF_CONSENT"), headerStyle: {width: "30%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "agreementDownloadLink", text: this.props.t("PDF"), headerStyle: {width: "30%"}, sort: true, classes: () => "text-truncate", titleHeader: "agreementName"},
              ]}
              page={1}
              sizePerPage={2}
              totalSize={2}
              disableFooter={true}
              rows={this.state.agreementList.map(agreement => {
                return {
                  ...agreement,
                  agreementDownloadLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDownloadClick(agreement.agreementId);
                            }}>
                        {this.props.t("DOWNLOAD")}
                      </Link>,
                };
              })}/>
        </div>
    );
  }

  setData = (data) => {
    let list = [{
      agreementId: this.props.t("DSA"),
      agreementName: this.props.t("DATA_SHARING_AGREEMENT"),
      agreementDate: data.dsaDate ? data.dsaDate : "-"
    }];
    if (data.dtaYN !== "N") {
      list.push({
        agreementId: this.props.t("DTA"),
        agreementName: this.props.t("EXHIBIT_B_AGREEMENT"),
        agreementDate: data.dtaDate ? data.dtaDate : "-"
      })
    }
    this.setState({
      agreementList: list
    });
  };

  fetchAgreementList = () => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_AGREEMENT_LIST_DATA_API)
          .then(response => {
            this.setData(response.data);
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
            reject(error);
          })
    });

  };

  componentDidMount() {
    this.fetchAgreementList();
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(AgreementListPanel)));
