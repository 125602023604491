import React, {Component, Fragment} from "react";
import icon_user from '../../images/icon-user.png';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {AXIOS_CLIENT_TIMEOUT, DPI_PORTAL_SERVICE_ID} from "../../utils/globalConstants";
import {URL_AFTER_SA_ACCOUNT_LOGOUT} from "../../utils/urlConstants";
import Form from "../Form";
import axios from 'axios';
import {getProperty} from '../../utils/propertiesByDomain';

class SignOutStatusPopup extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.formRef = React.createRef();
  }

  submitForm = (e) => {
    e.preventDefault();
    this.formRef.current.submit();
  }

  render() {
    const actionUrl = getProperty("samsungAccountSignOutGateUrl");
    const clientId = DPI_PORTAL_SERVICE_ID;
    const logoutUrl = getProperty("urlHome") + URL_AFTER_SA_ACCOUNT_LOGOUT;

    return (
        <Fragment>
          <div className="c_popover">
            <button type="button" className="user_info"><img src={icon_user} alt="user"/></button>
            <div className="popover bottom right">
              <div className="arrow"/>
              <div className="popover-body text-center">
                {this.props.sessionData.emailAddress}
                <hr/>
                <button className="btn btn-sm btn-gray" onClick={this.submitForm}>
                  {this.props.t('SIGNOUT')}
                </button>
              </div>
            </div>
          </div>
          <Form method="get" action={actionUrl} ref={this.formRef}>
            <input type="hidden" name="client_id" value={clientId}/>
            <input type="hidden" name="signOutURL" value={logoutUrl}/>
            <input type="hidden" name="state" value={this.props.sessionData.csrfToken}/>
          </Form>
        </Fragment>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
  };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(SignOutStatusPopup)));