import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from '../../utils/globalConstants';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {URL_GET_STATISTICS_CAMPAIGN_SALES_API, URL_GET_STATISTICS_CAMPAIGN_SALES_DOWNLOAD_API, URL_GET_STATISTICS_COUPON_NAMELIST_API} from '../../utils/urlConstants';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import {convertIntoDaily, convertIntoWeeks, convertToCouponId, endDateString, isDurationLessThan1Month, startDateString, typeDetails} from '../../utils/statisticsUtils';
import Table from '../statistics/Table';
import Graph from '../statistics/Graph';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import {toast} from 'react-toastify';
import {handleHttpError} from '../../utils/ErrorUtils';

class CampaignStatisticsPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      totalIssued: "0",
      totalAllocated: "0",
      totalUsed: "0",
      couponList: [],
      activeInterval: "MONTHLY",
      rowData: {},
      searchParameters: {},
      dataLoaded: false
    }
  }

  handleSelectInterval = (interval) => {
    this.setState({activeInterval: interval}, () => this.search());
  };

  handleDownloadClick = () => {
    const {searchParams} = this.state;
    handleDownloadFile(URL_GET_STATISTICS_CAMPAIGN_SALES_DOWNLOAD_API, {...searchParams, interval: this.state.activeInterval},
        this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  fetchCouponList = () => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_COUPON_NAMELIST_API, {
            params: {cpAppId: this.props.carousel.selectedAppId}
          })
          .then(response => {
            const couponList = response.data.data;
            if (couponList.length > 0) {
              this.setState({
                couponList: couponList,
                selectedCoupon: couponList[0]
              }, () => {
                resolve()
              });
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    });
  };

  handleSearchButtonClick = (searchParameters) => {
    if (isDateFutureThan(searchParameters.startDate, searchParameters.endDate)) {
      toast(this.props.t("CHECK_DATES"));
      return;
    }
    const params = {
      couponId: searchParameters.selectedFilter[0] ? searchParameters.selectedFilter[0].value : this.state.couponList[0],
      dateStart: searchParameters.startDate,
      dateEnd: searchParameters.endDate
    };
    this.setState({
      selectedCoupon: params.couponId
    });
    if (params.couponId) {
      params.couponId = convertToCouponId(params.couponId);
    }
    this.setState({
      searchParams: params,
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.interval = this.state.activeInterval;
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_STATISTICS_CAMPAIGN_SALES_API, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              dataLoaded: true,
              rowData: this.getValues(response.data.dataList, this.state.activeInterval),
              totalIssued: response.data.totalIssued,
              totalAllocated: response.data.totalAllocated,
              totalUsed: response.data.totalUsed,
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  getValues = (input, interval) => {
    let values = [];
    if (input ? input.length > 0 : false) {
      input.forEach(e => {
        if (interval === "MONTHLY") {
          values.push([e.period, e.totalAllocated, e.totalUsed, e.totalUsageRate]);
        } else if (interval === "DAILY") {
          values.push([convertIntoDaily(e.period), e.totalAllocated, e.totalUsed, e.totalUsageRate]);
        } else {
          values.push([convertIntoWeeks(e.period), e.totalAllocated, e.totalUsed, e.totalUsageRate]);
        }
      });
      return values;
    } else {
      return values;
    }
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const typeDetail = typeDetails["CAMPAIGN_STATISTICS"];
    const tableRows = [{id: "selectedCoupon", header: "COUPON_NAME", value: this.state.selectedCoupon},
      {id: "totalIssued", header: "STATISTICS_TOTAL_ISSUED", value: this.state.totalIssued},
      {id: "totalAllocated", header: "STATISTICS_TOTAL_ALLOCATIED", value: this.state.totalAllocated},
      {id: "totalUsed", header: "STATISTICS_TOTAL_USED", value: this.state.totalUsed}];
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, this.props.carousel.selectedAppId));

    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              datePanel={{
                show: true,
                label: this.props.t("CAMPAIGN_PERIOD"),
                defaultDuration: durationKey.DURATION_YEAR,
                showSearch: false,
                maxDate: true
              }}
              keywordPanel={{show: false}}
              filterPanel={{
                show: true,
                showSearch: true,
                label: this.props.t("COUPON_NAME"),
                filters: [
                  {key: "COUPON", value: this.state.couponList, defaultValue: this.state.couponList[0]},
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchButtonClick}
              onDownloadClick={() => {
              }}/>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{selectedApp.name + this.props.t("CAMPAIGN_STATISTICS_HEADER")}</strong></h2>
          </div>
          <Table data={tableRows}/>
          <Graph typeDetails={typeDetail}
                 showTypeSwitcher={false}
                 activeInterval={this.state.activeInterval}
                 handleSelectInterval={this.handleSelectInterval}
                 handleDownloadClick={this.handleDownloadClick}
                 isFooter={true}
                 footer={
                   <>
                     {this.props.t("CAMPAIGN_STATISTICS_1")}
                   </>}
                 isMonthly={isDurationLessThan1Month(this.state.searchParams.dateStart, this.state.searchParams.dateEnd)}
                 rows={this.state.rowData}
          />
        </div>
    );
  }

  componentDidMount() {
    this.fetchCouponList()
        .then(() => this.handleSearchButtonClick({
          startDate: startDateString,
          endDate: endDateString,
          selectedFilter: [{value: this.state.selectedCoupon}]
        }))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.fetchCouponList()
          .then(() => this.handleSearchButtonClick({
            startDate: startDateString,
            endDate: endDateString,
            selectedFilter: [{value: this.state.selectedCoupon}]
          }))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CampaignStatisticsPanel)));
