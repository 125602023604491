import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import BasePopup from './BasePopup';
import PayoutStatusListTable from "../datable/PayoutStatusListTable";
import {bizModelCode2Key} from "../../utils/settlementReportAttributes";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {API_SETTLEMENT_PAYOUT_REPORT, API_SETTLEMENT_PAYOUT_REPORT_DOWNLOAD} from "../../utils/urlConstants";
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';
import {connect} from 'react-redux';

class PayoutStatusPopup extends Component {
  constructor(props) {
    super(props);

    this.httpClient = axios.create({timeOut: AXIOS_CLIENT_TIMEOUT})
    this.state = {
      payoutStatusData: []
    }
  }

  fetchPayoutStatusData = (settlementMonth) => {
    // TODO should be changed to ajax code
    return new Promise((resolve, reject) => {
      this.httpClient.get(API_SETTLEMENT_PAYOUT_REPORT, {
        params: {dateStart: settlementMonth}
      }).then(response => {
        this.setState({payoutStatusData: response.data})
      })
    });
  }

  componentDidMount() {
    this.fetchPayoutStatusData(this.props.searchFilter);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      this.fetchPayoutStatusData(this.props.searchFilter);
    }
  }

  render() {
    if (this.state.payoutStatusData.length === 0) {
      return <div></div>;
    }

    return (
        <BasePopup
            className={"modal-xl"}
            open={this.props.open}
            title={this.props.t("PAYMENT_STATUS")}
            onClose={() => this.props.handlePopupClose(false)}
            body={
              <PayoutStatusListTable
                  columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                    {dataField: "bizModelDisplay", text: this.props.t("TAX_HANDLING_TYPE")},
                    {dataField: "targetDate", text: this.props.t("SETTLEMENT_PERIOD")},
                    {dataField: "expectedPayoutDate", text: this.props.t("EXPECTED_PAYOUT_DATE")},
                    {dataField: "paymentCurrencyCode", text: this.props.t("PAYOUT_CURRENCY")},
                    {dataField: "priceStandard", text: this.props.t("CURRENCY_TYPE")},
                    {dataField: "baseExpectedPayoutAmount", text: this.props.t("GROSS_PAYOUT_AMOUNT")},
                    {dataField: "baseRemittanceFeeAmount", text: this.props.t("WIRE_TRANSFER_FEE")},
                    {dataField: "baseRemittanceFailFeeAmount", text: this.props.t("FAILED_WIRE_TRANSFER_FEE")},
                    {dataField: "baseActualExpectedPayoutAmount", text: this.props.t("NET_PAYOUT_AMOUNT")}
                  ]}
                  rows={this.state.payoutStatusData.map(payout => {
                    return {
                      ...payout,
                      bizModelDisplay: this.props.t(bizModelCode2Key[payout.bizModelCode]),
                      priceStandard: payout.priceStandard,
                      targetDate: <>{payout.firstPaymentDate}<br/>~<br/> {payout.lastPaymentDate}</>
                    };
                  })}
                  onTableChange={this.fetchPayoutStatusData}
              />
            }
            footerClass={"d-block"}
            footer={
              <div className="row">
                <div className="col text-muted">
                  ※ Please note that the minimum payout amount is 500 USD or 500,000 KRW.<br/>
                  ※ Please refer to Settlement/Sales Report about detailed payout.
                </div>
                <div className="col text-right">
                  <button type="button" className="btn btn-outline-primary" onClick={(e) => this.onDownloadPayoutStatusClick(this.props.searchFilter, e)}>Download</button>
                </div>
              </div>
            }/>
    )
  };

  onDownloadPayoutStatusClick = (settlementMonth, e) => {
    e.preventDefault();
    let params = {
      dateStart: settlementMonth
    };
    handleDownloadFile(API_SETTLEMENT_PAYOUT_REPORT_DOWNLOAD, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  }
}

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(0, mapDispatchToProps)(withTranslation()(PayoutStatusPopup));